import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: "relative",
    background: "#292932",
    transition: "all 1s ease-out",
    overflow: "hidden",
    borderRadius: 10,
    minHeight: "80vh",
    display: "grid",
    flexDirection: "row",
    padding: 45,
    gridTemplateColumns: "100%",
    justifyItems: "center",
    alignItems: "center",
    [theme.breakpoints.down("lg")]: {
      justifyContent: "center",
      alignItems: "center",
    },
    [theme.breakpoints.down("sm")]: {
      padding: 0,
    },
  },
  localMediaPlayerContainer: {
    width: 88,
    height: 128,
    position: "absolute",
    zIndex: 20,
    top: 45,
    right: 45,
    borderRadius: 12,
    overflow: "hidden",
    [theme.breakpoints.down("md")]: {
      width: 88,
      height: 128,
      top: 20,
      right: 20,
    },
  },
  drawerOpenWrapper: {
    display: "flex",
  },
  videCallWrapper: {
    position: "relative",
    background: "#292932",
    minHeight: "80vh",
    width: "100%",
    margin: "auto",
    alignSelf: "flex-start",
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, minmax(320px, 1fr))",
    justifyItems: "center",
    alignItems: "center",
  },
  vid: {
    position: "relative",
    backgroundColor: "black",
    height: "100%",
    width: "100%",
  },
  bottomTabBar: {
    width: "100%",
    position: "absolute",
    bottom: 0,
    left: 0,
    backgroundColor: "rgba(20, 20, 20, 0.58)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: 10,
    paddingBottom: 16,
  },
  transparentBottomBar: {
    flexDirection: "column",
    backgroundColor: "transparent",
    paddingBottom: 20,
  },
  controlButton: {
    zIndex: 12,
    color: "white",
    cursor: "pointer",
    fontSize: 60,
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "0 14px",
  },
  chatIcon: {
    paddingLeft: "20%",
  },
  remoteMediaPlayerContainer: {
    position: "absolute",
    width: "100%",
    height: "100%",
    backgroundColor: "transparent",
  },
  closeIconContainer: {
    zIndex: 12,
    position: "absolute",
    top: 90,
    left: 90,
    [theme.breakpoints.down("sm")]: {
      top: 20,
      left: 20,
    },
  },
  participantNameContainer: {
    position: "absolute",
    background: "#1E1F20",
    borderRadius: "0px 10px 10px 0px",
    bottom: 0,
    left: 0,
    padding: "4px 8px",
    "& span": {
      fontWeight: 500,
      fontSize: 14,
      lineHeight: "20px",
    },
  },
  participantVideCallWrapper: {
    position: "relative",
    background: "#292932",
    minHeight: "80vh",
    width: "100%",
  },
  usersList: {
    marginTop: 40,
  },
  usersItemAvatar: {
    width: 77,
    height: 77,
    borderRadius: "50%",
    marginRight: 13,
    marginLeft: 13,
    cursor: "pointer",
  },
  selectedAvatar: {
    border: "2px #FFB3AB solid",
  },
  zeroWidth: {
    width: 0,
    height: 0,
    opacity: 0,
    zIndex: -100,
  },
}));

export default useStyles;
