import { createSlice } from "@reduxjs/toolkit";
import Service from "./service";
import { showSuccessSnack } from "../snackbar/snackBarSlice";

export const slice = createSlice({
  name: "user",
  initialState: {
    events: [],
    historyEvents: [],
    recordedEvents: [],
    bookings: [],
    loading: true,
  },
  reducers: {
    setEvents: (state, action) => {
      state.events = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setHistoryEvents: (state, action) => {
      state.historyEvents = action.payload;
    },
    setRecordedEvents: (state, action) => {
      state.recordedEvents = action.payload;
    },
    setBookings: (state, action) => {
      state.bookings = action.payload;
    },
  },
});

export const {
  setEvents,
  setBookings,
  setHistoryEvents,
  setLoading,
  setRecordedEvents,
} = slice.actions;

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched
export const getUpcomingEvents = (uid) => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    const filter = { time: "upcoming" };
    const events = await Service.getEvents(uid, filter);
    const purchased = await Service.getPurchasedEvents();

    if (purchased.length)
      purchased.reduce(
        (acc, e) =>
          events.forEach((i) =>
            i.id === e.EventId ? (i.refundRequested = e.refundRequested) : null
          ),
        []
      );
    dispatch(setEvents(events));
    dispatch(setLoading(false));
  } catch (e) {
    if (e.response) {
      console.log(e?.response?.data || e.message);
    }
    console.log(e);
    dispatch(setLoading(false));
    dispatch(setEvents([]));
  }
};
export const getHistoryEvents = (uid) => async (dispatch) => {
  try {
    const filter = { time: "history" };
    const events = await Service.getEvents(uid, filter);
    dispatch(setHistoryEvents(events));
  } catch (e) {
    if (e.response) {
      console.log(e?.response?.data || e.message);
    }
    console.log(e);
    dispatch(setHistoryEvents([]));
  }
};

export const getRecordedEvents = (uid) => async (dispatch) => {
  try {
    const events = await Service.getRecordedEvents(uid);
    dispatch(setRecordedEvents(events));
  } catch (e) {
    if (e.response) {
      console.log(e?.response?.data || e.message);
    }
    console.log(e);
    dispatch(setRecordedEvents([]));
  }
};
export const getBookings = () => async (dispatch) => {
  try {
    const bookings = await Service.getBookings();
    dispatch(setBookings(bookings));
  } catch (e) {
    if (e.response) {
      console.log(e?.response?.data || e.message);
    }
    console.log(e);
    dispatch(setBookings([]));
  }
};

export const saveEvent = () => async (dispatch) => {
  await Service.saveEvent();
};

export const requestEventRefund = (eventId) => async (dispatch) => {
  try {
    await Service.requestEventRefund({ eventId });
    return dispatch(showSuccessSnack("Refund request sent!"));
  } catch (e) {
    console.log(e);
  }
};

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`

const emptyArray = [];

export const selectEvents = (state) => state.user.events || emptyArray;
export const selectLoading = (state) => state.user.loading;
export const selectBookings = (state) => state.user.bookings || emptyArray;
export const selectHistoryEvents = (state) =>
  state.user.historyEvents || emptyArray;
export const selectRecordedEvents = (state) =>
  state.user.recordedEvents || emptyArray;

export default slice.reducer;
