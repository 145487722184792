/** @jsx jsx */
import { jsx } from "@emotion/core";
import { classes } from "./styles";

const SeeButton = ({ children, styles, onClick }) => {
  return (
    <button css={[classes.button, styles]} onClick={onClick}>
      {children}
    </button>
  );
};

export default SeeButton;
