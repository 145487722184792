import axios from "axios";

class Service {
  static async getEvents(uid, { time }) {
    const res = await axios.get(
      `/api/user/events?id=${uid}&time=${time}&onlyUpcoming=true`
    );
    return res.data;
  }
  static async getRecordedEvents(uid) {
    const res = await axios.get(`/api/user/recorded-events?id=${uid}`);
    return res.data;
  }

  static async sendVerificationCode() {
    const res = await axios.get(`/api/user/send-verification-code`);
    return res.data;
  }
  static async verifyCode(code) {
    const res = await axios.get(`/api/user/verify-code?code=${code}`);
    return res.data;
  }

  static async getUserDetails() {
    const res = await axios("/api/user/current");
    return res.data.userDetails;
  }

  static async getBookings() {
    const res = await axios.get(`/api/user/bookings`);
    return res.data;
  }

  static async saveEvent() {
    try {
      const res = await axios.post("/api/event");
      console.log(res.data);
    } catch (e) {
      console.log(e.response);
    }
  }

  static async updateUser(user) {
    const res = await axios.put("/api/user", { data: user });
    return res.data.userDetails;
  }
  static async createEventNotification(eventId) {
    const res = await axios.post("/api/notification/event-notification", {
      eventId,
    });
    return res.data;
  }
  static async getZoomMeetingDetails(zoomMeetingId) {
    const res = await axios.get(
      "/api/event/zoomEvent?zoomMeetingId=" + zoomMeetingId
    );
    return res.data;
  }

  static async makePayment(
    amount,
    customerId,
    paymentMethodId,
    email,
    connectedAccountId,
    webinarId
  ) {
    const res = await axios.get(
      `/api/booking/makePayment?amount=${amount}&customerId=${customerId}&paymentMethodId=${paymentMethodId}&email=${email}&connectedAccountId=${connectedAccountId}&webinarId=${webinarId}`
    );
    return res.data;
  }
  static async getPaymentCards(id) {
    const res = await axios.get(`/api/booking/paymentCards?customerId=${id}`);
    return res.data.paymentMethods.data;
  }
  static async setupPurchaseCard(customerId) {
    const res = await axios.get(
      `/api/booking/setupCard?customerId=${customerId}`
    );
    return res.data;
  }
  static async saveConnectedAccountId(authCode, token) {
    const res = await axios.post(
      `/api/booking/saveConnectedAccountId`,
      authCode,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res.data;
  }
  static async getStripeDashboardUrl(connectedAccountId) {
    const res = await axios.get(
      `/api/booking/dashboardUrl?connectedAccountId=${connectedAccountId}`
    );
    return res.data;
  }

  static async requestEventRefund(eventId) {
    const res = await axios.put("/api/event/requestRefund", eventId);
    return res.data;
  }

  static async getPurchasedEvents() {
    const res = await axios.get(`/api/event/purchasedEvents`);
    return res.data.events;
  }
}

export default Service;
