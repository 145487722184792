import React, { useState, useEffect } from "react";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import {
  setupPurchaseCard,
  selectCardSetupClientSecret,
  selectLoadingCardSetup,
  selectUserDetails,
  selectSetupIntent,
} from "../../features/auth/authSlice";
import CardSection from "../../components/CardSection/CardSection";
import { useDispatch, useSelector } from "react-redux";
import styles from "./AddCardForm.module.css";
import { useHistory } from "react-router";
import ArrowBackIosIcon from "../../assets/icons/Arrow-Left-Grey.svg";
import CircularProgress from "@material-ui/core/CircularProgress";
import { showSuccessSnack } from "../../features/snackbar/snackBarSlice";
import Layout from "../Layout/Layout";
import Grid from "@material-ui/core/Grid";
import { Container } from "react-bootstrap";
import useClasses from "../PaymentForm/styles";

const AddCardForm = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const stripe = useStripe();
  const elements = useElements();
  const clientSecret = useSelector(selectCardSetupClientSecret);
  const setupIntent = useSelector(selectSetupIntent);
  const userDetails = useSelector(selectUserDetails);
  const isLoading = useSelector(selectLoadingCardSetup);
  const [errorMessage, setErrorMessage] = useState("");
  const [isSavingCard, setSavingCard] = useState(false);
  const classes = useClasses();

  useEffect(() => {
    dispatch(setupPurchaseCard(userDetails.stripeCustomerId));
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) {
      return;
    }
    if (clientSecret) {
      setSavingCard(true);
      const result = await stripe.confirmCardSetup(clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement),
          billing_details: {
            name: userDetails.displayName,
          },
        },
      });

      if (result.error) {
        setErrorMessage(result.error.message);
        setSavingCard(false);
      } else {
        dispatch(showSuccessSnack("Added new card successfully"));
        history.goBack();
      }
    }
  };

  return (
    <Layout>
      <Container fluid="lg" className={classes.container}>
        <form onSubmit={handleSubmit} className={styles.AddCardForm}>
          {isLoading ? (
            <CircularProgress />
          ) : clientSecret ? (
            <>
              <h1 className={styles.AddCardTitle}>Add Card</h1>
              <CardSection setErrorMessage={setErrorMessage} />
              {errorMessage && (
                <p className={styles.ValidationErrorMessage}>{errorMessage}</p>
              )}
              {isSavingCard ? (
                <h1 className={styles.SavingCardStatusLabel}>Saving...</h1>
              ) : (
                <button disabled={!stripe} className={styles.SaveBtn}>
                  Save
                </button>
              )}
            </>
          ) : (
            <p className={styles.ErrorMessage}>
              Something went wrong, please try again later
            </p>
          )}
        </form>
      </Container>
    </Layout>
  );
};

export default AddCardForm;
