import React, { Component } from "react";
import "./Dropzone.css";
import UploadIcon from "../../../assets/icons/UploadIcon.svg";
import CheckIcon from "@material-ui/icons/Check";

import clsx from "clsx";
import Typography from "@material-ui/core/Typography";
import Icon from "../../Icon/Icon";
import CloseIcon from "../../../assets/icons/Close.svg";

class Dropzone extends Component {
  constructor(props) {
    super(props);
    this.state = { highlight: false };
    this.fileInputRef = React.createRef();

    this.openFileDialog = this.openFileDialog.bind(this);
    this.onFilesAdded = this.onFilesAdded.bind(this);
    this.onDragOver = this.onDragOver.bind(this);
    this.onDragLeave = this.onDragLeave.bind(this);
    this.onDrop = this.onDrop.bind(this);
  }

  openFileDialog() {
    if (this.props.disabled) return;
    this.fileInputRef.current.click();
  }

  onFilesAdded(evt) {
    if (this.props.disabled) return;
    const files = evt.target.files;
    if (this.props.onFilesAdded) {
      const array = this.fileListToArray(files);
      this.props.onFilesAdded(array);
    }
  }

  onDragOver(event) {
    event.preventDefault();
    if (this.props.disabled) return;
    this.setState({ highlight: true });
  }

  onDragLeave(event) {
    this.setState({ highlight: false });
  }

  onDrop(event) {
    event.preventDefault();
    if (this.props.disabled) return;
    const files = event.dataTransfer.files;
    if (this.props.onFilesAdded) {
      const array = this.fileListToArray(files);
      this.props.onFilesAdded(array);
    }
    this.setState({ highlight: false });
  }

  fileListToArray(list) {
    const array = [];
    for (var i = 0; i < list.length; i++) {
      array.push(list.item(i));
    }
    return array;
  }

  render() {
    return (
      <div className={this.props.className}>
        <div className={clsx("mb-2")}>
          <Typography
            variant="caption"
            color="textPrimary"
            style={{ fontWeight: 600 }}
          >
            {this.props.label?.toUpperCase()}
          </Typography>
        </div>
        <div
          className={`Dropzone ${this.state.highlight ? "Highlight" : ""}`}
          onDragOver={this.onDragOver}
          onDragLeave={this.onDragLeave}
          onDrop={this.onDrop}
          onClick={this.openFileDialog}
          style={{ cursor: this.props.disabled ? "default" : "pointer" }}
        >
          <input
            ref={this.fileInputRef}
            className="FileInput"
            type="file"
            accept={this.props.accept}
            onChange={this.onFilesAdded}
          />
          {this.props.disabled && (
            <div className="Remove-File-Icon">
              <Icon
                src={CloseIcon}
                backgroundColor="white"
                width={38}
                height={38}
                imgWidth={18}
                imgHeight={18}
                onClick={this.props.onClose}
              />
            </div>
          )}
          {!this.props.disabled ? (
            <img alt="upload" className="Icon" src={UploadIcon} />
          ) : (
            <CheckIcon color="secondary" fontSize="large" />
          )}
        </div>
      </div>
    );
  }
}

export default Dropzone;
