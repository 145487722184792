import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import styles from "./ScheduleWebinar.module.css";
import Layout from "../../components/Layout/Layout";
import { Formik } from "formik";
import Form from "react-bootstrap/Form";
import ArrowBackIosIcon from "../../assets/icons/ArrowBlack.svg";
import * as yup from "yup";
import {
  deleteEvent,
  saveEvent,
  selectEventSaveLoading,
  updateEvent,
} from "./eventSlice";
import AutocompleteInput from "../../components/AutocompleteInput/AutocompleteInput";
import Dropzone from "../../components/Upload/dropzone/Dropzone";
import Service from "./service";
import { useHistory } from "react-router";
import { selectUser, selectUserDetails } from "../auth/authSlice";
import { showErrorSnack, showSuccessSnack } from "../snackbar/snackBarSlice";
import "react-calendar/dist/Calendar.css";
import addMinutes from "date-fns/addMinutes";
import format from "date-fns/format";
import { minutesToHHmm } from "../../utils/time";
import Container from "@material-ui/core/Container";
import Card from "@material-ui/core/Card";
import Icon from "../../components/Icon/Icon";
import Step from "../../components/Navigation/Step/Step";
import clsx from "clsx";
import Typography from "@material-ui/core/Typography";
import TextField from "../../components/TextField/TextField";
import Grid from "@material-ui/core/Grid";
import PrimaryButton from "../../components/material/Button/PrimaryButton";
import InputTimePicker from "../../components/TimePicker/InputTimePicker";
import PricingCheckBox from "../../components/PricingCheckBox/PricingCheckBox";
import LinearProgress from "@material-ui/core/LinearProgress";
import Alert from "@material-ui/lab/Alert";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import CenteredBox from "../../components/Layout/CenteredBox";
import SecondaryButton from "../../components/material/Button/SecondaryButton";
import VideoIcon from "../../assets/icons/Video.svg";
import { useMediaQuery, useTheme } from "@material-ui/core";
import bookModalStyles from "../../components/BookModal/BookModal.module.css";

const MAX_SIZE = 25;

const generalInformationSchema = yup.object({
  name: yup.string().required(),
  seatsAmount: yup.number().required().moreThan(0),
});
const webinarDetailsSchema = yup.object({
  description: yup.string().required(),
});

const pricingSchema = yup.object({
  seatsAmount: yup.number().required(),
  price: yup.number().moreThan(0).required(),
});
const freePricingSchema = yup.object({
  seatsAmount: yup.number().required(),
});

export function ScheduleWebinar() {
  const dispatch = useDispatch();
  const loading = useSelector(selectEventSaveLoading);
  const userDetails = useSelector(selectUserDetails);
  const user = useSelector(selectUser);

  const [startDate, setStartDate] = useState(new Date());
  const [selectedTime, selectTime] = useState("");
  const [duration, setDuration] = useState(60);
  const [step, setStep] = useState(0);
  const [paidChecked, setPaidChecked] = useState(false);
  const [name, setName] = useState("");
  const [day, setDay] = useState("");
  const [description, setDescription] = useState("");
  const [seatsAmount, setSeatsAmount] = useState(10);
  const [tags, setTags] = useState([]);
  const [category, setCategory] = useState(userDetails.Category);
  const [videoIntro, setVideoIntro] = useState(null);
  const [videoIntroLoading, setVideoIntroLoading] = useState(false);
  const history = useHistory();

  const onFirstStepSubmit = ({ name, seatsAmount }) => {
    const day = format(startDate, "MM/dd/yyyy");
    setName(name);
    setDay(day);
    setSeatsAmount(seatsAmount);
    setStep(2);
  };
  const theme = useTheme();
  const smDownMatch = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    document.title = "Host a Hangout";
  }, []);

  const onSecondStepSubmit = ({ description }) => {
    setDescription(description);
    setStep(3);
  };

  const onSubmit = ({ price, seatsAmount }) => {
    let event = {
      name,
      description,
      tags,
      startDate,
      endDate: addMinutes(startDate, duration),
      type: "Webinar",
      day,
      duration,
      seatsAmount,
      price,
      timeInMinutes: selectedTime,
    };

    console.log("EVENT", event);
    dispatch(saveEvent(event)).then((res) => {
      const { type, payload } = res;
      if (type === "event/setEvent") {
        setVideoIntroLoading(true);
        Service.saveAndCompressIntro(
          videoIntro,
          `videos/events/${payload.id}/intro/intro`
        )
          .then(({ videoIntro, videoIntroThumb }) => {
            setVideoIntroLoading(false);
            const dateToUpdate = {
              id: payload.id,
              intro: videoIntro,
              introThumb: videoIntroThumb,
            };
            dispatch(updateEvent(dateToUpdate));
            history.push("/profile");

            dispatch(showSuccessSnack("Hangout is posted successfully"));
          })
          .catch((e) => {
            setVideoIntroLoading(false);
            dispatch(deleteEvent(payload.id));
          });
      }
    });
  };

  const onIntroAdded = ([file]) => {
    console.log("File = ", file);

    if (Math.floor(file?.size / 1e6) > MAX_SIZE) {
      dispatch(
        showErrorSnack("The uploaded file must be video and less than 25mb")
      );
    } else if (!file?.type.startsWith("video/")) {
      dispatch(
        showErrorSnack(
          "File type is not supported. Please upload a valid video file"
        )
      );
    } else {
      setVideoIntro(file);
    }
  };

  const isFirstStepButtonValid = () => {
    return startDate && selectedTime && tags.length;
  };

  const isSecondStepButtonValid = () => {
    return videoIntro;
  };

  const handleBackButton = () => {
    if (step !== 0) {
      setStep(step - 1);
    } else {
      history.goBack();
    }
  };

  const onTimeSelect = (time) => {
    if (time) {
      const mmHH = minutesToHHmm(time);
      const updatedDate = startDate;
      updatedDate.setHours(mmHH.hh, mmHH.mm, 0, 0);
      setStartDate(updatedDate);
      selectTime(time);
    }
  };

  return (
    <Layout style={{ paddingTop: 0 }}>
      <div style={{ width: "100%" }}>
        {step === 0 ? (
          <div
            className="d-flex flex-column align-items-center justify-content-center"
            style={{ height: "calc(100vh - 80px)" }}
          >
            <Icon
              src={VideoIcon}
              backgroundColor="#FFB3AB"
              width={94}
              height={94}
              imgWidth={40}
              imgHeight={19}
            />
            <Typography
              variant={"subtitle2"}
              className={clsx(styles.Subtitle, "mt-4")}
            >
              Host a Hangout
            </Typography>
            <Typography
              variant="caption"
              className={clsx(styles.Subtitle_2, "mt-4 mb-3")}
              color="textSecondary"
            >
              Click on the button below to enter hangout details
            </Typography>
            <div className="mt-3">
              <PrimaryButton
                variant="contained"
                color="secondary"
                className={styles.ScheduleButton}
                onClick={() => {
                  window.scrollTo(0, 0);
                  setStep(step + 1);
                }}
              >
                Schedule
              </PrimaryButton>
            </div>
          </div>
        ) : (
          <Container
            maxWidth="md"
            className={smDownMatch && step !== 0 ? styles.SmContainer : null}
          >
            <Card className={styles.Card}>
              <div className="d-flex justify-content-between">
                <Icon
                  src={ArrowBackIosIcon}
                  width={62}
                  height={62}
                  onClick={handleBackButton}
                  backgroundColor="#fff"
                  imgWidth={28}
                />
                <div
                  className={clsx(
                    "d-flex align-items-center justify-content-between",
                    styles.Stepper
                  )}
                >
                  <Step mode={step === 1 ? "active" : "disabled"} />
                  <Step
                    mode={
                      step === 1
                        ? "inactive"
                        : step === 2
                        ? "active"
                        : "disabled"
                    }
                  />
                  <Step
                    mode={step === 1 || step === 2 ? "inactive" : "active"}
                  />
                </div>
              </div>
              <div
                className={styles.GridContainer}
                style={{ display: step === 1 ? "block" : "none" }}
              >
                <Grid
                  container
                  direction="column"
                  spacing={1}
                  alignContent="center"
                >
                  <Grid
                    md={6}
                    sm={12}
                    xs={12}
                    lg={6}
                    item
                    className={styles.GridCell}
                  >
                    <Typography
                      variant="h4"
                      color="textPrimary"
                      className="mb-3"
                    >
                      General Information
                    </Typography>
                  </Grid>
                  <Grid
                    md={6}
                    sm={12}
                    xs={12}
                    lg={6}
                    item
                    className={styles.GridCell}
                  >
                    <Formik
                      onSubmit={onFirstStepSubmit}
                      initialValues={{
                        name: "",
                        seatsAmount: 10,
                      }}
                      validationSchema={generalInformationSchema}
                    >
                      {({
                        handleSubmit,
                        handleChange,
                        handleBlur,
                        values,
                        touched,
                        isValid,
                        errors,
                      }) => (
                        <Form
                          className="mt-2 w-100"
                          noValidate
                          onSubmit={handleSubmit}
                        >
                          <TextField
                            className="mt-3"
                            label="Hangout name"
                            name="name"
                            darkMode
                            isInvalid={errors.name}
                            onChange={handleChange}
                          />
                          <InputTimePicker
                            startDate={startDate}
                            selectTime={onTimeSelect}
                            setStartDate={setStartDate}
                            uid={user.uid}
                          />
                          <div className="mt-3">
                            <div className="mb-2">
                              <Typography
                                variant="caption"
                                color="textPrimary"
                                style={{ fontWeight: 600 }}
                              >
                                Tags
                              </Typography>
                            </div>
                            <div className={styles.TagWrapper}>
                              <AutocompleteInput
                                value={tags}
                                onChange={setTags}
                              />
                            </div>
                          </div>
                          {/* {category ? <TextField label="Category" className="mt-3" darkMode value={category.name} disabled/> :
                            <div className="mt-3">
                              <Alert variant="outlined"
                                     severity="warning"
                                     action={
                                       <Button color="inherit" size="small"
                                               onClick={() => history.push('/profile')}>
                                         UPDATE
                                       </Button>
                                     }>
                                Please update category to schedule a webinar!
                              </Alert>
                            </div>} */}
                          <div className="mt-5">
                            <PrimaryButton
                              color="secondary"
                              variant="contained"
                              type="submit"
                              disabled={
                                (!isValid && touched) ||
                                !isFirstStepButtonValid()
                              }
                            >
                              Next
                            </PrimaryButton>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </Grid>
                </Grid>
              </div>
              <div
                className={styles.GridContainer}
                style={{ display: step === 2 ? "block" : "none" }}
              >
                <Grid
                  container
                  direction="column"
                  spacing={1}
                  alignContent="center"
                >
                  <Grid
                    md={6}
                    sm={12}
                    xs={12}
                    lg={6}
                    item
                    className={styles.GridCell}
                  >
                    <Typography
                      variant="h4"
                      color="textPrimary"
                      className="mb-3"
                    >
                      Hangout details
                    </Typography>
                  </Grid>
                  <Grid
                    md={6}
                    sm={12}
                    xs={12}
                    lg={6}
                    item
                    className={styles.GridCell}
                  >
                    <Formik
                      onSubmit={onSecondStepSubmit}
                      initialValues={{
                        description: "",
                      }}
                      validationSchema={webinarDetailsSchema}
                    >
                      {({
                        handleSubmit,
                        handleChange,
                        handleBlur,
                        values,
                        touched,
                        isValid,
                        errors,
                      }) => (
                        <Form
                          className="mt-2 w-100"
                          noValidate
                          onSubmit={handleSubmit}
                        >
                          <Dropzone
                            onClose={() => setVideoIntro(null)}
                            className="mt-3"
                            label="Intro video"
                            accept="video/*"
                            onFilesAdded={onIntroAdded}
                            disabled={videoIntro}
                          />
                          <TextField
                            className={clsx(styles.TextArea, "mt-3")}
                            as="textarea"
                            label="Description"
                            darkMode
                            maxLength={2000}
                            name="description"
                            isInvalid={errors.description}
                            onChange={handleChange}
                          />
                          <div className="mt-5">
                            <PrimaryButton
                              color="secondary"
                              variant="contained"
                              type="submit"
                              disabled={
                                (!isValid && touched) ||
                                !isSecondStepButtonValid()
                              }
                            >
                              Next
                            </PrimaryButton>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </Grid>
                </Grid>
              </div>
              <div
                className={styles.GridContainer}
                style={{ display: step === 3 ? "block" : "none" }}
              >
                <Grid
                  container
                  direction="column"
                  spacing={1}
                  alignContent="center"
                >
                  <Grid
                    lg={5}
                    md={5}
                    sm={7}
                    xs={12}
                    item
                    className={styles.GridCell}
                  >
                    <Typography
                      variant="h4"
                      color="textPrimary"
                      className="mb-3"
                    >
                      Pricing model
                    </Typography>
                  </Grid>
                  <Grid
                    lg={5}
                    md={5}
                    sm={7}
                    xs={12}
                    item
                    className={styles.GridCell}
                  >
                    <div className="d-flex justify-content-between mt-3">
                      <PricingCheckBox
                        label="Paid"
                        checked={paidChecked}
                        onClick={() => setPaidChecked(true)}
                      />
                      <PricingCheckBox
                        label="Free"
                        checked={!paidChecked}
                        onClick={() => setPaidChecked(false)}
                      />
                    </div>
                  </Grid>
                  <Grid
                    lg={5}
                    md={5}
                    sm={7}
                    xs={12}
                    item
                    className={styles.GridCell}
                  >
                    <Formik
                      onSubmit={onSubmit}
                      initialValues={{
                        price: 0,
                        seatsAmount: 10,
                      }}
                      validationSchema={
                        paidChecked ? pricingSchema : freePricingSchema
                      }
                    >
                      {({
                        handleSubmit,
                        handleChange,
                        handleBlur,
                        values,
                        touched,
                        isValid,
                        errors,
                      }) => (
                        <Form
                          className="mt-2 w-100"
                          noValidate
                          onSubmit={handleSubmit}
                        >
                          {paidChecked &&
                            !userDetails.stripeConnectedAccountId && (
                              <Alert
                                variant="outlined"
                                severity="warning"
                                action={
                                  <Button
                                    color="inherit"
                                    size="small"
                                    onClick={() => history.push("/profile")}
                                  >
                                    UPDATE
                                  </Button>
                                }
                              >
                                Please set up a payment account first!
                              </Alert>
                            )}
                          {paidChecked && (
                            <TextField
                              className="mt-3"
                              label="price"
                              name="price"
                              darkMode
                              value={values.price}
                              isInvalid={errors.price}
                              onChange={handleChange}
                            />
                          )}
                          <TextField
                            className="mt-3"
                            label="Amount of seats"
                            darkMode
                            name="seatsAmount"
                            value={values.seatsAmount}
                            isInvalid={errors.seatsAmount}
                            onChange={handleChange}
                          />
                          <div className="mt-5">
                            {!videoIntroLoading ? (
                              <PrimaryButton
                                color="secondary"
                                variant="contained"
                                type="submit"
                                loading={loading || videoIntroLoading}
                                disabled={
                                  (!isValid && touched) ||
                                  loading ||
                                  videoIntroLoading ||
                                  !isSecondStepButtonValid() ||
                                  (paidChecked &&
                                    !userDetails.stripeConnectedAccountId)
                                }
                              >
                                Publish the webinar
                              </PrimaryButton>
                            ) : (
                              <div>
                                {videoIntroLoading ? (
                                  <LinearProgress
                                    className="mb-3"
                                    variant="indeterminate"
                                  />
                                ) : null}
                              </div>
                            )}
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </Grid>
                </Grid>
              </div>
            </Card>
          </Container>
        )}
      </div>
    </Layout>
  );
}
