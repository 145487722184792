import React from "react";
import PropTypes from "prop-types";
import styles from "./Step.module.css";

Step.defaultProps = {
  mode: "inactive",
};

function Step({ mode }) {
  return mode === "inactive" ? (
    <div className={styles.InactiveStep} />
  ) : mode === "active" ? (
    <div className={styles.ActiveStep} />
  ) : mode === "disabled" ? (
    <div className={styles.DisabledStep} />
  ) : null;
}

export default Step;
