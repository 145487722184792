import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import AddCardForm from "../../components/AddCardForm/AddCardForm";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const AddCard = () => (
  <Elements stripe={stripePromise}>
    <AddCardForm />
  </Elements>
);
export default AddCard;
