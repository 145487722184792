import { useEffect, useState } from "react";
import firebase from "firebase";
import { useSelector } from "react-redux";
import { selectUser, selectUserDetails } from "../features/auth/authSlice";

const db = firebase.database();
let userInMeetingRoomRef = null;

export function useAudioState(channel, talent) {
  const [audioState, setAudioState] = useState(null);
  const user = useSelector(selectUser);

  useEffect(() => {
    userInMeetingRoomRef = db.ref(`webinars/${channel}/users/${user?.uid}`);
    // const onceSubscription = userInMeetingRoomRef.once('value', (snapshot) => {
    //   if (!snapshot.exists() && user && user?.uid !== talent?.id) {
    //     userInMeetingRoomRef.set({
    //       id: user.uid,
    //       muted: true,
    //       userAvatar: user?.photoURL,
    //       userName: user?.displayName,
    //       isRaiseHandRequested: false
    //     })
    //   }
    // })

    const subscription = userInMeetingRoomRef.on(
      "value",
      function (snapshot, prevChildKey) {
        const data = snapshot.val();
        setAudioState(data);
      }
    );

    return function cleanup() {
      userInMeetingRoomRef.off("value", subscription);
      //userInMeetingRoomRef.off('value', onceSubscription)
    };
  }, [user]);

  return audioState;
}
