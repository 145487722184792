import React from 'react';
import signUpStyles from "../SignUp.module.css";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";

function AuthTab({title, subtitle, active, onPress, className}) {
  return (
    <div onClick={onPress} className={clsx(signUpStyles.TabContainer, !active && signUpStyles.InactiveTabContainer, className && className)}>
      <Typography
        variant="caption"
        className={signUpStyles.TabSubtitle}
        display="block"
      >
        {subtitle}
      </Typography>
      <Typography
        variant="subtitle2"
        className={signUpStyles.TabTitle}
        display="block"
      >
        {title}
      </Typography>
    </div>
  );
}

export default AuthTab;
