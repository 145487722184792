/** @jsx jsx */
import { jsx } from "@emotion/core";
import useClasses from "./styles";
import { useHistory } from "react-router";
import WebinarCard from "../WebinarCard";
import SeeButton from "../../SeeButton";

const WebinarsList = ({ title, events, slug, staticSlug }) => {
  const history = useHistory();
  const classes = useClasses();

  const goToWebinarsCategories = () => {
    if (staticSlug) {
      history.push(`/trending/${staticSlug}`);
    } else {
      history.push(`/trending/category/${slug}`);
    }
  };

  return (
    <div css={classes.webinarsListWrapper}>
      <h1 css={classes.webinarsHeading}>{title}</h1>
      <div
        css={classes.webinarsList}
        className="mt-4 justify-content-start d-flex"
      >
        {events.map((event) => (
          <WebinarCard
            key={event.slug}
            name={event.name}
            participantsCount={event.participantsCount}
            author={event.User.displayName}
            seatsAmount={event.seatsAmount}
            tags={event.Tags}
            image={event.introThumb}
            video={event.intro}
            slug={event.slug}
            startDate={event.startDate}
          />
        ))}
      </div>
      {events.length < 4 ? (
        ""
      ) : (
        <SeeButton styles={classes.button} onClick={goToWebinarsCategories}>
          see all
        </SeeButton>
      )}
    </div>
  );
};

export default WebinarsList;
