import axios from "axios";

class Service {
  static async saveBooking(booking) {
    try {
      const res = await axios.post(`/api/booking`, {
        booking,
      });
      return res.data;
    } catch (e) {
      console.log("Can't save booking", e);
      return null;
    }
  }

  static async getAvailability(day, uid) {
    const res = await axios.get(
      `/api/user/availability?day=${day}&userId=${uid}`
    );
    return res.data.AvailabilitySlots;
  }

  static async accept(bookingId) {
    const res = await axios.post("/api/booking/accept", {
      id: bookingId,
    });
    return res.data;
  }
}

export default Service;
