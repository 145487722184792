import { configureStore } from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import authReducer from "../features/auth/authSlice";
import actionReducer from "../features/action/actionSlice";
import userReducer from "../features/profile/userSlice";
import eventReducer from "../features/schedule/eventSlice";
import categoryReducer from "../features/category/categorySlice";
import discoverReducer from "../features/discover/discoverSlice";
import webinarDetailsReducer from "../features/webinarDetails/webinarDetailsSlice";
import talentProfileReducer from "../features/talent-profile/talentProfileSlice";
import homeReducer from "../features/home/homeSlice";
import webinarReducer from "../features/webinar/webinarSlice";
import snackBarReducer from "../features/snackbar/snackBarSlice";
import modalReducer from "../features/modal/modalSlice";
import bookingReducer from "../features/booking/bookingSlice";
import notificationsReducer from "../features/notification/notificationSlice";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { combineReducers } from "redux";
import { FirebaseAuth } from "../firebase";

const middlewares = [thunk];

if (process.env.NODE_ENV === `development`) {
  const { logger } = require(`redux-logger`);
  middlewares.push(logger);
}

const reducers = combineReducers({
  auth: authReducer,
  userAction: actionReducer,
  user: userReducer,
  event: eventReducer,
  category: categoryReducer,
  home: homeReducer,
  webinarDetails: webinarDetailsReducer,
  talentProfile: talentProfileReducer,
  snackBar: snackBarReducer,
  booking: bookingReducer,
  notification: notificationsReducer,
  discover: discoverReducer,
  webinar: webinarReducer,
  modal: modalReducer,
});

const persistConfig = {
  key: "root",
  whiteList: ["auth", "userAction", "category"],
  blacklist: ["modal"],
  storage,
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
  middleware: middlewares,
});

export default store;

export const persistor = persistStore(store);

// persistor.purge()
// FirebaseAuth.signOut();
