import React, { useEffect, useRef, useState } from "react";
import * as Cookies from "js-cookie";
import Layout from "../../components/Layout/Layout";
import { AGORA_APP_ID } from "../../agora.config";
import { selectUser, selectUserDetails } from "../auth/authSlice";
import { useHistory, useLocation } from "react-router";
import { useSelector } from "react-redux";
import Container from "@material-ui/core/Container";
import clsx from "clsx";
import useStyles from "./meetingStyles";
import AgoraRTC from "agora-rtc-sdk-ng";
import MediaPlayer from "../../components/MediaPlayer/MediaPlayer";
import useAgora from "../../hooks/useAgora";
import Icon from "../../components/Icon/Icon";
import MicIcon from "../../assets/icons/mic.svg";
import MicOffIcon from "../../assets/icons/mic_off.svg";
import CameraIcon from "../../assets/icons/VideoWhite.svg";
import CameraOffIcon from "../../assets/icons/Camera-Off.svg";
import cancelCallIcon from "../../assets/icons/cancel-icon.svg";
import Typography from "@material-ui/core/Typography";
import chatIcon from "../../assets/icons/chat-icon.svg";
import ChatDrawer from "../../components/ChatDrawer/ChatDrawer";
import { useMediaQuery, useTheme } from "@material-ui/core";
import CloseIcon from "../../assets/icons/Close.svg";
import { toast } from "react-toastify";
import peopleIcon from "../../assets/icons/people-icon.svg";
import PeopleDrawer from "../../components/PeopleDrawer/PeopleDrawer";
import RaiseHandIcon from "../../assets/icons/raise-hand-icon.svg";
import RaiseHandIconWhite from "../../assets/icons/raise-hand-icon-white.svg";
import {
  onUserConnectedToWebinar,
  onUserLeaveWebinar,
  rainseHandRequest,
} from "../../utils/db";
import { useUsersInMeeting } from "../../libs/useUsersInMeeting";
import _ from "lodash";

const client = AgoraRTC.createClient({ codec: "h264", mode: "rtc" });

const Meeting = () => {
  const channel = Cookies.get("channel") || "test";
  const user = useSelector(selectUser);
  const appId = AGORA_APP_ID;
  const location = useLocation();
  const userDetails = useSelector(selectUserDetails);
  const theme = useTheme();
  const classes = useStyles();
  const history = useHistory();
  const webinar = location.state.webinar;
  const usersInMeeting = useUsersInMeeting(channel, webinar?.User);
  const bottomTabBarTimerRef = useRef(0);

  const [isChatOpen, setIsChatOpen] = useState(false);
  const [micEnabled, setMicEnabled] = useState(true);
  const [cameraEnabled, setCameraEnabled] = useState(true);
  const [sessionStarted, setSessionStarted] = useState(false);
  const [isPeopleDrawerOpen, setIsPeopleDrawerOpen] = useState(false);
  const [isRaiseHandActive, setIsRaiseHandActive] = useState(false);
  const [selectedStream, setSelectedStream] = useState(null);

  const isDrawerOpen = isChatOpen || isPeopleDrawerOpen;

  const {
    localAudioTrack,
    localVideoTrack,
    leave,
    join,
    joinState,
    remoteUsers,
  } = useAgora(client);

  const waitingForParticipant = !remoteUsers?.length;
  const mdDownScreenMatch = useMediaQuery(theme.breakpoints.down("md"));
  const isTalent = user?.uid === webinar?.User?.id;

  let usersInMeetingList = null;
  if (usersInMeeting) {
    usersInMeetingList = Object.keys(usersInMeeting)
      .map((key) => ({
        user: usersInMeeting[key],
      }))
      .filter((item) => item.user?.id !== webinar?.User?.id);
  }

  /*Join agora user*/
  useEffect(() => {
    if (user?.uid) {
      join(appId, channel, null, user.uid)
        .then(() => {
          if (!isTalent && webinar) {
            onUserConnectedToWebinar(channel, user);
          }
        })
        .catch(console.log);
    }
  }, [user, isTalent]);

  useEffect(() => {
    if (mdDownScreenMatch) {
      const firstStream = _.first(usersInMeetingList);
      if (!firstStream) {
        return;
      }
      const remoteUser = remoteUsers.find(
        (remoteUser) => remoteUser.uid === firstStream.user.id
      );
      setSelectedStream(remoteUser);
    } else {
      setSelectedStream(0);
    }
  }, [mdDownScreenMatch, usersInMeetingList]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  /*Cleanup*/
  useEffect(() => {
    return () => {
      if (!isTalent) {
        onUserLeaveWebinar(channel, user);
      }
      leave();
    };
  }, []);

  useEffect(() => {
    if (!client) return;
    const handleUserLeft = (user) => {
      toast.info("Your interlocutor has left");
    };
    const handleUserJoined = (user) => {
      setSessionStarted(true);
    };
    client.on("user-left", handleUserLeft);
    client.on("user-joined", handleUserJoined);
    return () => {
      client.off("user-left", handleUserLeft);
      client.off("user-joined", handleUserJoined);
    };
  }, [client]);

  const handleMicro = () => {
    if (micEnabled) {
      localAudioTrack.setEnabled(false);
      setMicEnabled(false);
    } else {
      localAudioTrack.setEnabled(true);
      setMicEnabled(true);
    }
  };
  const handleCamera = () => {
    if (cameraEnabled) {
      localVideoTrack.setEnabled(false);
      setCameraEnabled(false);
    } else {
      localVideoTrack.setEnabled(true);
      setCameraEnabled(true);
    }
  };
  const handleCancelCall = async () => {
    const result = window.confirm("Are you sure you want to leave the call?");
    if (result) {
      await leave();
      onUserLeaveWebinar(channel, user);
      history.goBack();
    }
  };

  const handlePeopleDrawerToggle = () => {
    if (isChatOpen) {
      setIsChatOpen(false);
    }
    setIsPeopleDrawerOpen(!isPeopleDrawerOpen);
  };
  const handleChatDrawerToggle = () => {
    if (isPeopleDrawerOpen) {
      setIsPeopleDrawerOpen(false);
    }
    setIsChatOpen(!isChatOpen);
  };

  const handleChangeRaiseHandStatus = () => {
    rainseHandRequest(channel, user?.uid, !isRaiseHandActive);
    setIsRaiseHandActive(!isRaiseHandActive);
  };

  const handleAudioStateChange = ({ muted }) => {
    if (joinState) {
      if (muted) {
        localAudioTrack.setEnabled(false);
        setMicEnabled(false);
      } else {
        localAudioTrack.setEnabled(true);
        setMicEnabled(true);
      }
    }
  };
  const handleRaiseHandingStateChange = ({ isRaiseHandRequested }) => {
    setIsRaiseHandActive(isRaiseHandRequested);
  };

  const onTabBarMouseOver = () => {
    if (!!bottomTabBarTimerRef.current) {
      clearTimeout(bottomTabBarTimerRef.current);
    }
    let btnGroup = document.querySelector("#bottom-tab-bar");
    if (btnGroup) {
      btnGroup.classList.remove("opacity-0");
      btnGroup.classList.add("opacity-1");
      bottomTabBarTimerRef.current = setTimeout(() => {
        btnGroup.classList.remove("opacity-1");
        btnGroup.classList.add("opacity-0");
      }, 5000);
    }
  };

  const onUserInListClick = ({ id }) => {
    const remoteUser = remoteUsers.find((item) => item.uid === id);
    if (remoteUser) {
      setSelectedStream(remoteUser);
    }
  };

  const _renderBottomTabBar = () => {
    return (
      !!joinState && (
        <div
          id="bottom-tab-bar"
          className={clsx(
            classes.bottomTabBar,
            mdDownScreenMatch && classes.transparentBottomBar
          )}
        >
          <div className="d-flex d-inline-flex">
            {isTalent && (
              <Icon
                className={clsx(classes.controlButton)}
                src={micEnabled ? MicIcon : MicOffIcon}
                backgroundColor="white"
                width={48}
                height={48}
                imgWidth={25}
                imgHeight={25}
                onClick={handleMicro}
              />
            )}
            {!isTalent && (
              <Icon
                title="Click here to ask the Host to unmute you."
                className={clsx(classes.controlButton)}
                src={isRaiseHandActive ? RaiseHandIcon : RaiseHandIconWhite}
                backgroundColor="rgba(255, 255, 255, 0.2)"
                width={48}
                height={48}
                imgWidth={20}
                imgHeight={20}
                onClick={handleChangeRaiseHandStatus}
              />
            )}
            <Icon
              className={classes.controlButton}
              src={cameraEnabled ? CameraIcon : CameraOffIcon}
              backgroundColor="rgba(255, 255, 255, 0.2)"
              width={48}
              height={48}
              imgWidth={25}
              imgHeight={25}
              onClick={handleCamera}
            />
            <Icon
              className={classes.controlButton}
              src={cancelCallIcon}
              backgroundColor="#FF4E4E"
              onClick={handleCancelCall}
              width={48}
              height={48}
              imgWidth={25}
              imgHeight={25}
            />
            <Icon
              className={clsx(classes.controlButton)}
              src={chatIcon}
              backgroundColor={
                isChatOpen ? "#9e9e9e" : "rgba(255, 255, 255, 0.2)"
              }
              onClick={handleChatDrawerToggle}
              width={48}
              height={48}
              unreadIcon={false}
              imgWidth={20}
              imgHeight={20}
            />
            {isTalent && (
              <Icon
                className={clsx(classes.controlButton)}
                src={peopleIcon}
                backgroundColor={
                  isPeopleDrawerOpen ? "#9e9e9e" : "rgba(255, 255, 255, 0.2)"
                }
                onClick={handlePeopleDrawerToggle}
                width={48}
                height={48}
                imgWidth={25}
                imgHeight={25}
              />
            )}
          </div>
          {mdDownScreenMatch && (
            <div
              className={clsx(
                "d-flex d-inline-flex justify-content-center align-items-center",
                classes.usersList
              )}
            >
              {usersInMeetingList?.map((meetingUser) => {
                return (
                  <div
                    key={meetingUser?.user?.id}
                    onClick={() => onUserInListClick(meetingUser?.user)}
                  >
                    <img
                      className={clsx(
                        classes.usersItemAvatar,
                        selectedStream?.uid === meetingUser?.user?.id &&
                          classes.selectedAvatar
                      )}
                      src={meetingUser.user?.userAvatar}
                    />
                  </div>
                );
              })}
            </div>
          )}
        </div>
      )
    );
  };

  const _renderSingleRemoteStream = () => {
    return (
      <>
        <div
          className={clsx(
            classes.participantVideCallWrapper,
            mdDownScreenMatch && isDrawerOpen && classes.zeroWidth
          )}
          onMouseMove={onTabBarMouseOver}
        >
          <div className={classes.remoteMediaPlayerContainer}>
            {!!selectedStream && (
              <MediaPlayer
                videoTrack={selectedStream.videoTrack}
                audioTrack={selectedStream.audioTrack}
              />
            )}
          </div>
          <div className={clsx(classes.localMediaPlayerContainer)}>
            <MediaPlayer videoTrack={localVideoTrack} audioTrack={null} />
          </div>
          {_renderBottomTabBar()}
          {waitingForParticipant && (
            <Typography variant="h4" className="webinar_start_soon">
              {sessionStarted ? "User has left" : "Hangout will start soon…"}
            </Typography>
          )}
        </div>
      </>
    );
  };

  const _renderTalentView = () => {
    return (
      <>
        {mdDownScreenMatch && selectedStream ? (
          _renderSingleRemoteStream()
        ) : (
          <div
            className={clsx(
              classes.videCallWrapper,
              mdDownScreenMatch && isDrawerOpen && classes.zeroWidth
            )}
            onMouseMove={onTabBarMouseOver}
          >
            <div className={clsx(classes.vid)}>
              <MediaPlayer videoTrack={localVideoTrack} audioTrack={null} />
              <div className={classes.participantNameContainer}>
                <span>{user?.displayName}</span>
              </div>
            </div>
            {remoteUsers.map((user) => (
              <div className={classes.vid} key={user?.uid}>
                <MediaPlayer
                  videoTrack={user.videoTrack}
                  audioTrack={user.audioTrack}
                />
                <div className={classes.participantNameContainer}>
                  <span>
                    {
                      usersInMeetingList?.find(
                        (item) => item?.user?.id === user?.uid
                      )?.user?.userName
                    }
                  </span>
                </div>
              </div>
            ))}
            {_renderBottomTabBar()}
            {waitingForParticipant && (
              <Typography variant="h4" className="webinar_start_soon">
                {sessionStarted ? "User has left" : `Hangout will start soon…`}
              </Typography>
            )}
          </div>
        )}
      </>
    );
  };

  const _renderParticipantView = () => {
    return (
      <>
        <div
          className={clsx(
            classes.participantVideCallWrapper,
            mdDownScreenMatch && isDrawerOpen && classes.zeroWidth
          )}
          onMouseMove={onTabBarMouseOver}
        >
          {remoteUsers
            ?.filter((item) => item.uid === webinar?.User?.id)
            .map((user) => (
              <div
                className={classes.remoteMediaPlayerContainer}
                key={user?.uid}
              >
                <MediaPlayer
                  videoTrack={user.videoTrack}
                  audioTrack={user.audioTrack}
                />
              </div>
            ))}
          <div className={clsx(classes.localMediaPlayerContainer)}>
            <MediaPlayer videoTrack={localVideoTrack} audioTrack={null} />
          </div>
          {_renderBottomTabBar()}
          {waitingForParticipant && (
            <Typography variant="h4" className="webinar_start_soon">
              {sessionStarted ? "User has left" : "Hangout will start soon…"}
            </Typography>
          )}
        </div>
      </>
    );
  };
  return (
    <Layout>
      <Container maxWidth="xl">
        <div
          className={clsx(
            "mt-5 mb-5",
            classes.wrapper,
            isDrawerOpen && classes.drawerOpenWrapper
          )}
        >
          {!isDrawerOpen && (
            <div className={classes.closeIconContainer}>
              <Icon
                src={CloseIcon}
                backgroundColor="#e3e3e3"
                width={38}
                height={38}
                imgHeight={18}
                imgWidth={18}
                onClick={handleCancelCall}
              />
            </div>
          )}
          {isTalent ? _renderTalentView() : _renderParticipantView()}
          <ChatDrawer
            user={user}
            channel={channel}
            open={isChatOpen}
            onClose={() => setIsChatOpen(false)}
            talent={webinar?.User}
          />
          <PeopleDrawer
            talent={webinar?.User}
            usersInMeetingList={usersInMeetingList}
            user={user}
            channel={channel}
            open={isPeopleDrawerOpen && isTalent}
            handleAudioStateChange={handleAudioStateChange}
            handleRaiseHandingStateChange={handleRaiseHandingStateChange}
            onClose={() => setIsPeopleDrawerOpen(false)}
          />
        </div>
      </Container>
    </Layout>
  );
};

export default Meeting;
