import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { ReactComponent as ChevronLeft } from "../../assets/icons/Arrow-Left-Grey.svg";
import SvgIcon from "@material-ui/core/SvgIcon";
import clsx from "clsx";

BackButton.propTypes = {};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100px",
    height: 48,
    backgroundColor: theme.palette.secondary.light,
    borderRadius: 12,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "20px",
    paddingRight: "8px",
    /* identical to box height, or 143% */

    /* Color/5/100% */

    color: "#8F92A1",
  },
  img: {
    marginRight: "12px",
  },
}));

function BackButton({ onClick, className, fill }) {
  const classes = useStyles();
  return (
    <div className={clsx(classes.root, className)} onClick={onClick}>
      <ChevronLeft className="mr-2" fill={fill} />
      Back
    </div>
  );
}

export default BackButton;
