import React, { useEffect, useState } from "react";
import axios from "axios";
import Form from "react-bootstrap/Form";
import styles from "../AutosuggestInput/AutosaggestInput.module.css";
import ListGroup from "react-bootstrap/ListGroup";
import Card from "react-bootstrap/Card";
import Autosuggest from "react-autosuggest";

const TagAutosuggest = (props) => {
  // Autosuggest will pass through all these props to the input.
  const [suggestions, setSuggestions] = useState([]);

  console.log("suggestions", suggestions);
  const { addTag, onChange, value, ref, ...rest } = props.tagProps;
  const handleOnChange = (e, { newValue, method }) => {
    if (method === "enter") {
      console.log("Enter");
      e.preventDefault();
    } else {
      onChange(e);
    }
  };

  const inputProps = {
    value,
    onChange: handleOnChange,
    ...rest,
  };

  const renderInputComponent = (inputProps) => {
    return <Form.Control {...inputProps} className={styles.InputContainer} />;
  };

  function renderSuggestion(suggestion) {
    return (
      <ListGroup.Item className={styles.Suggestion}>
        {suggestion.value}
      </ListGroup.Item>
    );
  }

  function renderSuggestionsContainer({ containerProps, children, query }) {
    return (
      <div {...containerProps}>
        <Card
          {...containerProps}
          style={{ display: children ? "block" : "none" }}
        >
          <ListGroup variant="flush">{children}</ListGroup>
        </Card>
      </div>
    );
  }

  const onSuggestionsFetchRequested = async ({ value }) => {
    const response = await axios.get(`/api/tag/tags?search=${value}`);
    setSuggestions(response.data);
  };

  useEffect(() => {
    // noinspection JSIgnoredPromiseFromCall
    onSuggestionsFetchRequested({ value: "" });
  }, []);

  // Finally, render it!
  return (
    <Autosuggest
      ref={ref}
      suggestions={suggestions}
      getSuggestionValue={(suggestion) => suggestion.value}
      shouldRenderSuggestions={(value) => true}
      renderSuggestion={renderSuggestion}
      inputProps={inputProps}
      onSuggestionSelected={(e, { suggestion }) => {
        addTag(suggestion.value);
      }}
      onSuggestionsClearRequested={() => {
        setSuggestions([]);
      }}
      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
      renderInputComponent={renderInputComponent}
      renderSuggestionsContainer={renderSuggestionsContainer}
    />
  );
};
export default TagAutosuggest;
