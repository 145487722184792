import React from "react";
import * as Cookies from "js-cookie";

import "./meeting.css";
import AgoraVideoCall from "../../components/AgoraVideoCall";
import Layout from "../../components/Layout/Layout";
import { AGORA_APP_ID } from "../../agora.config";
import Chat from "../../components/Chat/Chat";
import { useUsersInMeeting } from "../../libs/useUsersInMeeting";
import {
  selectUser,
  selectUserDetails,
  selectUserUpdateLoading,
  updateUser,
} from "../../features/auth/authSlice";
import { useLocation } from "react-router";
import { useSelector } from "react-redux";

const Webinar = () => {
  const videoProfile = "720p_1";
  const channel = Cookies.get("channel") || "test";
  const transcode = Cookies.get("transcode") || "interop";
  const attendeeMode = Cookies.get("attendeeMode") || "video";
  const baseMode = Cookies.get("baseMode") || "avc";
  const user = useSelector(selectUser);

  const appId = AGORA_APP_ID;
  const location = useLocation();
  const userDetails = useSelector(selectUserDetails);

  const usersInMeeting = useUsersInMeeting(
    channel,
    location.state.webinar.User
  );

  let userInMeetingList = null;

  if (usersInMeeting) {
    userInMeetingList = Object.keys(usersInMeeting).map((key) => ({
      user: usersInMeeting[key],
    }));
  }

  return (
    <div className="ag-main" style={{ width: "100%" }}>
      <div className="ag-container">
        <AgoraVideoCall
          videoProfile={videoProfile}
          channel={channel}
          transcode={transcode}
          attendeeMode="video"
          baseMode={baseMode}
          appId={appId}
          uid={user.uid}
          webinar={location.state.webinar}
          userDetails={userDetails}
          currentUser={user}
          userInMeetingList={userInMeetingList}
        />
      </div>
    </div>
  );
};

export default Webinar;
