import { useQuery } from "react-query";
import axios from "axios";

const getCoaches = async () => {
  const { data } = await axios.post(`/api/user/talents`, {
    page: {
      start: 0,
      size: 5,
    },
  });

  return data;
};

export function useCoaches() {
  return useQuery("coaches", getCoaches, {
    staleTime: 10000,
    refetchOnWindowFocus: false,
  });
}

const getWebinars = async () => {
  const date = new Date().toDateString();
  const { data } = await axios.get(
    `/api/home?currentDate=${date}&hours=7&start=0&size=4`
  );
  return data;
};

export function useWebinars() {
  return useQuery("upcomingWebinars", getWebinars, {
    staleTime: 10000,
    refetchOnWindowFocus: false,
  });
}

const searchWebinars = async (_, name) => {
  const { data } = await axios.get(`/api/home?&search=${name}&start=0&size=4`);
  return data;
};

export function useSearchWebinars(name) {
  return useQuery(["searchWebinar", name], searchWebinars, {
    staleTime: 10000,
    refetchOnWindowFocus: false,
  });
}

const allTomorrowWebinars = async (_, start) => {
  const { data } = await axios.get(
    `api/event/home/events?mode=tomorrow&start=${start}&size=20&search`
  );
  return data;
};

export function useAllTomorrowWebinars(start) {
  return useQuery(["allTomorrowWebinars", start], allTomorrowWebinars, {
    staleTime: 10000,
    refetchOnWindowFocus: false,
  });
}

const allInHoursWebinars = async (_, start) => {
  const { data } = await axios.get(
    `/api/event/home/events?mode=hour&hours=7&start=0&size=20&search=`
  );
  return data;
};

export function useAllInHoursWebinars(start) {
  return useQuery(["allInHoursWebinars", start], allInHoursWebinars, {
    staleTime: 10000,
    refetchOnWindowFocus: false,
  });
}

const webinarsByTag = async (_, category) => {
  const { data } = await axios.post(`/api/event/events?start=0&size=20`, {
    filters: {
      tag: category,
    },
  });
  return data;
};

export function useWebinarsByTag(category) {
  return useQuery(["webinarsByTag", category], webinarsByTag, {
    staleTime: 10000,
    refetchOnWindowFocus: false,
  });
}

const webinarsByCategory = async (_, category) => {
  const { data } = await axios.post(
    `/api/event/events?start=0&size=20&onlyUpcoming=true`,
    {
      filters: {
        userCategory: category,
        onlyUpcoming: true,
      },
    }
  );
  console.log("return webinars", data);
  return data;
};

export function useWebinarsByCategory(category) {
  return useQuery(["webinarsByCategory", category], webinarsByCategory, {
    staleTime: 10000,
    refetchOnWindowFocus: false,
  });
}
