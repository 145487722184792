import React, { useState } from "react";
import TagsInput from "react-tagsinput";
import Tag from "../Tag";
import TagAutosuggest from "../TagAutosuggest/TagAutosuggest";
import clsx from "clsx";
import styles from "./AutocompleteInput.module.css";

function AutocompleteInput({ value, onChange }) {
  function renderLayout(tagComponents, inputComponent) {
    return (
      <span className="d-inline-flex flex-wrap">
        {tagComponents}
        {inputComponent}
      </span>
    );
  }

  function renderTag(props) {
    let {
      tag,
      key,
      disabled,
      onRemove,
      classNameRemove,
      getTagDisplayValue,
      ...other
    } = props;
    return (
      <div key={key} className={clsx("mr-2 d-inline-flex mb-2 mt-2")}>
        <Tag>{getTagDisplayValue(tag)}</Tag>
      </div>
    );
  }

  return (
    <TagsInput
      renderInput={(props) => (
        <TagAutosuggest
          tagProps={props}
          onCategorySelect={(cat) => console.log(cat)}
        />
      )}
      value={value}
      onChange={onChange}
      renderTag={renderTag}
      renderLayout={renderLayout}
    />
  );
}

export default AutocompleteInput;
