/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout/Layout";
import Container from "@material-ui/core/Container";
import ChartBubbleIcon from "../../assets/icons/chart_bubble.svg";
import styles from "./Discover.module.css";
import Icon from "../../components/Icon/Icon";
import ArrowButton from "../../components/ArrowButton/ArrowButton";
import BackButton from "../../components/BackButton/BackButton";
import { useDispatch } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import { getTagsWithEvents } from "./discoverSlice";
import Grid from "@material-ui/core/Grid";
import Skeleton from "@material-ui/lab/Skeleton";
import { useHistory, useLocation, useParams } from "react-router";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import Coach from "./components/Coach";
import {
  useTalentByCategory,
  useTalents,
} from "../../hooks/query/discover/discover";

function Discover(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { id, category } = useParams();

  const talents = useTalents();

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedCategories, selectCategories] = useState([]);
  const [selectedTag, selectTag] = useState(null);
  const categoriesSlugs = selectedCategories.map((item) => item.slug);

  const talentsByTags = useTalentByCategory(categoriesSlugs.join(","));

  const onTagSelected = (tag) => {
    selectTag(tag);
    history.push(`/discover/${tag}`);
  };

  const selectCategory = (category) => {
    const itemInd = selectedCategories.findIndex((cat) => cat === category);
    if (itemInd === -1) {
      const categories = [...selectedCategories, category];
      // fetchTagsByCategories(categories)
      selectCategories(categories);
    } else {
      const categories = selectedCategories.filter((cat) => cat !== category);
      // fetchTagsByCategories(categories)
      selectCategories(categories);
    }
  };

  const createFilter = (categories) => {
    const categoryIds = categories.map((cat) => {
      return cat.id;
    });
    return {
      categoryIds,
    };
  };

  const fetchTagsByCategories = (categories) => {
    if (categories.length) {
      const filters = createFilter(categories);
      dispatch(getTagsWithEvents(filters));
    } else {
      dispatch(getTagsWithEvents());
    }
  };

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };
  const unselectCategories = () => {
    selectCategories([]);
    dispatch(getTagsWithEvents());
    setDrawerOpen(false);
  };

  useEffect(() => {
    dispatch(getTagsWithEvents());
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const search = params.get("search");
    dispatch(getTagsWithEvents({ search }));
  }, [location]);

  const onSearch = (val) => {
    history.push(`/discover?search=${val}`);
  };

  if (talents.isFetching && !category) {
    return (
      <div className="centered_spinner_container">
        <CircularProgress color="secondary" />
      </div>
    );
  }

  // if (talentsByTags.isFetching) {
  //   return (
  //     <div className="centered_spinner_container">
  //       <CircularProgress color="secondary" />
  //     </div>
  //   )
  // }

  return (
    <Layout>
      {!category ? (
        <Container maxWidth="xl" className={clsx("mt-5 mb-5")}>
          <div className={styles.Wrapper}>
            {talentsByTags.data ? (
              <div className="mt-5">
                {talentsByTags.data.map((talent) => {
                  return (
                    <DiscoverItem
                      key={talent.category.id}
                      {...talent}
                      onTagSelected={() => onTagSelected(talent.category.slug)}
                    />
                  );
                })}
              </div>
            ) : (
              <div className="mt-5">
                {talents.data.map((talent) => {
                  return (
                    <DiscoverItem
                      key={talent.category.id}
                      {...talent}
                      onTagSelected={() => onTagSelected(talent.category.slug)}
                    />
                  );
                })}
              </div>
            )}
          </div>
        </Container>
      ) : (
        <ExpandedTag category={category} />
      )}
    </Layout>
  );
}

function titleCase(str) {
  var splitStr = str.toLowerCase().split(" ");
  for (var i = 0; i < splitStr.length; i++) {
    // You do not need to check if i is larger than splitStr length, as your for does that for you
    // Assign it back to the array
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  // Directly return the joined string
  return splitStr.join(" ");
}

const ExpandedTag = ({ category }) => {
  const talentsByCategory = useTalentByCategory(category);
  const history = useHistory();

  if (talentsByCategory.isFetching) {
    return (
      <div className="centered_spinner_container">
        <CircularProgress color="secondary" />
      </div>
    );
  }

  return (
    <Container maxWidth="xl" className={clsx("mt-5 mb-5")}>
      <div className={styles.Wrapper}>
        <div className="d-flex align-items-center">
          <BackButton
            className={styles.discoverBackButton}
            fill="white"
            onClick={() => history.goBack()}
          />
          <Typography variant="h4" className="ml-4">
            {titleCase(category.replaceAll("_", " "))}
          </Typography>
        </div>
        {!talentsByCategory.data[0] ? (
          <Typography variant="h4" className="mt-5">
            Sorry, no talents in this category yet 😯
          </Typography>
        ) : (
          <div className={clsx("mt-4", styles.coachesExpandedList)}>
            {talentsByCategory.data[0] &&
              talentsByCategory.data[0].talents.map((event) => {
                return <Coach {...event} />;
              })}
          </div>
        )}
      </div>
    </Container>
  );
};

const DiscoverItem = ({ category, onTagSelected, talents }) => {
  return (
    <div className="mt-5">
      <div className={styles.TagContainer}>
        <div className="d-flex align-items-center">
          <Icon
            backgroundColor="#FFB3AB"
            width={62}
            height={62}
            src={ChartBubbleIcon}
          />
          <div className="d-flex flex-column ml-3">
            <span className={styles.TagTitle}>{category.name}</span>
            <span className={styles.TagSubtitle}>Trending category</span>
          </div>
        </div>
        <ArrowButton
          onClick={onTagSelected}
          style={css`
            width: 44px;
            position: static;
            height: 44px;
          `}
        />
      </div>
      <div
        className={clsx(
          "mt-4 justify-content-start d-flex",
          styles.coachesList
        )}
      >
        {talents.map((event) => {
          return <Coach key={event?.slug} {...event} />;
        })}
      </div>
    </div>
  );
};

const DiscoverSkeleton = ({}) => {
  return (
    <div className="mt-5">
      <Skeleton
        variant="rect"
        width="100%"
        height={56}
        className={styles.TagContainerSkeleton}
        animation="wave"
      />
      <Grid
        className="mt-3"
        container
        spacing={2}
        style={{
          marginLeft: "-1rem",
          marginRight: "-1rem",
          width: "calc(100% + 2rem)",
        }}
      >
        {[0, 1, 2].map((item) => {
          return (
            <Grid
              item
              key={item}
              md={4}
              sm={6}
              xs={12}
              style={{ padding: "1rem" }}
            >
              <Skeleton
                variant="rect"
                width="100%"
                style={{ height: "calc(300px - 2rem)" }}
                className={styles.TagContainerSkeleton}
                animation="wave"
              />
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
};

export default Discover;
