import axios from "axios";

class Service {
  static async getEvents(filters) {
    const res = await axios.post("/api/event/events", {
      filters,
    });
    return res.data;
  }
  static async getTalents(filters) {
    const res = await axios.post("/api/user/talents", {
      filters,
    });
    return res.data;
  }
  static async getFeed(filters) {
    const res = await axios.get("/api/feed");
    console.log("res: ", res);
    return res.data;
  }
}

export default Service;
