import React from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import styles from "./Tab.module.css";
import clsx from "clsx";

Tab.propTypes = {};

function Tab({ className, title, active, onClick, darkMode }) {
  const activeTitleColor = darkMode ? "#fff" : "rgba(30, 31, 32, 1)";
  const inactiveTitleColor = darkMode
    ? "rgba(230, 230, 230, 0.6)"
    : "rgba(143, 146, 161, 1)";
  const backgroundColor = darkMode ? "#fff" : "#1E1F20";
  return (
    <div className={(clsx(styles.Wrapper), className)} onClick={onClick}>
      <div className={clsx(styles.Container, active && styles.active)}>
        <Typography
          variant="subtitle2"
          className={styles.Title}
          color="textSecondary"
          display="block"
        >
          {title}
        </Typography>
        {/*{active && <div className={clsx(styles.Oval, "mt-1")} style={{backgroundColor}}/> }*/}
      </div>
    </div>
  );
}

export default Tab;
