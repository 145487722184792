import React from "react";
import Typography from "@material-ui/core/Typography";
import Form from "react-bootstrap/Form";
import styles from "./TextFiled.module.css";
import "./TextField.css";
import clsx from "clsx";

TextField.propTypes = {};

function TextField({ label, className, darkMode, textArea, ...otherProps }) {
  return (
    <div className={className}>
      <div
        className={clsx("mb-2")}
        style={{ color: darkMode ? "white" : "#1E1F20" }}
      >
        <Typography
          variant="caption"
          color="inherit"
          style={{ fontWeight: 600, letterSpacing: 1 }}
        >
          {label?.toUpperCase()}
        </Typography>
      </div>
      <Form.Control
        style={{ minHeight: textArea ? 170 : 50 }}
        className={!darkMode ? styles.Input : styles.DarkInput}
        {...otherProps}
      />
    </div>
  );
}

export default TextField;
