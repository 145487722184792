import { createSlice } from "@reduxjs/toolkit";
import Service from "../profile/service";
import axios from "axios";
import { FirebaseAuth } from "../../firebase";

export const slice = createSlice({
  name: "auth",
  initialState: {
    user: null,
    userDetails: null,
    loading: true,
    userUpdateLoading: false,
    paymentCards: [],
    cardSetupClientSecret: null,
    setupIntent: null,
    loadingCardSetup: false,
    paymentCardsLoading: false,
    paymentStatus: null,
    paymentProcessing: false,
    paymentErrorMessage: null,
    connectingStripe: false,
  },
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setUserUpdateLoading: (state, action) => {
      state.userUpdateLoading = action.payload;
    },
    setUserDetails: (state, action) => {
      state.userDetails = action.payload;
    },
    setLoadingCardSetup: (state, action) => {
      state.loadingCardSetup = action.payload;
    },
    setCardSetupClientInfo: (state, action) => {
      state.cardSetupClientSecret = action.payload.client_secret;
      state.setupIntent = action.payload.setupIntent;
      if (!state.userDetails.stripeCustomerId)
        state.userDetails.stripeCustomerId = action.payload.customer_id;
    },
    setPaymentCardsLoading: (state, action) => {
      state.paymentCardsLoading = action.payload;
    },
    setPaymentCards: (state, action) => {
      state.paymentCards = action.payload;
    },
    setPaymentStatus: (state, action) => {
      state.paymentStatus = action.payload;
    },
    setPaymentErrorMessage: (state, action) => {
      state.paymentErrorMessage = action.payload;
    },
    setPaymentProcessing: (state, action) => {
      state.paymentProcessing = action.payload;
    },
    setConnectedAccountId: (state, action) => {
      state.userDetails.stripeConnectedAccountId = action.payload;
    },
    setDashboardUrl: (state, action) => {
      state.userDetails.stripeDashboardUrl = action.payload;
    },
    setConnectingStripe: (state, action) => {
      state.connectingStripe = action.payload;
    },
  },
});

export const updateUser = (user) => async (dispatch) => {
  try {
    dispatch(setUserUpdateLoading(true));
    const userDetails = await Service.updateUser(user);
    console.log(userDetails);
    console.log("user", user);
    dispatch(setUserDetails(userDetails));
  } catch (e) {
    console.log(e.response);
    throw e.response.error;
  } finally {
    dispatch(setUserUpdateLoading(false));
  }
};
export const refreshUserDetails = () => async (dispatch) => {
  try {
    const userDetails = await Service.getUserDetails();
    dispatch(setUserDetails(userDetails));
  } catch (e) {
    console.log(e.response);
  }
};

export const authChanged = (user) => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    if (user) {
      const idToken = await user.getIdToken();
      const isNewUser =
        user.metadata.creationTime === user.metadata.lastSignInTime;
      axios.defaults.headers.common["Authorization"] = `Bearer ${idToken}`;
      localStorage.setItem("authToken", idToken);
      dispatch(setUser(user));
      const userDetails = await Service.getUserDetails();
      dispatch(setUserDetails(userDetails));
      if (!userDetails && !isNewUser) {
        await FirebaseAuth.signOut();
        dispatch(setUser(null));
        delete axios.defaults.headers.common["Authorization"];
      }
    } else {
      dispatch(setUser(null));
      dispatch(setUserDetails(null));
      delete axios.defaults.headers.common["Authorization"];
    }
  } catch (e) {
    console.log(e);
    dispatch(setUser(null));
    dispatch(setUserDetails(null));
  } finally {
    dispatch(setLoading(false));
  }
};

export const makePayment =
  (amount, customerId, paymentMethodId, email, connectedAccountId, webinarId) =>
  async (dispatch) => {
    dispatch(setPaymentStatus(null));
    dispatch(setPaymentErrorMessage(null));
    dispatch(setPaymentProcessing(true));
    try {
      const { paymentIntent } = await Service.makePayment(
        amount,
        customerId,
        paymentMethodId,
        email,
        connectedAccountId,
        webinarId
      );
      dispatch(setPaymentProcessing(false));
      if (paymentIntent.status !== "succeeded")
        dispatch(
          setPaymentErrorMessage(paymentIntent.last_payment_error.message)
        );
      return dispatch(setPaymentStatus(paymentIntent.status));
    } catch (e) {
      dispatch(setPaymentProcessing(false));
      console.log(e);
    }
  };

export const getPaymentCards = (id) => async (dispatch) => {
  dispatch(setPaymentCardsLoading(true));
  try {
    const response = await Service.getPaymentCards(id);
    dispatch(setPaymentCardsLoading(false));
    const uniqueCards = response.reduce((unique, o) => {
      if (!unique.some((obj) => obj.card.fingerprint === o.card.fingerprint)) {
        unique.push(o);
      }
      return unique;
    }, []);

    return dispatch(setPaymentCards(uniqueCards));
  } catch (e) {
    dispatch(setPaymentCardsLoading(false));
    console.log(e);
  }
};

export const setupPurchaseCard = (customerId) => async (dispatch) => {
  dispatch(setLoadingCardSetup(true));
  try {
    const response = await Service.setupPurchaseCard(customerId);
    dispatch(setLoadingCardSetup(false));
    return dispatch(setCardSetupClientInfo(response));
  } catch (e) {
    console.log(e);
  }
};

export const saveConnectedAccountInfo =
  (authCode, token) => async (dispatch) => {
    dispatch(setConnectingStripe(true));
    try {
      const { connectedAccountId } = await Service.saveConnectedAccountId(
        { authCode },
        token
      );
      dispatch(setConnectedAccountId(connectedAccountId));
      if (connectedAccountId) {
        const { url } = await Service.getStripeDashboardUrl(connectedAccountId);
        dispatch(setDashboardUrl(url));
      }
      return dispatch(setConnectingStripe(false));
    } catch (e) {
      console.log(e);
    }
  };

export const {
  setUser,
  setUserDetails,
  setLoading,
  setUserUpdateLoading,
  setCardSetupClientInfo,
  setPaymentCards,
  setLoadingCardSetup,
  setPaymentCardsLoading,
  setPaymentStatus,
  setPaymentProcessing,
  setPaymentErrorMessage,
  setConnectedAccountId,
  setDashboardUrl,
  setConnectingStripe,
} = slice.actions;

export const selectUserLoading = (state) => state.auth.loading;
export const selectUserUpdateLoading = (state) => state.auth.userUpdateLoading;
export const selectUser = (state) => state.auth.user;
export const selectUserDetails = (state) => state.auth.userDetails;
export const selectPaymentProcessing = (state) => state.auth.paymentProcessing;
export const selectPaymentStatus = (state) => state.auth.paymentStatus;
export const selectPaymentErrorMessage = (state) =>
  state.auth.paymentErrorMessage;
export const selectCardSetupClientSecret = (state) =>
  state.auth.cardSetupClientSecret;
export const selectSetupIntent = (state) => state.auth.setupIntent;
export const selectLoadingCardSetup = (state) => state.auth.loadingCardSetup;
export const selectPaymentCardsLoading = (state) =>
  state.auth.paymentCardsLoading;
export const selectPaymentCards = (state) => state.auth.paymentCards;
export const selectConnectingStripe = (state) => state.auth.connectingStripe;

export default slice.reducer;
