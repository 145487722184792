import { css } from "@emotion/core";

const useClasses = (theme) => ({
  arrowBtn: css`
    width: 28px;
    height: 28px;
    background: #ffb3ab;
    border-radius: 8px;
    cursor: pointer;
    position: absolute;
    right: 15px;
  `,
});

export default useClasses;
