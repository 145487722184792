import React, { useEffect, useState } from "react";
import {
  getPaymentCards,
  selectUserDetails,
  selectPaymentCardsLoading,
  selectPaymentCards,
  makePayment,
  selectPaymentProcessing,
  selectPaymentErrorMessage,
  selectPaymentStatus,
  setPaymentStatus,
  setPaymentErrorMessage,
  setPaymentProcessing,
} from "../../features/auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import styles from "./PaymentForm.module.css";
import useClasses from "./styles";

import PlusIcon from "../../assets/icons/plus.svg";
import ArrowBackIosIcon from "../../assets/icons/Arrow-Left-Grey.svg";
import { useHistory } from "react-router";
import clsx from "clsx";
import CircularProgress from "@material-ui/core/CircularProgress";
import Layout from "../Layout/Layout";
import Grid from "@material-ui/core/Grid";
import { showErrorSnack } from "../../features/snackbar/snackBarSlice";
import { Container } from "react-bootstrap";

const PaymentForm = ({ amount, connectedAccountId, webinarId }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const userDetails = useSelector(selectUserDetails);
  const isCardsLoading = useSelector(selectPaymentCardsLoading);
  const paymentCards = useSelector(selectPaymentCards);
  const isPaymentProcessing = useSelector(selectPaymentProcessing);
  const paymentErrorMessage = useSelector(selectPaymentErrorMessage);
  const paymentStatus = useSelector(selectPaymentStatus);
  const [chosenCard, setChosenCard] = useState(null);
  const classes = useClasses();

  useEffect(() => {
    if (userDetails.stripeCustomerId)
      dispatch(getPaymentCards(userDetails.stripeCustomerId));
  }, []);

  useEffect(() => {
    if (paymentStatus !== "succeeded" && paymentErrorMessage)
      dispatch(showErrorSnack(paymentErrorMessage));
    else if (paymentStatus === "succeeded") history.goBack();
    return () => {
      if (paymentStatus !== "succeeded" && paymentErrorMessage) {
        dispatch(setPaymentStatus(null));
        dispatch(setPaymentErrorMessage(null));
      }
    };
  }, [paymentStatus]);

  const handlePayment = () => {
    dispatch(
      makePayment(
        amount,
        chosenCard.customer,
        chosenCard.id,
        userDetails.email,
        connectedAccountId,
        webinarId
      )
    );
  };

  return (
    <Layout>
      <Container fluid="lg" className={classes.container}>
        <div className={styles.PaymentForm}>
          <div className={styles.PaymentFormHeader}>
            <h1 className={styles.MyCardsTitle}>My Cards</h1>
            <button
              className={styles.AddCardBtn}
              onClick={() => history.push("/addCard")}
            >
              <img src={PlusIcon} alt="plus" />
            </button>
          </div>
          <div className={styles.PaymentFormBody}>
            <div className={styles.CardsListWrap}>
              {isCardsLoading ? (
                <div className={styles.CardsLoaderWrap}>
                  <CircularProgress />
                </div>
              ) : !paymentCards.length ? (
                <div className={styles.CardsLoaderWrap}>
                  <h1 className={styles.PaymentCardsStatusLabel}>
                    No cards found, add one first
                  </h1>
                </div>
              ) : (
                <div className={styles.CardsList}>
                  {paymentCards?.map((e) => (
                    <button
                      onClick={() => setChosenCard(e)}
                      key={e.id}
                      className={clsx(
                        styles.CardBody,
                        chosenCard?.id === e.id && styles.ActiveCard
                      )}
                    >
                      <p className={styles.CardNumbersLabel}>
                        &#8226;&#8226;&#8226;&#8226; {e.card.last4}
                      </p>
                      <img
                        src={require(`../../assets/icons/cards/${e.card.brand}.svg`)}
                        alt={e.card.brand}
                        className={styles.CardBrandLogo}
                      />
                    </button>
                  ))}
                </div>
              )}
            </div>
            {isPaymentProcessing ? (
              <h1 className={styles.PaymentCardsStatusLabel}>
                Processing payment...
              </h1>
            ) : (
              <button
                className={styles.PayNowBtn}
                disabled={isCardsLoading || !paymentCards.length || !chosenCard}
                onClick={handlePayment}
              >
                <p>Pay now</p>
                <p>
                  $
                  {amount +
                    amount * (+process.env.REACT_APP_PLATFORM_COMISSION / 100)}
                </p>
              </button>
            )}
          </div>
        </div>
      </Container>
    </Layout>
  );
};

export default PaymentForm;
