import axios from "axios";
import queryString from "query-string";

class Service {
  static async getTags(filter) {
    const parsedFilter = queryString.stringify(filter);
    const response = await axios.get(`/api/tag/with-events?${parsedFilter}`);
    return response.data;
  }
  static async getEventByTag(tagId) {
    const response = await axios.get(`/api/tag/events?tagId=${tagId}`);
    return response.data;
  }
}

export default Service;
