/** @jsx jsx */
import { jsx } from "@emotion/core";
import { useClasses } from "./styles";
import WebinarRowItem from "../WebinarRowItem";

const WebinarsRowList = ({ items, hideViewWebinarBtn, styleType }) => {
  const classes = useClasses();
  return (
    <div css={classes.webinarsListWrapper}>
      <div css={classes.webinarsList}>
        {items.map((item) => {
          return (
            <WebinarRowItem
              styleType={styleType}
              hideViewWebinarBtn={hideViewWebinarBtn}
              id={item.id}
              key={item.id}
              name={item.name}
              introThumb={item.introThumb}
              description={item.description}
              author={item.User ? item.User?.displayName : "Unknown"}
              authorUsername={item.User ? item.User?.slug : null}
              tags={item.Tags}
              type={item.type}
              price={item.price}
              participantsCount={item.participantsCount}
              startDate={item.startDate}
              endDate={item.endDate}
              seatsAmount={item.seatsAmount}
              slug={item.slug}
              userId={item.UserId}
              userInfo={item.User}
            />
          );
        })}
      </div>
    </div>
  );
};

export default WebinarsRowList;
