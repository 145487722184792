import { css, jsx } from "@emotion/core";

const useClasses = (theme) => ({
  webinarsListWrapper: css`
    position: relative;
  `,
  webinarsHeading: css`
    color: white;
    font-weight: bold;
    font-size: 30px;
    line-height: 36px;
  `,
  webinarsList: css`
    margin-bottom: 5rem;
    overflow-x: auto;
    min-width: 100%;
    min-height: 200px;
    overflow-x: auto;
    @media (max-width: 992px) {
      margin-bottom: 4rem;
      margin-left: -25px;
    }
    &::-webkit-scrollbar {
      display: none;
    }
    > div {
      min-width: 23%;
      margin-right: 2.6%;
      @media (max-width: 1300px) {
        min-width: 32%;
        margin-right: 2%;
      }
      @media (max-width: 992px) {
        min-width: 44%;
        margin-left: 2%;
        &:first-of-type {
          margin-left: 25px;
        }
        &:last-child {
          margin-right: 0;
        }
      }
      @media (max-width: 768px) {
        min-width: 84%;
        margin-right: 3%;
      }
    }
  `,
  button: css`
    position: absolute;
    top: 0;
    right: 0;
    @media (max-width: 700px) {
      position: relative;
      margin-top: 0;
      margin: auto;
      margin-top: -40px;
      margin-bottom: 40px;
    }
  `,
});

export default useClasses;
