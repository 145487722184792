import { css } from "@emotion/core";

const useClasses = (theme) => ({
  bannersHeading: css`
    color: white;
    font-weight: bold;
    font-size: 30px;
    line-height: 36px;
  `,
  bannersList: css`
    overflow-x: auto;
    min-width: 100%;
    min-height: 200px;
    margin-bottom: 50px;
    overflow-x: auto;
    &::-webkit-scrollbar {
      display: none;
    }
    > div {
      min-width: 32%;
      margin-right: 2%;
      @media (max-width: 1350px) {
        min-width: 48%;
        margin-right: 4%;
      }
      @media (max-width: 850px) {
        min-width: 90%;
        margin-right: 5%;
      }
    }
  `,
});

export default useClasses;
