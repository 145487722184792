import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Drawer from "@material-ui/core/Drawer";
import Icon from "../Icon/Icon";
import CloseIcon from "../../assets/icons/Close.svg";
import Button from "@material-ui/core/Button";
import EditIcon from "@material-ui/icons/Edit";
import { useDispatch, useSelector } from "react-redux";
import {
  Divider,
  Hidden,
  useMediaQuery,
  useTheme,
  Zoom,
} from "@material-ui/core";
import Avatar from "../Avatar/Avatar";
import Typography from "@material-ui/core/Typography";
import styles from "../Chat/Chat.module.css";
import Form from "react-bootstrap/Form";
import FormGroup from "react-bootstrap/FormGroup";
import FormControl from "react-bootstrap/FormControl";
import { Picker } from "emoji-mart";
import EmokiIcon from "../../assets/icons/Emoji.svg";
import { selectUser } from "../../features/auth/authSlice";
import firebase from "firebase";
import { setPendingChat } from "../../features/webinar/webinarSlice";

const useStyles = makeStyles((theme) => ({
  list: {
    height: "fit-content",
    position: "relative",
    width: 430,
    maxWidth: 430,
    padding: "2.5rem 1.5rem",
    overflowY: "hidden",
    overflowX: "hidden",
    color: theme.palette.text.primary,
    [theme.breakpoints.down("sm")]: {
      width: `100%`,
      padding: "1.5rem 0.75rem",
    },
  },
  fullList: {
    width: "auto",
  },
  listItem: {
    padding: "1rem 0",
    fontSize: "16px",
    lineHeight: "22px",
  },
  paperAnchorRight: {
    width: 430,
    maxWidth: 430,
    backgroundColor: "#141414",
    overflowX: "hidden",
    borderRadius: 32,
    [theme.breakpoints.down("sm")]: {
      borderRadius: 0,
      width: `100%`,
    },
  },
  buttonLabel: {
    textTransform: "none",
  },
  buttonRoot: {
    borderRadius: 12,
    backgroundColor: "rgba(143, 146, 161, 0.2)",
    width: "100%",
    color: theme.palette.text.primary,
    fontSize: 14,
    fontWeight: 600,
    height: 56,
  },
  wrapper: {
    marginLeft: 43,
    transition: "all 1s ease-out",
    [theme.breakpoints.down("md")]: {
      marginLeft: 0,
    },
  },
  closeIconContainer: {
    position: "absolute",
    top: 20,
    right: 20,
  },
}));

ChatDrawer.propTypes = {};
const db = firebase.database();
let chatRef = null;

function ChatDrawer({ user, channel, open, onClose, talent }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const messagesEndRef = useRef(null);

  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [emojiVisible, setEmojiVisible] = useState(false);
  const currentUser = useSelector(selectUser);

  useEffect(() => {
    chatRef = db.ref(`chats/${channel}/messages`);

    const subscription = chatRef.on("value", function (snapshot, prevChildKey) {
      const fetchedMessages = [];
      snapshot.forEach(function (childSnapshot) {
        const childKey = childSnapshot.key;
        const childData = childSnapshot.val();
        const fetchedMessage = {
          ...childData,
          id: childKey,
        };
        fetchedMessages.push(fetchedMessage);
      });
      if (
        fetchedMessages.length &&
        fetchedMessages.length !== messages.length &&
        !open
      ) {
        dispatch(setPendingChat(true));
      }
      setMessages(fetchedMessages);
    });
    return function cleanup() {
      chatRef.off("value", subscription);
    };
  }, [open, messages.length]);

  const scrollToBottom = () => {
    if (open) {
      // messagesEndRef.current.scrollIntoView({behavior: "smooth"})
    }
  };

  useEffect(scrollToBottom, [messages]);

  const onSendMessage = async (e) => {
    e.preventDefault();
    const msg = {
      body: message,
      date: new Date().toDateString(),
      uid: currentUser.uid,
      displayName: currentUser.displayName,
      avatar: currentUser.photoURL,
    };
    chatRef.push(msg);
    setMessage("");
  };

  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };
  const onEmojiClick = () => {
    setEmojiVisible(!emojiVisible);
  };

  const onEmojiSelect = (emoji) => {
    const msg = message + emoji.native;
    setMessage(msg);
  };

  const onEnterPress = async (e) => {
    if (e.key === "Enter") {
      await onSendMessage(e);
      setEmojiVisible(false);
    }
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      style={{ height: "80vh" }}
      role="presentation"
    >
      <Hidden lgUp>
        <div className={classes.closeIconContainer}>
          <Icon
            src={CloseIcon}
            backgroundColor="#e3e3e3"
            width={38}
            height={38}
            imgHeight={18}
            imgWidth={18}
            onClick={onClose}
          />
        </div>
      </Hidden>
      <div className="d-flex">
        <Avatar
          url={talent?.photoURL || user?.photoURL}
          imgHeight={48}
          imgWidth={48}
        />
        <div className="d-flex flex-column justify-content-between ml-3">
          <Typography variant="subtitle2">
            {talent?.displayName || user.displayName}
          </Typography>
          <div className={styles.LiveStatus}>Live hangout</div>
        </div>
      </div>
      <Divider className="mt-3" />
      <div className={"chat_content mt-2"}>
        {messages.map((item) => (
          <Message
            key={item.id}
            name={item.displayName}
            message={item.body}
            currentUser={currentUser}
            uid={item.uid}
            avatar={item.avatar}
          />
        ))}
        <div ref={messagesEndRef} />
      </div>
      <div className={clsx("mt-2", styles.SendInputWrapper)}>
        <Form onSubmit={onSendMessage}>
          <div className={styles.SendInputContainer}>
            <FormGroup>
              <FormControl
                placeholder="Start typing…"
                aria-label="Message"
                autoFocus
                className="chat_input"
                value={message}
                onKeyDown={onEnterPress}
                onChange={handleMessageChange}
              />
            </FormGroup>
            <Zoom in={emojiVisible}>
              <Picker
                set={"apple"}
                style={{
                  position: "absolute",
                  bottom: 65,
                  width: "100% !important",
                }}
                onSelect={onEmojiSelect}
              />
            </Zoom>
            <div className={styles.EmojiIcon} onClick={onEmojiClick}>
              <img src={EmokiIcon} />
            </div>
          </div>
        </Form>
      </div>
    </div>
  );

  return (
    open && (
      <div className={classes.wrapper}>
        <React.Fragment>
          <Hidden smUp smDown>
            <Button
              classes={{ root: classes.buttonRoot, label: classes.buttonLabel }}
              variant="contained"
              color="default"
              startIcon={<EditIcon />}
              disableElevation
            >
              Chat
            </Button>
          </Hidden>
          {open && (
            <div>
              <div className={classes.paperAnchorRight}>{list()}</div>
            </div>
          )}
        </React.Fragment>
      </div>
    )
  );
}

const Message = ({ name, message, avatar }) => {
  return (
    <div className="d-flex mt-1 mb-4">
      <Avatar
        url={avatar}
        imgHeight={48}
        imgWidth={48}
        width={48}
        height={48}
      />
      <div className="d-flex flex-column justify-content-between ml-3">
        <Typography variant="subtitle2" color="textPrimary">
          {name}
        </Typography>
        <Typography variant="subtitle1" className={styles.MessageBody}>
          {message}
        </Typography>
      </div>
    </div>
  );
};

export default ChatDrawer;
