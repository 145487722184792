import { css } from "@emotion/core";

const classes = {
  tag: css`
    padding: 4px 8px;
    font-size: 12px;
    line-height: 16px;
    border-radius: 4px;
    margin-right: 12px;
    align-self: flex-start;
    display: block;
    background: rgba(143, 146, 161, 0.2);
    color: #fff;
  `,
};

export { classes };
