import { css } from "@emotion/core";

const useClasses = (theme) => ({
  coach: css`
    max-width: 420px;
    width: 100%;
    position: relative;
    border-radius: 10px;
    cursor: pointer;
    overflow: hidden;
    > img {
      width: 100%;
      border-radius: 25px;
    }
    @media (max-width: 768px) {
      max-width: 100%;
    }
  `,
  coachVideoContainer: css`
    height: 310px;
    overflow: hidden;
    position: relative;
    video {
      object-fit: cover;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }
  `,
  coachImage: css`
    height: 315px;
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  `,
  coachVideo: css`
    position: absolute;
    z-index: 1;
    cursor: pointer;
    top: 0;
    right: 0;
    left: 0;
    height: 315px;
  `,
  coachInfo: css`
    background: #2f2f36;
    position: relative;
    width: 100%;
    padding: 1.5rem;
    padding-bottom: 5px;
    min-height: 100px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    h3 {
      margin-top: 0;
      display: inline-block;
      color: #fff;
      margin-bottom: 1.25rem;
      font-weight: 600;
      font-size: 20px;
      height: 20px;
      position: relative;
      line-height: 22px;
    }
  `,
  coachStatus: css`
    position: absolute;
    right: 20px;
    bottom: 6px;
    transform: translateY(-50%);
  `,
  coachTags: css`
    position: relative;
  `,
  coachImgPlaceholder: css`
    height: 310px;
    img {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      height: 320px;
      margin-top: -210px;
      margin-left: -50%;
      object-fit: cover;
    }
  `,
  webinarPlayBtn: css`
    z-index: 111;
    position: absolute;
    bottom: 18px;
    left: 15px;
  `,
  webinarProgress: css`
    position: absolute;
    top: 50%;
    left: 50%;
    opacity: 0.7;
    transform: translate(-50%, -50%);
  `,
});

export default useClasses;
