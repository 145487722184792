import React from "react";
import clsx from "clsx";
import { useMediaQuery, useTheme } from "@material-ui/core";
CenteredBox.propTypes = {};

function CenteredBox(props) {
  const theme = useTheme();
  const smDownMatch = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <div
      className={clsx(
        !smDownMatch && "d-flex align-items-center justify-content-center"
      )}
      style={{ height: "calc(100vh - 80px)" }}
    >
      {props.children}
    </div>
  );
}

export default CenteredBox;
