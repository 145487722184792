import { css } from "@emotion/core";

const classes = {
  status: css`
    border-radius: 12px;
    background: #ffb3ab;
    height: 20px;
    font-size: 12px;
    text-transform: capitalize;
    color: white;
    padding: 0 12px;
    line-height: 20px;
    display: block;
    position: relative;
    font-weight: normal;
    text-align: center;
  `,
};

export { classes };
