import { css, jsx } from "@emotion/core";

const useClasses = (theme) => ({
  coachesListWrapper: css`
    position: relative;
  `,
  coachesHeading: css`
    color: white;
    font-weight: bold;
    font-size: 30px;
    line-height: 36px;
  `,
  coachesList: css`
    margin-bottom: 2rem;
    overflow-x: auto;
    min-width: 100%;
    min-height: 200px;
    overflow-x: auto;
    @media (max-width: 992px) {
      margin-bottom: 4rem;
      margin-left: -25px;
    }
    &::-webkit-scrollbar {
      display: none;
    }
    > div {
      width: 18%;
      margin-right: 2.5%;
      @media (max-width: 1400px) {
        min-width: 23%;
        margin-right: 2.5%;
      }
      @media (max-width: 1100px) {
        min-width: 31%;
        margin-right: 3%;
      }
      @media (max-width: 992px) {
        min-width: 44%;
        margin-right: 3%;
        &:first-of-type {
          margin-left: 30px;
        }
      }
      @media (max-width: 768px) {
        min-width: 80%;
        margin-right: 6%;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  `,
  button: css`
    position: absolute;
    top: 0;
    right: 0;
    @media (max-width: 700px) {
      position: relative;
      margin-top: 0;
      margin: auto;
      margin-top: -40px;
      margin-bottom: 40px;
    }
  `,
});

export default useClasses;
