import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Calendar from "react-calendar";
import Fade from "@material-ui/core/Fade";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import { minutesToTime } from "../../utils/time";
import { useDispatch, useSelector } from "react-redux";
import {
  getAvailability,
  selectAvailabilityLoading,
  selectAvailabilitySlots,
} from "../../features/booking/bookingSlice";
import format from "date-fns/format";

TimePicker.propTypes = {};

function TimePicker({
  startDate,
  setStartDate,
  selectedTime,
  selectTime,
  uid,
}) {
  const dispatch = useDispatch();
  const availabilitySlots = useSelector(selectAvailabilitySlots);
  const availabilityLoading = useSelector(selectAvailabilityLoading);

  const onDayClick = (date) => {
    const formattedDate = format(date, "MM/dd/yyyy");
    dispatch(getAvailability(formattedDate, uid));
  };

  useEffect(() => {
    const formattedDate = format(startDate, "MM/dd/yyyy");
    dispatch(getAvailability(formattedDate, uid));
  }, []);

  return (
    <>
      <div className="mt-5">
        <Calendar
          minDate={new Date()}
          onChange={setStartDate}
          value={startDate}
          onClickDay={onDayClick}
        />
      </div>
      <div className="mt-5 mb-5 d-flex align-items-center justify-content-center w-100">
        <Fade in={availabilityLoading} unmountOnExit>
          <CircularProgress color="secondary" />
        </Fade>
      </div>
      <Fade in={!availabilityLoading} unmountOnExit>
        <div className="mt-5">
          {availabilitySlots?.map((item) => {
            return (
              <Button
                color="secondary"
                className="mr-3 mb-3"
                key={item.time}
                disabled={item.reserved}
                variant={
                  selectedTime === item.time && !item.reserved
                    ? "contained"
                    : "outlined"
                }
                onClick={() => selectTime(item.time)}
              >
                <span key={item.time}>{minutesToTime(item.time)}</span>
              </Button>
            );
          })}
        </div>
      </Fade>
    </>
  );
}

export default TimePicker;
