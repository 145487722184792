import React, { useEffect, useState } from "react";
import * as Cookies from "js-cookie";
import Layout from "../../components/Layout/Layout";
import { AGORA_APP_ID } from "../../agora.config";
import { selectUser, selectUserDetails } from "../auth/authSlice";
import { useLocation, useHistory } from "react-router";
import { useSelector } from "react-redux";
import Container from "@material-ui/core/Container";
import clsx from "clsx";
import useStyles from "./styles";
import AgoraRTC, { IAgoraRTCRemoteUser } from "agora-rtc-sdk-ng";
import MediaPlayer from "../../components/MediaPlayer/MediaPlayer";
import useAgora from "../../hooks/useAgora";
import Icon from "../../components/Icon/Icon";
import MicIcon from "../../assets/icons/mic.svg";
import MicOffIcon from "../../assets/icons/mic_off.svg";
import CameraIcon from "../../assets/icons/VideoWhite.svg";
import CameraOffIcon from "../../assets/icons/Camera-Off.svg";
import cancelCallIcon from "../../assets/icons/cancel-icon.svg";
import Typography from "@material-ui/core/Typography";
import chatIcon from "../../assets/icons/chat-icon.svg";
import ChatDrawer from "../../components/ChatDrawer/ChatDrawer";
import { useMediaQuery, useTheme } from "@material-ui/core";
import CloseIcon from "../../assets/icons/Close.svg";
import { toast, ToastContainer } from "react-toastify";

const client = AgoraRTC.createClient({ codec: "h264", mode: "rtc" });

const LiveSession = () => {
  const channel = Cookies.get("channel") || "test";
  const user = useSelector(selectUser);
  const appId = AGORA_APP_ID;
  const theme = useTheme();
  const classes = useStyles();
  const history = useHistory();

  const [isChatOpen, setIsChatOpen] = useState(false);
  const [micEnabled, setMicEnabled] = useState(true);
  const [cameraEnabled, setCameraEnabled] = useState(true);
  const [sessionStarted, setSessionStarted] = useState(false);

  const {
    localAudioTrack,
    localVideoTrack,
    leave,
    join,
    joinState,
    remoteUsers,
  } = useAgora(client);

  const waitingForParticipant = !remoteUsers?.length;
  const mdDownScreenMatch = useMediaQuery(theme.breakpoints.down("md"));

  /*Join agora user*/
  useEffect(() => {
    join(appId, channel, null, user?.id);
  }, []);

  useEffect(() => {
    toast("");
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (!client) return;
    const handleUserLeft = (user) => {
      toast.info("User has left");
    };
    const handleUserJoined = (user) => {
      setSessionStarted(true);
    };
    client.on("user-left", handleUserLeft);
    client.on("user-joined", handleUserJoined);
    return () => {
      client.off("user-left", handleUserLeft);
      client.off("user-joined", handleUserJoined);
    };
  }, [client]);

  const handleMicro = () => {
    if (micEnabled) {
      localAudioTrack.setEnabled(false);
      setMicEnabled(false);
    } else {
      localAudioTrack.setEnabled(true);
      setMicEnabled(true);
    }
  };
  const handleCamera = () => {
    if (cameraEnabled) {
      localVideoTrack.setEnabled(false);
      setCameraEnabled(false);
    } else {
      localVideoTrack.setEnabled(true);
      setCameraEnabled(true);
    }
  };
  const handleCancelCall = async () => {
    const result = window.confirm("Are you sure you want to leave the call?");
    if (result) {
      await leave();
      history.goBack();
    }
  };

  const _renderBottomTabBar = () => {
    return (
      !!joinState && (
        <div className={clsx(classes.bottomTabBar)}>
          <Icon
            className={clsx(classes.controlButton)}
            src={micEnabled ? MicIcon : MicOffIcon}
            backgroundColor="white"
            width={48}
            height={48}
            imgWidth={25}
            imgHeight={25}
            onClick={handleMicro}
          />
          <Icon
            className={classes.controlButton}
            src={cameraEnabled ? CameraIcon : CameraOffIcon}
            backgroundColor="rgba(255, 255, 255, 0.2)"
            width={48}
            height={48}
            imgWidth={25}
            imgHeight={25}
            onClick={handleCamera}
          />
          <Icon
            className={classes.controlButton}
            src={cancelCallIcon}
            backgroundColor="#FF4E4E"
            onClick={handleCancelCall}
            width={48}
            height={48}
            imgWidth={25}
            imgHeight={25}
          />
          <Icon
            className={clsx(classes.controlButton)}
            src={chatIcon}
            backgroundColor={
              isChatOpen ? "#9e9e9e" : "rgba(255, 255, 255, 0.2)"
            }
            onClick={() => setIsChatOpen(!isChatOpen)}
            width={48}
            height={48}
            unreadIcon={false}
            imgWidth={20}
            imgHeight={20}
          />
        </div>
      )
    );
  };
  return (
    <Layout>
      <Container maxWidth="xl">
        <div className={clsx("mt-5 mb-5", classes.wrapper)}>
          {!isChatOpen && (
            <div className={classes.closeIconContainer}>
              <Icon
                src={CloseIcon}
                backgroundColor="#e3e3e3"
                width={38}
                height={38}
                imgHeight={18}
                imgWidth={18}
                onClick={handleCancelCall}
              />
            </div>
          )}
          <div
            className={clsx(
              classes.videCallWrapper,
              mdDownScreenMatch && isChatOpen && classes.zeroWidth
            )}
          >
            {remoteUsers.map((user) => (
              <div
                className={classes.remoteMediaPlayerContainer}
                key={user?.uid}
              >
                <MediaPlayer
                  videoTrack={user.videoTrack}
                  audioTrack={user.audioTrack}
                  isLocal={false}
                />
              </div>
            ))}
            <div className={clsx(classes.localMediaPlayerContainer)}>
              <MediaPlayer
                videoTrack={localVideoTrack}
                audioTrack={null}
                isLocal={true}
              />
            </div>
            {_renderBottomTabBar()}
            {waitingForParticipant && (
              <Typography variant="h4" className="webinar_start_soon">
                {sessionStarted ? "User has left" : "Session will start soon…"}
              </Typography>
            )}
          </div>
          <ChatDrawer
            user={user}
            channel={channel}
            open={isChatOpen}
            onClose={() => setIsChatOpen(false)}
          />
        </div>
      </Container>
    </Layout>
  );
};

export default LiveSession;
