import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Drawer from "@material-ui/core/Drawer";
import Icon from "../Icon/Icon";
import CloseIcon from "../../assets/icons/Close.svg";
import Typography from "@material-ui/core/Typography";
import {
  selectUser,
  selectUserDetails,
  updateUser,
} from "../../features/auth/authSlice";
import { useSelector, useDispatch } from "react-redux";
import styles from "./NottificationsDrawer.module.css";
import Avatar from "../Avatar/Avatar";
import { Button, Divider } from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import MaterialCloseIcon from "@material-ui/icons/Close";
import {
  fetchNotifications,
  fetchUnreadNotifications,
  markNotificationsAsRead,
  selectNotifications,
  selectUnreadNotifications,
} from "../../features/notification/notificationSlice";
import { BookingDetailsModal } from "../BookModal/BookModal";
import { getUpcomingEvents } from "../../features/profile/userSlice";
import ReactPlayer from "react-player";

TimeAgo.addLocale(en);
const timeAgo = new TimeAgo("en-US");

const useStyles = makeStyles((theme) => ({
  list: {
    height: "100vh",
    borderTopLeftRadius: 32,
    borderBottomLeftRadius: 32,
    width: 320,
    padding: "2.5rem 0",
    color: "white",
  },
  fullList: {
    width: "auto",
  },
  listItem: {
    padding: "1rem 1.5rem",
    fontSize: "16px",
    lineHeight: "22px",
  },
  paperAnchorRight: {
    borderRadius: "32px 0px 0px 32px",
  },
  primary: {
    fontWeight: "bold",
  },
  viewBookingBtn: {
    height: 30,
    backgroundColor: "#4BDD08",
    "&:hover": {
      backgroundColor: "rgba(75, 221, 8, 0.9)",
    },
  },
  ignoreBtn: {
    height: 30,
    backgroundColor: "rgba(255, 255, 255, 0.2)",
    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.1)",
    },
  },
  viewBookingBtnLabel: {
    textTransform: "none",
    color: "white",
    fontSize: "14px",
    fontWeight: 500,
  },
}));

NotificationsDrawer.propTypes = {};

function NotificationsDrawer({ toggleCustomDrawer, open }) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [bookingOpen, setBookingOpen] = useState(false);
  const [selectedBooking, selectBooking] = useState(null);

  const notifications = useSelector(selectNotifications);
  const unreadNotifications = useSelector(selectUnreadNotifications);

  const user = useSelector(selectUser);

  useEffect(() => {
    if (user) {
      dispatch(fetchNotifications({ unread: false }));
      dispatch(fetchUnreadNotifications({ unread: true }));
    }
  }, [user]);

  useEffect(() => {
    if (open === true) {
      dispatch(markNotificationsAsRead({ unread: true }));
    } else {
      dispatch(fetchUnreadNotifications({ unread: true }));
    }
  }, [open]);

  const toggleBooking = () => {
    setBookingOpen(!bookingOpen);
  };

  const onViewBooking = async (booking) => {
    await selectBooking(booking);
    setBookingOpen(true);
  };

  const onAcceptBooking = () => {
    setBookingOpen(false);
    dispatch(fetchNotifications());
    dispatch(getUpcomingEvents(user.uid));
  };

  const renderNotification = (notification) => {
    switch (notification.type) {
      case "Booking":
        return (
          <BookingNotification
            key={notification.id}
            booking={notification.Booking}
            unread={notification.unread}
            createdAt={notification.createdAt}
            onViewBooking={onViewBooking}
          />
        );
      case "BookingAccepted":
        return (
          <AcceptBookingNotification
            key={notification.id}
            booking={notification.Booking}
            unread={notification.unread}
            createdAt={notification.createdAt}
          />
        );
      case "Following":
        return (
          <FollowedNotification
            key={notification.id}
            subscriber={notification.Subscriber}
            unread={notification.unread}
            createdAt={notification.createdAt}
          />
        );
      case "LiveStarted":
        return (
          <StartedVideoNotification
            key={notification.id}
            event={notification.Event}
            unread={notification.unread}
            createdAt={notification.createdAt}
          />
        );
      default:
        return null;
    }
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
    >
      <div
        className="d-flex align-items-center"
        style={{ padding: "0 1.5rem" }}
      >
        <Icon
          src={CloseIcon}
          backgroundColor="white"
          width={56}
          height={56}
          imgWidth={25}
          imgHeight={25}
          onClick={toggleCustomDrawer}
        />
        <div className="d-flex align-items-center">
          <Typography variant="h4" color="inherit" className="ml-4">
            Notifications
          </Typography>
          {unreadNotifications.length ? (
            <div className={clsx(styles.NotificationsCount, "ml-3")}>
              {unreadNotifications.length}
            </div>
          ) : null}
        </div>
      </div>

      <div
        style={{
          padding: "0 1.5rem",
          display: unreadNotifications.length ? "block" : "none",
        }}
        className="mt-5"
      >
        <Typography variant="caption">Unread</Typography>
        <div className="mt-4">
          {unreadNotifications.map((notification, ind) => {
            return renderNotification(notification);
          })}
        </div>
      </div>
      {unreadNotifications.length && notifications.length ? (
        <div style={{ paddingLeft: "1.5rem" }} className="mt-4">
          <div className={styles.Divider} />
        </div>
      ) : null}
      <div
        style={{
          padding: "0 1.5rem",
          display: notifications.length ? "block" : "none",
        }}
        className={clsx(unreadNotifications.length ? "mt-3" : "mt-5")}
      >
        {notifications.map((notification, ind) => {
          return renderNotification(notification);
        })}
      </div>
    </div>
  );

  return (
    <div>
      <React.Fragment>
        <BookingDetailsModal
          open={bookingOpen}
          handleClose={toggleBooking}
          booking={selectedBooking}
          onAcceptSuccessful={onAcceptBooking}
        />
        <Drawer
          ModalProps={{
            BackdropProps: {
              invisible: true,
            },
          }}
          classes={{
            paperAnchorRight: classes.paperAnchorRight,
          }}
          style={{ zIndex: 140000 }}
          anchor="right"
          open={open}
          onClose={toggleCustomDrawer}
        >
          {list()}
        </Drawer>
      </React.Fragment>
    </div>
  );
}

const BookingNotification = ({ unread, booking, createdAt, onViewBooking }) => {
  const classes = useStyles();
  return (
    <div className="mb-4">
      <div className="d-flex align-items-center">
        <div className={styles.Status}>
          {unread && <div className={styles.StatusIcon} />}
        </div>
        <Avatar
          url={booking?.Participant?.photoURL}
          imgWidth={24}
          imgHeight={24}
          width={24}
          height={24}
        />
        <Typography variant="subtitle2" color="inherit" className="ml-3">
          {booking?.Participant?.displayName}
        </Typography>
        <Typography variant="caption" color="inherit" className="ml-3">
          {timeAgo.format(new Date(createdAt), "twitter")}
        </Typography>
      </div>
      <div className={styles.BookingBody}>
        <Typography variant="caption" color="inherit">
          sent you a booking request
        </Typography>
        <div className="d-flex mt-2">
          <Button
            variant="contained"
            startIcon={<CheckIcon color="inherit" />}
            onClick={() => onViewBooking(booking)}
            disableElevation
            classes={{
              root: classes.viewBookingBtn,
              label: classes.viewBookingBtnLabel,
            }}
          >
            View
          </Button>
        </div>
      </div>
    </div>
  );
};
const AcceptBookingNotification = ({ unread, booking, createdAt }) => {
  const classes = useStyles();
  return (
    <div className="mb-4">
      <div className="d-flex align-items-center">
        <div className={styles.Status}>
          {unread && <div className={styles.StatusIcon} />}
        </div>
        <Avatar
          url={booking?.Talent?.photoURL}
          imgWidth={24}
          imgHeight={24}
          width={24}
          height={24}
        />
        <Typography variant="subtitle2" color="inherit" className="ml-3">
          {booking?.Talent?.displayName}
        </Typography>
        <Typography variant="caption" color="inherit" className="ml-3">
          {timeAgo.format(new Date(createdAt), "twitter")}
        </Typography>
      </div>
      <div className={styles.BookingBody}>
        <Typography variant="caption" color="inherit">
          accepted a booking request
        </Typography>
      </div>
    </div>
  );
};
const FollowedNotification = ({ subscriber, createdAt, unread }) => {
  const classes = useStyles();
  return (
    <div className="mb-4">
      <div className="d-flex align-items-center">
        <div className={styles.Status}>
          {unread && <div className={styles.StatusIcon} />}
        </div>
        <Avatar
          url={subscriber?.photoURL}
          imgWidth={24}
          imgHeight={24}
          width={24}
          height={24}
        />
        <Typography variant="subtitle2" color="inherit" className="ml-3">
          {subscriber?.displayName}
        </Typography>
        <Typography variant="caption" color="inherit" className="ml-3">
          {timeAgo.format(new Date(createdAt), "twitter")}
        </Typography>
      </div>
      <div className={styles.BookingBody}>
        <Typography variant="caption" color="inherit">
          just followed you
        </Typography>
      </div>
    </div>
  );
};
const StartedVideoNotification = ({ event, createdAt, unread }) => {
  const classes = useStyles();
  return (
    <div className="mb-4">
      <div className="d-flex justify-content-between">
        <div>
          <div className="d-flex align-items-center">
            <div className={styles.Status}>
              {unread && <div className={styles.StatusIcon} />}
            </div>
            <Avatar
              url={event?.User?.photoURL}
              imgWidth={24}
              imgHeight={24}
              width={24}
              height={24}
            />
            <Typography variant="subtitle2" color="inherit" className="ml-3">
              {event?.User?.displayName}
            </Typography>
            <Typography variant="caption" color="inherit" className="ml-2 mr-2">
              {timeAgo.format(new Date(createdAt), "twitter")}
            </Typography>
          </div>
          <div className={styles.BookingBody}>
            <Typography variant="caption" color="inherit">
              started a {event?.type === "Webinar" ? "live video" : "session"}
            </Typography>
          </div>
        </div>
        <ReactPlayer
          playing={false}
          height={44}
          width={68}
          url={event?.intro}
          controls={false}
          className="webinar_details_player"
        />
      </div>
    </div>
  );
};

export default NotificationsDrawer;
