import { createSlice } from "@reduxjs/toolkit";
import Service from "./service";
import HomeService from "../home/service";

export const slice = createSlice({
  name: "webinarDetails",
  initialState: {
    event: null,
    participants: [],
    participantsError: null,
    amIParticipant: false,
    loading: false,
    error: null,
    moreEventsState: {
      loading: false,
      events: [],
      error: null,
    },
  },
  reducers: {
    setEvent: (state, action) => {
      state.event = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setMoreEvents: (state, action) => {
      state.moreEventsState = {
        events: action.payload,
        loading: false,
        error: null,
      };
    },
    setMoreEventsLoading: (state, action) => {
      state.moreEventsState = {
        ...state.moreEventsState,
        loading: true,
      };
    },
    setMoreEventsError: (state, action) => {
      state.moreEventsState = {
        ...state.moreEventsState,
        error: action.payload,
        loading: false,
      };
    },
    setParticipants: (state, action) => {
      state.participants = action.payload;
    },
    setAmIParticipant: (state, action) => {
      state.amIParticipant = action.payload;
    },
    setParticipantsError: (state, action) => {
      state.error = action.payload;
    },
  },
});

const {
  setEvent,
  setLoading,
  setError,
  setParticipants,
  setParticipantsError,
  setAmIParticipant,
  setMoreEvents,
  setMoreEventsError,
  setMoreEventsLoading,
} = slice.actions;

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched
export const fetchEvent = (id) => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    const response = await Service.fetchEvent(id);
    dispatch(setLoading(false));
    return dispatch(setEvent(response));
  } catch (e) {
    return dispatch(setError(e));
  }
};
export const fetchMoreEvents =
  (userId, ignoreWebinarId) => async (dispatch) => {
    dispatch(setMoreEventsLoading(true));
    try {
      let response = await HomeService.getEvents({
        userId,
        onlyUpcoming: true,
      });
      console.log("getEvents", response);
      response = response.filter((webinar) => webinar.id !== ignoreWebinarId);
      return dispatch(setMoreEvents(response));
    } catch (e) {
      console.log("Get webinars error", e);
      return dispatch(setMoreEventsError(e));
    }
  };
export const addParticipant = (event) => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    await Service.addParticipant(event);
    dispatch(setLoading(false));
    return dispatch(setParticipants([]));
  } catch (e) {
    dispatch(setLoading(false));
    return dispatch(setParticipantsError(e));
  }
};
export const amIParticipant = (eventId) => async (dispatch) => {
  try {
    const response = await Service.amIParticipant(eventId);
    return dispatch(setAmIParticipant(!!response));
  } catch (e) {
    return dispatch(setAmIParticipant(false));
  }
};
export const fetchParticipants = (eventId) => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    const response = await Service.fetchParticipants(eventId);
    dispatch(setLoading(false));
    return dispatch(setParticipants(response));
  } catch (e) {
    return dispatch(setParticipantsError(e));
  }
};

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const selectEvent = (state) => state.webinarDetails.event;
export const selectMoreEvents = (state) =>
  state.webinarDetails.moreEventsState.events;
export const selectEventLoading = (state) => state.webinarDetails.loading;
export const selectParticipants = (state) => state.webinarDetails.event;
export const selectAmIParticipant = (state) =>
  state.auth.user && state.webinarDetails.amIParticipant;

export default slice.reducer;
