/** @jsx jsx */
import { jsx } from "@emotion/core";
import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import { useDispatch } from "react-redux";
import firebase from "firebase";
import {
  showErrorSnack,
  showSuccessSnack,
} from "../../../snackbar/snackBarSlice";
import axios from "axios";
import { FirebaseAuth } from "../../../../firebase";
import { useTheme } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Icon from "../../../../components/Icon/Icon";
import { showModal } from "../../../../features/modal/modalSlice";
import FacebookIcon from "../../../../assets/icons/social/Facebook.png";
import GoogleIcon from "../../../../assets/icons/social/Google.svg";
import TextField from "../../../../components/TextField/TextField";
import PrimaryButton from "../../../../components/material/Button/PrimaryButton";
import Container from "@material-ui/core/Container";
import Logo from "../../../../components/Logo/Logo";
import * as yup from "yup";
import useClasses from "../../styles";
import { Formik } from "formik";
import PhoneField from "../../../../components/TextField/PhoneField";
import { useHideModal } from "../../../../components/modals/hooks";
import AuthTab from "./components/AuthTab";

const schemaSignUp = yup.object({
  displayName: yup.string().required(),
  email: yup.string().required().email(),
  password: yup.string().required().min(6),
});

export const SignUp = ({
  redirectToMain,
  handleErrorMessage,
  handleKeyChange,
}) => {
  const [isLoading, setLoading] = useState(false);
  const [phoneFormattedNumber, setPhoneFormattedNumber] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneMatchesMask, setPhoneMatchesMask] = useState(false);
  const [mode, setMode] = useState("user");
  const classes = useClasses();
  const dispatch = useDispatch();
  const theme = useTheme();

  const handleOnClose = useHideModal();

  const isPhoneValid = () => {
    return !phoneNumber || phoneMatchesMask || phoneNumber.length === 1;
  };

  const onPhoneNumberChange = (value, country, e, formattedValue) => {
    setPhoneFormattedNumber(formattedValue);
    setPhoneMatchesMask(country.format.length === formattedValue.length);
    setPhoneNumber(value);
  };

  const signIn = async (email, password) => {
    await FirebaseAuth.signInWithEmailAndPassword(email, password);
  };

  console.log("handleErrorMessage", handleErrorMessage);

  console.log("handleErrorMessage", handleErrorMessage);

  const handleSubmitSignUp = async (data) => {
    const { email, password, displayName, phoneNumber } = data;
    const signUpData = phoneNumber
      ? { email, password, displayName, phoneNumber }
      : { email, password, displayName };
    signUpData.registeredAsTalent = mode === "talent";
    try {
      setLoading(true);
      await axios.post("/api/auth/create", signUpData);
      await signIn(email, password);
      dispatch(
        showSuccessSnack(
          "Welcome to Butter n Binge! Check your email for instructions."
        )
      );
      handleOnClose();
    } catch (e) {
      setLoading(false);
      if (e.response && e.response.data && e.response.data.error) {
        dispatch(showErrorSnack(e.response.data.error));
      } else if (e.message) {
        dispatch(showErrorSnack(e.message));
      } else {
        dispatch(showErrorSnack("Something went wrong"));
      }
    }
  };

  const handleOpenSignInModal = () => {
    dispatch(
      showModal({
        modalType: "signInModal",
      })
    );
  };

  const googleSignIn = async () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    await oAuthSignIn(provider);
  };
  const facebookSignIn = async () => {
    const provider = new firebase.auth.FacebookAuthProvider();
    await oAuthSignIn(provider);
  };

  const oAuthSignIn = async (provider) => {
    try {
      const result = await FirebaseAuth.signInWithPopup(provider);
      const user = result.user;
      await createUserDetails(user.uid);
      dispatch(showSuccessSnack("Logged in successfully"));
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        dispatch(showErrorSnack(error.response.data.error));
      } else if (error.message) {
        dispatch(showErrorSnack(error.message));
      } else {
        dispatch(showErrorSnack("Something went wrong"));
      }
    }
  };

  const createUserDetails = async (uid) => {
    try {
      await axios.post("/api/auth", { uid });
      window.location.href = "/";
    } catch (e) {
      console.log("Handle login error 2", e);
      handleErrorMessage(e);
    }
  };

  return (
    <div css={classes.authContainer}>
      <Container maxWidth="xs">
        <Logo
          backgroundColor={theme.palette.secondary.light}
          width={90}
          height={90}
          imgWidth={86}
          imgHeight={86}
        />
        <div className="d-flex flex-row align-items-center mt-5 mb-5">
          <AuthTab
            active={mode === "user"}
            title="User"
            subtitle="Sign up as a"
            onPress={() => setMode("user")}
          />
          <AuthTab
            active={mode === "talent"}
            title="Talent"
            subtitle="Sign up as a"
            onPress={() => setMode("talent")}
            className="ml-4"
          />
        </div>
        <Typography variant="h5" className="mt-5" css={classes.authHeading}>
          Create an account
        </Typography>
        <Typography variant="body2" className="mt-3" css={classes.authDescr}>
          Sign up to continue
        </Typography>
        {mode === "user" && (
          <>
            <div className="d-flex mt-5">
              <Icon
                src={FacebookIcon}
                backgroundColor="#39579B"
                className="mr-3"
                width={57}
                height={57}
                onClick={facebookSignIn}
              />
              <Icon
                src={GoogleIcon}
                backgroundColor="rgba(30, 31, 32, 0.1)"
                width={57}
                height={57}
                onClick={googleSignIn}
              />
            </div>
            <div className="mt-4">
              <Typography variant="caption" css={classes.authSmallDescr}>
                Or use your email address
              </Typography>
            </div>
          </>
        )}
        <div className="mt-5">
          <Formik
            onSubmit={handleSubmitSignUp}
            initialValues={{
              displayName: "",
              password: "",
              email: "",
              phoneNumber: "",
            }}
            validationSchema={schemaSignUp}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              dirty,
              isSubmitting,
              values,
              touched,
              isValid,
              errors,
            }) => (
              <Form onSubmit={handleSubmit} noValidate>
                <Form.Group controlId="formDisplayName">
                  <TextField
                    label="NAME"
                    name="displayName"
                    className="mb-4"
                    isInvalid={touched.displayName && errors.displayName}
                    value={values.displayName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <span className="input-error-message">
                    {touched.displayName && errors.displayName
                      ? errors.displayName
                      : null}
                  </span>
                </Form.Group>
                {mode === "talent" && (
                  <Form.Group controlId="formPhoneNumber">
                    <PhoneField
                      label="PHONE NUMBER"
                      value={phoneFormattedNumber}
                      onChange={onPhoneNumberChange}
                    />
                  </Form.Group>
                )}
                <Form.Group controlId="formEmail">
                  <TextField
                    label="EMAIL"
                    type="email"
                    name="email"
                    className="mb-4"
                    isInvalid={touched.email && errors.email}
                    value={values.email}
                    onBlur={handleBlur}
                    onChange={(val) => {
                      console.log(touched);

                      handleChange(val);
                    }}
                  />
                  <span className="input-error-message">
                    {touched.email && errors.email ? errors.email : null}
                  </span>
                </Form.Group>
                <Form.Group
                  controlId="formPassword"
                  style={{ marginBottom: 48 }}
                >
                  <TextField
                    label="PASSWORD"
                    type="password"
                    name="password"
                    className="mb-4"
                    onBlur={handleBlur}
                    isInvalid={touched.password && errors.password}
                    value={values.password}
                    onChange={handleChange}
                  />
                  <span className="input-error-message">
                    {touched.password && errors.password
                      ? errors.password
                      : null}
                  </span>
                </Form.Group>
                <PrimaryButton
                  loading={isLoading}
                  disabled={isLoading || !isPhoneValid()}
                  color="secondary"
                  type="submit"
                  variant="contained"
                >
                  Sign up
                </PrimaryButton>
              </Form>
            )}
          </Formik>
        </div>
        <div style={{ cursor: "pointer" }}>
          <Typography
            variant="body2"
            className="mt-4"
            css={classes.authForgotPasswordText}
            onClick={() => handleOpenSignInModal()}
          >
            Already have an account? Log in
          </Typography>
        </div>
      </Container>
    </div>
  );
};
