import React, { useEffect, useState } from "react";
import { Container } from "@material-ui/core";
import Layout from "../../components/Layout/Layout";
import { useHistory, useLocation, useParams } from "react-router";
import { useDispatch } from "react-redux";
import BackButton from "../../components/BackButton/BackButton";
import { showModal } from "../../features/modal/modalSlice";
import Typography from "@material-ui/core/Typography";
import WebinarCard from "../../components/Webinars/WebinarCard";
import WebinarList from "../../components/Webinars/WebinarsList";
import CoachesList from "../../components/Coaches/CoachesList/CoachesList";
import BannersList from "../../components/Banners/BannersList";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  useAllInHoursWebinars,
  useAllTomorrowWebinars,
  useCoaches,
  useSearchWebinars,
  useWebinars,
  useWebinarsByCategory,
} from "../../hooks/query/home/home";
import homeBannerImg from "../../assets/images/home-banner.webp";
import homeBannerMobileImg from "../../assets/images/home-banner-mobile.webp";
import "./Trending.css";
import moment from "moment";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";

const bannersList = [
  {
    id: 1,
    image: require("../../assets/images/bnb_small_banner_v1-1.webp"),
    link: "/discover",
  },
  {
    id: 2,
    image: require("../../assets/images/bnb_small_banner_v1-2.webp"),
    link: "/discover/single_mom",
  },
  {
    id: 3,
    image: require("../../assets/images/bnb_small_banner_v1-3.webp"),
    link: "/discover/lets_doula_this",
  },
  {
    id: 4,
    image: require("../../assets/images/bnb_small_banner_v1-4.webp"),
    link: "/discover/dont_be_vicious_be_nutritious",
  },
];

function titleCase(str) {
  var splitStr = str.toLowerCase().split(" ");
  for (var i = 0; i < splitStr.length; i++) {
    // You do not need to check if i is larger than splitStr length, as your for does that for you
    // Assign it back to the array
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  // Directly return the joined string
  return splitStr.join(" ");
}

function Trending() {
  const [searchValue, setSearchValue] = useState("");

  const theme = useTheme();
  const matchesDownSm = useMediaQuery(theme.breakpoints.down("sm"));
  const history = useHistory();
  const coaches = useCoaches();
  const dispatch = useDispatch();
  const webinars = useWebinars();
  const location = useLocation();
  const allTomorrowWebinars = useAllTomorrowWebinars(0);
  const allInHoursWebinars = useAllInHoursWebinars();
  const searchWebinars = useSearchWebinars(searchValue);
  const { category, staticSlug } = useParams();
  const webinarsByCategory = useWebinarsByCategory(category);

  useEffect(() => {
    window.scrollTo(0, 0);
    let body = document.getElementsByTagName("body")[0];
    body.style.overflow = "visible";
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    const searchParams = new URLSearchParams(location.search);
    const search = searchParams.get("search");
    if (search) {
      setSearchValue(search);
    } else {
      setSearchValue(null);
    }
  }, [location]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const actionMode = params.get("mode");
    const actionCode = params.get("oobCode");

    if (actionMode === "resetPassword" && !webinars.isFetching) {
      handleOpenResetPasswordModal();
    }

    //console.log('action', actionMode)
    // if (!actionMode || !actionCode) {
    //     history.push('/')
    // }
    // setActionMode(actionMode);
    // setActionCode(actionCode);
    // handleUserManagementAction(actionMode, actionCode);
  }, [webinars.isFetching]);

  const handleOpenResetPasswordModal = () => {
    dispatch(
      showModal({
        modalType: "resetPassword",
      })
    );
  };

  if (
    webinars.isFetching ||
    searchWebinars.isFetching ||
    webinarsByCategory.isFetching
  ) {
    return (
      <div className="centered_spinner_container">
        <CircularProgress color="secondary" />
      </div>
    );
  }

  if (staticSlug) {
    return (
      <Layout>
        <Container maxWidth="xl">
          <div className="Wrapper mt-5">
            <div className="d-flex align-items-center">
              <BackButton
                className="discoverBackButton"
                fill="white"
                onClick={() => history.goBack()}
              />
              <Typography variant="h4" className="ml-4">
                {staticSlug === "starting-tomorrow"
                  ? "Starting tomorrow"
                  : "Starting " +
                    moment(allInHoursWebinars[0].startDate).fromNow()}
              </Typography>
            </div>
            {staticSlug === "starting-tomorrow" ? (
              <div className="mt-5 tagsWebinarList mb-5">
                {allTomorrowWebinars.data &&
                  allTomorrowWebinars.data.map((event) => (
                    <WebinarCard
                      className="tagsWebinarCard"
                      name={event.name}
                      key={event.id}
                      author={event.User.displayName}
                      seatsAmount={event.seatsAmount}
                      tags={event.Tags}
                      image={event.introThumb}
                      participantsCount={event.participantsCount}
                      video={event.intro}
                      slug={event.slug}
                      startDate={event.startDate}
                    />
                  ))}
              </div>
            ) : (
              <div className="mt-5 tagsWebinarList mb-5">
                {allInHoursWebinars.data.map((event) => (
                  <WebinarCard
                    className="tagsWebinarCard"
                    name={event.name}
                    key={event.slug}
                    author={event.User.displayName}
                    seatsAmount={event.seatsAmount}
                    participantsCount={event.participantsCount}
                    tags={event.Tags}
                    image={event.introThumb}
                    video={event.intro}
                    slug={event.slug}
                    startDate={event.startDate}
                  />
                ))}
              </div>
            )}
          </div>
        </Container>
      </Layout>
    );
  }

  if (category) {
    return (
      <Layout>
        <Container maxWidth="xl">
          <div className="Wrapper mt-5">
            <div className="d-flex align-items-center">
              <BackButton
                className="discoverBackButton"
                fill="white"
                onClick={() => history.goBack()}
              />
              <Typography variant="h4" className="ml-4">
                {titleCase(category.replaceAll("_", " "))}
              </Typography>
            </div>
            <div className="mt-5 tagsWebinarList mb-5">
              {webinarsByCategory.data.map((event) => (
                <WebinarCard
                  className="tagsWebinarCard"
                  name={event.name}
                  key={event.slug}
                  participantsCount={event.participantsCount}
                  author={event.User.displayName}
                  seatsAmount={event.seatsAmount}
                  tags={event.Tags}
                  image={event.introThumb}
                  video={event.intro}
                  slug={event.slug}
                  startDate={event.startDate}
                />
              ))}
            </div>
          </div>
        </Container>
      </Layout>
    );
  }

  if (searchValue) {
    return (
      <Layout>
        <Container maxWidth="xl">
          <div className="Wrapper mt-5 mb-5">
            <div className="d-flex align-items-center">
              <BackButton
                className="discoverBackButton"
                fill="white"
                onClick={() => history.goBack()}
              />
              {searchWebinars.data.groupedWebinars.length === 0 && (
                <Typography variant="h4" className="ml-4">
                  No results found
                </Typography>
              )}
            </div>
            {searchWebinars.data.groupedWebinars.map((webinar, ind) => (
              <WebinarList key={ind} title="" events={webinar.events} />
            ))}
          </div>
        </Container>
      </Layout>
    );
  }

  return (
    <Layout>
      <Container maxWidth="xl">
        <div className="banner">
          <img src={matchesDownSm ? homeBannerMobileImg : homeBannerImg} />
        </div>

        <Typography variant="h1" className={"mainTitle"}>
          All upcoming Hangouts
        </Typography>

        {webinars.data && webinars.data.eventsInXHours.length > 0 && (
          <WebinarList
            title={
              "Starting " +
              moment(webinars.data.eventsInXHours[0].startDate).fromNow()
            }
            events={webinars.data.eventsInXHours}
            staticSlug="starting-in-7-hours"
          />
        )}
        {webinars.data && webinars.data.tomorrowEvents.length > 0 && (
          <WebinarList
            title="Starting Tomorrow"
            events={webinars.data.tomorrowEvents}
            staticSlug="starting-tomorrow"
          />
        )}
        <BannersList items={bannersList} />
        {webinars.data &&
          webinars.data.groupedWebinars
            .slice(0, 2)
            .map((webinar) => (
              <WebinarList
                key={webinar.category.name}
                title={webinar.category.name}
                slug={webinar.category.slug}
                events={webinar.events}
              />
            ))}
        <div className="banner-2 mb-5">
          <CoachesList title="Butter n Binge Coaches" items={coaches.data} />
        </div>
        {webinars.data &&
          webinars.data.groupedWebinars
            .slice(2, 9)
            .map((webinar) => (
              <WebinarList
                key={webinar.category.name}
                title={webinar.category.name}
                slug={webinar.category.slug}
                events={webinar.events}
              />
            ))}
      </Container>
    </Layout>
  );
}

export default Trending;
