import { css } from "@emotion/core";
import bgBalloons from "../../assets/images/bg-balloons.png";

const useClasses = (theme) => ({
  talentProfile: css`
    background: #292932;
    border-radius: 10px;
    padding: 1.3rem 1.5rem 3rem;
    position: relative;
    @media (max-width: 1100px) {
      padding: 2.5rem 30px;
    }
  `,
  talentBio: css`
    background: #292932;
    border-radius: 10px;
    padding: 2rem 2.5rem;
    min-height: 370px;
    @media (max-width: 1100px) {
      padding: 2.5rem 30px;
    }
  `,
  talentNextWebinar: css`
    background: #292932;
    border-radius: 10px;
    padding: 2rem 2.5rem 4rem;
    position: relative;
    @media (max-width: 1100px) {
      padding: 2.5rem 30px;
    }
    @media (max-width: 768px) {
      padding-bottom: 7rem;
      height: 425px;
    }
  `,
  talentVideo: css`
    position: relative;
    height: 425px;
    margin: auto;
    width: 425px;
    border-radius: 15px;
    background: #000;
    margin-bottom: 2rem;
    video {
      object-fit: cover;
    }
    @media (min-width: 960px) {
      width: 100%;
      height: 350px;
    }
    @media (max-width: 768px) {
      width: 100%;
      height: 350px;
    }
  `,
  talentFullScreenIcon: css`
    position: absolute;
    bottom: 15px;
    z-index: 111;
    cursor: pointer;
    right: 15px;
  `,
  talentVideoContainer: css`
    position: relative;
  `,
  talentSocialItem: css`
    cursor: pointer;
  `,
  talentFullPlayIcon: css`
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 111;
    cursor: pointer;
    transform: translate(-50%, -50%);
    img {
      width: 60px;
    }
  `,
  talentSocial: css`
    width: fit-content;
    margin: auto;
  `,
  talentVideoSeparator: css`
    margin: 0 -1.5rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
  `,
  talentBtnsContainer: css`
    justify-content: center;
    flex-direction: column;
    align-items: center;
    button {
      margin-bottom: 15px;
    }
    @media (max-width: 768px) {
      flex-direction: column;
    }
  `,
  webinarsSeparator: css`
    margin: 0 -3rem;
  `,
  recorderdContent: css`
    position: relative;
  `,
  separator: css`
    margin: 0 -2.5rem;
  `,
  webinarStarts: css`
    position: absolute;
    top: 22px;
    right: 15px;
    height: 42px;
    background: #1d0d3e;
    border-radius: 15px;
    padding: 0 3rem 0 1rem;
    svg {
      width: 18px;
    }
    @media (max-width: 768px) {
      bottom: 50px;
      top: auto;
      left: 30px;
    }
  `,
  webinar: css`
    background-color: #733fff;
    background-image: url(${bgBalloons});
    border-radius: 12px;
    padding: 12px 1rem;
    margin: auto;
    height: 170px;
    position: relative;
    background-repeat: no-repeat;
    background-position: 100% 100%;
    background-size: contain;
    cursor: pointer;
  `,
  webinarTime: css`
    position: absolute;
    bottom: 20px;
    align-items: center;
    h4 {
      font-weight: 600;
    }
    svg {
      width: 18px;
    }
  `,
  webinarsListWrapper: css`
    background: #292932;
    padding: 2.5rem 3rem;
    border-radius: 10px;
    @media (max-width: 1100px) {
      padding: 2.5rem 30px;
    }
  `,
  recordedItem: css`
    border-radius: 12px;
    overflow: hidden;
    position: relative;
    img {
      border-radius: 12px;
    }
    svg {
      position: absolute;
      left: 20px;
      bottom: 30px;
    }
  `,
  recordedTitle: css`
    position: absolute;
    top: 20px;
    left: 15px;
    font-weight: bold;
  `,
  reviewItem: css`
    background: #3e3e48;
    padding: 2rem;
    border-radius: 23.4694px;
    position: relative;
    @media (max-width: 768px) {
      padding-bottom: 5rem;
      h3 {
        font-size: 20px;
      }
    }
  `,
  reviewDate: css`
    position: absolute;
    right: 2rem;
    top: 2rem;
    font-size: 20px;
    line-height: 39px;
    text-align: right;
    color: #8f92a1;
    @media (max-width: 768px) {
      top: auto;
      bottom: 1rem;
      left: 2rem;
      right: auto;
      font-size: 14px;
    }
  `,
  reviewAvatar: css`
    border-radius: 23.4694px;
    overflow: hidden;
    img {
      border-radius: 23.4694px;
      width: 76px;
    }
  `,
  reviewText: css`
    font-size: 18px;
    line-height: 33px;
    color: #eaeaed;
    @media (max-width: 768px) {
      font-size: 1rem;
      line-height: 150%;
    }
  `,
  subscribeBtn: css`
    background: #ffb3ab;
    border-radius: 12px;
    height: 58px;
    font-weight: bold;
    text-transform: none;
    font-size: 14px;
    width: 167px;
    line-height: 20px;
    color: #1e1f20;
    padding: 1rem;
    &:hover {
      background: #ee8a80;
    }
    @media (max-width: 768px) {
      width: 100%;
      margin-bottom: 15px;
    }
  `,
  subscribedBtn: css`
    background: #ffb3ab;
    border-radius: 12px;
    height: 58px;
    font-weight: bold;
    text-transform: none;
    font-size: 14px;
    width: 167px;
    line-height: 20px;
    color: #1e1f20;
    padding: 1rem;
    @media (max-width: 768px) {
      width: 100%;
      margin-bottom: 15px;
    }
  `,
  bookSessionBtn: css`
    background: #fbfbfb;
    border-radius: 12px;
    height: 58px;
    width: 167px;
    font-weight: bold;
    text-transform: none;
    font-size: 14px;
    line-height: 20px;
    color: #1e1f20;
    padding: 1rem;
    &:hover {
      background: #dbdbdb;
    }
    @media (max-width: 768px) {
      width: 100%;
    }
  `,
});

export default useClasses;
