import { createSlice } from "@reduxjs/toolkit";

export const slice = createSlice({
  name: "modal",
  initialState: {
    show: false,
  },
  reducers: {
    showModal: (state, action) => {
      state.show = {
        show: true,
        ...action.payload,
      };
    },
    hideModal: (state) => {
      state.show = false;
    },
  },
});

export const { showModal, hideModal } = slice.actions;

export const getCurrentModal = (state) => state.modal.show;

export default slice.reducer;
