/** @jsx jsx */
import { jsx } from "@emotion/core";
import { classes } from "./styles";
import InstagramIcon from "@material-ui/icons/Instagram";
import FacebookIcon from "@material-ui/icons/Facebook";
import YobeIcons from "../../assets/icons/Yobe.svg";
import Container from "@material-ui/core/Container";
import appStoreBtn from "../../assets/images/footer/appStoreBtn.webp";
import googleBtn from "../../assets/images/footer/googleBtn.webp";
import moment from "moment";

const Footer = () => {
  return (
    <div css={classes.footer}>
      <Container maxWidth="xl">
        <div css={classes.footerInner}>
          <div className="d-flex justify-content-between flex-wrap">
            <div css={classes.footerColumn}>
              <img src={YobeIcons} />
              <ul
                css={classes.footerSocial}
                className="d-flex align-items-center"
              >
                <a href="https://www.facebook.com/ButternbingeNYC/">
                  <li className="d-flex justify-content-center align-items-center">
                    <FacebookIcon />
                  </li>
                </a>
                <a href="https://www.instagram.com/butternbinge/">
                  <li className="d-flex justify-content-center align-items-center">
                    <InstagramIcon />
                  </li>
                </a>
              </ul>
            </div>
            <div css={classes.footerColumn}>
              <h4>Company</h4>
              <ul css={classes.linksList}>
                <li>
                  <a href="/about">About Butter N Binge</a>
                </li>
                <li>
                  {/*<a href="mailto:yes@butternbinge.com">Contact Us</a>*/}
                </li>
                <li>
                  <a href="https://www.privacypolicygenerator.info/live.php?token=ZnPjJoPXp332k9PNi8rMnW11dXNIvK0b">
                    Privacy Policy
                  </a>
                </li>
              </ul>
            </div>
            <div css={classes.footerColumn}>
              <h4>Contact Us</h4>
              <ul css={classes.linksList}>
                <li>
                  <a href="https://goo.gl/maps/SExaMEddU79GVR636">
                    25 Broadway, New York, NY 10004, USA
                  </a>
                </li>
                <li>{/*<a href="callto:+18006450421">800-645-0421</a>*/}</li>
                <li>
                  {/*<a href="mailto:yes@butternbinge.com">yes@butternbinge.com</a>*/}
                </li>
              </ul>
            </div>
            <div css={classes.footerColumn}>
              <h4>Coming soon</h4>
              <div css={classes.socialBtns} className="d-flex mb-52">
                <a href="" css={classes.appStoreBtn}>
                  <img src={appStoreBtn} alt="appStore" />
                </a>
                <a href="" css={classes.googleBtn}>
                  <img src={googleBtn} alt="googleBtn" />
                </a>
              </div>
              <span css={classes.copyright}>
                Copyright ©Butter n Binge Ltd {moment().year()}. All rights
                reserved
              </span>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Footer;
