import React, { useState } from "react";
import PropTypes from "prop-types";
import DateFnsUtils from "@date-io/date-fns";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import TextField from "../TextField/TextField";
import format from "date-fns/format";

YobeDatePicker.propTypes = {};

function YobeDatePicker({ startDate, setStartDate, label = "Start date" }) {
  const [datePickerVisible, setDatePickerVisible] = useState(false);

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <DatePicker
        open={datePickerVisible}
        disablePast
        format="dd/MM/yyyy"
        label={label}
        value={startDate}
        onAccept={(date) => {
          setDatePickerVisible(false);
          setStartDate(date);
        }}
        autoOk={true}
        onClose={() => setDatePickerVisible(false)}
        TextFieldComponent={() => (
          <TextField
            label="Start Date"
            className="mt-3"
            darkMode
            value={format(startDate, "MM/dd/yyyy")}
            onFocus={() => setDatePickerVisible(true)}
            readOnly
          />
        )}
        onChange={setStartDate}
      />
    </MuiPickersUtilsProvider>
  );
}

export default YobeDatePicker;
