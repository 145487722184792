import { css } from "@emotion/core";

const classes = {
  button: css`
    border-radius: 15px;
    background: #2b2b2b;
    height: 40px;
    font-weight: normal;
    font-size: 16px;
    line-height: 16px;
    color: white;
    padding: 0 20px;
    line-height: 20px;
    display: block;
    max-width: max-content;
    position: relative;
    font-weight: normal;
  `,
};

export { classes };
