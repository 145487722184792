import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    outline: 0,
    maxHeight: "100vh",
    overflowY: "scroll",
    zIndex: "999999!important",
    [theme.breakpoints.down("sm")]: {
      alignItems: "start",
    },
  },
  paper: {
    borderRadius: "12px",
    padding: "2.5rem",
    width: "820px",
    height: "auto",
    overflowY: "scroll",
    [theme.breakpoints.down("sm")]: {
      width: `calc(100% - ${10}px)`,
      padding: "2rem 1rem 4rem",
      height:
        "100vh" /* Use vh as a fallback for browsers that do not support Custom Properties */,
      height: "calc(var(--vh, 1vh) * 100)",
    },
    outline: 0,
  },
  bookingDetailsPaper: {
    borderRadius: "12px",
    padding: "2.5rem",
    width: "600px",
    [theme.breakpoints.down("sm")]: {
      width: `calc(100% - ${80}px)`,
    },
    outline: 0,
  },
  sliderRoot: {
    // slider styles start
    maxWidth: "100%",
    color: theme.palette.primary.main,
    "& span[data-index='0'][aria-hidden='true']": {
      transform: "translateX(-15%)",
    },
    "& span[data-index='1'][aria-hidden='true']": {
      transform: "translateX(-95%)",
    },
  },
  sliderRail: {
    color: "#ccc",
  },
  sliderThumb: {
    width: "8px",
    height: "8px",
    marginTop: "-3px",
    "&:after": {
      borderRadius: "12px",
      width: "38px",
      height: "38px",
      border: `solid 2px ${theme.palette.primary.main}`,
    },
  },
  sliderMark: {
    color: "#ccc",
    width: "8px",
    height: "8px",
    marginTop: "-3px",
    borderRadius: "50%",
    marginLeft: "-6px",
  },
  sliderMarkActive: {
    backgroundColor: theme.palette.primary.main,
  },
  sliderMarkLabel: {
    fontWeight: "bold",
    fontSize: "14px",
    color: theme.overrides.MuiPickersDay.day.color,
    marginTop: "18px",
  },
  sliderMarkLabelActive: {
    fontWeight: "bold",
  }, // slider styles end
  buttonRoot: {
    // select time buttons styles start
    width: "52px",
    height: "32px",
    margin: "-3px",
  },
  baseButtonDisabled: {
    "& span": {
      color: "#8F92A1",
    },
  },
  buttonOutlinedSecondary: {
    "&:disabled": {
      border: "none",
      backgroundColor: "#f2f2f4",
      fontSize: "12px",
    },
  },
  buttonLabel: {
    lineHeight: 0,
    fontSize: "12px",
  }, // time buttons style ends
  "@global": {
    // calendar style starts
    ".MuiPickersSlideTransition-transitionContainer.MuiPickersCalendarHeader-transitionContainer":
      {
        order: -1,
      },
    ".MuiTypography-root.MuiTypography-body1.MuiTypography-alignCenter": {
      fontWeight: "bold",
      textAlign: "start",
      marginLeft: 31,
    },
    ".MuiButtonBase-root.MuiIconButton-root.MuiPickersCalendarHeader-iconButton":
      {
        padding: 4,
        color: theme.overrides.MuiPickersDay.day.color,
      },
    ".MuiButtonBase-root.MuiIconButton-root.MuiPickersCalendarHeader-iconButton.Mui-disabled.Mui-disabled":
      {
        color: theme.palette.text.disabled,
      },
    ".MuiPickersCalendarHeader-switchHeader": {
      maxWidth: 300,
      minWidth: 290,
    },
    ".MuiTypography-root.MuiPickersCalendarHeader-dayLabel.MuiTypography-caption":
      {
        color: theme.overrides.MuiPickersDay.day.color,
      },
  },
  whatsNextImage: {
    width: "20%",
    borderRadius: 10,
  },
}));
