/** @jsx jsx */
import { jsx } from "@emotion/core";
import useClasses from "./styles";
import React, { useEffect, useState } from "react";
import styles from "./TalentProfile.module.css";
import "./TalentProfile.css";
import Layout from "../../components/Layout/Layout";
import DateIcon from "../../assets/icons/date_range.svg";
import YoutubeIcon from "../../assets/icons/social/youtube_outlined.svg";
import InstagramIcon from "../../assets/icons/social/instagram.svg";
import FacebookIcon from "../../assets/icons/social/facebook_square.svg";
import BackButton from "../../components/BackButton/BackButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import Avatar from "../../components/Avatar/Avatar";
import Separator from "../../components/Separator/index";
import ArrowButton from "../../components/ArrowButton/ArrowButton";
import { ReactComponent as ColoredClock } from "../../assets/icons/coloredClock.svg";
import { ReactComponent as ClockColored } from "../../assets/icons/ClockColoured.svg";
import { useDispatch, useSelector } from "react-redux";
import { selectUser, selectUserDetails } from "../auth/authSlice";
import {
  getAmISubscribed,
  getHistoryEvents,
  getRecordedEvents,
  getTalent,
  getUpcomingEvents,
  selectAmISubscribed,
  selectHistoryEvents,
  selectSubscribeState,
  selectTalentRecordedEvents,
  selectTalentUpcomingEvents,
  selectTalentUser,
  subscribe,
} from "./talentProfileSlice";
import { useHistory, useParams } from "react-router";
import BookModal from "../../components/BookModal/BookModal";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { Hidden, useTheme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Tag from "../../components/Tag/index";
import WebinarsRowList from "../../features/webinarDetails/components/WebinarsRowList";
import Tab from "../../components/Tab/Tab";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { showModal } from "../../features/modal/modalSlice";
import format from "date-fns/format";
import { isUserActive } from "../../utils/time";
import Container from "@material-ui/core/Container";
import Status from "../../components/Status/Status";
import { showErrorSnack } from "../snackbar/snackBarSlice";
import Player from "../../components/Player/Player";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  buttonRoot: {
    borderRadius: 12,
    border: 0,
    backgroundColor: "rgba(143, 146, 161, 0.2)",
    width: "100%",
    color: theme.palette.text.primary,
    fontSize: 14,
    fontWeight: 600,
    height: 56,
  },
  subscribedButton: {
    borderRadius: 12,
    border: 0,
    backgroundColor: theme.palette.success.main,
    width: "100%",
    color: "white",
    fontSize: 14,
    fontWeight: 600,
    height: 56,
    "&:disabled": {
      backgroundColor: theme.palette.success.main,
      color: "white",
    },
  },
  buttonLabel: {
    textTransform: "none",
  },
}));

TalentProfile.propTypes = {};

const webinarsList = [
  {
    id: 1,
    title: "Exclusive session LA Beast | User",
    descr:
      "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ratione impedit consectetur molestias, aliquid eum  voluptatibus quam natus? Aliquid, maiores. Laboriosam inventore itaque officia voluptatem fugit",
    author: "Johann Ive",
    tags: ["Travel", "Art", "Music", "Food"],
  },
  {
    id: 2,
    title: "Exclusive session LA Beast | User",
    descr:
      "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ratione impedit consectetur molestias, aliquid eum  voluptatibus quam natus? Aliquid, maiores. Laboriosam inventore itaque officia voluptatem fugit",
    author: "Johann Ive",
    tags: ["Travel", "Art", "Music", "Food"],
  },
];

const tags = ["Travel", "Art", "Sport"];

/**
 * @return {null}
 */
function TalentProfile(props) {
  const user = useSelector(selectUser);
  const userDetails = useSelector(selectUserDetails);
  const { id } = useParams();
  const theme = useTheme();
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useClasses();
  const historyEvents = useSelector(selectHistoryEvents);

  const matchesDownMd = useMediaQuery(theme.breakpoints.down("md"));

  const [activeTab, setActiveTab] = useState("webinar");
  const [bookModalOpen, setBookModalOpen] = useState(false);
  const [playing, setPlaying] = useState(true);

  const upcomingEvents = useSelector(selectTalentUpcomingEvents);
  const recordedEvents = useSelector(selectTalentRecordedEvents);

  const talent = useSelector(selectTalentUser);
  const [isBuffering, setIsBuffering] = useState(true);
  const [isProfileVideoPlaying, setIsProfileVideoPlaying] = useState(false);

  const amISubscribed = useSelector(selectAmISubscribed);
  const subscribeState = useSelector(selectSubscribeState);

  useEffect(() => {
    dispatch(getTalent(id));
    dispatch(getAmISubscribed(id));
    dispatch(getUpcomingEvents(id));
    dispatch(getHistoryEvents(id));
    dispatch(getRecordedEvents(id));
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (talent) document.title = talent.displayName + " | Butter n Binge";
  }, [talent]);

  const authEventHandler = () => {
    if (!user) {
      handleOpenSignInModal();
      dispatch(showErrorSnack("Please log in first"));
    }
  };

  const handleOpenSignInModal = () => {
    dispatch(
      showModal({
        modalType: "signInModal",
      })
    );
  };

  const checkWebinarStarting = (startDate) => {
    let result = moment(startDate).fromNow();

    if (moment().diff(moment(startDate)) < 0) {
      return result;
    }
  };

  const onBookSession = () => {
    if (user) {
      setBookModalOpen(true);
    } else {
      handleOpenSignInModal();
      dispatch(showErrorSnack("Please log in first"));
    }
  };
  const onSubscribe = () => {
    authEventHandler();
    dispatch(subscribe(id));
  };
  const openLinkInNewTab = (link) => {
    window.open(link, "_blank");
  };
  const goIntroFullScreen = () => {
    const elem = document.getElementById("video-intro");
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.mozRequestFullScreen) {
      /* Firefox */
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) {
      /* Chrome, Safari and Opera */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      /* IE/Edge */
      elem.msRequestFullscreen();
    }
  };
  const onEventDetails = (eventId) => {
    history.push({
      pathname: `/webinar/${eventId}`,
      search: "",
    });
  };

  if (!talent) {
    return (
      <div className="centered_spinner_container">
        <CircularProgress color="secondary" />
      </div>
    );
  }

  return (
    <Layout>
      <div className={styles.Wrapper}>
        <BookModal
          id={id}
          open={bookModalOpen}
          handleOpen={() => setBookModalOpen(true)}
          handleClose={() => setBookModalOpen(false)}
          talent={talent}
        />

        <Container maxWidth="xl" className="mb-5 mt-5">
          <Grid container spacing={5}>
            <Grid item md={5} lg={4} xs={12}>
              <div css={classes.talentProfile}>
                <BackButton
                  fill="#8F92A1"
                  onClick={() => history.goBack()}
                  className="mb-4"
                />
                <div css={classes.talentVideo}>
                  {talent && talent.videoIntro ? (
                    <Player
                      url={talent.videoIntro}
                      play={false}
                      controls={false}
                    />
                  ) : (
                    <div className={styles.UserImagePlaceholder}>
                      <Avatar url={null} />
                    </div>
                  )}
                </div>
                {/* <div css={classes.talentVideoContainer}>
                  <div
                    css={classes.talentVideo}
                    onClick={() => {
                      setIsProfileVideoPlaying(!isProfileVideoPlaying)
                    }}
                  >
                    {talent.videoIntro ? (
                      <ReactPlayer
                        playsinline
                        url={talent.videoIntro}
                        loop
                        muted={!global.dontMute}
                        playing={isProfileVideoPlaying}
                        onReady={() => setIsBuffering(false)}
                        onPlay={() => setIsBuffering(false)}
                        onBuffer={() => setIsBuffering(true)}
                        config={{
                          file: {
                            forceVideo: true,
                            attributes: {
                              id: 'video-intro',
                            },
                          },
                        }}
                        width="100%"
                        height="100%"
                      />
                    ) : (
                      <div className={styles.UserImagePlaceholder}>
                        <Avatar url={null} />
                      </div>
                    )}
                    {!isProfileVideoPlaying && (
                      <div css={classes.talentFullPlayIcon}>
                        <img src={PlayIcon} />
                      </div>
                    )}
                  </div>
                  <div onClick={goIntroFullScreen} css={classes.talentFullScreenIcon}>
                    <img src={FullScreenIcon} />
                  </div>
                </div> */}
                <div className="d-flex align-items-center justify-content-center">
                  <Typography variant="h4" color="textPrimary" className="mr-3">
                    {talent.displayName}
                  </Typography>
                  {isUserActive(talent.timestamp) ? (
                    <Status styles={classes.coachStatus}>Online now</Status>
                  ) : (
                    ""
                  )}
                </div>
                <Separator css={classes.talentVideoSeparator} />
                {recordedEvents?.count ? (
                  <>
                    <div className="d-flex mr-3 justify-content-center">
                      <Typography
                        variant="subtitle2"
                        color="textPrimary"
                        className="font-weight-bold mr-2"
                      >
                        {recordedEvents.count}
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        style={{ color: "#8F92A1" }}
                        className="font-weight-bold"
                      >
                        Published webinars
                      </Typography>
                    </div>
                    <Separator css={classes.talentVideoSeparator} />
                  </>
                ) : (
                  <div className="mb-5"></div>
                )}
                <div css={classes.talentSocial}>
                  <div
                    className="d-flex align-items-center mb-3"
                    onClick={() =>
                      talent.facebookLink &&
                      openLinkInNewTab(talent.facebookLink)
                    }
                    css={classes.talentSocialItem}
                  >
                    <img
                      src={FacebookIcon}
                      style={{ height: 25, width: 25 }}
                      className="mr-2"
                    />
                    <Typography
                      variant="caption"
                      style={{ color: "#8F92A1", wordBreak: "break-word" }}
                    >
                      {talent.facebookLink ? talent.facebookLink : "Not linked"}
                    </Typography>
                  </div>
                  <div
                    className="d-flex align-items-center mb-3"
                    onClick={() =>
                      talent.instagramLink &&
                      openLinkInNewTab(talent.instagramLink)
                    }
                    css={classes.talentSocialItem}
                  >
                    <img
                      src={InstagramIcon}
                      style={{ height: 25, width: 25 }}
                      className="mr-2"
                    />
                    <Typography
                      variant="caption"
                      style={{ color: "#8F92A1", wordBreak: "break-word" }}
                    >
                      {talent.instagramLink
                        ? talent.instagramLink
                        : "Not linked"}
                    </Typography>
                  </div>
                  <div
                    className="d-flex align-items-center mb-3"
                    onClick={() =>
                      talent.youtubeLink && openLinkInNewTab(talent.youtubeLink)
                    }
                    css={classes.talentSocialItem}
                  >
                    <img
                      src={YoutubeIcon}
                      style={{ height: 25, width: 25 }}
                      className="mr-2"
                    />
                    <Typography
                      variant="caption"
                      style={{ color: "#8F92A1", wordBreak: "break-word" }}
                    >
                      {talent.youtubeLink ? talent.youtubeLink : "Not linked"}
                    </Typography>
                  </div>
                </div>
                <Separator css={classes.talentVideoSeparator} />
                <div className="d-flex" css={classes.talentBtnsContainer}>
                  <Button
                    startIcon={<img src={DateIcon} />}
                    onClick={onBookSession}
                    css={classes.bookSessionBtn}
                  >
                    Book a session
                  </Button>
                </div>
              </div>
            </Grid>
            <Grid item md={7} xs={12} lg={8}>
              <Grid container spacing={5}>
                <Grid item md={12} lg={6} xs={12}>
                  <div css={classes.talentBio}>
                    <Typography variant="h4" color="textPrimary">
                      Bio
                    </Typography>
                    <Separator
                      className="mt-4 mb-4"
                      style={classes.separator}
                    />
                    <Typography
                      variant="caption"
                      color="textSecondary"
                      className="mb-3"
                      style={{ wordBreak: "break-word" }}
                    >
                      {talent.bio}
                    </Typography>
                    {talent.Category && (
                      <div className="d-flex mt-4">
                        <Tag>{talent.Category.name}</Tag>
                      </div>
                    )}
                  </div>
                </Grid>
                <Grid item md={12} xs={12} lg={6}>
                  <div css={classes.talentNextWebinar}>
                    <Typography variant="h4" color="textPrimary">
                      {upcomingEvents[0]
                        ? "Next Hangout"
                        : "No upcoming hangouts"}
                    </Typography>
                    {upcomingEvents[0] && (
                      <div
                        css={classes.webinarStarts}
                        className="d-flex align-items-center"
                      >
                        <ColoredClock />
                        <Typography
                          variant="caption"
                          color="textPrimary"
                          className="ml-2 mr-2"
                        >
                          {checkWebinarStarting(upcomingEvents[0].startDate)}
                        </Typography>
                        <ArrowButton
                          onClick={() =>
                            history.push(
                              `/webinar/details/${upcomingEvents[0].slug}`
                            )
                          }
                        />
                      </div>
                    )}
                    <Separator
                      className="mt-4 mb-4"
                      style={classes.separator}
                    />
                    <div css={classes.webinar} className="mt-5">
                      <Typography
                        variant="subtitle2"
                        color="textPrimary"
                        className="mb-3"
                      >
                        {upcomingEvents[0]
                          ? upcomingEvents[0].name
                          : `${
                              talent.displayName.split(" ")[0]
                            } doesn't have any upcoming hangouts yet. Stay tuned!`}
                      </Typography>
                      {upcomingEvents[0] && (
                        <div className="d-flex" css={classes.webinarTime}>
                          <ClockColored fill="#FFB3AB" />
                          <Typography
                            variant="h4"
                            color="textPrimary"
                            className="ml-2"
                          >
                            {format(
                              new Date(upcomingEvents[0].startDate),
                              "h:mm a"
                            )}
                          </Typography>
                        </div>
                      )}
                    </div>
                  </div>
                </Grid>
                {upcomingEvents.length > 0 && (
                  <Hidden mdDown>
                    <Grid item md={12} xs={12}>
                      <div css={classes.webinarsListWrapper}>
                        <div className="d-flex align-items-center">
                          <Tab
                            title={
                              matchesDownMd
                                ? "Upcoming Hangouts"
                                : "Upcoming Binge Hangouts"
                            }
                            onClick={() => setActiveTab("webinar")}
                            active={activeTab === "webinar"}
                          />
                          <Tab
                            title="Past Hangouts"
                            onClick={() => setActiveTab("recorded")}
                            active={activeTab === "recorded"}
                          />
                          {/* <Tab title="Reviews" onClick={() => setActiveTab('reviews')} active={activeTab === 'reviews'} /> */}
                        </div>
                        <Separator
                          className="mt-4 mb-5"
                          css={classes.webinarsSeparator}
                        />
                        {activeTab === "webinar" && upcomingEvents && (
                          <WebinarsRowList items={upcomingEvents} />
                        )}

                        {activeTab === "recorded" && (
                          <div css={classes.recorderdContent}>
                            {historyEvents && (
                              <WebinarsRowList items={historyEvents} />
                            )}
                          </div>
                        )}
                      </div>
                    </Grid>
                  </Hidden>
                )}
              </Grid>
            </Grid>
            {upcomingEvents.length > 0 && (
              <Hidden lgUp>
                <Grid item md={12} xs={12}>
                  <div css={classes.webinarsListWrapper}>
                    <div className="d-flex align-items-center">
                      <Tab
                        title={
                          matchesDownMd
                            ? "Upcoming Hangouts"
                            : "Upcoming Binge Hangouts"
                        }
                        onClick={() => setActiveTab("webinar")}
                        active={activeTab === "webinar"}
                      />
                      <Tab
                        title="Past Hangouts"
                        onClick={() => setActiveTab("recorded")}
                        active={activeTab === "recorded"}
                      />
                      {/* <Tab title="Reviews" onClick={() => setActiveTab('reviews')} active={activeTab === 'reviews'} /> */}
                    </div>
                    <Separator
                      className="mt-4 mb-5"
                      css={classes.webinarsSeparator}
                    />
                    {activeTab === "webinar" && upcomingEvents && (
                      <WebinarsRowList items={upcomingEvents} />
                    )}

                    {activeTab === "recorded" && (
                      <div css={classes.recorderdContent}>
                        {historyEvents && (
                          <WebinarsRowList items={historyEvents} />
                        )}
                      </div>
                    )}

                    {/* {activeTab === 'reviews' && (
                        <div css={classes.reviewsContent}>
                          <Grid container spacing={5}>
                            <Grid item md={6}>
                              <div css={classes.reviewItem}>
                                <p css={classes.reviewDate}>14 December 2020</p>
                                <div className="d-flex mb-3">
                                  <div css={classes.reviewAvatar} className="mr-3">
                                    <img src={reviewAvatar} />
                                  </div>
                                  <div>
                                    <Typography
                                      variant="h3"
                                      color="textPrimary"
                                      className="font-weight-normal mt-2 mb-3"
                                    >
                                      Ann Tailor
                                    </Typography>
                                    <div css={classes.reviewRating} className="d-flex align-items-center">
                                      <StarIcon style={{ fontSize: 30, color: '#FFAA27' }} />
                                      <Typography variant="h3" color="textPrimary" className="font-weight-normal ml-1">
                                        5.0
                                      </Typography>
                                    </div>
                                  </div>
                                </div>
                                <p css={classes.reviewText}>
                                  Lorem ipsum dolor, sit amet consectetur adipisicing elit. Facere cumque eos vitae,
                                  nemo laudantium, quisquam amet nobis dolorum minima tempore quo, sequi nam temporibus
                                  consequuntur fugit et tenetur enim dolores!
                                </p>
                              </div>
                            </Grid>
                            <Grid item md={6}>
                              <div css={classes.reviewItem}>
                                <p css={classes.reviewDate}>14 December 2020</p>
                                <div className="d-flex mb-3">
                                  <div css={classes.reviewAvatar} className="mr-4">
                                    <img src={reviewAvatar} />
                                  </div>
                                  <div>
                                    <Typography
                                      variant="h3"
                                      color="textPrimary"
                                      className="font-weight-normal mt-2 mb-3"
                                    >
                                      Ann Tailor
                                    </Typography>
                                    <div css={classes.reviewRating} className="d-flex align-items-center">
                                      <StarIcon style={{ fontSize: 30, color: '#FFAA27' }} />
                                      <Typography variant="h3" color="textPrimary" className="font-weight-normal ml-1">
                                        5.0
                                      </Typography>
                                    </div>
                                  </div>
                                </div>
                                <p css={classes.reviewText}>
                                  Lorem ipsum dolor, sit amet consectetur adipisicing elit. Facere cumque eos vitae,
                                  nemo laudantium, quisquam amet nobis dolorum minima tempore quo, sequi nam temporibus
                                  consequuntur fugit et tenetur enim dolores!
                                </p>
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                      )} */}
                  </div>
                </Grid>
              </Hidden>
            )}
          </Grid>
        </Container>
      </div>
    </Layout>
  );
}

export default TalentProfile;

// return talent ? (
//   <Layout>
//     <div className={styles.Wrapper}>
//       <BookModal
//         id={id}
//         open={bookModalOpen}
//         handleOpen={() => setBookModalOpen(true)}
//         handleClose={() => setBookModalOpen(false)}
//         talent={talent}
//       />
//       <Container maxWidth="lg" style={{ background: 'white' }}>
//         <Grid container className="ContentContainer">
//           <Grid item md={6} sm={12} xs={12} zeroMinWidth>
//             <div className={clsx(!matchesDownMd && styles.UserSection, matchesDownMd && styles.UserSectionMdDown)}>
//               <Grid container spacing={3} direction="column">
//                 <Grid item md={12}>
//                   <div className={styles.UserImageContainer}>
//                     {talent.videoIntro ? (
//                       <ReactPlayer
//                         url={talent.videoIntro}
//                         controls={false}
//                         playing={playing}
//                         loop
//                         onClick={() => setPlaying(!playing)}
//                         className={clsx('talent_card_video', styles.Intro)}
//                         config={{
//                           file: {
//                             forceVideo: true,
//                             attributes: {
//                               id: 'video-intro',
//                             },
//                           },
//                         }}
//                         width="100%"
//                         height={450}
//                       />
//                     ) : (
//                       <div className={styles.UserImagePlaceholder}>
//                         <Avatar url={null} />
//                       </div>
//                     )}
//                     <div className={styles.FullScreenIconContainer} onClick={goIntroFullScreen}>
//                       <img src={FullScreenIcon} />
//                     </div>
//                     {!playing && (
//                       <div className={styles.PlayIconContainer}>
//                         <img className={styles.PlayIcon} src={PlayIcon} />
//                       </div>
//                     )}
//                   </div>
//                 </Grid>
//               </Grid>
//             </div>
//           </Grid>
//           <Grid item md={5} sm={12} xs={12} zeroMinWidth className="align-self-center">
//             <Paper className={styles.UserDetails} elevation={0}>
//               <Grid container alignItems="center" alignContent="center" spacing={3}>
//                 <Grid item md={12}>
//                   <Avatar url={talent.photoURL} />
//                 </Grid>
//                 <Grid item md={12}>
//                   <div className="d-flex flex-row">
//                     <Typography variant="h4" style={{ fontSize: 34 }}>
//                       {talent.displayName}
//                     </Typography>
//                     <div className="ml-2">
//                       <StarIcon
//                         style={{
//                           fontSize: 30,
//                           color: '#FACD5D',
//                           marginBottom: 10,
//                           marginRight: 4,
//                           marginLeft: 10,
//                         }}
//                       />
//                       <span
//                         style={{ fontSize: 24, paddingTop: 30 }}
//                         className={clsx('font-weight-bold', !talent.averageRating && 'd-none')}
//                       >
//                         {talent.averageRating}
//                       </span>
//                     </div>
//                   </div>
//                 </Grid>
//                 <UserPresence status={talent.timestamp ? isUserActive(talent.timestamp) : false} />
//               </Grid>
//               <Grid container alignItems="center" alignContent="center" className="mt-3" spacing={3}>
//                 <Grid item md={6}>
//                   <div className="d-flex">
//                     <Typography variant="h4" className={styles.Count}>
//                       {recordedEvents.length}
//                     </Typography>
//                     <Typography variant="h4" color="textSecondary" className={clsx(styles.Count, 'ml-1')}>
//                       Videos
//                     </Typography>
//                   </div>
//                 </Grid>
//                 <Grid item>
//                   <div className="d-flex">
//                     <Typography variant="h4" className={styles.Count}>
//                       {talent.followersCount}
//                     </Typography>
//                     <Typography variant="h4" color="textSecondary" className={clsx(styles.Count, 'ml-1')}>
//                       {' '}
//                       Followers
//                     </Typography>
//                   </div>
//                 </Grid>
//               </Grid>
//               {!userDetails || id !== userDetails.slug ? (
//                 <Grid
//                   container
//                   alignItems="center"
//                   alignContent="center"
//                   justify="space-between"
//                   className="mt-3"
//                   spacing={3}
//                 >
//                   <Grid item lg={6} md={12} sm={12} xs={12}>
//                     {!amISubscribed ? (
//                       <Button
//                         startIcon={<AddIcon />}
//                         classes={{
//                           root: classes.buttonRoot,
//                           label: classes.buttonLabel,
//                         }}
//                         color={'default'}
//                         onClick={onSubscribe}
//                         disabled={subscribeState.loading}
//                         variant="contained"
//                         disableElevation
//                       >
//                         Subscribe
//                       </Button>
//                     ) : (
//                       <Button
//                         startIcon={<CheckIcon />}
//                         variant="contained"
//                         classes={{
//                           root: classes.subscribedButton,
//                           label: classes.buttonLabel,
//                         }}
//                         disabled={true}
//                         disableElevation
//                       >
//                         Subscribed
//                       </Button>
//                     )}
//                   </Grid>
//                   <Grid item lg={6} md={12} sm={12} xs={12}>
//                     <PrimaryButton
//                       variant="contained"
//                       color="primary"
//                       startIcon={<img src={DateIcon} />}
//                       onClick={onBookSession}
//                     >
//                       Book a session
//                     </PrimaryButton>
//                   </Grid>
//                 </Grid>
//               ) : null}
//               {talent.Category && (
//                 <div className="mt-5">
//                   <Tag title={talent.Category.name} />
//                 </div>
//               )}
//             </Paper>
//           </Grid>
//           {talent.facebookLink || talent.instagramLink || talent.youtubeLink ? (
//             <Grid item md={1} sm={12} xs={12} zeroMinWidth className="ml-auto">
//               <div className="social-icons-row">
//                 <Hidden mdDown>
//                   <Typography color="textSecondary" variant="subtitle2" className={styles.SocialText}>
//                     Social
//                   </Typography>
//                 </Hidden>
//                 {talent.facebookLink && (
//                   <div
//                     className={clsx(styles.Link, 'social-icon')}
//                     onClick={() => openLinkInNewTab(talent.facebookLink)}
//                   >
//                     <img src={FacebookIcon} className="social-icon" />
//                   </div>
//                 )}
//                 {talent.instagramLink && (
//                   <div
//                     className={clsx(styles.Link, 'social-icon')}
//                     onClick={() => openLinkInNewTab(talent.instagramLink)}
//                   >
//                     <img src={InstagramIcon} className="social-icon" />
//                   </div>
//                 )}
//                 {talent.youtubeLink && (
//                   <div
//                     className={clsx(styles.Link, 'social-icon')}
//                     onClick={() => openLinkInNewTab(talent.youtubeLink)}
//                   >
//                     <img src={YoutubeIcon} className="social-icon" />
//                   </div>
//                 )}
//               </div>
//             </Grid>
//           ) : null}
//         </Grid>
//         <div>
//           <div>
//             <Divider className={styles.divider} />
//             <Typography variant="h4">Description</Typography>
//             <Typography variant="body2" color="textSecondary" className="mt-2 mb-4">
//               {talent.bio}
//             </Typography>
//           </div>
//           {upcomingEvents && upcomingEvents.length ? (
//             <div>
//               <Divider className={styles.divider} />
//               <Typography variant="h4">Upcoming</Typography>
//               <Grid container spacing={3} className="mt-3">
//                 {upcomingEvents.map((item) => (
//                   <Grid key={item.id} item lg={4} md={5} sm={12} xs={12}>
//                     <EventCard {...item} onDetailsClick={() => onEventDetails(item.id)} />
//                   </Grid>
//                 ))}
//               </Grid>
//             </div>
//           ) : null}
//           {recordedEvents && recordedEvents.length ? (
//             <div>
//               <Divider className={styles.divider} />
//               <Typography variant="h4">Recorded</Typography>
//               <Grid container spacing={3} className="mt-3">
//                 {recordedEvents.map((event) => {
//                   return (
//                     <Grid
//                       item
//                       lg={4}
//                       md={5}
//                       xs={12}
//                       key={event.id}
//                       onClick={() => history.push(`/webinar/details/${event.id}`)}
//                       className="cursor-pointer"
//                     >
//                       <RecordedVideoCard {...event} />
//                     </Grid>
//                   )
//                 })}
//               </Grid>
//             </div>
//           ) : null}
//         </div>
//       </Container>
//     </div>
//   </Layout>
// ) : id === user?.uid ? (
//   <Redirect to="/profile" />
// ) : null
