import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectUser, selectUserDetails } from "../features/auth/authSlice";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const user = useSelector(selectUser);
  const userDetails = useSelector(selectUserDetails);
  return (
    <Route
      {...rest}
      render={(props) =>
        !!user && userDetails ? (
          <Component {...props} />
        ) : (
          <Redirect to="/trending" />
        )
      }
    />
  );
};

export default PrivateRoute;
