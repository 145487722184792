import React from "react";
import { CardElement } from "@stripe/react-stripe-js";
import styles from "./CardSection.module.css";

const CARD_ELEMENT_OPTIONS = {
  hidePostalCode: true,
  style: {
    base: {
      color: "#ffffff",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

const CardSection = ({ setErrorMessage }) => {
  return (
    <div className={styles.CardInputWrap}>
      <CardElement
        options={CARD_ELEMENT_OPTIONS}
        onChange={() => setErrorMessage(false)}
      />
    </div>
  );
};

export default CardSection;
