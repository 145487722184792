import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Drawer from "@material-ui/core/Drawer";
import SettingsIcon from "@material-ui/icons/Settings";
import PrimaryButton from "../material/Button/PrimaryButton";
import Icon from "../Icon/Icon";
import CloseIcon from "../../assets/icons/Close.svg";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import Button from "@material-ui/core/Button";
import NotificationsDrawer from "../../components/NotificationsDrawer/NotificationsDrawer";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import UserIcon from "../../assets/icons/user.svg";
import NotificationIcon from "../../assets/icons/notificationsDark.svg";
import LockIcon from "../../assets/icons/LockDark.svg";
import HelpIcon from "../../assets/icons/HelpDark.svg";
import ModeUserIcon from "../../assets/icons/ModeUser.svg";
import ModeTalentIcon from "../../assets/icons/ModeTalent.svg";
import EmailIcon from "../../assets/icons/email.svg";
import SignOutIcon from "../../assets/icons/SignOutDark.svg";
import SpeakerNotesIcon from "@material-ui/icons/SpeakerNotes";
import { FirebaseAuth } from "../../firebase";
import { persistor } from "../../app/store";
import { selectUserDetails, updateUser } from "../../features/auth/authSlice";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { CircularProgress, useTheme } from "@material-ui/core";
import CenteredBox from "../Layout/CenteredBox";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { showErrorSnack } from "../../features/snackbar/snackBarSlice";

const useStyles = makeStyles((theme) => ({
  list: {
    height: "100vh",
    borderTopLeftRadius: 32,
    borderBottomLeftRadius: 32,
    backgroundColor: theme.palette.background.paper,
    width: 400,
    padding: "2.5rem 0",
    color: "white",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  fullList: {
    width: "auto",
  },
  listItem: {
    padding: "1rem 1.5rem",
    fontSize: "16px",
    lineHeight: "22px",
  },
  paperAnchorRight: {
    borderRadius: "32px 0px 0px 32px",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  primary: {
    fontWeight: "bold",
  },
  btnSettings: {
    width: 175,
    borderRadius: 12,
    textTransform: "capitalize",
    border: 0,
    color: "#1E1F20",
    fontSize: 14,
    fontWeight: 600,
    height: 58,
  },
}));

SettingsDrawer.propTypes = {};

function SettingsDrawer({ onNotificationsClick, enableSmsNotifications }) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const userDetails = useSelector(selectUserDetails);
  const [notificationsDrawer, setNotificationsDrawer] = useState(false);
  const [smsNotificationsEnabled, setSmsNotificationsEnabled] = useState(
    userDetails.smsNotificationsEnabled
  );

  const signOut = async () => {
    setOpen(false);
    FirebaseAuth.signOut().then(() => {
      persistor.purge();
      localStorage.removeItem("authToken");
    });
  };

  useEffect(() => {
    setSmsNotificationsEnabled(userDetails.smsNotificationsEnabled);
  }, [userDetails]);

  const changeUserMode = async () => {
    try {
      await dispatch(
        updateUser({ mode: userDetails?.mode === "User" ? "Talent" : "User" })
      );
    } catch (e) {
      console.log("Switching mode failed", e);
      dispatch(showErrorSnack("Switching mode failed!"));
    }
  };

  const onChangeSmsNotification = () => {
    if (userDetails.smsNotificationsEnabled) {
      dispatch(updateUser({ smsNotificationsEnabled: false }));
    } else {
      enableSmsNotifications();
    }
  };

  const toggleNotificationsDrawer = () => {
    setNotificationsDrawer(!notificationsDrawer);
    setOpen(false);
  };

  const ItemsList = [
    {
      name: `${
        smsNotificationsEnabled ? "Disable" : "Enable"
      } sms notifications`,
      icon: EmailIcon,
      onClick: onChangeSmsNotification,
    },
    // {
    //   name: "My Account",
    //   icon: UserIcon,
    //   onClick: () => console.log('My Account')
    // },
    {
      name: "Notifications",
      icon: NotificationIcon,
      onClick: toggleNotificationsDrawer,
    },
    // {
    //   name: "Security & Privacy",
    //   icon: LockIcon,
    //   onClick: () => console.log('Security')
    // },
    // {
    //   name: "Help",
    //   icon: HelpIcon,
    //   onClick: () => console.log('Help')
    // },
    {
      name: "Sign out",
      icon: SignOutIcon,
      onClick: async () => signOut(),
    },
  ];

  const toggleCustomDrawer = () => {
    setOpen(!open);
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
    >
      <div
        className="d-flex align-items-center"
        style={{ padding: "0 1.5rem" }}
      >
        <Icon
          src={CloseIcon}
          backgroundColor="white"
          width={56}
          height={56}
          imgWidth={25}
          imgHeight={25}
          onClick={toggleCustomDrawer}
          borderRadius={18}
        />
        <Typography variant="h4" color="inherit" className="ml-4">
          Settings
        </Typography>
      </div>

      <List className="mt-3">
        {!!userDetails?.registeredAsTalent && <ListItem
          button
          key="Switch to User"
          className={classes.listItem}
          onClick={changeUserMode}
        >
          <ListItemIcon>
            <Icon
              src={ModeUserIcon}
              backgroundColor={theme.palette.primary.main}
              width={48}
              height={48}
            />
          </ListItemIcon>
          <ListItemText
            style={{marginLeft: 8}}
            primary={`Switch to ${
              userDetails?.mode === "Talent" ? "User" : "Talent"
            } Mode`}
            classes={{
              primary: classes.primary,
            }}
          />
        </ListItem>
        }

        {ItemsList.map(({ icon, name, onClick }, index) => (
          <ListItem
            button
            key={name}
            className={classes.listItem}
            onClick={onClick}
          >
            <ListItemIcon>
              <Icon
                src={icon}
                backgroundColor={theme.palette.primary.main}
                width={48}
                height={48}
              />
            </ListItemIcon>
            <ListItemText
              style={{ marginLeft: 8 }}
              primary={name}
              classes={{
                primary: classes.primary,
              }}
            />
          </ListItem>
        ))}
      </List>
    </div>
  );

  return (
    <div>
      <React.Fragment>
        {/* <Button
          classes={{ root: classes.btnSettings }}
          variant="contained"
          color="primary"
          startIcon={<SettingsIcon />}
          onClick={toggleCustomDrawer}
          disableElevation
        >

        </Button> */}

        <div onClick={toggleCustomDrawer}>Settings</div>
        <Drawer
          style={{ zIndex: 140000 }}
          ModalProps={{
            BackdropProps: {
              invisible: true,
            },
          }}
          classes={{
            paperAnchorRight: classes.paperAnchorRight,
          }}
          anchor="right"
          open={open}
          onClose={toggleCustomDrawer}
        >
          {list()}
        </Drawer>
        <NotificationsDrawer
          open={notificationsDrawer}
          toggleCustomDrawer={toggleNotificationsDrawer}
        />
      </React.Fragment>
    </div>
  );
}

export default SettingsDrawer;
