/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import { useClasses } from "./styles";
import Typography from "@material-ui/core/Typography";
import { useHistory } from "react-router";
import Separator from "../../../../components/Separator";
import { useSelector } from "react-redux";
import ArrowButton from "../../../../components/ArrowButton/ArrowButton";
import WebinarColumnInfo from "../WebinarColumnInfo/WebinarColumnInfo";
import { selectUser } from "../../../auth/authSlice";
import moment from "moment";

const WebinarRowItem = ({
  name,
  description,
  author,
  authorUsername,
  tags,
  price,
  startDate,
  endDate,
  seatsAmount,
  slug,
  introThumb,
  hideViewWebinarBtn,
  type,
  participantsCount,
  id,
  styleType,
  userId,
  userInfo,
}) => {
  const user = useSelector(selectUser);
  const classes = useClasses();
  const history = useHistory();

  let mine = false;

  if (user) {
    mine = userId === user.uid;
  }

  const goToWebinar = () => {
    history.push(`/webinar/details/${slug}`);
  };

  const goToAuthor = (event) => {
    event.stopPropagation();
    history.push(`/talent/${authorUsername}`);
  };

  const goToWebinarEditing = () => {
    history.push(`/webinar/update/${id}`);
  };

  return (
    <div css={classes.webinarWrapper}>
      <div
        css={classes.webinarItem}
        className="d-flex align-items-start"
        onClick={goToWebinar}
      >
        <div css={classes.webinarImgContainer}>
          <img
            src={
              introThumb
                ? introThumb
                : require("../../../../assets/images/webinar-image-3.webp")
            }
          />
        </div>
        <div css={classes.webinarInfo}>
          <Typography
            variant="h4"
            color="textPrimary"
            className="mb-3"
            css={css`
              width: 55%;
            `}
          >
            {name}
          </Typography>
          <Typography variant="caption" color="textSecondary" className="mb-4">
            {description}
          </Typography>
          {styleType !== "webinarDetails" &&
            moment(startDate).isAfter(moment()) && (
              <WebinarColumnInfo
                type={type}
                price={price}
                startDate={startDate}
                endDate={endDate}
                seatsAmount={seatsAmount}
                participantsCount={participantsCount}
              />
            )}
          {styleType === "webinarDetails" && (
            <div>
              <div className="d-flex align-items-center mb-3 mt-5">
                <Typography
                  variant="caption"
                  color="textSecondary"
                  className="mr-2"
                >
                  Created by:
                </Typography>
                <span css={classes.webinarName} onClick={goToAuthor}>
                  {author}
                </span>
                <span css={classes.webinarDate}>
                  {moment(startDate).format("MMMM Do, yyyy")}
                </span>
              </div>
            </div>
          )}
          {!hideViewWebinarBtn && (
            <div
              css={classes.webinarViewBtn}
              className="d-flex align-items-center"
            >
              <Typography
                variant="caption"
                color="textPrimary"
                className="ml-2 mr-3"
              >
                {!mine && moment(startDate).isAfter(moment())
                  ? "Book Now"
                  : "View"}
              </Typography>
              <ArrowButton />
            </div>
          )}
        </div>
      </div>
      <Separator
        className="mb-5 mt-5"
        css={css`
          margin: 0 -50px;
        `}
      />
      <div className="d-flex">
        {mine && moment(startDate).isAfter(moment()) && (
          <div css={classes.webinarEditBtn}>
            <Typography variant="caption" onClick={goToWebinarEditing}>
              Edit hangout
            </Typography>
          </div>
        )}
      </div>
    </div>
  );
};

export default WebinarRowItem;
