/** @jsx jsx */
import { jsx } from "@emotion/core";
import { useState } from "react";
import Form from "react-bootstrap/Form";
import firebase from "firebase";
import axios from "axios";
import {
  showErrorSnack,
  showSuccessSnack,
} from "../../../snackbar/snackBarSlice";
import { FirebaseAuth } from "../../../../firebase";
import { useTheme } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { showModal } from "../../../../features/modal/modalSlice";
import Typography from "@material-ui/core/Typography";
import Icon from "../../../../components/Icon/Icon";
import FacebookIcon from "../../../../assets/icons/social/Facebook.png";
import GoogleIcon from "../../../../assets/icons/social/Google.svg";
import TextField from "../../../../components/TextField/TextField";
import PrimaryButton from "../../../../components/material/Button/PrimaryButton";
import SecondaryButton from "../../../../components/material/Button/SecondaryButton";
import Container from "@material-ui/core/Container";
import Logo from "../../../../components/Logo/Logo";
import * as yup from "yup";
import useClasses from "../../styles";
import { Formik } from "formik";
import { useHideModal } from "../../../../components/modals/hooks";

const schemaSignIn = yup.object({
  email: yup.string().required().email(),
  password: yup.string().required().min(6),
});

export const SignIn = ({ handleErrorMessage }) => {
  const [isLoading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const classes = useClasses();
  const theme = useTheme();
  const handleOnClose = useHideModal();

  const signIn = async (email, password) => {
    await FirebaseAuth.signInWithEmailAndPassword(email, password);
  };

  const handleSubmitSignIn = async (data) => {
    const { email, password } = data;
    try {
      setLoading(true);
      await signIn(email, password);
      setLoading(false);
      handleOnClose();
    } catch (e) {
      if (e.response && e.response.data && e.response.data.error) {
        dispatch(showErrorSnack(e.response.data.error));
      } else if (e.message) {
        dispatch(showErrorSnack(e.message));
      } else {
        dispatch(showErrorSnack("Something went wrong"));
      }
      setLoading(false);
    }
  };

  const googleSignIn = async () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    await oAuthSignIn(provider);
  };
  const facebookSignIn = async () => {
    const provider = new firebase.auth.FacebookAuthProvider();
    await oAuthSignIn(provider);
  };

  const oAuthSignIn = async (provider) => {
    try {
      const result = await FirebaseAuth.signInWithPopup(provider);
      const user = result.user;
      await createUserDetails(user.uid);
      dispatch(showSuccessSnack("Logged in successfully"));
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        dispatch(showErrorSnack(error.response.data.error));
      } else if (error.message) {
        dispatch(showErrorSnack(error.message));
      } else {
        dispatch(showErrorSnack("Something went wrong"));
      }
    }
  };

  const createUserDetails = async (uid) => {
    try {
      await axios.post("/api/auth", { uid });
      window.location.href = "/";
    } catch (e) {
      handleErrorMessage(e);
    }
  };

  const handleOpenSignUpModal = () => {
    dispatch(
      showModal({
        modalType: "signUpModal",
      })
    );
  };

  const handleOpenForgotPassowordModal = () => {
    dispatch(
      showModal({
        modalType: "forgotPassword",
      })
    );
  };

  return (
    <div css={classes.authContainer}>
      <Container maxWidth="xs">
        <Logo
          backgroundColor={theme.palette.secondary.light}
          width={90}
          height={90}
          imgWidth={86}
          imgHeight={86}
        />
        <Typography variant="h5" className="mt-5" css={classes.authHeading}>
          Welcome back!
        </Typography>
        <Typography variant="body2" className="mt-3" css={classes.authDescr}>
          Login to your account to continue
        </Typography>
        <div className="d-flex mt-5">
          <Icon
            src={FacebookIcon}
            backgroundColor="#39579B"
            className="mr-3"
            width={57}
            height={57}
            onClick={facebookSignIn}
          />
          <Icon
            src={GoogleIcon}
            backgroundColor="rgba(30, 31, 32, 0.1)"
            width={57}
            height={57}
            onClick={googleSignIn}
          />
        </div>
        <div className="mt-4">
          <Typography variant="caption" css={classes.authSmallDescr}>
            Or use your email address
          </Typography>
        </div>
        <div className="mt-5">
          <Formik
            onSubmit={handleSubmitSignIn}
            initialValues={{
              password: "",
              email: "",
            }}
            validationSchema={schemaSignIn}
          >
            {({
              handleSubmit,
              handleChange,
              values,
              touched,
              isValid,
              errors,
              handleBlur,
            }) => (
              <Form onSubmit={handleSubmit} noValidate>
                <Form.Group controlId="formEmail">
                  <TextField
                    label="EMAIL"
                    type="email"
                    name="email"
                    className="mb-4"
                    isInvalid={touched.email && errors.email}
                    value={values.email}
                    onBlur={handleBlur}
                    onChange={(val) => {
                      console.log(touched);
                      handleChange(val);
                    }}
                  />
                  <span className="input-error-message">
                    {touched.email && errors.email ? errors.email : null}
                  </span>
                </Form.Group>
                <Form.Group
                  controlId="formPassword"
                  style={{ marginBottom: 48 }}
                >
                  <TextField
                    label="PASSWORD"
                    type="password"
                    name="password"
                    isInvalid={touched.password && errors.password}
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <span className="input-error-message">
                    {touched.password && errors.password
                      ? errors.password
                      : null}
                  </span>
                </Form.Group>
                <PrimaryButton
                  loading={isLoading}
                  disabled={isLoading}
                  color="secondary"
                  type="submit"
                  variant="contained"
                >
                  Login
                </PrimaryButton>
              </Form>
            )}
          </Formik>
        </div>
        <div style={{ cursor: "pointer" }}>
          <Typography
            variant="body2"
            className="mt-4"
            css={classes.authForgotPasswordText}
            onClick={() => handleOpenForgotPassowordModal()}
          >
            Forgot password?
          </Typography>
        </div>
        <SecondaryButton
          className="mt-5"
          onClick={() => handleOpenSignUpModal()}
        >
          Create an account
        </SecondaryButton>
      </Container>
    </div>
  );
};
