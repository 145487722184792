import React, { useContext, useState } from "react";
import Navbar from "react-bootstrap/Navbar";
import Button from "react-bootstrap/Button";
import { MdPlaylistPlay } from "react-icons/all";
import { withRouter } from "react-router";
import { useSelector } from "react-redux";
import { selectUser, selectUserDetails } from "../../features/auth/authSlice";
import Avatar from "../Avatar/Avatar";
import styles from "./Header.module.css";
import Media from "react-media";
import Container from "react-bootstrap/Container";
import Drawer from "rc-drawer/es";

const Header = ({ history }) => {
  const user = useSelector(selectUser);
  const userDetails = useSelector(selectUserDetails);

  const onGetStartedClick = () => {
    history.push("/signup");
  };

  const onUserClick = () => {
    history.push("/profile");
  };

  return (
    <div className="fixed-top">
      <header className={styles.headerContainer}>
        <Navbar fixed="top">
          <Media
            queries={{
              small: "(max-width: 599px)",
              medium: "(min-width: 600px) and (max-width: 1199px)",
              large: "(min-width: 1200px)",
            }}
          >
            {(matches) => (
              <>
                <Container>
                  <div className="d-flex align-items-center w-100">
                    {/* <h2 className={styles.Logo} onClick={() => history.push('/')}>Yobe</h2> */}
                    <div className="ml-auto">
                      {!user ? (
                        <Button variant="link" onClick={onGetStartedClick}>
                          Get Started
                        </Button>
                      ) : (
                        <div
                          className={styles.AvatarContainer}
                          onClick={onUserClick}
                        >
                          <Avatar url={user ? user.photoURL : null} />

                          {(matches.medium || matches.large) && (
                            <h2 id="displayName" className="ml-2">
                              {user.displayName}
                            </h2>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </Container>
              </>
            )}
          </Media>
        </Navbar>
      </header>
    </div>
  );
};

export default withRouter(Header);
