import { createSlice } from "@reduxjs/toolkit";
import Service from "./service";
import {
  setTalents,
  setTalentsError,
  setTalentsLoading,
} from "../home/homeSlice";

const initialRecordingState = {
  cname: null,
  uid: null,
  resourceId: null,
  sid: null,
  error: null,
  started: false,
};

export const slice = createSlice({
  name: "webinar",
  initialState: {
    recordingState: initialRecordingState,
    isPendingChat: false,
  },
  reducers: {
    setRecordingState: (state, action) => {
      state.recordingState = {
        ...state.recordingState,
        ...action.payload,
      };
    },
    setRecordingStarted: (state, action) => {
      state.recordingState = {
        ...state.recordingState,
        started: true,
      };
    },
    setPendingChat: (state, action) => {
      state.isPendingChat = action.payload;
    },
  },
});

export const { setRecordingState, setRecordingStarted, setPendingChat } =
  slice.actions;

export const startRecording = (data, started) => async (dispatch) => {
  if (started) {
    return;
  }
  dispatch(setRecordingStarted());
  try {
    const response = await Service.startRecording(data);
    dispatch(setRecordingState({ ...response, ...data }));
  } catch (e) {
    dispatch(setRecordingState(e));
  }
};
export const stopRecording = (data) => async (dispatch) => {
  try {
    const res = await Service.stopRecording(data);
    console.log("STOP RECORDING", res);
    dispatch(setRecordingState(initialRecordingState));
  } catch (e) {
    dispatch(setRecordingState(initialRecordingState));
  }
};

export const selectRecordingState = (state) => state.webinar.recordingState;

export default slice.reducer;
