import axios from "axios";
import { FirebaseStorage } from "../../firebase";

class Service {
  static async saveEvent(event) {
    try {
      const res = await axios.post(`/api/event`, { event });
      return res.data.event;
    } catch (e) {
      throw e.response.data.error;
    }
  }

  static async updateEvent(event) {
    try {
      const res = await axios.put(`/api/event`, { data: event });
      return res.data.event;
    } catch (e) {
      throw e.response.data.error;
    }
  }

  static async deleteEvent(id) {
    try {
      await axios.delete(`/api/event?id=${id}`);
    } catch (e) {
      throw e.response.data.error;
    }
  }

  static saveIntro(id, intro, onComplete, onError, onProgress) {
    const extension = intro.name.split(".").pop();
    const ref = FirebaseStorage.ref();
    const uploadTask = ref
      .child(`/videos/events/${id}/intro/intro.${extension}`)
      .put(intro);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        onProgress(progress);
      },
      (e) => {
        onError(e);
      },
      () => {
        console.log(uploadTask.snapshot);
        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
          onComplete(downloadURL);
        });
      }
    );
  }

  static async saveAndCompressIntro(intro, destination) {
    const formData = new FormData();
    formData.append("video", intro);
    const res = await axios.post(
      `/api/video/compress?destination=${encodeURI(destination)}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    console.log("Compress response", res.data);
    return res.data;
  }

  static removeIntro(id, intro, onSuccess, onError) {
    const ref = FirebaseStorage.ref();
    const rootFolder = ref.child(`/videos/events/${id}/intro/`);
    rootFolder
      .listAll()
      .then((files) => {
        if (files.items && files.items[0]) {
          const filePath = ref.child(files.items[0].fullPath);
          filePath
            .delete()
            .then(function () {
              onSuccess();
            })
            .catch(function (error) {
              onError(error);
            });
        } else {
          onError("File not found!");
        }
      })
      .catch((e) => {
        onError(e);
      });
  }

  static saveThumbnail(id, thumbnail, onComplete, onError, onProgress) {
    const extension = thumbnail.name.split(".").pop();
    const ref = FirebaseStorage.ref();
    const uploadTask = ref
      .child(`/images/events/${id}/thumbnail/thumb.${extension}`)
      .put(thumbnail);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        onProgress(progress);
      },
      (e) => {
        onError(e);
      },
      () => {
        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
          onComplete(downloadURL);
        });
      }
    );
  }
}

export default Service;
