/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React, { useEffect, useState } from "react";
import { useTheme } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import CloseIcon from "../../assets/icons/Close.svg";
import Icon from "../Icon/Icon";
import FormControl from "@material-ui/core/FormControl";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import ClockIcon from "../../assets/icons/Clock.svg";
import DateFnsUtils from "@date-io/date-fns";
import Paper from "@material-ui/core/Paper";
import { useDispatch, useSelector } from "react-redux";
import {
  getAvailability,
  selectAvailabilityError,
  selectAvailabilityLoading,
  selectAvailabilitySlots,
} from "../../features/booking/bookingSlice";
import format from "date-fns/format";
import Button from "@material-ui/core/Button";
import {
  isTimeSelectionDisabled,
  minutesToHHmm,
  minutesToTime,
} from "../../utils/time";
import PrimaryButton from "../material/Button/PrimaryButton";
import addMinutes from "date-fns/addMinutes";
import Service from "../../features/booking/service";
import BookingService from "../../features/booking/service";
import {
  showErrorSnack,
  showSuccessSnack,
} from "../../features/snackbar/snackBarSlice";
import { useHistory } from "react-router";
import WhatNextImage from "../../assets/images/logo_for_what_happens_next_and_sign_up.jpeg";
import clsx from "clsx";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Slider from "@material-ui/core/Slider";
import styles from "./BookModal.module.css";
import { useStyles } from "./BookModal.jss";
import {
  ItemDetails,
  UserInfo,
} from "../../features/webinarDetails/WebinarDetails";
import ComboBox from "../ComboBox/ComboBox";
import Skeleton from "@material-ui/lab/Skeleton";

export default function BookModal({
  id,
  open,
  handleOpen,
  handleClose,
  talent,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const availabilitySlots = useSelector(selectAvailabilitySlots);
  const availabilityLoading = useSelector(selectAvailabilityLoading);
  const availabilityError = useSelector(selectAvailabilityError);

  const [duration, setDuration] = useState("30");
  const [startDate, setStartDate] = useState(new Date());
  const [selectedTime, selectTime] = useState(null);
  const [saveLoading, setSaveLoading] = useState(false);
  const [whatNextOpen, setWhatNextOpen] = useState(false);
  const theme = useTheme();
  const matchesSmDown = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    if (open) {
      const formattedDate = format(startDate, "MM/dd/yyyy");
      dispatch(getAvailability(formattedDate, id));
    }
  }, [open]);

  const handleWhatNextClose = () => {
    setWhatNextOpen(false);
  };

  const onBookSession = async () => {
    setSaveLoading(true);
    const day = format(startDate, "MM/dd/yyyy");
    const data = {
      talentKey: id,
      day,
      duration: parseInt(duration),
      timeInMinutes: selectedTime,
      startDate,
      endDate: addMinutes(startDate, duration),
    };
    const booking = await Service.saveBooking(data);
    if (booking) {
      dispatch(showSuccessSnack("Session successfully booked"));
      dispatch(getAvailability(day, id));
      handleClose();
      setWhatNextOpen(true);
    } else {
      dispatch(showErrorSnack("Cannot book a session (1)"));
    }
    setSaveLoading(false);
  };

  const handleChange = (event) => {
    setDuration(event.target.value);
  };
  const onDayClick = (date) => {
    const formattedDate = format(date, "MM/dd/yyyy");
    setStartDate(date);
    dispatch(getAvailability(formattedDate, id));
  };
  const onTimeSelect = (time) => {
    if (time) {
      const mmHH = minutesToHHmm(time);
      const updatedDate = startDate;
      updatedDate.setHours(mmHH.hh, mmHH.mm, 0, 0);
      setStartDate(updatedDate);
      selectTime(time);
    }
  };

  const marks = [
    {
      value: 30,
      label: "30 min",
    },
    {
      value: 60,
      label: "60 min",
    },
  ];

  const valuetext = (value) => {
    return `${value} min`;
  };

  const valueLabelFormat = (value) => {
    return marks.findIndex((mark) => mark.value === value) + 1;
  };

  const renderDay = (date, selectedDate, dayInCurrentMonth, dayComponent) => {
    if (!dayInCurrentMonth) {
      return React.cloneElement(dayComponent, {
        disabled: true,
        hidden: false,
      });
    }
    return React.cloneElement(dayComponent, { hidden: false });
  };

  return (
    <div>
      <WhatHappenNextModal
        open={whatNextOpen}
        handleClose={handleWhatNextClose}
        talent={talent}
      />
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        style={{ outline: 0 }}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Paper className={classes.paper}>
          <div className="d-flex justify-content-between align-items-center">
            <Typography variant="h4" color="textPrimary">
              Book a session
            </Typography>
            <Icon
              onClick={handleClose}
              src={CloseIcon}
              backgroundColor={"#fff"}
              height={38}
              width={38}
              imgHeight={18}
              imgWidth={18}
            />
          </div>
          <Grid container className="mt-5" justify="space-between">
            <Grid lg={6} md={6} sm={12} xs={12} item>
              <FormControl component="fieldset">
                <Typography
                  variant="subtitle2"
                  color="textPrimary"
                  className={styles.Subtitle}
                >
                  Duration of the session
                </Typography>
                <div
                  style={{
                    marginTop: "35px",
                    paddingRight: 19,
                    paddingLeft: 19,
                  }}
                >
                  <Slider
                    classes={{
                      root: classes.sliderRoot,
                      rail: classes.sliderRail,
                      thumb: classes.sliderThumb,
                      mark: classes.sliderMark,
                      markActive: classes.sliderMarkActive,
                      markLabel: classes.sliderMarkLabel,
                    }}
                    defaultValue={30}
                    getAriaValueText={valuetext}
                    aria-labelledby="session-slider"
                    step={30}
                    marks={marks}
                    min={30}
                    max={60}
                  />
                </div>
              </FormControl>
              {!matchesSmDown ? (
                <div className="mt-5">
                  <Typography
                    variant="subtitle2"
                    color="textPrimary"
                    className={styles.Subtitle}
                  >
                    {!availabilityError ? "Select Time" : "No available slots"}
                  </Typography>
                  {!availabilityLoading ? (
                    <div className="mt-3">
                      {availabilitySlots?.map((item) => {
                        return (
                          <Button
                            classes={{
                              root: classes.buttonRoot,
                              disabled: classes.baseButtonDisabled,
                              label: classes.buttonLabel,
                            }}
                            css={css`
                              background: rgba(143, 146, 161, 0.2);
                              color: #8f92a1;
                              border: none;
                              background: ${selectedTime === item.time &&
                              !item.reserved
                                ? "#FFB3AB"
                                : "outlined"};
                              color: ${selectedTime === item.time &&
                              !item.reserved
                                ? "#1E1F20"
                                : "outlined"};
                              &:hover {
                                background: #ffb3ab;
                                color: #1e1f20;
                              }
                            `}
                            disableElevation
                            color="primary"
                            className="m-1"
                            key={item.time}
                            disabled={isTimeSelectionDisabled(startDate, item)}
                            variant={
                              selectedTime === item.time && !item.reserved
                                ? "contained"
                                : "outlined"
                            }
                            onClick={() => onTimeSelect(item.time)}
                          >
                            <span key={item.time}>
                              {minutesToTime(item.time)}
                            </span>
                          </Button>
                        );
                      })}
                    </div>
                  ) : (
                    <div className="mt-3 d-flex flex-row flex-wrap">
                      {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((i) => {
                        return (
                          <Skeleton
                            key={i}
                            variant="rect"
                            width={52}
                            height={32}
                            className="m-1"
                          />
                        );
                      })}
                    </div>
                  )}
                </div>
              ) : (
                <div>
                  {!availabilityError ? (
                    <ComboBox
                      className="mt-5 mb-5"
                      label="Select Time"
                      loading={availabilityLoading}
                      options={availabilitySlots}
                      getOptionDisabled={(option) =>
                        isTimeSelectionDisabled(startDate, option)
                      }
                      getOptionLabel={(option) =>
                        option ? minutesToTime(option.time) : ""
                      }
                      onSelect={(option) => onTimeSelect(option?.time)}
                    />
                  ) : (
                    <Typography
                      variant="subtitle2"
                      color="textPrimary"
                      className={clsx(styles.Subtitle, "mt-5 mb-4")}
                    >
                      No available slots
                    </Typography>
                  )}
                </div>
              )}
            </Grid>
            <Grid lg={6} md={6} sm={12} xs={12} item>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  disablePast
                  disableToolbar={true}
                  variant="static"
                  value={startDate}
                  renderDay={renderDay}
                  onChange={onDayClick}
                  onMonthChange={() => {}} // todo: add month change handler
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid xs={12} md={12} lg={12} item className="mt-3">
              <PrimaryButton
                color="secondary"
                variant="contained"
                loading={saveLoading}
                disabled={availabilityLoading || saveLoading || !selectedTime}
                onClick={onBookSession}
              >
                Book a session
              </PrimaryButton>
            </Grid>
          </Grid>
        </Paper>
      </Modal>
    </div>
  );
}

const Step = ({ order, caption, hasTail }) => {
  return (
    <div>
      <div className="d-flex flex-row">
        <div className="d-flex flex-column align-items-center">
          <div className={styles.StepBox}>{order}</div>
        </div>
        <div className="ml-3 d-flex justify-content-between flex-column">
          <Typography variant="subtitle2" color="textPrimary">
            Step {order}
          </Typography>
          <span className={styles.StepCaption}>{caption}</span>
        </div>
      </div>
      {hasTail && (
        <div className={styles.StepTailContainer}>
          <div className={styles.StepTail} />
        </div>
      )}
    </div>
  );
};

export const WhatHappenNextModal = ({ handleClose, open, talent }) => {
  const classes = useStyles();
  const theme = useTheme();
  const matchesDownSm = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Paper className={classes.paper}>
          <div className="d-flex justify-content-between align-items-center">
            <div />
            <Typography
              variant="h4"
              color="textPrimary"
              style={{ paddingLeft: 48 }}
            >
              What happens next?
            </Typography>
            <Icon
              onClick={handleClose}
              src={CloseIcon}
              backgroundColor={"#fff"}
              height={38}
              width={38}
              imgHeight={18}
              imgWidth={18}
            />
          </div>
          <div
            className={clsx("d-flex flex-column align-items-center")}
            style={{ padding: matchesDownSm ? 0 : "0 70px 0 70px" }}
          >
            {/*250h - mobile, 250x250 web*/}
            <img
              src={WhatNextImage}
              className={clsx("mt-4", classes.whatsNextImage)}
            />
            <div className="mt-3 mb-5">
              <Step
                order={1}
                caption={`You will receive an email confirming your session/hangout details shortly.`}
                hasTail={true}
              />
              <Step
                order={2}
                caption="You will receive an email reminder 24 hours before your session begins. We will not charge your credit card until you receive this reminder."
                hasTail={true}
              />
              <Step
                order={3}
                caption="You will receive a final email reminder 30 mins before your session begins that will include the unique link to join your session."
              />
              {/*<Step order={4} caption="If the request is not accepted - we’ll return your money" hasTail={false} />*/}
            </div>
            <div
              className="w-100"
              style={{ padding: matchesDownSm ? 0 : "0 50px 0 50px" }}
            >
              <PrimaryButton
                color="secondary"
                variant="contained"
                onClick={handleClose}
              >
                Got It
              </PrimaryButton>
            </div>
          </div>
        </Paper>
      </Modal>
    </div>
  );
};

export const BookingDetailsModal = ({
  handleClose,
  booking,
  open,
  onAcceptSuccessful,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const onAccept = async () => {
    setLoading(true);
    try {
      const res = await BookingService.accept(booking.id);
      if (res.error) {
        dispatch(showErrorSnack(res.error));
      } else {
        dispatch(showSuccessSnack(res.message));
      }
      onAcceptSuccessful();
      setLoading(false);
    } catch (e) {
      dispatch(showErrorSnack("Request failed"));
      setLoading(false);
    }
  };

  return (
    booking && (
      <div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Paper className={classes.bookingDetailsPaper}>
            <div className="d-flex justify-content-between align-items-center">
              <div />
              <Typography variant="h4" color="textPrimary">
                Booking details
              </Typography>
              <Icon
                onClick={handleClose}
                src={CloseIcon}
                backgroundColor={"#fff"}
                height={38}
                width={38}
                imgHeight={18}
                imgWidth={18}
              />
            </div>
            <div className="mt-5">
              <UserInfo user={booking?.Participant} />
            </div>
            <Grid
              container
              justify="space-between"
              spacing={3}
              className="mt-3"
            >
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <ItemDetails
                  icon={ClockIcon}
                  name="Start Date"
                  subtitle={format(
                    new Date(booking?.startDate),
                    "dd MMM, yyyy h:mm a"
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <ItemDetails
                  icon={ClockIcon}
                  name="End Date"
                  subtitle={format(
                    new Date(booking?.endDate),
                    "dd MMM, yyyy h:mm a"
                  )}
                />
              </Grid>
            </Grid>
            <div className="mt-5">
              <PrimaryButton
                color="secondary"
                variant="contained"
                onClick={onAccept}
                disabled={loading}
              >
                Accept
              </PrimaryButton>
            </div>
          </Paper>
        </Modal>
      </div>
    )
  );
};
