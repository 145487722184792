/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import { useState, useEffect } from "react";
import { useHistory } from "react-router";
import useClasses from "./styles";
import { DateTime } from "luxon";
import { ReactComponent as ColoredClock } from "../../../assets/icons/coloredClock.svg";
import { ReactComponent as PlayButton } from "../../../assets/icons/PlayButton.svg";
import { ReactComponent as PauseButton } from "../../../assets/icons/PauseButton.svg";
import { ReactComponent as Seats } from "../../../assets/icons/Seats.svg";
import ReactPlayer from "react-player";
import ArrowButton from "../../ArrowButton/ArrowButton";
import Player from "../../../components/Player/Player";
import Typography from "@material-ui/core/Typography";
import Tag from "../../Tag";
import moment from "moment";
import CircularProgress from "@material-ui/core/CircularProgress";
import { isMobile } from "../../../utils/mobile";

const WebinarCard = ({
  name,
  author,
  seatsAmount,
  tags,
  video,
  slug,
  startDate,
  className,
  image,
  participantsCount,
}) => {
  const classes = useClasses();
  const history = useHistory();
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const [isBuffering, setIsBuffering] = useState(true);
  const [currentWebinarDate, setCurrenctWebinarDate] = useState(null);

  useEffect(() => {
    checkWebinarStarting();
  }, []);

  const checkWebinarStarting = () => {
    let result = moment(startDate).fromNow(true);

    if (moment().diff(moment(startDate)) < 0) {
      setCurrenctWebinarDate(result);
    }
  };

  const gotoWebinar = () => {
    history.push(`/webinar/details/${slug}`);
  };

  return (
    <div
      css={classes.webinar}
      className={className}
      onMouseEnter={() => !isMobile() && setIsVideoPlaying(true)}
      onMouseLeave={() => !isMobile() && setIsVideoPlaying(false)}
    >
      <div
        css={classes.webinarVideoContainer}
        onClick={() => {
          global.dontMute = true;
          setIsVideoPlaying(!isVideoPlaying);
        }}
      >
        {isVideoPlaying ? (
          <PauseButton css={classes.webinarPlayBtn} />
        ) : (
          <PlayButton css={classes.webinarPlayBtn} />
        )}
        <div css={classes.webinarImage}>
          <img src={image} />
        </div>
        {isVideoPlaying && (
          <div css={classes.webinarVideo}>
            <ReactPlayer
              playsinline
              url={video}
              loop
              muted={!global.dontMute}
              playing={true}
              onReady={() => setIsBuffering(false)}
              onPlay={() => setIsBuffering(false)}
              onBuffer={() => setIsBuffering(true)}
              width="100%"
              height="100%"
            />

            {isBuffering && (
              <div css={classes.webinarProgress}>
                <CircularProgress color="white" />
              </div>
            )}
          </div>
        )}
      </div>
      <div css={classes.webinarInfo} onClick={gotoWebinar}>
        {currentWebinarDate && (
          <div
            css={classes.webinarStarts}
            className="d-flex align-items-center"
          >
            <Typography variant="caption" color="textPrimary" className="mr-1">
              Starts in
            </Typography>
            <ColoredClock />
            <Typography
              variant="caption"
              color="textPrimary"
              className="ml-2 mr-2"
            >
              {currentWebinarDate}
            </Typography>
            <ArrowButton
              onClick={gotoWebinar}
              style={css`
                position: static;
              `}
            />
          </div>
        )}
        <h3>{name}</h3>
        <div className="d-flex mb-2 justify-content-between flex-wrap">
          <div className="d-flex">
            <Typography
              variant="caption"
              style={{ color: "#FFB3AB" }}
              className="mr-1"
            >
              Author:
            </Typography>
            <Typography variant="caption" color="textSecondary">
              {author}
            </Typography>
          </div>
          <div className="d-flex align-items-center">
            <Seats fill="#FFB3AB" width="18" />
            <Typography
              variant="caption"
              color="textSecondary"
              className="ml-1"
            >
              {seatsAmount - participantsCount} seats left
            </Typography>
          </div>
        </div>
        <div css={classes.webinarTags} className="d-flex mb-3">
          {tags && tags.map((tag) => <Tag key={tag.value}>{tag.value}</Tag>)}
        </div>
      </div>
    </div>
  );
};

export default WebinarCard;
