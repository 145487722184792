import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Provider, useDispatch, useSelector } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { StylesProvider, ThemeProvider } from "@material-ui/core/styles";
import { BrowserRouter as Router, Route } from "react-router-dom";
import GlobalStyles from "../styles/global";
import App from "../App";
import { persistor } from "../app/store";
import Webinar from "../features/webinar/Webinar";
import PrivateRoute from "./PrivateRoot";
import PublicRoute from "./PublicRoot";
import { Switch } from "react-router";
import CssBaseline from "@material-ui/core/CssBaseline";
import ModalWrapper from "../components/modals/Modal";
import {
  authChanged,
  selectUser,
  selectUserDetails,
  selectUserLoading,
} from "../features/auth/authSlice";
import Profile from "../features/profile/Profile";
import { FirebaseAuth } from "../firebase";
import theme from "../styles/theme";
import EditProfile from "../features/profile/EditProfile";
import { ScheduleWebinar } from "../features/schedule/ScheduleWebinar";
import WebinarDetails from "../features/webinarDetails/WebinarDetails";
import TalentProfile from "../features/talent-profile/TalentProfile";
import Booking from "../features/booking/Booking";
import Trending from "../features/trending/Trending";
import Discover from "../features/discover/Discover";
import firebase from "firebase";
import { fetchUnreadNotifications } from "../features/notification/notificationSlice";
import { UpdateWebinar } from "../features/update-webinar/UpdateWebinar";
import Payment from "../features/payment/Payment";
import AddCard from "../features/addCard/AddCard";
import LiveSession from "../features/webinar/LiveSession";
import Meeting from "../features/webinar/Meeting";
import StripeConnect from "../features/stripe/StripeConnect";
import About from "../features/about/About";
import Protect from "react-app-protect";

const db = firebase.database();
let chatRef = null;
const Navigation = () => {
  const dispatch = useDispatch();
  const localUser = useSelector(selectUser);
  const userDetails = useSelector(selectUserDetails);
  const loading = useSelector(selectUserLoading);
  const [onIdTokenChangedSuccess, setOnIdTokenChangedSuccess] = useState(false);

  useEffect(() => {
    document.title = "Butter n Binge";
  }, []);

  useEffect(() => {
    if (userDetails && localUser && onIdTokenChangedSuccess) {
      chatRef = db.ref(`notifications/${userDetails.id}`);
      const subscription = chatRef.on(
        "value",
        function (snapshot, prevChildKey) {
          dispatch(fetchUnreadNotifications({ unread: true }));
        }
      );
      return function cleanup() {
        chatRef.off("value", subscription);
      };
    }
  }, [userDetails, localUser]);

  useEffect(() => {
    const unsubscribe = FirebaseAuth.onIdTokenChanged((user) => {
      dispatch(authChanged(user));
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    let vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty("--vh", `${vh}px`);

    // We listen to the resize event
    window.addEventListener("resize", () => {
      // We execute the same script as before
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    });
  }, []);

  return (
    <Router>
      <Switch>
        <Route exact path="/" component={App} />
        <Route exact path="/stripe-connect" component={StripeConnect} />
        <Route exact path="/trending" component={Trending} />
        <Route exact path="/trending/category/:category" component={Trending} />
        <Route exact path="/trending/:staticSlug" component={Trending} />
        <Route exact path="/discover" component={Discover} />
        <Route exact path="/about" component={About} />
        <Route exact path="/discover/:category" component={Discover} />
        <PublicRoute
          restricted={!!localUser && localUser.emailVerified}
          exact
          path="/action"
          component={Trending}
        />
        <PrivateRoute exact path="/webinar" component={Webinar} />
        <Route exact path="/session" component={LiveSession} />
        <Route exact path="/meeting" component={Meeting} />
        <PrivateRoute exact path="/profile" component={Profile} />
        <PrivateRoute exact path="/webinar/purchase/:id" component={Payment} />
        <PrivateRoute exact path="/addCard" component={AddCard} />
        <Route exact path="/talent/:id" component={TalentProfile} />
        <PrivateRoute exact path="/profile/edit" component={EditProfile} />
        <PrivateRoute exact path="/booking/:id" component={Booking} />
        <PublicRoute
          restricted={
            !localUser || !userDetails || userDetails.mode !== "Talent"
          }
          exact
          path="/profile/schedule"
          component={ScheduleWebinar}
        />
        <Route exact path="/webinar/details/:id" component={WebinarDetails} />
        <Route exact path="/webinar/update/:id" component={UpdateWebinar} />
      </Switch>
      <ModalWrapper />
    </Router>
  );
};

const Root = ({ store }) => (
  <Protect
    boxTitle={"Coming soon"}
    blur
    sha512="93d1a4d74a54d9a3213872ae1813067bf4ac64075d3895764d56815f4ea379c2c01216b1521c75d21939b1ee152da91e3fa57bc7cf6f461fbdcec7f67bcbcb50"
  >
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <GlobalStyles />
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <StylesProvider injectFirst>
            <Navigation />
          </StylesProvider>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  </Protect>
);

Root.propTypes = {
  store: PropTypes.object.isRequired,
};

export default Root;
