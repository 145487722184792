/** @jsx jsx */
import { jsx } from "@emotion/core";
import { useHistory } from "react-router";
import useClasses from "./styles";
import Coach from "../Coach/Coach";
import SeeButton from "../../SeeButton";

const CoachesList = ({ title, items }) => {
  const classes = useClasses();
  const history = useHistory();

  return (
    <div css={classes.coachesListWrapper}>
      <h1 css={classes.coachesHeading}>{title}</h1>
      <div
        css={classes.coachesList}
        className="mt-4 justify-content-start d-flex"
      >
        {items?.map((item) => (
          <Coach
            key={item.slug}
            image={item.videoIntroThumb}
            name={item.displayName}
            tag={item.Category ? item.Category.name : null}
            timestamp={item.timestamp}
            video={item.videoIntro}
            slug={item.slug}
          />
        ))}
      </div>
      <SeeButton
        styles={classes.button}
        onClick={() => history.push("/discover")}
      >
        see all
      </SeeButton>
    </div>
  );
};

export default CoachesList;
