import React, { useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Drawer from "@material-ui/core/Drawer";
import Icon from "../Icon/Icon";
import CloseIcon from "../../assets/icons/Close.svg";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import EditIcon from "@material-ui/icons/Edit";
import { toast } from "react-toastify";
import UploadIcon from "../../assets/icons/UploadImage.svg";
import BinIcon from "../../assets/icons/Bin.svg";
import Avatar from "../Avatar/Avatar";
import styles from "./EditProfileDrawer.module.css";
import * as yup from "yup";
import Form from "react-bootstrap/Form";
import TextField from "../TextField/TextField";
import PrimaryButton from "../material/Button/PrimaryButton";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import {
  selectUser,
  selectUserDetails,
  selectUserUpdateLoading,
  updateUser,
} from "../../features/auth/authSlice";
import DoneIcon from "@material-ui/icons/Done";
import RemoveIcon from "@material-ui/icons/Remove";
import SandClockIcon from "../../assets/icons/SandClockIcon.svg";
import uploadArrow from "../../assets/images/uploadArrow.webp";
import Service from "./service";
import ScheduleService from "../../features/schedule/service";
import { CircularProgress, Zoom } from "@material-ui/core";
import {
  showErrorSnack,
  showSuccessSnack,
} from "../../features/snackbar/snackBarSlice";
import { minutesToTime, timeToMinutes } from "../../utils/time";
import Timekeeper from "react-timekeeper";
import CategoryAutosuggest from "../CategoryAutosuggest/CategoryAutosuggest";
import PhoneField from "../TextField/PhoneField";

const useStyles = makeStyles((theme) => ({
  list: {
    width: 470,
    boxSizing: "border-box",
    [theme.breakpoints.down("sm")]: {
      width: `100vw`,
      paddingBottom: 30,
    },
    padding: "2.5rem 2rem",
    color: "white",
    height: "calc(100vh - 90px)",
    position: "relative",
  },
  fullList: {
    width: "auto",
  },
  listItem: {
    padding: "1rem 0",
    fontSize: "16px",
    lineHeight: "22px",
  },
  paperAnchorRight: {
    borderRadius: "32px 0px 0px 32px",
    // backgroundColor: theme.palette.text.primary,
    // [theme.breakpoints.down('sm')]: {
    //   borderRadius: 0,
    // },
  },
  buttonLabel: {
    textTransform: "none",
  },
  editProfileTab: {
    "&.active": {
      background: "red",
    },
    "& h6": {
      fontSize: 14,
    },
  },
  buttonRoot: {
    borderRadius: 12,
    border: 0,
    backgroundColor: "white",
    color: "#1E1F20",
    width: 175,
    fontSize: 14,
    fontWeight: 600,
    height: 58,
  },
}));

EditProfileDrawer.propTypes = {};

function EditProfileDrawer(props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [open, setOpen] = useState(props.open);
  const [activeTab, setActiveTab] = useState("general");

  const toggleCustomDrawer = () => {
    setOpen(!open);
  };

  const onAvailabilitySubmit = async (data) => {
    try {
      const availability = { availability: data };
      await dispatch(updateUser(availability));
      //toast.success('Updated Successfully')
    } catch (e) {
      //toast.error('Cannot update Profile')
    }
  };

  const updateLocalUser = (data, successMsg, errorMsg) => {
    dispatch(updateUser(data))
      .then((res) => {
        toast.success("Profile is successfully saved");
        dispatch(showSuccessSnack(successMsg));
      })
      .catch((e) => {
        dispatch(showErrorSnack(errorMsg));
      });
  };
  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
    >
      <div className="d-flex align-items-center mb-5">
        <Icon
          src={CloseIcon}
          backgroundColor="white"
          width={56}
          height={56}
          imgWidth={25}
          imgHeight={25}
          onClick={toggleCustomDrawer}
        />
        <Typography variant="h4" color="inherit" className="ml-4">
          Edit Profile
        </Typography>
      </div>
      <div className="d-flex justify-content-between mt-3 mb-5">
        <MenuTab
          title="General"
          active={activeTab === "general"}
          darkMode
          onClick={() => setActiveTab("general")}
        />
        <MenuTab
          title="Social profiles"
          active={activeTab === "socialProfiles"}
          darkMode
          onClick={() => setActiveTab("socialProfiles")}
        />
        <MenuTab
          title="Availability"
          active={activeTab === "availability"}
          darkMode
          onClick={() => setActiveTab("availability")}
        />
        <MenuTab
          title="Prices"
          active={activeTab === "prices"}
          darkMode
          onClick={() => setActiveTab("prices")}
        />
      </div>
      <div className="mt-3">
        {activeTab === "general" && <General onSubmit={updateLocalUser} />}
        {activeTab === "socialProfiles" && (
          <SocialProfiles onSubmit={updateLocalUser} />
        )}
        {activeTab === "availability" && (
          <Availability onSubmit={updateLocalUser} />
        )}
        {activeTab === "prices" && (
          <PricingEditing onSubmit={updateLocalUser} />
        )}
      </div>
    </div>
  );

  return (
    <div>
      <React.Fragment>
        <Button
          classes={{ root: classes.buttonRoot, label: classes.buttonLabel }}
          variant="contained"
          color="default"
          startIcon={<EditIcon />}
          onClick={() => toggleCustomDrawer()}
          disableElevation
        >
          Edit Profile
        </Button>
        <Drawer
          ModalProps={{
            BackdropProps: {
              invisible: true,
            },
          }}
          style={{ zIndex: 140000 }}
          classes={{
            paperAnchorRight: classes.paperAnchorRight,
          }}
          anchor="right"
          open={open}
          onClose={toggleCustomDrawer}
        >
          {list()}
        </Drawer>
      </React.Fragment>
    </div>
  );
}

const Availability = ({ onSubmit }) => {
  const [activeDay, setDay] = useState(0);
  const userDetails = useSelector(selectUserDetails);
  const loading = useSelector(selectUserUpdateLoading);

  const [timeSlot, setTimeSlot] = useState(null);
  const [availabilities, setAvailabilities] = useState(userDetails.Schedule);

  const onAvailabilityChange = (day) => {
    let modifiedAvailability = [...availabilities];
    let modifiedDay = { ...modifiedAvailability[day] };
    modifiedDay.available = !modifiedDay.available;
    modifiedAvailability[day] = { ...modifiedDay };
    setAvailabilities(modifiedAvailability);
  };

  const onTimeChange = (time, day, prop) => {
    let modifiedAvailability = [...availabilities];
    let modifiedDay = { ...modifiedAvailability[day] };
    modifiedDay[prop] = timeToMinutes(time);
    modifiedAvailability[day] = { ...modifiedDay };
    setAvailabilities(modifiedAvailability);
  };

  const onSave = () => {
    onSubmit({ Schedule: availabilities }, "Updated!", "Cannot update!");
  };

  const setActiveDay = (number) => {
    setDay(number);
    setTimeSlot(null);
  };

  return (
    <div className="mt-3 justify-content-between d-flex flex-column">
      <div>
        <div className="d-flex justify-content-between">
          <DayTab
            day="S"
            active={activeDay === 0}
            onClick={() => setActiveDay(0)}
            available={availabilities[0].available}
            onAvailableClick={() => onAvailabilityChange(0)}
          />
          <DayTab
            day="M"
            active={activeDay === 1}
            onClick={() => setActiveDay(1)}
            available={availabilities[1].available}
            onAvailableClick={() => onAvailabilityChange(1)}
          />
          <DayTab
            day="T"
            active={activeDay === 2}
            onClick={() => setActiveDay(2)}
            available={availabilities[2].available}
            onAvailableClick={() => onAvailabilityChange(2)}
          />
          <DayTab
            day="W"
            active={activeDay === 3}
            onClick={() => setActiveDay(3)}
            available={availabilities[3].available}
            onAvailableClick={() => onAvailabilityChange(3)}
          />
          <DayTab
            day="T"
            active={activeDay === 4}
            onClick={() => setActiveDay(4)}
            available={availabilities[4].available}
            onAvailableClick={() => onAvailabilityChange(4)}
          />
          <DayTab
            day="F"
            active={activeDay === 5}
            onClick={() => setActiveDay(5)}
            available={availabilities[5].available}
            onAvailableClick={() => onAvailabilityChange(5)}
          />
          <DayTab
            day="S"
            active={activeDay === 6}
            onClick={() => setActiveDay(6)}
            available={availabilities[6].available}
            onAvailableClick={() => onAvailabilityChange(6)}
          />
        </div>
        {availabilities[activeDay].available ? (
          <div className={clsx(styles.TimeSelectorWrapper, "d-flex mt-4")}>
            <TimeSelector
              label="Start time:"
              minutes={availabilities[activeDay].start}
              selected={timeSlot?.half === "start"}
              onClick={() =>
                setTimeSlot({
                  half: "start",
                  value: availabilities[activeDay].start,
                })
              }
            />
            <TimeSelector
              label="End time:"
              minutes={availabilities[activeDay].end}
              selected={timeSlot?.half === "end"}
              onClick={() =>
                setTimeSlot({
                  half: "end",
                  value: availabilities[activeDay].end,
                })
              }
            />
          </div>
        ) : null}
        <div className="mt-5 w-100 justify-content-center d-flex">
          <Zoom in={!!timeSlot}>
            <div>
              {!!timeSlot && (
                <Timekeeper
                  onChange={(time) =>
                    onTimeChange(time, activeDay, timeSlot.half)
                  }
                  time={minutesToTime(timeSlot?.value)}
                  coarseMinutes={30}
                  forceCoarseMinutes={true}
                />
              )}
            </div>
          </Zoom>
        </div>
      </div>

      <div className={clsx(styles.saveBtnWrapper)}>
        <PrimaryButton
          color="secondary"
          variant="contained"
          type="submit"
          loading={loading}
          disabled={loading}
          onClick={onSave}
        >
          Save
        </PrimaryButton>
      </div>
    </div>
  );
};

const SocialProfiles = ({ onSubmit }) => {
  const schema = yup.object({
    youtubeLink: yup.string().url(),
    instagramLink: yup.string().url(),
    facebookLink: yup.string().url(),
  });
  const loading = useSelector(selectUserUpdateLoading);
  const userDetails = useSelector(selectUserDetails);

  const onSave = (data) => {
    onSubmit(data, "Updated!", "Cannot update!");
  };

  return (
    <div className="mt-3">
      <Formik
        onSubmit={onSave}
        initialValues={{
          youtubeLink: userDetails.youtubeLink ? userDetails.youtubeLink : "",
          instagramLink: userDetails.instagramLink
            ? userDetails.instagramLink
            : "",
          facebookLink: userDetails.facebookLink
            ? userDetails.facebookLink
            : "",
        }}
        validationSchema={schema}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          isValid,
          errors,
        }) => (
          <Form className="mt-2 w-100" noValidate onSubmit={handleSubmit}>
            <TextField
              darkMode
              className="mt-4"
              label="Youtube"
              name="youtubeLink"
              value={values.youtubeLink}
              isInvalid={errors.youtubeLink}
              onChange={handleChange}
            />
            <TextField
              darkMode
              className="mt-4"
              label="Instagram"
              name="instagramLink"
              value={values.instagramLink}
              isInvalid={errors.instagramLink}
              onChange={handleChange}
            />
            <TextField
              darkMode
              className="mt-4"
              label="Facebook"
              name="facebookLink"
              value={values.facebookLink}
              isInvalid={errors.facebookLink}
              onChange={handleChange}
            />

            <div className={clsx(styles.saveBtnWrapper)}>
              <PrimaryButton
                color="secondary"
                variant="contained"
                type="submit"
                loading={loading}
                disabled={(!isValid && touched) || loading}
              >
                Save
              </PrimaryButton>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

const PricingEditing = ({ loading, onSubmit }) => {
  const pricingSchema = yup.object({
    time30m: yup.number(),
    time60m: yup.number(),
  });

  const onSave = (data) => {
    onSubmit(
      {
        Pricing: {
          id: data.id,
          time30m: parseFloat(data.time30m),
          time60m: parseFloat(data.time60m),
        },
      },
      "Updated!",
      "Cannot update!"
    );
  };

  const userDetails = useSelector(selectUserDetails);
  return (
    <div className="mt-3">
      <Formik
        onSubmit={onSave}
        initialValues={{
          id: userDetails.Pricing.id,
          time30m: userDetails.Pricing.time30m,
          time60m: userDetails.Pricing.time60m,
        }}
        validationSchema={pricingSchema}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          isValid,
          errors,
        }) => (
          <Form className="mt-2 w-100" noValidate onSubmit={handleSubmit}>
            <TextField
              darkMode
              className="mt-4"
              label="30 minutes price"
              name="time30m"
              value={values.time30m}
              isValid={touched.time30m && !errors.time30m}
              isInvalid={errors.time30m}
              onChange={handleChange}
            />
            <TextField
              darkMode
              className="mt-4"
              label="60 minutes price"
              name="time60m"
              value={values.time60m}
              isValid={touched.time60m && !errors.time60m}
              isInvalid={errors.time60m}
              onChange={handleChange}
            />
            <div className={clsx(styles.saveBtnWrapper)}>
              <PrimaryButton
                color="secondary"
                variant="contained"
                type="submit"
                loading={loading}
                disabled={(!isValid && touched) || loading}
              >
                Save
              </PrimaryButton>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

const General = ({ onSubmit }) => {
  const schema = yup.object({
    displayName: yup.string().required(),
    email: yup.string().required(),
    bio: yup.string().min(6),
  });
  const userDetails = useSelector(selectUserDetails);
  const user = useSelector(selectUser);
  const loading = useSelector(selectUserUpdateLoading);
  const dispatch = useDispatch();

  const introUploadRef = useRef(null);
  const avatarUploadRef = useRef(null);

  const [introProgress, setIntroProgress] = useState(false);
  const [avatarProgress, setAvatarProgress] = useState(100);
  const [loadingAvatar, setLoadingAvatar] = useState(false);

  const [phoneFormattedNumber, setPhoneFormattedNumber] = useState("");
  const [phoneNumber, setPhoneNumber] = useState(
    userDetails.phoneNumber ? userDetails.phoneNumber : ""
  );
  const [phoneMatchesMask, setPhoneMatchesMask] = useState(
    !!userDetails.phoneNumber
  );

  const [category, setCategory] = useState(
    userDetails.Category ? userDetails.Category : ""
  );
  const [autosuggestVisible, setAutosuggestVisible] = useState(
    !userDetails.Category
  );
  const [autosuggestRef, setAutosuggestRef] = useState(null);

  const onPhoneNumberChange = (value, country, e, formattedValue) => {
    console.log("formattedValue", formattedValue);
    setPhoneFormattedNumber(formattedValue);
    setPhoneMatchesMask(country.format.length === formattedValue.length);
    console.log(
      "country.format.length === formattedValue.length",
      country.format.length === formattedValue.length
    );
    setPhoneNumber(value);
  };

  const isPhoneValid = () => {
    return !phoneNumber || phoneMatchesMask || phoneNumber.length !== 1;
  };

  const validatePhoneNumber = (isValid) => {
    console.log("validatePhoneNumber", isValid);
    setPhoneMatchesMask(isValid);
  };

  const onSave = (data) => {
    if (phoneNumber.length !== 1 || !phoneNumber.length) {
      onSubmit(
        { ...data, CategoryId: category.id, phoneNumber },
        "Updated!",
        "Cannot update!"
      );
    } else {
      let phone = phoneNumber.startsWith("+") ? phoneNumber : `+${phoneNumber}`;
      onSubmit(
        { ...data, CategoryId: category.id, phoneNumber: phone },
        "Updated!",
        "Cannot update!"
      );
    }
  };

  const onIntroDelete = async () => {
    setIntroProgress(true);
    try {
      await Service.deleteIntro(user.uid);
      onSubmit(
        { videoIntro: null },
        "Intro deleted!",
        "Cannot delete the intro"
      );
      setTimeout(() => {
        setIntroProgress(false);
      }, 2000);
    } catch (e) {
      console.log(e);
      dispatch(showErrorSnack("Cannot delete the intro"));
      setIntroProgress(false);
    }
  };
  const onSaveIntro = (videoIntro) => {
    setIntroProgress(true);
    console.log("UPLOAD INTRO", videoIntro);
    ScheduleService.saveAndCompressIntro(
      videoIntro,
      `videos/users/${user.uid}/intro/intro`
    )
      .then(({ videoIntro, videoIntroThumb }) => {
        setTimeout(() => {
          setIntroProgress(false);
        }, 2000);
        onSubmit(
          { videoIntro, videoIntroThumb },
          "Intro Uploaded",
          "Cannot upload an Intro"
        );
      })
      .catch((e) => {
        setIntroProgress(false);
        dispatch(showErrorSnack("Cannot upload an Intro"));
      });
  };
  const onSaveAvatar = (avatar) => {
    setLoadingAvatar(true);
    Service.saveAvatar(
      user.uid,
      avatar,
      (photoURL) => {
        setAvatarProgress(100);
        onSubmit({ photoURL }, "Avatar changed!", "Cannot change an avatar!");
        setTimeout(() => {
          setLoadingAvatar(false);
        }, 4000);
      },
      (e) => {
        setAvatarProgress(100);
        console.log("Error saving your profile picture", e);
        dispatch(showErrorSnack("Error saving your profile picture"));
        setLoadingAvatar(false);
      },
      (progress) => {
        setAvatarProgress(progress);
      }
    );
  };
  const onIntroAdded = (evt) => {
    const intro = evt.target.files[0];
    onSaveIntro(intro);
  };
  const onAvatarAdded = (evt) => {
    const avatar = evt.target.files[0];
    onSaveAvatar(avatar);
  };
  const onAvatarClick = () => {
    if (userDetails.photoURL || !avatarUploadRef.current) {
      return;
    }
    avatarUploadRef.current.click();
  };
  const onAvatarDelete = async () => {
    setLoadingAvatar(true);
    try {
      await Service.deleteAvatar(user.uid);
      onSubmit(
        { photoURL: null },
        "Profile image is deleted",
        "Cannot delete the profile image"
      );
      setTimeout(() => {
        setLoadingAvatar(false);
      }, 4000);
    } catch (e) {
      console.log(e);
      dispatch(showErrorSnack("Cannot delete the profile image"));
      setLoadingAvatar(false);
    }
  };
  const onCategoryPlaceholderClick = () => {
    setAutosuggestVisible(true);
    const autosuggestInput = document.getElementById("autosuggest_input");
    console.log(autosuggestInput);
    autosuggestInput.focus();
    autosuggestInput.focus();
  };

  return (
    <div style={{ paddingBottom: 80 }}>
      <div>
        <div
          className="d-flex justify-content-around align-items-center"
          style={{ width: "80%", margin: "auto" }}
        >
          <div style={{ cursor: "pointer" }}>
            <Icon
              height={48}
              width={48}
              imgWidth={24}
              imgHeight={24}
              src={UploadIcon}
              backgroundColor="#FFB3AB"
              onClick={onAvatarClick}
            />
          </div>
          {loadingAvatar ? (
            <div style={{ position: "relative", height: 120, width: 120 }}>
              <div
                style={{
                  position: "absolute",
                  left: "50%",
                  top: "50%",
                  transform: "translate(-50%, -50%)",
                }}
              >
                <CircularProgress size={48} />
              </div>
            </div>
          ) : (
            <div style={{ cursor: "pointer" }}>
              <Avatar
                url={userDetails.photoURL}
                height={120}
                width={120}
                borderRadius={40}
                imgWidth={120}
                imgHeight={120}
                onUserClick={onAvatarClick}
              />
              <input
                ref={avatarUploadRef}
                className="d-none"
                type="file"
                accept="image/*"
                onChange={onAvatarAdded}
              />
            </div>
          )}
          {userDetails.photoURL ? (
            <div className={styles.deleteAvatarContainer}>
              <DeleteMenu onAvatarDelete={onAvatarDelete} />
            </div>
          ) : (
            <div style={{ width: 48 }} />
          )}
        </div>
        <div
          style={{
            marginBottom: 50,
            marginTop: 30,
            borderBottom: "1px solid rgb(255 255 255 / 0.1)",
          }}
          className={styles.uploadTextContainer}
        >
          <p>Upload a New Photo</p>
          <span>Click the button above to upload your photo</span>
        </div>

        <div
          className="d-flex justify-content-around align-items-center"
          onClick={() => introUploadRef.current.click()}
          style={{
            width: "80%",
            margin: "auto",
            marginBottom: 30,
            cursor: "pointer",
          }}
        >
          <div className={styles.uploadContainer}>
            <img
              src={userDetails.videoIntro ? userDetails.videoIntroThumb : null}
              className={styles.uploadImg}
              style={!userDetails.videoIntro ? { paddingBottom: 80 } : null}
            />
            {!introProgress ? (
              <img src={uploadArrow} className={styles.uploadArrow} />
            ) : (
              <div className={styles.spinnerContainer}>
                <CircularProgress color="secondary" />
              </div>
            )}
          </div>
        </div>
        <div className={styles.uploadTextContainer}>
          <p>Upload a New Video</p>
          <span>Click the button below to upload a new video</span>
        </div>
        <div className={styles.buttonsContainer}>
          <PrimaryButton
            style={{
              width: userDetails.videoIntro ? 150 : 330,
              color: "black",
            }}
            disabled={introProgress}
            color="secondary"
            type="submit"
            variant="contained"
            onClick={() => introUploadRef.current.click()}
          >
            <font style={{ fontColor: "red" }}>Upload a File</font>
            <input
              ref={introUploadRef}
              className="d-none"
              type="file"
              accept="video/*"
              onChange={onIntroAdded}
            />
          </PrimaryButton>
          {userDetails.videoIntro ? (
            <div
              className={styles.deleteButtonContainer}
              onClick={() => {
                if (
                  window.confirm(
                    "Are you sure you want to delete your intro video?"
                  )
                ) {
                  onIntroDelete().then(() => null);
                }
              }}
              style={{ cursor: "pointer" }}
            >
              <DeleteMenu onIntroDelete={onIntroDelete} />
              <span>Delete</span>
            </div>
          ) : null}
        </div>
      </div>

      <div></div>

      <Formik
        onSubmit={onSave}
        initialValues={{
          displayName: userDetails.displayName ? userDetails.displayName : "",
          email: userDetails.email ? userDetails.email : "",
          bio: userDetails.bio ? userDetails.bio : "",
        }}
        validationSchema={schema}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          isValid,
          errors,
        }) => (
          <Form className="mt-2 w-100" noValidate onSubmit={handleSubmit}>
            <TextField
              darkMode
              className="mt-4"
              label="NAME"
              name="displayName"
              value={values.displayName}
              isInvalid={errors.displayName}
              onChange={handleChange}
            />
            <TextField
              darkMode
              className="mt-4"
              label="EMAIL"
              name="email"
              disabled
              value={values.email}
              isInvalid={errors.email}
              onChange={handleChange}
            />
            <div className={clsx(!autosuggestVisible && "d-none")}>
              <CategoryAutosuggest
                value={category}
                onCategorySelect={setCategory}
                onInputProps={({ ref }) => setAutosuggestRef(ref)}
              />
            </div>
            <div className="mt-4">
              <PhoneField
                darkMode
                label="PHONE NUMBER"
                className="dark-react-phone-input"
                value={phoneNumber}
                isValid={validatePhoneNumber}
                onChange={onPhoneNumberChange}
              />
            </div>
            <TextField
              darkMode
              className={clsx("mt-4", autosuggestVisible && "d-none")}
              label="CATEGORY"
              name="category"
              value={category.name ? category.name : ""}
              onChange={() => {}}
              onFocus={onCategoryPlaceholderClick}
            />
            <TextField
              darkMode
              textArea
              className="mt-4"
              as="textarea"
              label="Bio"
              name="bio"
              value={values.bio}
              isInvalid={errors.bio}
              onChange={handleChange}
            />
            <div className={clsx(styles.saveBtnWrapper)}>
              <PrimaryButton
                style={{ color: "black" }}
                loading={loading}
                disabled={(!isValid && touched) || loading || !isPhoneValid()}
                color="secondary"
                type="submit"
                variant="contained"
              >
                Save
              </PrimaryButton>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

const DeleteMenu = ({ onIntroDelete, onAvatarDelete }) => {
  const handleClick = (event) => {
    if (onIntroDelete) {
      if (window.confirm("Are you sure you want to delete your intro video?")) {
        deleteIntro();
      }
    } else {
      if (
        window.confirm("Are you sure you want to delete your profile image?")
      ) {
        deleteAvatar();
      }
    }
  };

  const handleClose = () => {};

  const deleteIntro = () => {
    onIntroDelete();
    handleClose();
  };
  const deleteAvatar = () => {
    onAvatarDelete();
    handleClose();
  };
  return (
    <div
      className={styles.DeleteIconContainer}
      aria-controls="simple-menu"
      aria-haspopup="true"
      onClick={handleClick}
    >
      <img src={BinIcon} />
    </div>
  );
};

const DayTab = ({ day, active, available, onClick, onAvailableClick }) => {
  const labelColor = active ? "white" : "#8F92A1";
  return (
    <>
      <div
        style={{ color: labelColor }}
        className={clsx(
          "d-flex flex-column align-items-center",
          active ? styles.ActiveDayTab : styles.InactiveDayTab
        )}
        onClick={onClick}
      >
        <Typography variant="caption" color="inherit">
          {day}
        </Typography>
        <div style={{ color: "white" }} onClick={onAvailableClick}>
          {available ? <DoneIcon /> : <RemoveIcon />}
        </div>
      </div>
    </>
  );
};

const MenuTab = ({ className, title, active, onClick }) => {
  return (
    <div className={(clsx(styles.menuTabWrapper), className)} onClick={onClick}>
      <div className={clsx(styles.menuTab, active && styles.activeMenuTab)}>
        <Typography
          variant="subtitle2"
          className={styles.Title}
          color="textSecondary"
          display="block"
        >
          {title}
        </Typography>
      </div>
    </div>
  );
};

const TimeSelector = ({ label, minutes, selected, onClick }) => {
  return (
    <div
      className={clsx(
        styles.TimeSelector,
        selected && styles.TimeSelectorSelected
      )}
      onClick={onClick}
    >
      <div className="d-flex flex-column">
        <div>
          <img src={SandClockIcon} />
        </div>
        <span className={clsx(styles.TimeSelectorLabel, "mt-4")}>{label}</span>
        <span className={clsx(styles.TimeSelectorTime, "mt-1")}>
          {minutesToTime(minutes)}
        </span>
      </div>
    </div>
  );
};

export default EditProfileDrawer;
