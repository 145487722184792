import axios from "axios";

class Service {
  static async getCategories() {
    const res = await axios.get("/api/category/categories");
    return res.data;
  }
}

export default Service;
