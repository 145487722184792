import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation, withRouter } from "react-router";
import Form from "react-bootstrap/Form";
import styles from "./SearchInput.module.css";
import "./Search.css";
import SearchIcon from "../../assets/icons/Search.svg";
import clsx from "clsx";

const SearchInput = ({ className, placeholder, onSubmit }) => {
  const location = useLocation();
  const [search, setSearch] = useState("");

  const onFormSubmit = (e) => {
    e.preventDefault();
    onSubmit(search);
  };
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const search = params.get("search");
    if (typeof search === "string") {
      setSearch(search);
    }
  }, [location]);

  return (
    <div className={className}>
      <div className={clsx("d-flex position-relative", styles.Container)}>
        <Form onSubmit={onFormSubmit} style={{ width: "100%" }}>
          <Form.Control
            value={search}
            onChange={(event) => setSearch(event.target.value)}
            id="search_input"
            placeholder={placeholder}
            className={styles.Input}
          />
        </Form>
        <img className={styles.SearchIcon} src={SearchIcon} />
      </div>
    </div>
  );
};

export default SearchInput;
