import { css, Global } from "@emotion/core";
import React from "react";

export default function GlobalStyles() {
  return (
    <Global
      styles={css`
        * {
          outline: none;
        }
        button {
          background: transparent;
        }
        button,
        button:focus {
          outline: none;
          border: none;
        }
        p {
          line-height: 21px;
          margin: 0;
        }
        ul {
          margin: 0;
          padding: 0;
          list-style: none;
        }
        input:-internal-autofill-selected {
          background: transparent;
        }
        .form-control:focus {
          background: transparent;
        }
        .form-group {
          position: relative;
        }
        .form-control:disabled {
          background-color: #36373e;
          &:hover {
            background-color: #36373e !important;
          }
        }
        .input-error-message {
          color: #dc3545;
          position: absolute;
          bottom: -22px;
          font-size: 12px;
          left: 15px;
        }
        /* .center-circle-image {
          display: inline-block;
          position: relative;
          overflow: hidden;
          border-radius: 50%;
          img {
            position: absolute;
            top: 50%;
            left: 50%;
            object-fit: cover;
            margin-left: -50%;
            margin-top: -50%;
          }
        } */
      `}
    />
  );
}
