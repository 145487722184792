/** @jsx jsx */
import { jsx } from "@emotion/core";
import { useState } from "react";
import Form from "react-bootstrap/Form";
import axios from "axios";
import {
  showErrorSnack,
  showSuccessSnack,
} from "../../../snackbar/snackBarSlice";
import { useTheme } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { showModal } from "../../../../features/modal/modalSlice";
import Typography from "@material-ui/core/Typography";
import TextField from "../../../../components/TextField/TextField";
import PrimaryButton from "../../../../components/material/Button/PrimaryButton";
import Container from "@material-ui/core/Container";
import Logo from "../../../../components/Logo/Logo";
import * as yup from "yup";
import useClasses from "../../styles";
import { Formik } from "formik";

const schemaResetPassword = yup.object({
  password: yup.string().required().min(6),
  confirmPassword: yup.string().required().min(6),
});

export const ResetPassword = () => {
  const [isLoading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const classes = useClasses();
  const theme = useTheme();

  const resetEmailSubmit = async (data) => {
    const { email } = data;
    try {
      setLoading(true);

      await axios.post("/api/auth/passwordReset", { email });
      dispatch(showSuccessSnack("Please check your email"));
    } catch (error) {
      console.log(error);
      if (error.message) {
        dispatch(
          showErrorSnack(
            "There is no user record corresponding to this identifier. The user may have been deleted."
          )
        );
      } else {
        dispatch(showErrorSnack("Something went wrong"));
      }
    } finally {
      setLoading(false);
    }
  };

  const handleOpenSignUpModal = () => {
    dispatch(
      showModal({
        modalType: "signUpModal",
      })
    );
  };

  const handleOpenSignInModal = () => {
    dispatch(
      showModal({
        modalType: "signInModal",
      })
    );
  };

  return (
    <div css={classes.authContainer}>
      <Container maxWidth="xs">
        <Logo
          backgroundColor={theme.palette.secondary.light}
          width={90}
          height={90}
          imgWidth={86}
          imgHeight={86}
        />
        <Typography variant="h5" className="mt-5" css={classes.authHeading}>
          Reset Password
        </Typography>
        <div className="mt-4">
          <Formik
            onSubmit={resetEmailSubmit}
            initialValues={{
              password: "",
              consfirmPassword: "",
            }}
            validationSchema={schemaResetPassword}
          >
            {({
              handleSubmit,
              handleChange,
              values,
              touched,
              isValid,
              errors,
              handleBlur,
            }) => (
              <Form onSubmit={handleSubmit} noValidate>
                <Form.Group controlId="formPassword">
                  <TextField
                    label="PASSWORD"
                    type="password"
                    name="password"
                    isInvalid={touched.password && errors.password}
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="mb-4"
                  />
                  <span className="input-error-message">
                    {touched.password && errors.password
                      ? errors.password
                      : null}
                  </span>
                </Form.Group>
                <Form.Group
                  controlId="formPassword"
                  style={{ marginBottom: 48 }}
                >
                  <TextField
                    label="CONFIRM PASSWORD"
                    type="password"
                    name="confirmPassword"
                    isInvalid={
                      touched.confirmPassword && errors.confirmPassword
                    }
                    value={values.confirmPassword}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <span className="input-error-message">
                    {touched.confirmPassword && errors.confirmPassword
                      ? errors.confirmPassword
                      : null}
                  </span>
                </Form.Group>
                <PrimaryButton
                  loading={isLoading}
                  disabled={isLoading}
                  color="secondary"
                  type="submit"
                  variant="contained"
                >
                  Reset
                </PrimaryButton>
              </Form>
            )}
          </Formik>
        </div>
      </Container>
    </div>
  );
};
