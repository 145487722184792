import React from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Redirect } from "react-router";
import HttpsRedirect from "react-https-redirect";
import axios from "axios";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import Protect from "react-app-protect";

console.log("process.env.PRODUCTION", process.env.PRODUCTION);
if (
  process.env.NODE_ENV === "production" ||
  process.env.PRODUCTION === "true"
) {
  axios.defaults.baseURL = "https://api-dot-butter-n-binge.uk.r.appspot.com/";
} else {
  axios.defaults.baseURL = "http://localhost:1337/";
}

const token = localStorage.getItem("authToken");
if (token) {
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
}

process.env.NODE_ENV === "production" &&
  Sentry.init({
    dsn: "https://88a064e154314274b5357d42b9742b72@o1043159.ingest.sentry.io/6012661",
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });

function App() {
  return (
    <HttpsRedirect>
      <Redirect to={"/trending"} />
    </HttpsRedirect>
  );
}

export default App;
