/** @jsx jsx */
import { jsx } from "@emotion/core";
import { useState } from "react";
import useClasses from "./styles";
import Status from "../../Status/Status";
import ReactPlayer from "react-player";
import Tag from "../../Tag";
import { useHistory } from "react-router";
import { isUserActive } from "../../../utils/time";
import { ReactComponent as PauseButton } from "../../../assets/icons/PauseButton.svg";
import { ReactComponent as PlayButton } from "../../../assets/icons/PlayButton.svg";
import CircularProgress from "@material-ui/core/CircularProgress";
import { isMobile } from "../../../utils/mobile";

const Coach = ({ name, tag, image, video, slug, timestamp }) => {
  const classes = useClasses();
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const [isBuffering, setIsBuffering] = useState(true);
  const history = useHistory();
  const goToCoach = () => {
    history.push(`/talent/${slug}`);
  };

  return (
    <div
      css={classes.coach}
      onMouseEnter={() => !isMobile() && setIsVideoPlaying(true)}
      onMouseLeave={() => !isMobile() && setIsVideoPlaying(false)}
    >
      <div
        css={classes.coachVideoContainer}
        onClick={() => {
          global.dontMute = true;
          setIsVideoPlaying(!isVideoPlaying);
        }}
      >
        <div css={classes.coachImage}>
          <img src={image} />
        </div>
        {isVideoPlaying && (
          <div css={classes.coachVideo}>
            <ReactPlayer
              url={video}
              playing={true}
              width="100%"
              loop
              height="100%"
              playsinline
              muted={!global.dontMute}
              onReady={() => setIsBuffering(false)}
              onPlay={() => setIsBuffering(false)}
              onBuffer={() => setIsBuffering(true)}
            />

            {isBuffering && (
              <div css={classes.webinarProgress}>
                <CircularProgress color="white" />
              </div>
            )}
          </div>
        )}
        {isVideoPlaying ? (
          <PauseButton css={classes.webinarPlayBtn} />
        ) : (
          <PlayButton css={classes.webinarPlayBtn} />
        )}
      </div>
      <div
        css={classes.coachInfo}
        onClick={() => {
          global.dontMute = true;
          goToCoach();
        }}
      >
        <h3>{name}</h3>
        <div css={classes.coachTags} className="d-flex mb-3">
          {tag && <Tag>{tag}</Tag>}
        </div>
        {isUserActive(timestamp) ? (
          <Status styles={classes.coachStatus}>Online</Status>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default Coach;
