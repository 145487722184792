import React, { useEffect } from "react";
import { Container } from "@material-ui/core";
import Layout from "../../components/Layout/Layout";
import homeBannerImg from "../../assets/images/home-banner.webp";
import homeBannerMobileImg from "../../assets/images/home-banner-mobile.webp";
import "./About.css";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

function About() {
  const theme = useTheme();
  const matchesDownSm = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    window.scrollTo(0, 0);
    let body = document.getElementsByTagName("body")[0];
    body.style.overflow = "visible";
  }, []);

  return (
    <Layout>
      <Container maxWidth="xl">
        <div className="banner">
          <img src={matchesDownSm ? homeBannerMobileImg : homeBannerImg} />
        </div>

        <Typography variant="h3" color="inherit" align="center">
          About Butter N Binge
        </Typography>

        <Typography
          variant="caption"
          color="inherit"
          align="center"
          className="mt-5"
        >
          The creation of Butter N Binge was inspired by my mom. I admired her
          not only as a mom but as a person. BNB was created to acknowledge and
          appreciate each and every mom. With our primary focus to create a
          unique experience for moms worldwide, we have developed a community of
          moms who value and support one another. While also introducing you to
          a network of professionals who aim to help you be the best you. Our
          passion and purpose is to make your lives a little bit easier one day
          at a time.
        </Typography>

        <Typography
          variant="caption"
          color="inherit"
          align="center"
          className="mt-5 mb-5"
        >
          (It’s also pretty awesome because the creation of Butter N Binge let’s
          me tell everybody about my mom)
        </Typography>
      </Container>
    </Layout>
  );
}

export default About;
