import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: "relative",
    background: "#292932",
    transition: "all 1s ease-out",
    overflow: "hidden",
    borderRadius: 10,
    minHeight: "80vh",
    display: "flex",
    flexDirection: "row",
    padding: 45,
    [theme.breakpoints.down("lg")]: {
      justifyContent: "center",
      alignItems: "center",
    },
    [theme.breakpoints.down("sm")]: {
      padding: 0,
    },
  },
  videCallWrapper: {
    position: "relative",
    background: "#292932",
    minHeight: "80vh",
    width: "100%",
  },
  localMediaPlayerContainer: {
    width: 88,
    height: 128,
    position: "absolute",
    zIndex: 20,
    top: 45,
    right: 45,
    borderRadius: 12,
    overflow: "hidden",
    [theme.breakpoints.down("md")]: {
      width: 88,
      height: 128,
      top: 20,
      right: 20,
    },
  },
  bottomTabBar: {
    width: "100%",
    height: 75,
    position: "absolute",
    bottom: 0,
    left: 0,
    backgroundColor: "rgba(20, 20, 20, 0.58)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  controlButton: {
    zIndex: 12,
    color: "white",
    cursor: "pointer",
    fontSize: 60,
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "0 14px",
  },
  chatIcon: {
    paddingLeft: "20%",
  },
  remoteMediaPlayerContainer: {
    position: "absolute",
    width: "100%",
    height: "100%",
    backgroundColor: "transparent",
  },
  closeIconContainer: {
    zIndex: 12,
    position: "absolute",
    top: 90,
    left: 90,
    [theme.breakpoints.down("sm")]: {
      top: 20,
      left: 20,
    },
  },
  zeroWidth: {
    width: 0,
    height: 0,
    opacity: 0,
    zIndex: -100,
  },
}));

export default useStyles;
