import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout/Layout";
import Card from "react-bootstrap/Card";
import { useHistory, useParams } from "react-router";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import styles from "./Booking.module.css";
import {
  KeyboardDatePicker,
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import addMinutes from "date-fns/addMinutes";
import startOfDay from "date-fns/startOfDay";
import format from "date-fns/format";

import Button from "@material-ui/core/Button";
import { useDispatch, useSelector } from "react-redux";
import Service from "./service";
import {
  getAvailability,
  selectAvailabilityLoading,
  selectAvailabilitySlots,
} from "./bookingSlice";
import Container from "@material-ui/core/Container";
import { showErrorSnack, showSuccessSnack } from "../snackbar/snackBarSlice";
import TimePicker from "../../components/TimePicker/TimePicker";
import { minutesToHHmm, minutesToTime } from "../../utils/time";

Booking.propTypes = {};

function Booking(props) {
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const [selectedTime, selectTime] = useState(null);
  const [duration, setDuration] = useState(30);
  const [startDate, setStartDate] = useState(new Date());
  const [saveLoading, setSaveLoading] = useState(false);

  const handleDuration = (event) => {
    setDuration(event.target.value);
  };

  const onTimeSelect = (time) => {
    const mmHH = minutesToHHmm(time);
    const updatedDate = startDate;
    updatedDate.setHours(mmHH.hh, mmHH.mm, 0, 0);
    setStartDate(updatedDate);
    selectTime(time);
  };

  const onBookSession = async () => {
    setSaveLoading(true);
    const day = format(startDate, "MM/dd/yyyy");
    const data = {
      talentKey: id,
      day,
      duration,
      timeInMinutes: selectedTime,
      startDate,
      endDate: addMinutes(startDate, duration),
    };
    const booking = await Service.saveBooking(data);
    if (booking) {
      dispatch(showSuccessSnack("Session successfully booked"));
      dispatch(getAvailability(day, id));
      history.push(`/profile`);
    } else {
      dispatch(showErrorSnack("Cannot book a session (2)"));
    }
    setSaveLoading(false);
  };

  return (
    <Layout>
      <Container>
        <Card className="mt-5">
          <Card.Body>
            <Card.Title>Book a session</Card.Title>
            <div className="mt-5">
              <FormControl className={styles.DurationSelect}>
                <InputLabel id="duration-select-label">
                  Duration of the session
                </InputLabel>
                <Select
                  labelId="duration-select-label"
                  id="duration-select"
                  value={duration}
                  onChange={handleDuration}
                >
                  <MenuItem value={30}>30m</MenuItem>
                  <MenuItem value={60}>60m</MenuItem>
                </Select>
              </FormControl>
              <TimePicker
                selectedTime={selectedTime}
                selectTime={onTimeSelect}
                uid={id}
                startDate={startDate}
                setStartDate={setStartDate}
              />
              <div className="d-flex w-100 justify-content-end mt-3">
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={onBookSession}
                  disabled={saveLoading || !selectedTime}
                >
                  Book a session
                </Button>
              </div>
            </div>
          </Card.Body>
        </Card>
      </Container>
    </Layout>
  );
}

export default Booking;
