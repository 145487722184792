/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import styles from "./UpdateWebinar.module.css";
import Layout from "../../components/Layout/Layout";
import { Formik } from "formik";
import Form from "react-bootstrap/Form";
import ArrowBackIosIcon from "../../assets/icons/ArrowBlack.svg";
import * as yup from "yup";
import {
  deleteEvent,
  selectEventSaveLoading,
  updateEvent,
} from "../schedule/eventSlice";
import AutocompleteInput from "../../components/AutocompleteInput/AutocompleteInput";
import Dropzone from "../../components/Upload/dropzone/Dropzone";
import Service from "../schedule/service";
import { useHistory, useParams } from "react-router";
import { selectUser, selectUserDetails } from "../auth/authSlice";
import { showErrorSnack, showSuccessSnack } from "../snackbar/snackBarSlice";
import "react-calendar/dist/Calendar.css";
import Container from "@material-ui/core/Container";
import CircularProgress from "@material-ui/core/CircularProgress";
import Card from "@material-ui/core/Card";
import Icon from "../../components/Icon/Icon";
import Step from "../../components/Navigation/Step/Step";
import clsx from "clsx";
import Typography from "@material-ui/core/Typography";
import TextField from "../../components/TextField/TextField";
import Grid from "@material-ui/core/Grid";
import PrimaryButton from "../../components/material/Button/PrimaryButton";
import LinearProgress from "@material-ui/core/LinearProgress";
import CenteredBox from "../../components/Layout/CenteredBox";
import VideoIcon from "../../assets/icons/Video.svg";
import { useMediaQuery, useTheme } from "@material-ui/core";
import { fetchEvent, selectEvent } from "../webinarDetails/webinarDetailsSlice";
import validator from "validator";
import ConfirmationModal from "../../components/ConfirmationModal/ConfirmationModal";

const MAX_SIZE = 25;
let generalInformationSchema = yup.object({
  name: yup.string().required(),
  seatsAmount: yup.number().required().moreThan(0),
});
const webinarDetailsSchema = yup.object({
  description: yup.string().required(),
});

export function UpdateWebinar() {
  const dispatch = useDispatch();
  const { id } = useParams();

  const loading = useSelector(selectEventSaveLoading);
  const userDetails = useSelector(selectUserDetails);
  const user = useSelector(selectUser);
  const webinar = useSelector(selectEvent);
  const theme = useTheme();
  const smDownMatch = useMediaQuery(theme.breakpoints.down("sm"));

  const [step, setStep] = useState(1);
  const [name, setName] = useState("");
  const [seatsAmount, setSeatsAmount] = useState(10);
  const [tags, setTags] = useState([]);
  const [videoIntro, setVideoIntro] = useState(null);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [videoIntroLoading, setVideoIntroLoading] = useState(false);
  const [deleteEventLoading, setDeleteEventLoading] = useState(false);
  const history = useHistory();

  const onFirstStepSubmit = ({ name, seatsAmount }) => {
    setName(name);
    setSeatsAmount(seatsAmount);
    setStep(2);
  };

  // useEffect(() => {
  //   if ((webinar && userDetails) || !userDetails) {
  //     if (!userDetails || webinar.User.id !== userDetails.id) {
  //       history.push('/')
  //     }
  //   }
  // }, [webinar, userDetails])

  useEffect(() => {
    if (webinar) {
      setTags(webinar.Tags.map((v) => v.value));
      setVideoIntro(webinar.intro);
      generalInformationSchema = yup.object({
        name: yup.string().required(),
        seatsAmount: yup
          .number()
          .required()
          .moreThan(
            webinar.participantsCount,
            "Should be more than participants count!"
          ),
      });
    }
  }, [webinar]);

  useEffect(() => {
    dispatch(fetchEvent(id));
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    document.title = "Update Webinar";
  }, []);

  const onUpdateEvent = (event) => {
    dispatch(updateEvent(event)).then((res) => {
      const { type, payload } = res;
      if (type === "event/setEvent") {
        history.push(`/webinar/details/${webinar.id}`);
      } else {
        dispatch(showErrorSnack("Cannot update"));
      }
    });
  };

  const onSubmit = ({ description }) => {
    let event = {
      id: webinar.id,
      name,
      description,
      tags,
      seatsAmount,
    };
    if (typeof videoIntro === "string" && validator.isURL(videoIntro)) {
      onUpdateEvent(event);
    } else {
      setVideoIntroLoading(true);
      Service.saveAndCompressIntro(
        videoIntro,
        `videos/events/${webinar.id}/intro/intro`
      )
        .then(({ videoIntro, videoIntroThumb }) => {
          setVideoIntroLoading(false);
          const dateToUpdate = {
            ...event,
            intro: videoIntro,
            introThumb: videoIntroThumb,
          };
          onUpdateEvent(dateToUpdate);
        })
        .catch((e) => {
          dispatch(showErrorSnack("Cannot upload intro"));
        });
    }
  };

  const onIntroAdded = ([file]) => {
    if (
      file.type.startsWith("video/") &&
      Math.floor(file.size / 1e6) < MAX_SIZE
    ) {
      setVideoIntro(file);
    } else {
      dispatch(
        showErrorSnack("The uploaded file must be video and less than 25mb")
      );
    }
  };

  const onIntroRemove = async () => {
    Service.removeIntro(
      webinar.id,
      webinar.intro,
      () => {
        setVideoIntro(null);
      },
      (err) => {
        console.log(err);
        dispatch(showErrorSnack("An error occurred when deleting intro!"));
      }
    );
  };

  const isFirstStepButtonValid = () => {
    return tags.length;
  };

  const isSecondStepButtonValid = () => {
    return videoIntro;
  };

  const handleBackButton = () => {
    if (step !== 1) {
      setStep(step - 1);
    } else {
      history.goBack();
    }
  };

  const onDelete = async () => {
    try {
      setDeleteEventLoading(true);
      await Service.deleteEvent(webinar.id);
      setDeleteEventLoading(false);
      toggleDeleteConfirmation();
      history.push("/profile");
    } catch (e) {
      setDeleteEventLoading(false);
      toggleDeleteConfirmation();
      dispatch(showErrorSnack(e));
    }
  };

  const toggleDeleteConfirmation = () => {
    setDeleteConfirmation(!deleteConfirmation);
  };

  if (!webinar) {
    return (
      <div className="centered_spinner_container">
        <CircularProgress color="secondary" />
      </div>
    );
  }

  return (
    <Layout>
      <ConfirmationModal
        open={deleteConfirmation}
        title={`Delete ${webinar.name}?`}
        onAcceptSuccessful={onDelete}
        disabled={deleteEventLoading}
        handleClose={toggleDeleteConfirmation}
      />
      <Container
        maxWidth="md"
        className={smDownMatch && step !== 0 ? styles.SmContainer : null}
      >
        <Card className={styles.Card}>
          <div className="d-flex justify-content-between">
            <Icon
              src={ArrowBackIosIcon}
              width={62}
              height={62}
              onClick={handleBackButton}
              backgroundColor="#fff"
              imgWidth={28}
            />
            <div
              className={clsx(
                "d-flex align-items-center justify-content-between",
                styles.Stepper
              )}
            >
              <Step mode={step === 1 ? "active" : "disabled"} />
              <Step
                mode={
                  step === 1 ? "inactive" : step === 2 ? "active" : "disabled"
                }
              />
              {/*<Step mode={step === 0 || step === 1 ? "inactive" : "active"}/>*/}
            </div>
          </div>
          <div
            className={styles.GridContainer}
            style={{ display: step === 1 ? "block" : "none" }}
          >
            <Grid
              container
              direction="column"
              spacing={1}
              alignContent="center"
            >
              <Grid
                md={6}
                sm={12}
                xs={12}
                lg={6}
                item
                className={styles.GridCell}
              >
                <Typography variant="h4" color="textPrimary" className="mb-3">
                  General Information
                </Typography>
              </Grid>
              <Grid
                md={6}
                sm={12}
                xs={12}
                lg={6}
                item
                className={styles.GridCell}
              >
                <Formik
                  onSubmit={onFirstStepSubmit}
                  initialValues={{
                    name: webinar?.name,
                    seatsAmount: webinar?.seatsAmount,
                  }}
                  validationSchema={generalInformationSchema}
                >
                  {({
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    values,
                    touched,
                    isValid,
                    errors,
                  }) => (
                    <Form
                      className="mt-2 w-100"
                      noValidate
                      onSubmit={handleSubmit}
                    >
                      <TextField
                        className="mt-3"
                        label="Hangout name"
                        name="name"
                        darkMode
                        value={values.name}
                        isInvalid={errors.name}
                        onChange={handleChange}
                      />
                      <TextField
                        className="mt-3"
                        label="Amount of seats"
                        name="seatsAmount"
                        darkMode
                        value={values.seatsAmount}
                        isInvalid={errors.seatsAmount}
                        onChange={handleChange}
                      />
                      <div className="mt-3">
                        <div className="mb-2">
                          <Typography
                            variant="caption"
                            color="textPrimary"
                            style={{ fontWeight: 600 }}
                          >
                            TAGS
                          </Typography>
                        </div>
                        <div className={styles.TagWrapper}>
                          <AutocompleteInput value={tags} onChange={setTags} />
                        </div>
                      </div>
                      <div className="d-flex flex-row mt-5 justify-content-between">
                        <div className="w-70">
                          <PrimaryButton
                            color="secondary"
                            variant="contained"
                            type="submit"
                            disabled={
                              (!isValid && touched) || !isFirstStepButtonValid()
                            }
                          >
                            Next
                          </PrimaryButton>
                        </div>
                        <div className="w-25">
                          <PrimaryButton
                            css={css`
                              background: #fa7267;
                              &:hover {
                                background: rgb(250 114 103 / 75%);
                              }
                            `}
                            color="secondary"
                            variant="contained"
                            type="button"
                            onClick={toggleDeleteConfirmation}
                          >
                            Delete
                          </PrimaryButton>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </Grid>
            </Grid>
          </div>
          <div
            className={styles.GridContainer}
            style={{ display: step === 2 ? "block" : "none" }}
          >
            <Grid
              container
              direction="column"
              spacing={1}
              alignContent="center"
            >
              <Grid
                md={6}
                sm={12}
                xs={12}
                lg={6}
                item
                className={styles.GridCell}
              >
                <Typography variant="h4" color="textPrimary" className="mb-3">
                  Hangout details
                </Typography>
              </Grid>
              <Grid
                md={6}
                sm={12}
                xs={12}
                lg={6}
                item
                className={styles.GridCell}
              >
                <Formik
                  onSubmit={onSubmit}
                  initialValues={{
                    description: webinar?.description,
                  }}
                  validationSchema={webinarDetailsSchema}
                >
                  {({
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    values,
                    touched,
                    isValid,
                    errors,
                  }) => (
                    <Form
                      className="mt-2 w-100"
                      noValidate
                      onSubmit={handleSubmit}
                    >
                      <Dropzone
                        onClose={onIntroRemove}
                        className="mt-3"
                        label="Intro video"
                        accept="video/*"
                        onFilesAdded={onIntroAdded}
                        disabled={videoIntro}
                      />
                      <TextField
                        className="mt-3"
                        as="textarea"
                        darkMode
                        label="Description"
                        value={values.description}
                        name="description"
                        isInvalid={errors.description}
                        onChange={handleChange}
                      />
                      <div className="mt-3">
                        <div className="d-flex flex-row justify-content-between mt-5">
                          <div className="w-70">
                            <PrimaryButton
                              color="secondary"
                              variant="contained"
                              type="submit"
                              loading={loading || videoIntroLoading}
                              disabled={
                                (!isValid && touched) ||
                                loading ||
                                videoIntroLoading ||
                                !isSecondStepButtonValid()
                              }
                            >
                              Update the webinar
                            </PrimaryButton>
                          </div>
                          <div className="w-25">
                            <PrimaryButton
                              css={css`
                                background: #fa7267;
                                &:hover {
                                  background: rgb(250 114 103 / 75%);
                                }
                              `}
                              color="secondary"
                              variant="contained"
                              type="button"
                              onClick={toggleDeleteConfirmation}
                            >
                              Delete
                            </PrimaryButton>
                          </div>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </Grid>
            </Grid>
          </div>
        </Card>
      </Container>
    </Layout>
  );
}
