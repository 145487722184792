import React, { useContext } from "react";
import Image from "react-bootstrap/Image";
import Icon from "../Icon/Icon";
import UserIcon from "../../assets/icons/user.svg";
import { useTheme, makeStyles } from "@material-ui/core";
import styles from "./Avatar.module.css";
import { Avatar as MaterialAvatar } from "@material-ui/core";
import theme from "../../styles/theme";
import clsx from "clsx";

const useStyles = makeStyles({
  root: {
    background: `linear-gradient(45deg, #666 30%, #707070 90%)`,
  },
});

const Avatar = ({ url, width, height, imgWidth, imgHeight, onUserClick }) => {
  const classes = useStyles();
  return url ? (
    <div onClick={onUserClick}>
      <MaterialAvatar
        src={url}
        className={styles.Img}
        style={{ borderRadius: "12%", height: imgHeight, width: imgWidth }}
      />
    </div>
  ) : (
    <div
      onClick={onUserClick}
      className="d-flex align-items-center justify-content-center"
      style={{
        width,
        height,
        borderRadius: "12px",
        backgroundColor: theme.palette.primary,
        overflow: "hidden",
      }}
    >
      <MaterialAvatar
        className={clsx(styles.Img, classes.root)}
        style={{ borderRadius: "12%", height: imgHeight, width: imgWidth }}
      />
    </div>
  );
};

Avatar.defaultProps = {
  width: 68,
  height: 68,
  imgWidth: 48,
  imgHeight: 48,
};

export default Avatar;
