import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "130000!important",
  },

  modalContent: {
    position: "relative",
    overflow: "auto",
    maxHeight: "90vh",
    "&::-webkit-scrollbar": { display: "none" },
    background: "#fff",
    borderRadius: "40px",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      borderRadius: "0",
      height: "calc(var(--vh, 1vh) * 100)",
      overflow: "auto",
      maxHeight: "100vh",
    },
  },
  closeBtn: {
    position: "absolute",
    right: 30,
    color: "#2F2F36",
    top: 30,
    [theme.breakpoints.down("xs")]: {
      top: 20,
      right: 15,
    },
  },
}));

export default useStyles;
