import { createMuiTheme } from "@material-ui/core/styles";
import ModernMachine from "../assets/fonts/fonts/modern_machine/Modern-Machine.otf";

const theme = createMuiTheme({
  typography: {
    fontFamily: "Poppins",
    h3: {
      fontSize: "24px",
      lineHeight: "22px",
      fontWeight: "bold",
    },
    h4: {
      fontSize: "22px",
      lineHeight: "30px",
      fontWeight: "bold",
    },
    h5: {
      fontWeight: 600,
    },
    subtitle2: {
      fontSize: "1rem",
      fontWeight: 600,
      lineHeight: "24px",
      "@media (max-width: 768px)": {
        fontSize: "0.875rem",
      },
    },
    caption: {
      fontSize: "0.875rem",
      display: "block",
      lineHeight: "25px",
      wordBreak: "break-word",
    },
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        // '@font-face': [sfpro],
        ".MuiContainer-maxWidthXl": {
          maxWidth: 1800,
        },
      },
    },
    MuiPickersDay: {
      day: {
        color: "#fff",
        borderRadius: 12,
        display: "block",
        opacity: 1,
        "& p": {
          fontWeight: "bold",
        },
      },
      daySelected: {
        backgroundColor: "#FFB3AB",
        borderRadius: 12,
        "&:hover": {
          backgroundColor: "#ee8a80",
        },
      },
      dayDisabled: {
        color: "#8F92A1",
        "& p": {
          fontWeight: 500,
        },
      },
      dayHover: {
        backgroundColor: "rgba(250, 114, 103, 1)",
      },
      current: {
        color: "#FFB3AB",
        borderRadius: 12,
      },
    },
    MuiFormControl: {
      root: {
        width: "100%",
      },
    },
  },
  palette: {
    common: { black: "#000", white: "#fff" },
    background: { paper: "#141414", default: "#141414" },
    primary: {
      light: "#ee8a80",
      main: "#FFB3AB",
      dark: "#ee8a80",
      contrastText: "#1E1F20",
    },
    secondary: {
      light: "rgba(143, 146, 161, 0.15)",
      main: "#FFB3AB",
      contrastText: "#fff",
    },
    error: {
      light: "rgba(143, 146, 161, 0.2)",
      main: "#f44336",
      dark: "#d32f2f",
      contrastText: "#fff",
    },
    success: {
      light: "#81c784",
      main: "#4caf50",
      dark: "#388e3c",
      contrastText: "#fff",
    },
    text: {
      primary: "#fff",
      secondary: "rgba(255, 255, 255, 0.7)",
      disabled: "rgba(143, 146, 161, 0.6)",
      hint: "rgba(0, 0, 0, 0.38)",
      contrastText: "#000",
    },
    action: {
      disabledBackground: "#F1F1F2",
    },
  },
});
export default theme;
