import React from "react";
import PropTypes from "prop-types";
import { Button } from "@material-ui/core";
import clsx from "clsx";
import styles from "./PricingCheckBox.module.css";

PricingCheckBox.propTypes = {};

function PricingCheckBox({ label, checked, onClick }) {
  return (
    <div
      onClick={onClick}
      className={clsx(
        styles.Container,
        checked ? styles.Checked : styles.UnChecked
      )}
    >
      {label}
    </div>
  );
}

export default PricingCheckBox;
