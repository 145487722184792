import React from "react";
import PropTypes from "prop-types";

Icon.defaultProps = {
  width: 42,
  height: 42,
  imgWidth: 20,
  imgHeight: 20,
  backgroundColor: "black",
  borderRadius: 10,
  image: false,
  onClick: () => {},
};

function Icon({
  width,
  title,
  height,
  backgroundColor,
  src,
  borderRadius,
  imgWidth,
  imgHeight,
  className,
  image,
  onClick,
  unreadIcon,
}) {
  return (
    <div title={title || ""} className={className} onClick={onClick}>
      <div>
        <div
          style={{ width, height, backgroundColor, borderRadius }}
          className="d-flex align-items-center justify-content-center"
        >
          <img
            src={src}
            width={imgWidth}
            height={imgHeight}
            style={{ borderRadius: image ? 12 : 0 }}
          />
        </div>
      </div>
      {unreadIcon ? (
        <div className="unread-dot-wrapper">
          <div className="unread-dot" />
        </div>
      ) : null}
    </div>
  );
}

export default Icon;
