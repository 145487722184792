/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout/Layout";
import { useHistory, useLocation, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
  addParticipant,
  amIParticipant,
  fetchEvent,
  fetchMoreEvents,
  selectAmIParticipant,
  selectEvent,
  selectEventLoading,
  selectMoreEvents,
} from "./webinarDetailsSlice";
import { selectTalentRecordedEvents } from "../talent-profile/talentProfileSlice";
import styles from "./WebinarDetails.module.css";
import Avatar from "../../components/Avatar/Avatar";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  selectPaymentStatus,
  selectUser,
  selectUserDetails,
  setPaymentErrorMessage,
  setPaymentStatus,
} from "../auth/authSlice";
import * as Cookies from "js-cookie";
import ProfileService from "../profile/service";
import SessionPlaceholder from "../../assets/images/session-placeholder.svg";
import Icon from "../../components/Icon/Icon";
import Tab from "../../components/Tab/Tab";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import { useTheme } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import BackButton from "../../components/BackButton/BackButton";
import Player from "../../components/Player/Player";
import Separator from "../../components/Separator";
import { showErrorSnack, showSuccessSnack } from "../snackbar/snackBarSlice";
import Container from "@material-ui/core/Container";
import { showModal } from "../../features/modal/modalSlice";
import isAfter from "date-fns/isAfter";
import BookNow from "../../components/BookNow";
import WebinarColumnInfo from "./components/WebinarColumnInfo/WebinarColumnInfo";
import WebinarsRowList from "./components/WebinarsRowList";
import { useOtherWebinars } from "../../hooks/query/webinarDetails/webinarDetails";
import moment from "moment";
import { isMobile } from "../../utils/mobile";
import { WhatHappenNextModal } from "../../components/BookModal/BookModal";

WebinarDetails.propTypes = {};

function WebinarDetails(props) {
  const { id } = useParams();
  const location = useLocation();
  const history = useHistory();
  const user = useSelector(selectUser);

  const webinar = useSelector(selectEvent);
  const otherWebinars = useOtherWebinars(webinar !== null && webinar.UserId);
  const moreWebinars = useSelector(selectMoreEvents);
  const currentUser = useSelector(selectUser);
  const iAmParticipant = useSelector(selectAmIParticipant);
  const eventLoading = useSelector(selectEventLoading);
  const userDetails = useSelector(selectUserDetails);
  const recordedEvents = useSelector(selectTalentRecordedEvents);
  const paymentStatus = useSelector(selectPaymentStatus);
  const theme = useTheme();
  const [zoomLink, setZoomLink] = useState(null);
  const [whatNextOpen, setWhatNextOpen] = useState(false);

  const dispatch = useDispatch();

  const getZoomLink = async (webinar) => {
    const { zoomMeetingId } = webinar;

    const zoomWebinar = await ProfileService.getZoomMeetingDetails(
      zoomMeetingId
    );

    console.log("zoomWebinar details", zoomWebinar);

    if (zoomMeetingId && userDetails) {
      setZoomLink(
        userDetails.mode === "Talent"
          ? zoomWebinar.start_url
          : zoomWebinar.join_url
      );
    }
  };

  useEffect(() => {
    if (webinar) {
      dispatch(fetchMoreEvents(webinar.UserId, webinar.id));

      getZoomLink(webinar);
    }
  }, [webinar]);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(fetchEvent(id));
  }, [location]);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(fetchEvent(id));
  }, []);

  useEffect(() => {
    if (currentUser) {
      dispatch(amIParticipant(id));
    }
  }, [currentUser]);
  useEffect(() => {
    document.title = webinar?.name ? webinar.name : "Hangout Details";
  }, [webinar]);

  useEffect(() => {
    if (paymentStatus === "succeeded") {
      if (currentUser) {
        dispatch(addParticipant(webinar)).then((res) => {
          const { type, payload } = res;
          if (type === "webinarDetails/setParticipants") {
            dispatch(amIParticipant(id));
            dispatch(
              showSuccessSnack("You successfully signed up for this webinar")
            );
            setWhatNextOpen(true);
            dispatch(setPaymentStatus(null));
            dispatch(setPaymentErrorMessage(null));
          } else {
            dispatch(showErrorSnack("Cannot join the webinar"));
          }
        });
      } else {
        if (window.location.pathname != "/login") {
          history.push(
            "/login?returnUrl=" + encodeURIComponent(window.location.pathname)
          );
        }
      }
    }
  }, []);
  const [startsSoon, setStartsSoon] = useState(false);

  useEffect(() => {
    if (webinar) {
      setStartsSoon(
          moment(webinar?.startDate).diff(moment(), "minutes") < 10 &&
          moment(webinar?.endDate).isAfter(moment())
      );
      const interval = setInterval(() => {
        setStartsSoon(
            moment(webinar.startDate).diff(moment(), "minutes") < 10 &&
            moment(webinar.endDate).isAfter(moment())
        );
      }, 10000);
      return () => clearInterval(interval);
    }
  }, [webinar]);

  const handleOpenSignInModal = () => {
    dispatch(
      showModal({
        modalType: "signInModal",
      })
    );
  };

  const authEventHandler = () => {
    if (!user) {
      handleOpenSignInModal();
      dispatch(showErrorSnack("Please log in first"));
    }
  };

  const joinWebinar = async () => {
    authEventHandler();
    if (webinar.price)
      history.push({
        pathname: `/webinar/purchase/${webinar.id}`,
        state: {
          webinarId: webinar.id,
          amount: webinar.price,
          stripeConnectedAccountId: webinar.User.stripeConnectedAccountId,
        },
      });
    else {
      if (currentUser) {
        dispatch(addParticipant(webinar)).then((res) => {
          const { type, payload } = res;
          if (type === "webinarDetails/setParticipants") {
            dispatch(
              showSuccessSnack("You successfully signed up for this webinar")
            );
            setWhatNextOpen(true);
            dispatch(amIParticipant(id));
          } else {
            dispatch(showErrorSnack("Cannot join the webinar"));
          }
        });
      } else {
        if (window.location.pathname != "/login") {
          handleOpenSignInModal();
          dispatch(showErrorSnack("Please log in first"));
        }
      }
    }
  };

  const onUserClick = (user) => {
    history.push(`/talent/${user.slug}`);
  };

  const isEnteringAvailable = () => {
    return (
      !iAmParticipant &&
      !webinar.downloadLink &&
      webinar.seatsAmount > webinar.participantsCount &&
      isAfter(new Date(webinar.endDate), new Date())
    );
  };

  const onEnterTalent = async (webinar, isTalent) => {
    try {
      const { id, UserId, type, zoomMeetingId } = webinar;

      const mine = UserId === user.uid;
      if (mine) {
        ProfileService.createEventNotification(id)
          .then((res) => {
            console.log("Successfully created notifications");
          })
          .catch((e) => {
            console.log("Notifications creating failed!");
          });
      }

      Cookies.set("channel", `${id}`);
      history.push({
        pathname: type === "Webinar" ? "/meeting" : "/session",
        state: {
          webinar,
        },
      });
    } catch (e) {
      console.log("Cannot join the webinar: ", e);
      dispatch(showErrorSnack("Cannot join the webinar: " + e.message));
    }
  };

  const checkWebinarStarting = () => {
    let startDate = webinar.startDate;
    let endDate = webinar.endDate;

    let result = moment(startDate).fromNow();

    if (moment().diff(moment(startDate)) < 0) {
      return result;
    } else if (moment().diff(moment(endDate)) < 0) {
      return "Started " + result;
    }
  };

  const isPastDay = (firstDate, secondDate) => {
    return moment(firstDate).diff(moment(secondDate)) < 0;
  };

  const goIntroFullScreen = () => {
    const elem = document.getElementById("video-intro");
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.mozRequestFullScreen) {
      /* Firefox */
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) {
      /* Chrome, Safari and Opera */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      /* IE/Edge */
      elem.msRequestFullscreen();
    }
  };

  const mode =
    currentUser && webinar && currentUser.uid === webinar.UserId
      ? "admin"
      : "user";

  if (!webinar || otherWebinars.isFetching || !mode || eventLoading) {
    return (
      <div className="centered_spinner_container">
        <CircularProgress color="secondary" />
      </div>
    );
  }

  const renderWebinarButton = () => (
    <>
      {mode &&
        startsSoon &&
        mode === "user" &&
        iAmParticipant &&
        !webinar.downloadLink && (
          <a href={zoomLink}>
            <Button
              color="secondary"
              variant="contained"
              fullWidth
              className={styles.webinarEnterRoomBtn}
            >
              Enter The Room
            </Button>
          </a>
        )}
      {userDetails &&
        userDetails.mode === "Talent" &&
        !webinar.downloadLink &&
        webinar.UserId === user.uid &&
        isPastDay(new Date(), new Date(webinar.endDate)) && (
          <a href={zoomLink}>
            <Button
              color="secondary"
              variant="contained"
              fullWidth
              className={styles.webinarEnterRoomBtn}
            >
              Enter The Room
            </Button>
          </a>
        )}
    </>
  );

  const render1on1Button = () => (
    <>
      {mode && mode === "user" && iAmParticipant && !webinar.downloadLink && (
        <Button
          color="secondary"
          variant="contained"
          fullWidth
          onClick={() => onEnterTalent(webinar, false)}
          className={styles.webinarEnterRoomBtn}
        >
          Enter The Room
        </Button>
      )}
      {userDetails &&
        userDetails.mode === "Talent" &&
        !webinar.downloadLink &&
        webinar.UserId === user.uid &&
        isPastDay(new Date(), new Date(webinar.endDate)) && (
          <Button
            color="secondary"
            variant="contained"
            fullWidth
            onClick={() => onEnterTalent(webinar, true)}
            className={styles.webinarEnterRoomBtn}
          >
            Enter The Room
          </Button>
        )}
    </>
  );

  const handleWhatNextClose = () => {
    setWhatNextOpen(false);
  };

  return (
    <Layout>
      <Container maxWidth="xl">
        <div className={clsx("mt-5 mb-5", styles.Wrapper)}>
          <BackButton fill="#8F92A1" onClick={() => history.goBack()} />
          <div className={styles.PlayerContainer}>
            {webinar.type === "Webinar" ? (
              // <img src={ webinar.introThumb} className={styles.PlayerImageThumb} />
              <Player
                height={isMobile() ? 180 : 560}
                url={webinar.intro}
                play={false}
                controls={false}
              />
            ) : (
              <img
                className={styles.SessionPlaceholder}
                src={SessionPlaceholder}
              />
            )}
          </div>
          <div className={styles.webinarInfo}>
            <Typography variant="h4" color="textPrimary" className="mt-4 mb-4">
              {webinar.name}
            </Typography>
            <div className={clsx("mb-5", styles.webinarDetails)}>
              {isPastDay(new Date(), new Date(webinar.endDate)) ? (
                <WebinarColumnInfo
                  type={webinar.type}
                  price={webinar.price}
                  startDate={webinar.startDate}
                  endDate={webinar.endDate}
                  seatsAmount={webinar.seatsAmount}
                  participantsCount={webinar.participantsCount}
                />
              ) : (
                <div
                  css={css`
                    color: #ffb3ab;
                    font-size: 20px;
                  `}
                >
                  Webinar ended
                </div>
              )}
            </div>
          </div>
          <Separator className="mt-4 mb-4" />
          <div className={styles.userInfoContainer}>
            <UserInfo
              user={webinar?.User}
              onClick={onUserClick}
              hostedBy
              rating={true}
              recordedEvents={recordedEvents}
            />
            <div className={styles.descrContainer}>{webinar.description}</div>
            {mode && mode === "user" && isEnteringAvailable() && (
              <BookNow
                startDate={webinar.startDate}
                checkWebinarStarting={checkWebinarStarting}
                className={styles.webinarBookNow}
                onClick={joinWebinar}
                loading={
                  eventLoading ||
                  (paymentStatus === "succeeded" && !iAmParticipant)
                }
                disabled={
                  eventLoading ||
                  (paymentStatus === "succeeded" && !iAmParticipant)
                }
              />
            )}
            {webinar.type === "Webinar"
              ? renderWebinarButton()
              : render1on1Button()}
          </div>
        </div>
        <div className={clsx("mt-5 mb-5", styles.Wrapper)}>
          <div className="d-flex align-items-center">
            <Tab title="Upcoming webinars by this host" active />
          </div>
          <Separator
            className="mt-4 mb-5"
            css={css`
              margin: 0 -65px;
            `}
          />
          <WebinarsRowList
            styleType="webinarDetails"
            items={otherWebinars.data.filter(
              (item) =>
                item.id !== webinar.id &&
                moment(item.startDate).isAfter(moment())
            )}
          />
        </div>
        <WhatHappenNextModal
          open={whatNextOpen}
          handleClose={handleWhatNextClose}
        />
      </Container>
    </Layout>
  );
}

export const ItemDetails = ({ icon, name, subtitle, className, imgWidth }) => {
  return (
    <div className={clsx("d-flex mr-4", className)}>
      <Icon
        src={icon}
        backgroundColor={"rgb(255 255 255 / 0.2)"}
        imgWidth={imgWidth}
      />
      <div style={{ marginLeft: "0.625rem" }}>
        <Typography
          variant="subtitle2"
          style={{ lineHeight: 1.3, marginTop: 2 }}
        >
          {name}
        </Typography>
        <Typography variant="caption" color="textSecondary">
          {subtitle}
        </Typography>
      </div>
    </div>
  );
};

export const UserInfo = ({
  user,
  onClick,
  rating = false,
  recordedEvents,
  hostedBy = false,
}) => {
  return (
    <div
      className={styles.AvatarContainer}
      onClick={() => (onClick ? onClick(user) : null)}
    >
      <div style={{ cursor: "pointer", borderRadius: 12 }}>
        <Avatar url={user.photoURL} imgHeight={68} imgWidth={68} />
      </div>
      <div className="ml-3 d-flex justify-content-between flex-column">
        {hostedBy ? (
          <div>
            <Typography
              variant="h4"
              color="textSecondary"
              style={{ fontWeight: 500, fontSize: 18 }}
            >
              Hosted by
            </Typography>
            <div className="d-flex">
              <div className="profileStats d-flex mr-3">
                <Typography
                  variant="h4"
                  color="textPrimary"
                  className={styles.webinarName}
                  //className="font-weight-bold mr-2"
                >
                  {user.displayName}
                </Typography>
              </div>
            </div>
          </div>
        ) : (
          <Typography
            variant="h4"
            color="textPrimary"
            style={{
              fontWeight: 500,
              fontSize: 24,
              marginLeft: 8,
              marginTop: 18,
            }}
          >
            {user.displayName}
          </Typography>
        )}
      </div>
    </div>
  );
};

export default WebinarDetails;
