import { createSlice } from "@reduxjs/toolkit";
import Service from "./service";
import { showErrorSnack } from "../snackbar/snackBarSlice";

export const slice = createSlice({
  name: "event",
  initialState: {
    event: null,
    loading: false,
    error: null,
  },
  reducers: {
    setEvent: (state, action) => {
      state.event = action.payload;
      state.loading = false;
      state.error = null;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
  },
});

const { setEvent, setLoading, setError } = slice.actions;

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched
export const saveEvent = (event) => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    const response = await Service.saveEvent(event);
    return dispatch(setEvent(response));
  } catch (e) {
    dispatch(showErrorSnack(e));
    return dispatch(setError(e));
  }
};
export const updateEvent = (event) => async (dispatch) => {
  setLoading(true);
  try {
    console.log("Update", event);
    const response = await Service.updateEvent(event);
    return setEvent(response);
  } catch (e) {
    return setError(e);
  }
};
export const deleteEvent = (id) => async (dispatch) => {
  setLoading(true);
  try {
    await Service.deleteEvent(id);
    return setEvent(null);
  } catch (e) {
    return setError(e);
  }
};

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const selectEventSaveLoading = (state) => state.event.loading;

export default slice.reducer;
