import { css } from "@emotion/core";

const useClasses = (theme) => ({
  webinar: css`
    max-width: 375px;
    width: 100%;
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    cursor: pointer;
    > img {
      width: 100%;
      border-radius: 25px;
    }
    @media (max-width: 768px) {
      max-width: 100%;
    }
  `,
  webinarPlayBtn: css`
    z-index: 111;
    position: absolute;
    bottom: 15px;
    left: 15px;
  `,
  webinarVideoContainer: css`
    height: 215px;
    position: relative;
    overflow: hidden;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    &:before {
      content: "";
      top: 0;
      left: 0;
      position: absolute;
      width: 100%;
      z-index: 11;
      height: 100%;
      background: rgb(0 0 0 / 0.05);
    }
  `,
  webinarImage: css`
    display: flex;
    align-items: center;
    height: 215px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    img {
      width: 100%;
      object-fit: cover;
      position: absolute;
      left: 0;
      top: 0;
      height: 215px;
    }
  `,
  webinarVideo: css`
    display: flex;
    align-items: center;
    height: 215px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    video {
      width: 100%;
      object-fit: cover;
      position: absolute;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      left: 0;
      top: 0;
      height: 215px;
    }
  `,
  webinarProgress: css`
    position: absolute;
    top: 50%;
    left: 50%;
    opacity: 0.7;
    transform: translate(-50%, -50%);
  `,
  webinarInfo: css`
    background: #2f2f36;
    position: relative;
    width: 100%;
    padding: 1rem;
    padding-bottom: 5px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    h3 {
      margin-top: 0;
      display: inline-block;
      color: #fff;
      margin-bottom: 0.5em;
      font-weight: 600;
      font-size: 18px;
      position: relative;
      line-height: 22px;
    }
  `,
  webinarStatus: css`
    position: absolute;
    left: 110%;
    top: 50%;
    transform: translateY(-50%);
  `,
  webinarTags: css`
    position: relative;
  `,
  webinarStarts: css`
    position: absolute;
    top: -45px;
    z-index: 22;
    right: 15px;
    height: 35px;
    background: #1d0d3eaa;
    border-radius: 15px;
    padding: 0 1rem;
    svg {
      width: 14px;
    }
  `,
  webinarImgPlaceholder: css`
    position: relative;
    height: 220px;
    > svg {
      position: absolute;
      left: 1rem;
      bottom: 1rem;
    }
    img {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      height: 220px;
      margin-top: -110px;
      margin-left: -50%;
      object-fit: cover;
    }
  `,
});

export default useClasses;
