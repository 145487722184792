import { css } from "@emotion/core";

const classes = {
  footerInner: css`
    padding: 4.5rem 2rem 3rem;
  `,
  footer: css`
    background: #000000;
    h4 {
      color: #fff;
      font-size: 1.313rem;
      margin-bottom: 2.625rem;
    }
    button {
      border: 1px solid #fff;
      color: #fff;
      width: 250px;
      height: 46px;
      font-weight: 500;
      border-radius: 10.65px;
      cursor: pointer;
      display: block;
    }
    .mb-52 {
      margin-bottom: 3.25rem;
    }
  `,
  footerColumn: css`
    @media (max-width: 1450px) {
      &:nth-of-type(1) {
        width: 220px;
      }
      width: 50%;
      margin-bottom: 4rem;
    }
    @media (max-width: 768px) {
      width: 100%;
      &:last-child {
        margin-bottom: 0;
      }
    }
  `,
  footerSocial: css`
    margin-top: 3rem;
    margin-left: -10px;
    li {
      width: 45px;
      height: 45px;
      border-radius: 50%;
      color: #fff;
      cursor: pointer;
      &:hover {
        background: #2f2f36;
      }
    }
  `,
  linksList: css`
    li {
      color: #fff;
      margin-bottom: 1.4rem;
      line-height: 19px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    a {
      cursor: pointer;
      line-height: 1.3;
      font-size: 18px;
      color: white;
      text-decoration: none;
    }
  `,
  socialBtns: css`
    img {
      width: 100%;
    }
    a {
      display: block;
      cursor: pointer;
    }
  `,
  appStoreBtn: css`
    width: 165px;
    margin-right: 1.625rem;
  `,
  googleBtn: css`
    width: 173px;
  `,
  copyright: css`
    color: #fff;
    opacity: 0.62;
    line-height: 1.4;
  `,
};

export { classes };
