import React from "react";
import PropTypes from "prop-types";
import { Button, withStyles } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

const PrimaryButton = ({ loading, disabled, ...otherProps }) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
      alignItems: "center",
    },
    wrapper: {
      position: "relative",
      width: "100%",
    },
    buttonProgress: {
      color: theme.palette.primary.main,
      position: "absolute",
      top: "50%",
      left: "50%",
      marginTop: -12,
      marginLeft: -12,
    },
    buttonRoot: {
      borderRadius: 12,
      border: 0,
      width: "100%",
      fontSize: 14,
      fontWeight: 600,
      height: 58,
    },
    buttonLabel: {
      fontWeight: "bold",
      textTransform: "none",
      color: "black",
      display: loading ? "none" : "inherit",
    },
  }));
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <Button
          {...otherProps}
          classes={{ root: classes.buttonRoot, label: classes.buttonLabel }}
          disabled={!!disabled}
          disableElevation
        />
        {loading && (
          <CircularProgress size={24} className={classes.buttonProgress} />
        )}
      </div>
    </div>
  );
};

export default PrimaryButton;
