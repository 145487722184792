import { css } from "@emotion/core";

const useClasses = (theme) => ({
  coach: css`
    width: 100%;
    position: relative;
    border-radius: 12px;
    overflow: hidden;
    margin-right: 2%;
    height: 350px;
    background: #141414;
    margin-bottom: 2%;
    cursor: pointer;
    transition: 0.1s transform ease-in-out;
    > img {
      width: 100%;
      border-radius: 25px;
    }
    @media (max-width: 768px) {
      max-width: 100%;
    }
  `,
  coachVideoContainer: css`
    height: 350px;
    overflow: hidden;
    video {
      position: absolute;
      object-fit: cover;
    }
  `,

  coachProgress: css`
    position: absolute;
    top: 50%;
    left: 50%;
    opacity: 0.7;
    transform: translate(-50%, -50%);
  `,
  coachInfo: css`
    width: 100%;
    padding: 1.5rem;
    position: absolute;
    padding-bottom: 5px;
    bottom: 15px;
    h3 {
      margin-top: 0;
      display: inline-block;
      color: #fff;
      margin-bottom: 0.1rem;
      font-weight: 600;
      font-size: 20px;
      position: relative;
      line-height: 1.3;
      position: relative;
    }
  `,
  coachStatus: css`
    border-radius: 12px;
    line-height: 32px;
    margin-top: 1rem;
    width: 120px;
    height: 32px;
  `,
  coachRating: css`
    position: absolute;
    right: 20px;
    bottom: 5px;
    h3 {
      margin: 0;
    }
  `,
  coachTags: css`
    position: relative;
  `,
  coachImgPlaceholder: css`
    height: inherit;
  `,
  coachImg: css`
    position: absolute;
    height: 100%;
    width: 100%;
    object-fit: cover;
  `,
  webinarProgress: css`
    position: absolute;
    top: 50%;
    left: 50%;
    background: red;
    opacity: 0.7;
    transform: translate(-50%, -50%);
  `,
});

export default useClasses;
