import { createSlice } from "@reduxjs/toolkit";
import Service from "./service";

export const slice = createSlice({
  name: "notification",
  initialState: {
    notificationState: {
      notifications: [],
      loading: false,
      error: null,
    },
    unreadNotificationState: {
      notifications: [],
      loading: false,
      error: null,
    },
    loading: false,
  },
  reducers: {
    setNotificationsLoading: (state, action) => {
      state.notificationState = {
        ...state.notificationState,
        loading: true,
      };
    },
    setNotifications: (state, action) => {
      state.notificationState = {
        ...state.notificationState,
        notifications: action.payload,
        loading: false,
      };
    },
    setNotificationsError: (state, action) => {
      state.notificationState = {
        ...state.notificationState,
        loading: false,
        error: action.payload,
      };
    },
    setUnreadNotificationsLoading: (state, action) => {
      state.unreadNotificationState = {
        ...state.unreadNotificationState,
        loading: true,
      };
    },
    setUnreadNotifications: (state, action) => {
      state.unreadNotificationState = {
        ...state.unreadNotificationState,
        notifications: action.payload,
        loading: false,
      };
    },
    setUnreadNotificationsError: (state, action) => {
      state.unreadNotificationState = {
        ...state.unreadNotificationState,
        loading: false,
        error: action.payload,
      };
    },
  },
});

export const {
  setNotificationsLoading,
  setNotifications,
  setNotificationsError,
  setUnreadNotifications,
  setUnreadNotificationsError,
  setUnreadNotificationsLoading,
} = slice.actions;

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched

export const fetchNotifications = (filter) => async (dispatch) => {
  dispatch(setNotificationsLoading(true));
  try {
    const notifications = await Service.getNotification(filter);
    dispatch(setNotifications(notifications));
  } catch (e) {
    console.log(e?.response?.data || e.message);
    dispatch(setNotificationsError(e.response.data));
  }
};
export const markNotificationsAsRead = (filter) => async (dispatch) => {
  try {
    let response = await Service.markNotificationsAsRead(filter);
  } catch (e) {
    console.log(e.response ? e.response.data : e);
  }
};
export const fetchUnreadNotifications = (filter) => async (dispatch) => {
  dispatch(setUnreadNotificationsLoading(true));
  try {
    const notifications = await Service.getNotification(filter);
    dispatch(setUnreadNotifications(notifications));
  } catch (e) {
    console.log(e?.response?.data || e.message);
    dispatch(setNotificationsError(e.response.data));
  }
};

export const selectNotifications = (state) =>
  state.notification.notificationState.notifications;
export const selectNotificationsLoading = (state) =>
  state.notification.notificationState.loading;

export const selectUnreadNotifications = (state) =>
  state.notification.unreadNotificationState.notifications;
export const selectUnreadNotificationsLoading = (state) =>
  state.notification.unreadNotificationState.loading;

export default slice.reducer;
