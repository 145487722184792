import styles from "../../features/webinarDetails/WebinarDetails.module.css";
import ReactPlayer from "react-player";
import React, {useEffect, useState} from "react";
import clsx from "clsx";
import PlayIcon from "../../assets/icons/Play.svg";
import FullScreenIcon from "../../assets/icons/FullScreen.svg";

const Player = ({url, height = "100%", play = true, controls = false}) => {
  const [playing, setPlaying] = useState(play);

  useEffect(() => {
    setPlaying(play)
  }, [play])

  const goIntroFullScreen = () => {
    const elem = document.getElementById("video-intro");
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.mozRequestFullScreen) {
      /* Firefox */
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) {
      /* Chrome, Safari and Opera */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      /* IE/Edge */
      elem.msRequestFullscreen();
    }
  };

  return (
    <div
      onClick={() => setPlaying(!playing)}
      className={clsx(styles.PlayerBackground)}>
      <ReactPlayer
        url={url}
        playing={playing}
        loop
        controls={controls}
        width="100%"
        config={{
          file: {
            forceVideo: true,
            attributes: {
              id: "video-intro",
            },
          },
        }}
        height={height}
      />
      {!playing && (
        <div className={styles.PlayIconContainer}>
          <img className={styles.PlayIcon} src={PlayIcon}/>
        </div>
      )}
      <div
        className={styles.FullScreenIconContainer}
        onClick={goIntroFullScreen}
      >
        <img src={FullScreenIcon}/>
      </div>
    </div>
  );
};
export default Player;
