import React, { useEffect } from "react";
import Layout from "../../components/Layout/Layout";
import { saveConnectedAccountInfo, selectUser } from "../auth/authSlice";
import { useHistory, useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

function StripeConnect() {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const user = useSelector(selectUser);

  useEffect(() => {
    if (user) {
      const params = new URLSearchParams(location.search);
      const stripeCode = params.get("code");
      if (stripeCode) {
        if (user?.refreshToken) {
          user.getIdToken().then((token) => {
            dispatch(saveConnectedAccountInfo(stripeCode, token)).then(
              (res) => {
                if (res) {
                  toast.success("Account id sucessfuly updated.");
                  history.push("/profile");
                } else {
                  toast.error("Unable to connect express account.");
                  history.push("/");
                }
              }
            );
          });
        }
      } else {
        history.push("/");
      }
    }
  }, [user]);
  return <Layout></Layout>;
}

export default StripeConnect;
