import { useEffect, useState } from "react";
import firebase from "firebase";
import { useSelector } from "react-redux";
import { selectUser } from "../features/auth/authSlice";
import { RiNurseFill } from "react-icons/ri";

const db = firebase.database();
let usersInMeetingList = null;

export function useUsersInMeeting(channel, talent) {
  const [usersInMeeting, setUsersInMeeting] = useState(null);
  const user = useSelector(selectUser);

  useEffect(() => {
    usersInMeetingList = db.ref(`webinars/${channel}/users/`);
    if (user && user?.uid === talent?.id) {
      const subscription = usersInMeetingList.on(
        "value",
        function (snapshot, prevChildKey) {
          const data = snapshot.val();
          setUsersInMeeting(data);
        }
      );

      return function cleanup() {
        usersInMeetingList.off("value", subscription);
      };
    }
  }, [user]);

  return usersInMeeting;
}
