import makeStyles from "@material-ui/core/styles/makeStyles";

const useClasses = makeStyles((theme) => ({
  container: {
    width: "100%",
    height: "auto",
    background: "#292932",
    marginTop: 100,
    marginBottom: 100,
    padding: "50px 60px",
    borderRadius: 25,
  },
}));

export default useClasses;
