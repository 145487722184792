/** @jsx jsx */
import { jsx } from "@emotion/core";
import { useState } from "react";
import Form from "react-bootstrap/Form";
import axios from "axios";
import {
  showErrorSnack,
  showSuccessSnack,
} from "../../../snackbar/snackBarSlice";
import { useTheme } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { showModal } from "../../../../features/modal/modalSlice";
import Typography from "@material-ui/core/Typography";
import TextField from "../../../../components/TextField/TextField";
import PrimaryButton from "../../../../components/material/Button/PrimaryButton";
import Container from "@material-ui/core/Container";
import Logo from "../../../../components/Logo/Logo";
import * as yup from "yup";
import useClasses from "../../styles";
import { Formik } from "formik";

const schemaForgotPassword = yup.object({
  email: yup.string().required().email(),
});

export const ForgotPassword = () => {
  const [isLoading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const classes = useClasses();
  const theme = useTheme();

  const resetEmailSubmit = async (data) => {
    const { email } = data;
    try {
      setLoading(true);

      await axios.post("/api/auth/passwordReset", { email });
      dispatch(showSuccessSnack("Please check your email"));
    } catch (error) {
      console.log(error);
      if (error.message) {
        dispatch(
          showErrorSnack(
            "There is no user record corresponding to this identifier. The user may have been deleted."
          )
        );
      } else {
        dispatch(showErrorSnack("Something went wrong"));
      }
    } finally {
      setLoading(false);
    }
  };

  const handleOpenSignUpModal = () => {
    dispatch(
      showModal({
        modalType: "signUpModal",
      })
    );
  };

  const handleOpenSignInModal = () => {
    dispatch(
      showModal({
        modalType: "signInModal",
      })
    );
  };

  return (
    <div css={classes.authContainer}>
      <Container maxWidth="xs">
        <Logo
          backgroundColor={theme.palette.secondary.light}
          width={90}
          height={90}
          imgWidth={86}
          imgHeight={86}
        />
        <Typography variant="h5" className="mt-5" css={classes.authHeading}>
          Reset Password
        </Typography>
        <Typography variant="body2" className="mt-3" css={classes.authDescr}>
          Enter your email and follow the instructions
        </Typography>
        <div className="mt-4">
          <Formik
            onSubmit={resetEmailSubmit}
            initialValues={{
              email: "",
            }}
            validationSchema={schemaForgotPassword}
          >
            {({
              handleSubmit,
              handleChange,
              values,
              touched,
              isValid,
              errors,
              handleBlur,
            }) => (
              <Form onSubmit={handleSubmit} noValidate>
                <Form.Group controlId="formEmail" style={{ marginBottom: 48 }}>
                  <TextField
                    label="EMAIL"
                    type="email"
                    name="email"
                    className="mb-4"
                    isInvalid={touched.email && errors.email}
                    value={values.email}
                    onBlur={handleBlur}
                    onChange={(val) => {
                      console.log(touched);
                      handleChange(val);
                    }}
                  />
                  <span className="input-error-message">
                    {touched.email && errors.email ? errors.email : null}
                  </span>
                </Form.Group>
                <PrimaryButton
                  loading={isLoading}
                  disabled={isLoading}
                  color="secondary"
                  type="submit"
                  variant="contained"
                >
                  Reset
                </PrimaryButton>
                <div
                  style={{ cursor: "pointer" }}
                  className="mt-4 d-flex justify-content-around"
                >
                  <Typography
                    variant="body2"
                    css={classes.authForgotPasswordText}
                    onClick={() => handleOpenSignInModal()}
                  >
                    Login
                  </Typography>
                  <Typography
                    variant="body2"
                    css={classes.authForgotPasswordText}
                    onClick={() => handleOpenSignUpModal()}
                  >
                    Create an account
                  </Typography>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Container>
    </div>
  );
};
