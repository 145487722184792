import { createSlice } from "@reduxjs/toolkit";

export const slice = createSlice({
  name: "userAction",
  initialState: {
    email: null,
  },
  reducers: {
    setEmail: (state, action) => {
      state.email = action.payload;
    },
  },
});

export const { setEmail } = slice.actions;

export const selectEmail = (state) => state.userAction.email;

export default slice.reducer;
