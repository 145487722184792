import React from "react";
import LogoIcon from "../../assets/icons/circle_logo.png";
import Icon from "../Icon/Icon";

Logo.defaultProps = {
  imgWidth: 30,
  imgHeight: 30,
};

function Logo(props) {
  return (
    <Icon
      src={LogoIcon}
      borderRadius={90}
      backgroundColor="transparent"
      {...props}
    />
  );
}

export default Logo;
