import axios from "axios";

class Service {
  static async startRecording(data) {
    const res = await axios.post("/api/recording/start", data);
    return res.data;
  }

  static async stopRecording(data) {
    if (data.cname && data.uid && data.resourceId && data.sid) {
      const res = await axios.post("/api/recording/stop", data);
      return res.data;
    }
    return null;
  }

  static async saveRating(eventId, userId, rating, feedback) {
    const res = await axios.post("/api/rating/", {
      eventId,
      userId,
      rating,
      feedback,
    });
    console.log("RES", res);
  }
}

export default Service;
