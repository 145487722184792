import { createSlice } from "@reduxjs/toolkit";

export const slice = createSlice({
  name: "snackBar",
  initialState: {
    options: {
      message: "",
      severity: "info",
      visible: false,
    },
  },
  reducers: {
    showSuccessSnack: (state, action) => {
      state.options = {
        ...state.options,
        severity: "success",
        message: action.payload,
        visible: true,
      };
    },
    showErrorSnack: (state, action) => {
      state.options = {
        ...state.options,
        severity: "error",
        message: action.payload,
        visible: true,
      };
    },
    showInfoSnack: (state, action) => {
      state.options = {
        ...state.options,
        severity: "info",
        message: action.payload,
        visible: true,
      };
    },
    closeSnack: (state, action) => {
      state.options = {
        ...state.options,
        visible: false,
        severity: "info",
        message: "",
      };
    },
  },
});

export const { showSuccessSnack, closeSnack, showErrorSnack, showInfoSnack } =
  slice.actions;

export const selectSnackOptions = (state) => state.snackBar.options;

export default slice.reducer;
