import { css } from "@emotion/core";

const useClasses = () => ({
  webinarWrapper: css`
    position: relative;
    &:last-child {
      padding-bottom: 30px;
      > div:last-child {
        display: none;
      }
    }
  `,
  webinarItem: css`
    position: relative;
    cursor: pointer;
    @media (max-width: 992px) {
      flex-direction: column;
    }
  `,
  webinarViewBtn: css`
    background: #1d0d3e;
    position: absolute;
    height: 42px;
    cursor: pointer;
    border-radius: 15px;
    padding: 7px 40px 7px 15px;
    right: 0;
    top: -5px;
    @media (max-width: 992px) {
      position: absolute;
      margin-left: 15px;
    }
  `,
  webinarEditBtn: css`
    background: white;
    width: 140px;
    position: absolute;
    height: 42px;
    cursor: pointer;
    border-radius: 15px;
    padding: 7px 10px;
    text-align: center;
    left: 15px;
    top: 15px;
    span {
      color: #1e1f20;
    }
    @media (max-width: 992px) {
      position: absolute;
      top: 15px;
      right: 15px;
    }
  `,
  webinarImgContainer: css`
    min-width: 360px;
    width: 360px;
    margin-right: 2rem;
    height: 255px;
    border-radius: 20%;
    border-radius: 8px;
    overflow: hidden;
    @media (max-width: 992px) {
      margin-bottom: 35px;
      margin-right: 0;
      width: 100%;
      min-width: 100%;
    }
    img {
      border-radius: 8px;
      width: 100%;
      height: 255px;
      object-fit: cover;
      @media (max-width: 768px) {
        max-width: auto;
        width: 100%;
      }
    }
  `,
  webinarDate: css`
    font-size: 12px;
    line-height: 20px;
    color: #8f92a1;
    opacity: 0.55;
    position: absolute;
    right: 20px;
    @media (max-width: 768px) {
      bottom: -25px;
      right: 0;
    }
  `,
  webinarName: css`
    &:hover {
      text-decoration: underline;
    }
  `,
  webinarInfo: css`
    width: 75%;
    position: relative;
    @media (max-width: 992px) {
      max-width: 100%;
      width: 100%;
    }
  `,
  webinarRating: css`
    position: absolute;
    bottom: 20px;
    right: 0;
    @media (max-width: 768px) {
      position: absolute;
      bottom: -25px;
      left: 0;
    }
  `,
});

export { useClasses };
