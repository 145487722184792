import { createSlice } from "@reduxjs/toolkit";
import Service from "./service";

export const slice = createSlice({
  name: "category",
  initialState: {
    categories: [],
    loading: false,
  },
  reducers: {
    setCategories: (state, action) => {
      state.categories = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
  },
});

const { setCategories, setLoading, setError } = slice.actions;

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched
export const fetchCategories = () => async (dispatch) => {
  setLoading(true);
  try {
    const response = await Service.getCategories();
    dispatch(setLoading(false));
    return dispatch(setCategories(response));
  } catch (e) {
    dispatch(setLoading(false));
    return dispatch(setError(e));
  }
};

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const selectCategories = (state) => state.category.categories;

export default slice.reducer;
