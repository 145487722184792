import { useCallback } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";

import { getCurrentModal } from "../../features/modal/modalSlice";
import { hideModal } from "../../features/modal/modalSlice";

export const useModal = () => {
  return {
    modal: useSelector(getCurrentModal, shallowEqual),
  };
};

export const useHideModal = () => {
  const dispatch = useDispatch();
  const handleOnClose = useCallback(() => {
    dispatch(hideModal());
  }, [dispatch]);
  return handleOnClose;
};
