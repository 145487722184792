import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCategories,
  selectCategories,
} from "../../features/category/categorySlice";
import ComboBox from "../ComboBox/ComboBox";

CategoryAutosuggest.propTypes = {};

function CategoryAutosuggest({ value, onCategorySelect }) {
  const dispatch = useDispatch();
  const categories = useSelector(selectCategories);

  useEffect(() => {
    dispatch(fetchCategories());
  }, []);

  return (
    <ComboBox
      darkMode
      className="mt-4"
      label="Category"
      loading={false}
      options={categories}
      getOptionDisabled={(option) => false}
      getOptionLabel={(option) => option.name}
      onSelect={(option) => onCategorySelect(option)}
    />
  );
}

export default CategoryAutosuggest;
