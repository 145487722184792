import React, { useEffect } from "react";
import { isTimeSelectionDisabled, minutesToTime } from "../../utils/time";
import { useDispatch, useSelector } from "react-redux";
import {
  getAvailability,
  selectAvailabilityError,
  selectAvailabilityLoading,
  selectAvailabilitySlots,
} from "../../features/booking/bookingSlice";
import format from "date-fns/format";
import YobeDatePicker from "../YobeDatePicker/YobeDatePicker";
import ComboBox from "../ComboBox/ComboBox";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import styles from "../BookModal/BookModal.module.css";

function InputTimePicker({ startDate, setStartDate, selectTime, uid }) {
  const dispatch = useDispatch();

  const availabilitySlots = useSelector(selectAvailabilitySlots);
  const availabilityLoading = useSelector(selectAvailabilityLoading);
  const availabilityError = useSelector(selectAvailabilityError);

  const onDayClick = (date) => {
    const formattedDate = format(date, "MM/dd/yyyy");
    setStartDate(date);
    dispatch(getAvailability(formattedDate, uid));
  };

  useEffect(() => {
    const formattedDate = format(startDate, "MM/dd/yyyy");
    dispatch(getAvailability(formattedDate, uid));
  }, []);

  return (
    <>
      <YobeDatePicker startDate={startDate} setStartDate={onDayClick} />
      {!availabilityError ? (
        <ComboBox
          className="mt-3"
          label="Start Time"
          loading={availabilityLoading}
          options={availabilitySlots}
          getOptionDisabled={(option) =>
            isTimeSelectionDisabled(startDate, option)
          }
          getOptionLabel={(option) =>
            option ? minutesToTime(option.time) : ""
          }
          onSelect={(option) => selectTime(option?.time)}
        />
      ) : (
        <Typography
          variant="caption"
          color="textSecondary"
          className={clsx("mt-1 mb-4")}
        >
          No available time slots
        </Typography>
      )}
    </>
  );
}

export default InputTimePicker;
