import axios from "axios";

class Service {
  static async getNotification(filter) {
    const res = await axios.post("/api/notification/all", {
      filter,
    });
    return res.data;
  }
  static async markNotificationsAsRead(filter) {
    const res = await axios.post("/api/notification/markRead", {
      filter,
    });
    return res.data;
  }
}

export default Service;
