import axios from "axios";
import { FirebaseStorage } from "../../firebase";

class Service {
  static async updateUser(user) {
    const res = await axios.put("/api/user", { data: user });
    return res.data.userDetails;
  }

  static async deleteIntro(id) {
    const ref = FirebaseStorage.ref();
    const introDirectory = ref.child(`/videos/users/${id}/intro`);
    const list = await introDirectory.list();
    const introRef = list.items[0];
    if (introRef) {
      await introRef.delete();
    }
  }

  static async deleteAvatar(id) {
    const ref = FirebaseStorage.ref();
    const avatarDirectory = ref.child(`/videos/users/${id}/avatar`);
    const list = await avatarDirectory.list();
    const avatarRef = list.items[0];
    if (avatarRef) {
      await avatarRef.delete();
    }
  }

  static saveIntro(id, intro, onComplete, onError, onProgress) {
    const extension = intro.name.split(".").pop();
    const ref = FirebaseStorage.ref();
    const uploadTask = ref
      .child(`/videos/users/${id}/intro/intro.${extension}`)
      .put(intro);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        onProgress(progress);
      },
      (e) => {
        onError(e);
      },
      () => {
        console.log("Upload 1", uploadTask.snapshot);
        console.log("Upload 2", uploadTask.snapshot.ref);
        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
          onComplete(downloadURL);
        });
      }
    );
  }

  static saveAvatar(id, avatar, onComplete, onError, onProgress) {
    console.log("save avatar");
    const extension = avatar.name.split(".").pop();
    const ref = FirebaseStorage.ref();
    const uploadTask = ref
      .child(`/images/users/${id}/avatar/avatar.${extension}`)
      .put(avatar);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        onProgress(progress);
      },
      (e) => {
        onError(e);
      },
      () => {
        console.log(uploadTask.snapshot);
        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
          onComplete(downloadURL);
        });
      }
    );
  }
}

export default Service;
