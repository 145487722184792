import { createSlice } from "@reduxjs/toolkit";
import Service from "./service";
import { groupByCategory } from "../../utils/talents";

export const slice = createSlice({
  name: "home",
  initialState: {
    events: [],
    feed: [],
    talentsState: {
      talents: [],
      groupedTalents: [],
      loading: false,
      error: null,
    },
    loading: false,
  },
  reducers: {
    setEvents: (state, action) => {
      state.events = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setTalentsLoading: (state, action) => {
      state.talentsState = {
        ...state.talentsState,
        loading: true,
      };
    },
    setTalents: (state, action) => {
      state.talentsState = {
        ...state.talentsState,
        talents: action.payload,
        loading: false,
      };
    },
    setGroupedTalents: (state, action) => {
      state.talentsState = {
        ...state.talentsState,
        groupedTalents: action.payload,
        loading: false,
      };
    },
    setTalentsError: (state, action) => {
      state.talentsState = {
        ...state.talentsState,
        loading: false,
        error: action.payload,
      };
    },
    setFeed: (state, action) => {
      state.feed = action.payload;
    },
  },
});

export const {
  setEvents,
  setLoading,
  setTalentsLoading,
  setTalents,
  setTalentsError,
  setGroupedTalents,
  setFeed,
} = slice.actions;

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched
export const fetchEvents = (filter) => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    const events = await Service.getEvents(filter);
    dispatch(setLoading(false));
    dispatch(setEvents(events));
  } catch (e) {
    console.log(e.response ? e.response.data : e);
    dispatch(setLoading(false));
  }
};

export const fetchTalents = (filter) => async (dispatch) => {
  dispatch(setTalentsLoading());
  try {
    const talents = await Service.getTalents(filter);
    dispatch(setTalents(talents));
    dispatch(setGroupedTalents(Object.values(groupByCategory(talents))));
  } catch (e) {
    console.log(e.response ? e.response.data : e);
    dispatch(setTalentsError(e.response ? e.response.data : e));
  }
};

export const fetchFeed = (filter) => async (dispatch) => {
  dispatch(setTalentsLoading());
  try {
    const feed = await Service.getFeed(filter);
    dispatch(setFeed(feed));
  } catch (e) {
    console.log(e.response ? e.response.data : e);
    dispatch(setLoading(false));
  }
};

export const saveEvent = () => async (dispatch) => {
  await Service.saveEvent();
};

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const selectEvents = (state) => state.home.events;
export const selectFeed = (state) => state.home.feed;
export const selectLoading = (state) => state.home.loading;

export const selectTalents = (state) => state.home.talentsState.talents;
export const selectGroupedTalents = (state) =>
  state.home.talentsState.groupedTalents;
export const selectTalentsLoading = (state) => state.home.talentsState.loading;

export default slice.reducer;
