import isSameDay from "date-fns/isSameDay";
import isBefore from "date-fns/isBefore";

export const minutesToTime = (minutes) => {
  let postFix = "AM";
  let hours = parseInt(minutes / 60);
  let diffMinutes = minutes % 60;

  if (hours > 12) {
    hours -= 12;
    postFix = "PM";
  } else if (hours === 0) {
    hours = 12;
  }
  if (hours < 10) {
    hours = `0${hours}`;
  }
  if (diffMinutes < 10) {
    diffMinutes = `0${diffMinutes}`;
  }
  return `${hours}:${diffMinutes}${postFix}`;
};

export const minutesToHHmm = (minutes) => {
  let hours = parseInt(minutes / 60);
  let diffMinutes = minutes % 60;

  return {
    hh: hours,
    mm: diffMinutes,
  };
};

export const timeToMinutes = (time) => {
  if (time.hour === 12 && time.meridiem === "pm") {
    return 24 * 60;
  }
  return time.hour * 60 + time.minute;
};

export const isTimeSelectionDisabled = (date, option) => {
  if (option.reserved) {
    return true;
  }
  const transformedDate = new Date(date);
  const currentDate = new Date();
  if (isSameDay(transformedDate, currentDate)) {
    const { hh, mm } = minutesToHHmm(option.time);
    transformedDate.setHours(hh);
    transformedDate.setMinutes(mm);
    return isBefore(transformedDate, currentDate);
  }
  return false;
};

export const isUserActive = (time) =>
  !(
    Math.floor((new Date(new Date().toISOString()) - new Date(time)) / 60e3) > 5
  );
