import axios from "axios";
import { FirebaseStorage } from "../../firebase";

class Service {
  static async fetchEvent(id) {
    try {
      const res = await axios.get(`/api/event?id=${id}`);
      return res.data.event;
    } catch (e) {
      throw e.response.data.error;
    }
  }
  static async addParticipant(event) {
    try {
      const res = await axios.post(`/api/event/addParticipant`, {
        event,
      });
      return res.data.participants;
    } catch (e) {
      throw e.response.data.error;
    }
  }
  static async fetchParticipants(eventId) {
    try {
      const res = await axios.get(`/api/event/participants?id=${eventId}`);
      return res.data.participants;
    } catch (e) {
      throw e.response.data.error;
    }
  }
  static async amIParticipant(eventId) {
    try {
      const res = await axios.get(`/api/event/amIParticipant?id=${eventId}`);
      return res.data.amIParticipant;
    } catch (e) {
      throw e.response.data.error;
    }
  }
}

export default Service;
