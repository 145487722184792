/** @jsx jsx */
import { jsx } from "@emotion/core";
import { useClasses } from "./styles";

const Separator = ({ className, style }) => {
  const classes = useClasses();
  return <div css={[classes.separator, style]} className={className}></div>;
};

export default Separator;
