/** @jsx jsx */
import { jsx } from "@emotion/core";
import useClasses from "./styles";

const BannerCard = ({ link, image }) => {
  const classes = useClasses();
  return (
    <div css={classes.banner}>
      {/*<a href={"/discover"}>*/}
      <div css={classes.bannerWrapper} className="mb-4">
        <div css={classes.bannerImgPlaceholder} className="center-circle-image">
          <img src={image} alt="webinar" />
        </div>
      </div>
      {/*</a>*/}
    </div>
  );
};

export default BannerCard;
