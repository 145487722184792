import React, { useContext } from "react";
import Header from "../Header/Header";
import { useSelector } from "react-redux";
import { selectUser } from "../../features/auth/authSlice";
import Alert from "react-bootstrap/Alert";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./layout.css";
import ResponsiveDrawer from "../ResponsiveDrawer/ResponsiveDrawer";

const Layout = ({ children }) => {
  return (
    <>
      <ResponsiveDrawer>{children}</ResponsiveDrawer>
      <ToastContainer />
    </>
  );
};

export default Layout;
