import React from "react";
import Typography from "@material-ui/core/Typography";
import PhoneInput from "react-phone-input-2";
import clsx from "clsx";
import startsWith from "lodash.startswith";
import "react-phone-input-2/lib/bootstrap.css";
import "./TextField.css";

function PhoneField({ label, className, darkMode, value, onChange, isValid }) {
  return (
    <div className={className}>
      <div
        className={clsx("mb-2")}
        style={{ color: darkMode ? "white" : "#1E1F20" }}
      >
        <Typography
          variant="caption"
          color="inherit"
          style={{ fontWeight: 600, letterSpacing: 1 }}
        >
          {label?.toUpperCase()}
        </Typography>
      </div>
      <PhoneInput
        inputClass={!darkMode ? "react-phone-input" : "dark-react-phone-input"}
        country={"us"}
        masks={{ us: "(...) ...-...." }}
        isValid={(inputNumber, country, countries) => {
          return countries.some((country) => {
            return (
              startsWith(inputNumber, country.dialCode) ||
              startsWith(country.dialCode, inputNumber)
            );
          });
        }}
        dropdownStyle={{ width: "100%" }}
        value={value}
        onChange={onChange}
        placeholder="(702) 123-4567"
        disableAreaCodes
        disableDropdown
      />
    </div>
  );
}

export default PhoneField;
