import React from "react";
import PropTypes from "prop-types";
import Form from "react-bootstrap/Form";
import styles from "../TimePicker/InputTimePicker.module.css";
import useAutocomplete from "@material-ui/lab/useAutocomplete";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Typography from "@material-ui/core/Typography";

ComboBox.propTypes = {};

function ComboBox({
  label,
  onSelect,
  options,
  getOptionLabel,
  className,
  getOptionDisabled,
  loading,
  darkMode,
}) {
  const useStyles = makeStyles((theme) => ({
    listbox: {
      width: "inherit",
      margin: 0,
      top: 10,
      borderRadius: 5,
      zIndex: 9999,
      position: "absolute",
      listStyle: "none",
      backgroundColor: "#2d2d30",
      overflow: "scroll",
      height: 150,
      border: `1px solid rgba(0,0,0,.25)`,
      '& li[data-focus="true"]': {
        backgroundColor: "#46464a",
        color: theme.palette.text.primary,
        cursor: "pointer",
      },
      "& li:active": {
        backgroundColor: "#46464a",
        color: theme.palette.text.primary,
      },
      "& li": {
        fontSize: 18,
        padding: "6px 16px",
      },
      "& li.disabled": {
        opacity: 0.2,
        pointerEvents: "none",
      },
    },
  }));
  const classes = useStyles();
  const labelColor = "#fff";

  const {
    getRootProps,
    getInputLabelProps,
    getInputProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
  } = useAutocomplete({
    id: "autosuggest_input",
    options: !loading && options ? options : [],
    getOptionLabel,
    getOptionDisabled,
    onChange: (event, value) => onSelect(value),
  });

  return (
    <div className={className}>
      <div {...getRootProps()}>
        <div className={clsx("mb-2")} style={{ color: labelColor }}>
          <Typography
            variant="caption"
            color="inherit"
            style={{ fontWeight: 600, letterSpacing: "1px" }}
          >
            {label?.toUpperCase()}
          </Typography>
        </div>
        <Form.Control className={styles.Input} {...getInputProps()} />
      </div>
      <div style={{ position: "relative", width: "100%" }}>
        {groupedOptions.length > 0 && !loading ? (
          <ul className={classes.listbox} {...getListboxProps()}>
            {groupedOptions.map((option, index) => (
              <li
                className={getOptionDisabled(option) ? "disabled" : null}
                {...getOptionProps({ option, index })}
              >
                {getOptionLabel(option)}
              </li>
            ))}
          </ul>
        ) : null}
      </div>
    </div>
  );
}

export default ComboBox;
