import React, { useCallback, useState } from "react";
import { Formik } from "formik";
import * as yup from "yup";
import Layout from "../../components/Layout/Layout";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Avatar from "../../components/Avatar/Avatar";
import { useDispatch, useSelector } from "react-redux";
import {
  selectUser,
  selectUserDetails,
  selectUserUpdateLoading,
  updateUser,
} from "../auth/authSlice";
import Form from "react-bootstrap/Form";
import Timekeeper from "react-timekeeper";
import styles from "./Profile.module.css";
import Button from "react-bootstrap/Button";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import { minutesToTime, timeToMinutes } from "../../utils/time";
import { FirebaseAuth } from "../../firebase";
import firebase from "firebase";
import { toast } from "react-toastify";
import InputGroup from "react-bootstrap/InputGroup";
import Switch from "react-switch";
import ImageCropper from "../../components/ImageCropper/ImageCropper";
import CategoryAutosuggest from "../../components/CategoryAutosuggest/CategoryAutosuggest";
import Icon from "../../components/Icon/Icon";
import VideoIcon from "../../assets/icons/Video.svg";
import Dropzone from "../../components/Upload/dropzone/Dropzone";
import Service from "./service";
import { deleteEvent, updateEvent } from "../schedule/eventSlice";
import { showErrorSnack, showSuccessSnack } from "../snackbar/snackBarSlice";

const profileSchema = yup.object({
  email: yup.string().email(),
  password: yup.string().min(6),
  displayName: yup.string().required(),
  platformName: yup.string().min(6),
});
const socialProfilesSchema = yup.object({
  youtubeLink: yup.string().url(),
  instagramLink: yup.string().url(),
  facebookLink: yup.string().url(),
});

const pricingSchema = yup.object({
  time15m: yup.number(),
  time30m: yup.number(),
  time45m: yup.number(),
  time60m: yup.number(),
});

EditProfile.propTypes = {};

const reauthenticateUser = async (email, password) => {
  const credential = firebase.auth.EmailAuthProvider.credential(
    email,
    password
  );
  await FirebaseAuth.currentUser.reauthenticateWithCredential(credential);
};

function EditProfile(props) {
  const user = useSelector(selectUser);
  const userDetails = useSelector(selectUserDetails);
  const dispatch = useDispatch();
  const loading = useSelector(selectUserUpdateLoading);

  const onProfileSubmit = async (data) => {
    const { videoIntro } = data;

    if (videoIntro) {
      Service.saveIntro(
        user.uid,
        videoIntro,
        (introUrl) => {
          data.videoIntro = introUrl;
          dispatch(updateUser(data))
            .then(() => {
              if (data.password) {
                reauthenticateUser(user.email, data.password);
              }
              dispatch(showSuccessSnack("Updated Successfully"));
            })
            .catch((err) => {
              dispatch(showErrorSnack("Cannot update Profile"));
            });
        },
        (e) => {
          dispatch(showErrorSnack("Cannot upload intro"));
        },
        (progress) => {
          console.log(progress);
        }
      );
    } else {
      try {
        delete data.videoIntro;
        await dispatch(updateUser(data));
        if (data.password) {
          await reauthenticateUser(user.email, data.password);
        }
        dispatch(showSuccessSnack("Updated Successfully"));
      } catch (e) {
        dispatch(showErrorSnack("Cannot update Profile"));
      }
    }
  };

  const onSocialProfilesSubmit = async (data) => {
    try {
      await dispatch(updateUser(data));
      toast.success("Updated Successfully");
    } catch (e) {
      toast.error("Cannot update Profile");
    }
  };
  const onPricingSubmit = async (data) => {
    try {
      const pricing = {
        pricing: {
          time15m: parseFloat(data.time15m),
          time30m: parseFloat(data.time30m),
          time45m: parseFloat(data.time45m),
          time60m: parseFloat(data.time60m),
        },
      };
      await dispatch(updateUser(pricing));
      toast.success("Updated Successfully");
    } catch (e) {
      toast.error("Cannot update Profile");
    }
  };
  const onAvailabilitySubmit = async (data) => {
    try {
      const availability = { availability: data };
      await dispatch(updateUser(availability));
      toast.success("Updated Successfully");
    } catch (e) {
      toast.error("Cannot update Profile");
    }
  };

  return (
    <Layout>
      <Container className="mt-5 pb-5">
        <ProfileEditing
          {...{ user, userDetails, loading }}
          onSubmit={onProfileSubmit}
        />
        <SocialProfilesEditing
          {...{ user, userDetails, loading }}
          onSubmit={onSocialProfilesSubmit}
        />
        <PricingEditing
          {...{ user, userDetails, loading }}
          onSubmit={onPricingSubmit}
        />
        {/*<AvailabilityEditing {...{user, userDetails, loading}} onSubmit={onAvailabilitySubmit}/>*/}
      </Container>
    </Layout>
  );
}

const ProfileEditing = ({ user, userDetails, onSubmit, loading }) => {
  const [modalShow, setModalShow] = useState(false);
  const [userMode, setUserMode] = useState(userDetails.mode);
  const [category, setCategory] = useState(userDetails.category);
  const [videoIntro, setVideoIntro] = useState(null);

  const onEditAvatarClick = () => {
    setModalShow(true);
  };

  const onIntroAdded = (files) => {
    setVideoIntro(files[0]);
  };

  return (
    <>
      <ImageCropper
        onSave={(img) => {
          console.log(img);
          setModalShow(false);
        }}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
      <Card>
        <Card.Body>
          <Card.Title>Profile</Card.Title>
          <div className={styles.EditProfileForm}>
            <div className="mt-5 d-flex flex-column">
              <div className="align-self-center">
                <div className="d-flex flex-column justify-content-center">
                  <Avatar url={user.photoURL} width="100px" height="100px" />
                  <div style={{ width: "200px" }}>
                    <Dropzone
                      className="mt-3"
                      label="Intro video"
                      accept="video/*"
                      onFilesAdded={onIntroAdded}
                      disabled={videoIntro || userDetails.videoIntro}
                    />
                  </div>
                </div>
              </div>
              <Formik
                validationSchema={profileSchema}
                onSubmit={(data) =>
                  onSubmit({ ...data, mode: userMode, category, videoIntro })
                }
                initialValues={{
                  email: user.email,
                  password: "",
                  displayName: user.displayName,
                  platformName: userDetails.platformName,
                  bio: userDetails.bio,
                }}
              >
                {({
                  handleSubmit,
                  handleChange,
                  handleBlur,
                  values,
                  touched,
                  isValid,
                  errors,
                }) => (
                  <Form className="mt-2" noValidate onSubmit={handleSubmit}>
                    <Form.Group>
                      <Form.Label>Your email</Form.Label>
                      <Form.Control
                        type="email"
                        name="email"
                        placeholder="Enter your email"
                        disabled
                        value={values.email}
                        isValid={touched.email && !errors.email}
                        isInvalid={errors.email}
                        onChange={handleChange}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.email}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Your password</Form.Label>
                      <Form.Control
                        type="password"
                        name="password"
                        placeholder="Enter your password"
                        value={values.password}
                        isValid={touched.password && !errors.password}
                        isInvalid={errors.password}
                        disabled={
                          user.providerData[0].providerId !== "password"
                        }
                        onChange={handleChange}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.password}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Your name</Form.Label>
                      <Form.Control
                        name="displayName"
                        placeholder="Enter your name"
                        value={values.displayName}
                        isValid={touched.displayName && !errors.displayName}
                        isInvalid={errors.displayName}
                        onChange={handleChange}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.displayName}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Your platform name</Form.Label>
                      <Form.Control
                        name="platformName"
                        placeholder="Enter your platform name"
                        value={values.platformName}
                        isValid={touched.platformName && !errors.platformName}
                        isInvalid={errors.platformName}
                        onChange={handleChange}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.platformName}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Bio</Form.Label>
                      <Form.Control
                        name="bio"
                        rows={5}
                        placeholder="Enter your bio"
                        value={values.bio}
                        onChange={handleChange}
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Category</Form.Label>
                      <CategoryAutosuggest
                        value={userDetails.category}
                        onCategorySelect={setCategory}
                      />
                    </Form.Group>
                    <div className="mt-2 mb-3 d-flex flex-column">
                      <Form.Label>Mode</Form.Label>
                      <div className="d-flex">
                        <Form.Check
                          checked={userMode === "User"}
                          onChange={() => setUserMode("User")}
                          inline
                          label="User"
                          type="radio"
                          id="user"
                        />
                        <Form.Check
                          checked={userMode === "Talent"}
                          onChange={() => setUserMode("Talent")}
                          inline
                          label="Talent"
                          type="radio"
                          id="talent"
                        />
                      </div>
                    </div>
                    <Button
                      className="w-100"
                      type="submit"
                      disabled={(!isValid && touched) || loading}
                    >
                      Save
                    </Button>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </Card.Body>
      </Card>
    </>
  );
};

const SocialProfilesEditing = ({ user, userDetails, onSubmit, loading }) => {
  return (
    <Card className="mt-5">
      <Card.Body>
        <Card.Title>Social Profiles</Card.Title>
        <div className={styles.EditProfileForm}>
          <div className="mt-5 d-flex flex-column">
            <Formik
              onSubmit={onSubmit}
              initialValues={{
                youtubeLink: userDetails.youtubeLink,
                instagramLink: userDetails.instagramLink,
                facebookLink: userDetails.facebookLink,
              }}
              validationSchema={socialProfilesSchema}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                isValid,
                errors,
              }) => (
                <Form className="mt-2" noValidate onSubmit={handleSubmit}>
                  <Form.Group>
                    <Form.Label>Your Youtube</Form.Label>
                    <Form.Control
                      name="youtubeLink"
                      placeholder="Enter your youtube link"
                      value={values.youtubeLink}
                      isValid={touched.youtubeLink && !errors.youtubeLink}
                      isInvalid={errors.youtubeLink}
                      onChange={handleChange}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.youtubeLink}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Your Instagram</Form.Label>
                    <Form.Control
                      name="instagramLink"
                      placeholder="Enter your instagram link"
                      value={values.instagramLink}
                      isValid={touched.instagramLink && !errors.instagramLink}
                      isInvalid={errors.instagramLink}
                      onChange={handleChange}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.instagramLink}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Your Facebook</Form.Label>
                    <Form.Control
                      name="facebookLink"
                      placeholder="Enter your facebook link"
                      value={values.facebookLink}
                      isValid={touched.facebookLink && !errors.facebookLink}
                      isInvalid={errors.facebookLink}
                      onChange={handleChange}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.facebookLink}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Button
                    className="w-100"
                    type="submit"
                    disabled={(!isValid && touched) || loading}
                  >
                    Save
                  </Button>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

const PricingEditing = ({ user, userDetails, loading, onSubmit }) => {
  return (
    <Card className="mt-5">
      <Card.Body>
        <Card.Title>Pricing</Card.Title>
        <div className={styles.EditProfileForm}>
          <div className="mt-5 d-flex flex-column">
            <Formik
              onSubmit={onSubmit}
              initialValues={{
                time15m: userDetails.pricing.time15m,
                time30m: userDetails.pricing.time30m,
                time45m: userDetails.pricing.time45m,
                time60m: userDetails.pricing.time60m,
              }}
              validationSchema={pricingSchema}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                isValid,
                errors,
              }) => (
                <Form className="mt-2" noValidate onSubmit={handleSubmit}>
                  <Form.Group>
                    <Form.Label>15 minutes price</Form.Label>
                    <InputGroup>
                      <InputGroup.Prepend>
                        <InputGroup.Text id="inputGroupPrepend">
                          $
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <Form.Control
                        name="time15m"
                        value={values.time15m}
                        isValid={touched.time15m && !errors.time15m}
                        isInvalid={errors.time15m}
                        onChange={handleChange}
                      />
                    </InputGroup>
                    <Form.Control.Feedback type="invalid">
                      {errors.time15m}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>30 minutes price</Form.Label>
                    <InputGroup>
                      <InputGroup.Prepend>
                        <InputGroup.Text id="inputGroupPrepend">
                          $
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <Form.Control
                        name="time30m"
                        value={values.time30m}
                        isValid={touched.time30m && !errors.time30m}
                        isInvalid={errors.time30m}
                        onChange={handleChange}
                      />
                    </InputGroup>
                    <Form.Control.Feedback type="invalid">
                      {errors.time30m}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>45 minutes price</Form.Label>
                    <InputGroup>
                      <InputGroup.Prepend>
                        <InputGroup.Text id="inputGroupPrepend">
                          $
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <Form.Control
                        name="time45m"
                        value={values.time45m}
                        isValid={touched.time45m && !errors.time45m}
                        isInvalid={errors.time45m}
                        onChange={handleChange}
                      />
                    </InputGroup>
                    <Form.Control.Feedback type="invalid">
                      {errors.time45m}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>60 minutes price</Form.Label>
                    <InputGroup>
                      <InputGroup.Prepend>
                        <InputGroup.Text id="inputGroupPrepend">
                          $
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <Form.Control
                        name="time60m"
                        value={values.time60m}
                        isValid={touched.time60m && !errors.time60m}
                        isInvalid={errors.time60m}
                        onChange={handleChange}
                      />
                    </InputGroup>
                    <Form.Control.Feedback type="invalid">
                      {errors.time60m}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Button
                    className="w-100"
                    type="submit"
                    disabled={(!isValid && touched) || loading}
                  >
                    Save
                  </Button>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

const AvailabilityEditing = ({ user, userDetails, loading, onSubmit }) => {
  const [key, setKey] = useState("sunday");
  const [availability, setAvailability] = useState(userDetails.availability);

  const onTimeChange = (time, day, prop) => {
    let modifiedAvailability = { ...availability };
    let modifiedDay = { ...modifiedAvailability[day] };
    modifiedDay[prop] = timeToMinutes(time);
    modifiedAvailability[day] = { ...modifiedDay };
    setAvailability(modifiedAvailability);
  };

  const onAvailabilityChange = (val, day) => {
    let modifiedAvailability = { ...availability };
    let modifiedDay = { ...modifiedAvailability[day] };
    modifiedDay.available = val;
    modifiedAvailability[day] = { ...modifiedDay };
    setAvailability(modifiedAvailability);
  };

  return (
    <Card className="mt-5">
      <Card.Body>
        <Card.Title>Availability</Card.Title>
        <Tabs
          id="controlled-tab-example"
          activeKey={key}
          onSelect={(k) => setKey(k)}
        >
          <Tab eventKey="sunday" title="Sunday">
            <div className="d-flex mt-5">
              <div>
                <h1>Start Time</h1>
                <Timekeeper
                  onChange={(time) => onTimeChange(time, "sunday", "start")}
                  time={minutesToTime(availability.sunday.start)}
                />
              </div>
              <div className="ml-5">
                <h1>End Time</h1>
                <Timekeeper
                  onChange={(time) => onTimeChange(time, "sunday", "end")}
                  time={minutesToTime(availability.sunday.end)}
                />
              </div>
              <div className="ml-5">
                <h1>Available</h1>
                <Switch
                  checked={!!availability.sunday.available}
                  onChange={(val) => onAvailabilityChange(val, "sunday")}
                />
              </div>
            </div>
          </Tab>
          <Tab eventKey="monday" title="Monday">
            <div className="d-flex mt-5  align-self-center">
              <div>
                <h1>Start Time</h1>
                <Timekeeper
                  onChange={(time) => onTimeChange(time, "monday", "start")}
                  time={minutesToTime(availability.monday.start)}
                />
              </div>
              <div className="ml-5">
                <h1>End Time</h1>
                <Timekeeper
                  onChange={(time) => onTimeChange(time, "monday", "end")}
                  time={minutesToTime(availability.monday.end)}
                />
              </div>
              <div className="ml-5">
                <h1>Available</h1>
                <Switch
                  checked={!!availability.monday.available}
                  onChange={(val) => onAvailabilityChange(val, "monday")}
                />
              </div>
            </div>
          </Tab>
          <Tab eventKey="tuesday" title="Tuesday">
            <div className="d-flex mt-5">
              <div>
                <h1>Start Time</h1>
                <Timekeeper
                  onChange={(time) => onTimeChange(time, "tuesday", "start")}
                  time={minutesToTime(availability.tuesday.start)}
                />
              </div>
              <div className="ml-5">
                <h1>End Time</h1>
                <Timekeeper
                  onChange={(time) => onTimeChange(time, "tuesday", "end")}
                  time={minutesToTime(availability.tuesday.end)}
                />
              </div>
              <div className="ml-5">
                <h1>Available</h1>
                <Switch
                  checked={!!availability.tuesday.available}
                  onChange={(val) => onAvailabilityChange(val, "tuesday")}
                />
              </div>
            </div>
          </Tab>
          <Tab eventKey="wednesday" title="Wednesday">
            <div className="d-flex mt-5">
              <div>
                <h1>Start Time</h1>
                <Timekeeper
                  onChange={(time) => onTimeChange(time, "wednesday", "start")}
                  time={minutesToTime(availability.wednesday.start)}
                />
              </div>
              <div className="ml-5">
                <h1>End Time</h1>
                <Timekeeper
                  onChange={(time) => onTimeChange(time, "wednesday", "end")}
                  time={minutesToTime(availability.wednesday.end)}
                />
              </div>
              <div className="ml-5">
                <h1>Available</h1>
                <Switch
                  checked={!!availability.wednesday.available}
                  onChange={(val) => onAvailabilityChange(val, "wednesday")}
                />
              </div>
            </div>
          </Tab>
          <Tab eventKey="thursday" title="Thursday">
            <div className="d-flex mt-5">
              <div>
                <h1>Start Time</h1>
                <Timekeeper
                  onChange={(time) => onTimeChange(time, "thursday", "start")}
                  time={minutesToTime(availability.thursday.start)}
                />
              </div>
              <div className="ml-5">
                <h1>End Time</h1>
                <Timekeeper
                  onChange={(time) => onTimeChange(time, "thursday", "end")}
                  time={minutesToTime(availability.thursday.end)}
                />
              </div>

              <div className="ml-5">
                <h1>Available</h1>
                <Switch
                  checked={!!availability.thursday.available}
                  onChange={(val) => onAvailabilityChange(val, "thursday")}
                />
              </div>
            </div>
          </Tab>
          <Tab eventKey="friday" title="Friday">
            <div className="d-flex mt-5">
              <div>
                <h1>Start Time</h1>
                <Timekeeper
                  onChange={(time) => onTimeChange(time, "friday", "start")}
                  time={minutesToTime(availability.friday.start)}
                />
              </div>
              <div className="ml-5">
                <h1>End Time</h1>
                <Timekeeper
                  onChange={(time) => onTimeChange(time, "friday", "end")}
                  time={minutesToTime(availability.friday.end)}
                />
              </div>
              <div className="ml-5">
                <h1>Available</h1>
                <Switch
                  checked={!!availability.friday.available}
                  onChange={(val) => onAvailabilityChange(val, "friday")}
                />
              </div>
            </div>
          </Tab>
          <Tab eventKey="saturday" title="Saturday">
            <div className="d-flex mt-5">
              <div>
                <h1>Start Time</h1>
                <Timekeeper
                  onChange={(time) => onTimeChange(time, "saturday", "start")}
                  time={minutesToTime(availability.saturday.start)}
                />
              </div>
              <div className="ml-5">
                <h1>End Time</h1>
                <Timekeeper
                  onChange={(time) => onTimeChange(time, "saturday", "end")}
                  time={minutesToTime(availability.saturday.end)}
                />
              </div>
              <div className="ml-5">
                <h1>Available</h1>
                <Switch
                  checked={!!availability.saturday.available}
                  onChange={(val) => onAvailabilityChange(val, "saturday")}
                />
              </div>
            </div>
          </Tab>
        </Tabs>
        <Button
          disabled={loading}
          className="w-100 mt-5"
          onClick={() => onSubmit(availability)}
        >
          Save
        </Button>
      </Card.Body>
    </Card>
  );
};

export default EditProfile;
