import React, { useState } from "react";
import { useStyles } from "../BookModal/BookModal.jss";
import BookingService from "../../features/booking/service";
import {
  showErrorSnack,
  showSuccessSnack,
} from "../../features/snackbar/snackBarSlice";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Icon from "../Icon/Icon";
import CloseIcon from "../../assets/icons/Close.svg";
import {
  ItemDetails,
  UserInfo,
} from "../../features/webinarDetails/WebinarDetails";
import Grid from "@material-ui/core/Grid";
import ClockIcon from "../../assets/icons/Clock.svg";
import format from "date-fns/format";
import styles from "./ConfirmationModal.module.css";
import PrimaryButton from "../material/Button/PrimaryButton";

const ConfirmationModal = ({
  handleClose,
  title,
  mainButtonTitle = "Confirm",
  subtitle,
  open,
  onAcceptSuccessful,
  disabled,
  children,
}) => {
  const classes = useStyles();
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Paper className={classes.bookingDetailsPaper}>
          <div className="d-flex justify-content-between align-items-center">
            <div />
            <Typography variant="h5" style={{ color: "#fff" }}>
              {title}
            </Typography>
            <Icon
              onClick={handleClose}
              src={CloseIcon}
              backgroundColor={"#fff"}
              height={38}
              width={38}
              imgHeight={18}
              imgWidth={18}
            />
          </div>
          <div>{children}</div>
          <Typography
            variant="body2"
            className="mt-4 mb-4 text-center"
            style={{ fontSize: 18, color: "#8f92a1" }}
          >
            {subtitle}
          </Typography>
          <div className="mt-3">
            <PrimaryButton
              color="secondary"
              variant="contained"
              onClick={onAcceptSuccessful}
              disabled={disabled}
            >
              {mainButtonTitle}
            </PrimaryButton>
          </div>
        </Paper>
      </Modal>
    </div>
  );
};

export default ConfirmationModal;
