import React from "react";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { useModal, useHideModal } from "./hooks";
import CloseIcon from "@material-ui/icons/Close";
import useStyles from "./styles";
import { SignIn } from "../../features/auth/components/SignIn/SignIn";
import { SignUp } from "../../features/auth/components/SignUp/SignUp";
import { ForgotPassword } from "../../features/auth/components/ForgotPassword/ForgotPassword";
import { ResetPassword } from "../../features/auth/components/ResetPassword/ResetPassword";
import Div100vh from "react-div-100vh";

export default function TransitionsModal() {
  const classes = useStyles();

  const { modal } = useModal();
  const handleOnClose = useHideModal();

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={!!modal.show}
        className={classes.modal}
        onClose={handleOnClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modal.show}>
          <div className={classes.modalContent}>
            {modal.modalType === "signInModal" && <SignIn />}
            {modal.modalType === "signUpModal" && <SignUp />}
            {modal.modalType === "forgotPassword" && <ForgotPassword />}
            {modal.modalType === "resetPassword" && <ResetPassword />}
            <button className={classes.closeBtn} onClick={handleOnClose}>
              <CloseIcon />
            </button>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
