import { css } from "@emotion/core";

const useClasses = (theme) => ({
  authContainer: css`
    width: 600px;
    position: relative;
    /* padding: 3rem 0rem; */
    > div {
      padding: 3rem 1rem;
    }
    @media (max-width: 768px) {
      width: 100%;
      border-radius: 0px;
      /* padding: 3rem 0rem; */
      > div {
        max-width: 100%;
        /* overflow: scroll;
        height: 100vh; /* Fallback for browsers that do not support Custom Properties */
        /* height: calc(var(--vh, 1vh) * 100); */
      }
    }
  `,
  authHeading: css`
    color: #1e1f20;
  `,
  authDescr: css`
    color: #8f92a1;
    font-size: 18px;
  `,
  authSmallDescr: css`
    color: #8f92a1;
    font-size: 12px;
  `,
  authForgotPasswordText: css`
    color: #8f92a1;
    font-size: 14px;
    font-weight: 500;
  `,
});

export default useClasses;
