import axios from "axios";

class Service {
  static async subscribe(talentId) {
    const res = await axios.get(`/api/user/subscribe?talentId=${talentId}`);
    return res.data;
  }

  static async getTalent(uid) {
    try {
      const res = await axios.get(`/api/user?id=${uid}`);
      return res.data;
    } catch (e) {
      return null;
    }
  }

  static async getAmISubscribed(talentId) {
    try {
      const res = await axios.get(
        `/api/user/amISubscribed?talentId=${talentId}`
      );
      return res.data.subscribed;
    } catch (e) {
      console.log(e);
      return false;
    }
  }
}

export default Service;
