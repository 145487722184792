import { createSlice } from "@reduxjs/toolkit";
import Service from "./service";
import ProfileService from "../profile/service";

export const slice = createSlice({
  name: "talentProfile",
  initialState: {
    talent: null,
    upcomingEvents: [],
    loading: false,
    amISubscribed: false,
    historyEvents: [],
    subscribeState: {
      loading: false,
      error: null,
      success: false,
    },
    recordedEventsState: {
      loading: false,
      error: null,
      events: [],
    },
  },
  reducers: {
    setUpcomingEvents: (state, action) => {
      state.upcomingEvents = action.payload;
      state.loading = false;
    },
    setTalent: (state, action) => {
      state.talent = action.payload;
      state.loading = false;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setAmISubscribed: (state, action) => {
      state.amISubscribed = action.payload;
    },
    setSubscribeLoading: (state, action) => {
      state.subscribeState = {
        ...state.subscribeState,
        loading: action.payload,
      };
    },
    setSubscribeSuccess: (state, action) => {
      state.subscribeState = {
        ...state.subscribeState,
        loading: false,
        success: true,
      };
      state.amISubscribed = true;
    },
    setSubscribeError: (state, action) => {
      state.subscribeState = {
        ...state.subscribeState,
        loading: false,
        error: action.payload,
      };
    },
    setRecordedEventsLoading: (state, action) => {
      state.recordedEventsState = {
        ...state.recordedEventsState,
        loading: action.payload,
      };
    },
    setRecordedEvents: (state, action) => {
      state.recordedEventsState = {
        ...state.recordedEventsState,
        events: action.payload,
        loading: false,
      };
    },
    setHistoryEvents: (state, action) => {
      state.historyEvents = action.payload;
    },
    setRecordedEventsError: (state, action) => {
      state.recordedEventsState = {
        ...state.recordedEventsState,
        error: action.payload,
        events: [],
        loading: false,
      };
    },
  },
});

export const {
  setUpcomingEvents,
  setTalent,
  setLoading,
  setAmISubscribed,
  setSubscribeLoading,
  setSubscribeSuccess,
  setSubscribeError,
  setRecordedEventsLoading,
  setRecordedEvents,
  setRecordedEventsError,
  setHistoryEvents,
} = slice.actions;

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched
export const getUpcomingEvents = (uid) => async (dispatch) => {
  try {
    const filter = { time: "upcoming" };
    const events = await ProfileService.getEvents(uid, filter);
    dispatch(setUpcomingEvents(events));
  } catch (e) {
    dispatch(setUpcomingEvents([]));
  }
};
export const getHistoryEvents = (uid) => async (dispatch) => {
  try {
    const filter = { time: "history" };
    const events = await ProfileService.getEvents(uid, filter);
    dispatch(setHistoryEvents(events));
  } catch (e) {
    if (e.response) {
      console.log(e?.response?.data || e.message);
    }
    console.log(e);
    dispatch(setHistoryEvents([]));
  }
};
export const getRecordedEvents = (uid) => async (dispatch) => {
  dispatch(setRecordedEventsLoading(true));
  try {
    const events = await ProfileService.getRecordedEvents(uid);
    dispatch(setRecordedEvents(events));
  } catch (e) {
    dispatch(setRecordedEventsError(e));
  }
};

export const getTalent = (uid) => async (dispatch) => {
  dispatch(setLoading(true));
  console.log("get by id = " + uid);
  const talent = await Service.getTalent(uid);
  console.log("get by id = ", talent);
  dispatch(setTalent(talent));
};

export const subscribe = (talentId) => async (dispatch) => {
  dispatch(setSubscribeLoading(true));
  try {
    await Service.subscribe(talentId);
    dispatch(setSubscribeSuccess());
  } catch (e) {
    dispatch(setSubscribeError(e));
  }
};

export const getAmISubscribed = (talentId) => async (dispatch) => {
  const subscribed = await Service.getAmISubscribed(talentId);
  dispatch(setAmISubscribed(subscribed));
};

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const selectTalentUser = (state) => state.talentProfile.talent;
export const selectTalentUpcomingEvents = (state) =>
  state.talentProfile.upcomingEvents;
export const selectTalentRecordedEvents = (state) =>
  state.talentProfile.recordedEventsState.events;
export const selectAmISubscribed = (state) => state.talentProfile.amISubscribed;
export const selectSubscribeState = (state) =>
  state.talentProfile.subscribeState;
export const selectHistoryEvents = (state) => state.talentProfile.historyEvents;

export default slice.reducer;
