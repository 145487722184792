import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import PaymentForm from "../../components/PaymentForm/PaymentForm";
import { useLocation } from "react-router";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const Payment = () => {
  const location = useLocation();
  return (
    <Elements stripe={stripePromise}>
      <PaymentForm
        amount={location.state.amount}
        connectedAccountId={location.state.stripeConnectedAccountId}
        webinarId={location.state.webinarId}
      />
    </Elements>
  );
};

export default Payment;
