/** @jsx jsx */
import { jsx } from "@emotion/core";
import useClasses from "./styles";
import { ReactComponent as Arrow } from "../../assets/icons/Arrow-right.svg";

const ArrowButton = ({ style, onClick }) => {
  const classes = useClasses();
  return (
    <div
      css={[classes.arrowBtn, style]}
      onClick={onClick}
      className="d-flex align-items-center justify-content-center"
    >
      <Arrow />
    </div>
  );
};

export default ArrowButton;
