import { useQuery } from "react-query";
import axios from "axios";

const getOtherWebinars = async (_, userId) => {
  const { data } = await axios.post(
    `/api/event/events?start=0&size=20&onlyUpcoming=true`,
    {
      filters: {
        userId: userId,
      },
    }
  );
  return data;
};

export function useOtherWebinars(userId) {
  return useQuery(["otherWebinars", userId], getOtherWebinars, {
    staleTime: 10000,
    refetchOnWindowFocus: false,
  });
}
