import { useQuery } from "react-query";
import axios from "axios";

const getTalents = async () => {
  const response = await axios.get(`/api/user/talents-group`);
  return response.data;
};

export function useTalents() {
  return useQuery("talents", getTalents, {
    staleTime: 10000,
    refetchOnWindowFocus: false,
  });
}

const getTalentsByCategory = async (_, categories) => {
  const { data } = await axios.get(
    `/api/user/talents-group?categories=${categories}&start=0&size=20`
  );
  return data;
};

export function useTalentByCategory(categories) {
  return useQuery(["talentsByCategory", categories], getTalentsByCategory, {
    staleTime: 10000,
    refetchOnWindowFocus: false,
    enabled: categories,
  });
}
