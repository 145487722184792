import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import WebcamIcon from "../../assets/icons/VideoCall.svg";
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
import VideoCallIcon from "@material-ui/icons/VideoCall";
import NotificationsIconDark from "../../assets/icons/notifications.svg";
import CloseIcon from "../../assets/icons/Close.svg";
import Toolbar from "@material-ui/core/Toolbar";
import styles from "./ResponsiveDrawer.module.css";
import theme from "../../styles/theme";
import YobeIcons from "../../assets/icons/Yobe.svg";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import CompassIcon from "../../assets/icons/compass.svg";
import { useDispatch, useSelector } from "react-redux";
import SettingsDrawer from "../../components/SettingsDrawer/SettingsDrawer";
import { showModal } from "../../features/modal/modalSlice";
import { selectUser, selectUserDetails } from "../../features/auth/authSlice";
import Avatar from "../Avatar/Avatar";
import { useHistory, useLocation } from "react-router";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import {
  closeSnack,
  selectSnackOptions,
} from "../../features/snackbar/snackBarSlice";
import Button from "@material-ui/core/Button";
import makeStyles from "@material-ui/core/styles/makeStyles";
import SearchInput from "../SearchInput/SearchInput";
import { FirebaseAuth } from "../../firebase";
import { persistor } from "../../app/store";
import Footer from "../Footer/Footer";
import clsx from "clsx";
import Icon from "../Icon/Icon";
import NotificationsDrawer from "../../components/NotificationsDrawer/NotificationsDrawer";
import { selectUnreadNotifications } from "../../features/notification/notificationSlice";
import Div100vh from "react-div-100vh";
import ReactTooltip from "react-tooltip";
import { Container, Typography } from "@material-ui/core";
import {
  fetchCategories,
  selectCategories,
} from "../../features/category/categorySlice";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const HEADER_HEIGHT = 80;

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100vw",
    ["@media (min-width:480px)"]: {
      height: "auto!important",
      minHeight: "100vh",
    },
  },
  rootFullHeight: {
    width: "100vw",
    minHeight: "100vh",
  },
  content: {
    flex: 1,
    width: "100%",
    display: "flex",
    backgroundColor: "#141414",
    overflow: "hidden",
    minHeight: "calc(100vh - 313px)",
    paddingTop: HEADER_HEIGHT,
  },
  contentWithoutNavbar: {
    paddingTop: 0,
  },
  menuButton: {
    color: theme.palette.text.primary,
    height: HEADER_HEIGHT,
  },
  toolbar: {
    width: "100%",
    marginLeft: "auto",
    boxSizing: "border-box",
    padding: 0,
    marginRight: "auto",
    height: HEADER_HEIGHT,
    color: theme.palette.text.primary,
  },
  title: {
    flexGrow: 1,
  },
  loginLabel: {
    fontSize: "18px",
    textTransform: "none",
    fontWeight: "bold",
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px !important",
    },
  },
  signInBtn: {
    height: 60,
    width: 130,
    textTransform: "none",
    background: "#FFB3AB",
    borderRadius: "12px",
    cursor: "pointer",
    marginLeft: 16,
    "&:hover": {
      backgroundColor: "#ee8a80",
    },

    [theme.breakpoints.down("xs")]: {
      height: 36,
      width: 90,
      marginLeft: 4,
    },
  },
  watchBtn: {
    height: 48,
    width: 155,
    marginBottom: 2,
    textTransform: "none",
    background: "#FFB3AB",
    borderRadius: "12px",
    marginLeft: 16,
  },
  hostBtn: {
    height: 48,
    width: 170,
    marginBottom: 2,
    textTransform: "none",
    background: "#FFB3AB",
    borderRadius: "12px",
    marginLeft: 16,
    "&:hover": {
      backgroundColor: "#ee8a80",
    },
  },
  hostBtnLabel: {
    fontWeight: "normal",
    fontSize: 14,
  },
  loginBtn: {
    height: 60,
    width: 70,
    borderRadius: "12px",
    color: "#fff",
    [theme.breakpoints.down("xs")]: {
      width: 50,
      height: 36,
    },
  },
  searchContainer: {
    [theme.breakpoints.up("md")]: {
      width: 450,
    },
    [theme.breakpoints.up("lg")]: {
      width: 600,
    },
    [theme.breakpoints.up("xl")]: {
      width: 700,
    },
  },
  signInLabel: {
    textTransform: "none",
    color: "#1E1F20",
    fontSize: "18px",
    fontWeight: "bold",
    [theme.breakpoints.down("xs")]: {
      fontSize: "13px",
    },
  },
  paperAnchorLeft: {
    width: 320,
    height: "100vh",
  },
  notificationsWrap: {
    position: "relative",
  },
  compassWrap: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  headerGrey: {
    background: "#292932",
    padding: "10px 0",
    zIndex: 99999,
  },
  headerDark: {
    backgroundColor: "#141414",
    padding: "10px 0",
    zIndex: 99999,
  },
  notifications: {
    color: "white",
    backgroundColor: "#FFB3AB",
    position: "absolute",
    borderRadius: "4px",
    paddingLeft: "10px",
    paddingRight: "10px",
    maxHeight: "20px",
    lineHeight: "20px",
    right: -8,
    top: -8,
    fontSize: 12,
  },
  logo: {
    width: 200,
    marginRight: 8,
    [theme.breakpoints.down("md")]: {
      width: 170,
    },
    [theme.breakpoints.down("xs")]: {
      width: 120,
      marginRight: 0,
      marginLeft: -24,
    },
  },
}));

function ResponsiveDrawer(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const { container, children } = props;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [drawer, setDrawer] = useState(false);

  const user = useSelector(selectUser);
  const userDetails = useSelector(selectUserDetails);
  const snackBarOptions = useSelector(selectSnackOptions);

  const isTalent = userDetails?.mode == "Talent";

  const toggleDrawer = () => {
    setDrawer(!drawer);
  };

  const onGetStartedClick = () => {};

  const handleOpenSignInModal = () => {
    dispatch(
      showModal({
        modalType: "signInModal",
      })
    );
  };

  const handleOpenSignUpModal = () => {
    dispatch(
      showModal({
        modalType: "signUpModal",
      })
    );
  };

  const onUserClick = () => {
    history.push("/profile");
  };

  const onLogoClick = () => {
    history.push("/");
  };

  const goToDiscover = () => {
    history.push("/discover");
  };

  const onSearch = (val, path) => {
    history.push({
      pathname: `/${path}`,
      search: `?search=${val}`,
    });
  };

  const signOut = async () => {
    FirebaseAuth.signOut().then(() => {
      persistor.purge();
      localStorage.removeItem("authToken");
    });
  };

  const isNavbarVisible = () => {
    return location.pathname !== "/webinar";
  };

  let showMenu =
    location.pathname !== "/login" &&
    location.pathname !== "/signup" &&
    location.pathname !== "/action";

  const [notificationsDrawer, setNotificationsDrawer] = useState(false);

  const toggleNotificationsDrawer = () => {
    setNotificationsDrawer(!notificationsDrawer);
  };

  const unreadNotifications = useSelector(selectUnreadNotifications);
  const matchesDownXs = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <div>
      <Div100vh
        className={
          location.pathname.includes("/trending")
            ? classes.root
            : classes.rootFullHeight
        }
      >
        <Snackbar
          style={{ marginTop: 70, zIndex: 1400000 }}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          autoHideDuration={5000}
          key={`snack`}
          open={snackBarOptions.visible}
          onClose={() => dispatch(closeSnack())}
        >
          <Alert
            onClose={() => dispatch(closeSnack())}
            severity={snackBarOptions.severity}
          >
            {snackBarOptions.message}
          </Alert>
        </Snackbar>
        {isNavbarVisible() ? (
          <div>
            <AppBar
              position="fixed"
              elevation={0}
              classes={{
                root:
                  location.pathname === "/trending" ||
                  location.pathname === "/about"
                    ? classes.headerDark
                    : classes.headerGrey,
              }}
            >
              <Container maxWidth="xl">
                <Toolbar
                  classes={{
                    root: classes.toolbar,
                  }}
                >
                  <Drawer
                    anchor="left"
                    open={drawer}
                    onClose={toggleDrawer}
                    style={{ zIndex: 140000 }}
                  >
                    <MenuDrawer toggleDrawer={toggleDrawer} />
                  </Drawer>
                  {showMenu && (
                    <div
                      className={clsx(styles.MenuWrap, "d-flex")}
                      onClick={toggleDrawer}
                    >
                      <Typography
                        className={
                          matchesDownXs ? styles.MenuTextSmall : styles.MenuText
                        }
                        variant="h4"
                        color="textSecondary"
                      >
                        BROWSE
                      </Typography>
                      <ArrowDropDown
                        color="textSecondary"
                        className={styles.MenuIcon}
                      />
                    </div>
                  )}
                  <div className="d-flex justify-content-between w-100">
                    <img
                      src={YobeIcons}
                      className={clsx(styles.Logo, classes.logo)}
                      onClick={onLogoClick}
                    />
                    <Hidden smDown>
                      <div className={classes.searchContainer}>
                        <SearchInput
                          placeholder="Search for a masterclass"
                          onSubmit={(val) => onSearch(val, "trending")}
                        />
                      </div>
                    </Hidden>

                    {!userDetails ? (
                      <div className="d-flex align-items-center">
                        <Button
                          disableElevation
                          classes={{
                            root: classes.loginBtn,
                            label: classes.loginLabel,
                          }}
                          onClick={() => handleOpenSignInModal()}
                        >
                          Login
                        </Button>
                        <Button
                          disableElevation
                          color="primary"
                          type="submit"
                          variant="contained"
                          classes={{
                            root: classes.signInBtn,
                            label: classes.signInLabel,
                          }}
                          onClick={() => handleOpenSignUpModal()}
                        >
                          Sign Up
                        </Button>
                      </div>
                    ) : (
                      <div className="d-flex flex-row flex-nowrap align-items-center">
                        {isTalent && (
                          <>
                            <Hidden lgUp>
                              <Icon
                                className="mr-3"
                                visible={false}
                                backgroundColor={theme.palette.primary.main}
                                width={48}
                                height={48}
                                src={WebcamIcon}
                                imgHeight={24}
                                imgWidth={24}
                                onClick={() =>
                                  history.push("/profile/schedule")
                                }
                              />
                            </Hidden>
                            <Hidden mdDown>
                              <Button
                                className="mr-3"
                                disableElevation
                                color="primary"
                                type="submit"
                                variant="contained"
                                classes={{
                                  root: classes.hostBtn,
                                  label: classes.hostBtnLabel,
                                }}
                                onClick={() =>
                                  history.push("/profile/schedule")
                                }
                                startIcon={
                                  <VideoCallIcon style={{ fontSize: 24 }} />
                                }
                              >
                                Host a Hangout
                              </Button>
                            </Hidden>
                          </>
                        )}
                        <a
                          data-tip
                          data-for="search"
                          data-background-color="#fff"
                          data-text-color="#000"
                        >
                          <Icon
                            visible={false}
                            backgroundColor="#36373e"
                            width={48}
                            height={48}
                            src={CompassIcon}
                            borderRadius={12}
                            className={clsx("mr-3", classes.compassWrap)}
                            imgHeight={20}
                            imgWidth={20}
                            onClick={goToDiscover}
                          />
                        </a>
                        <ReactTooltip id="search" type="error">
                          <span>Search</span>
                        </ReactTooltip>
                        <a
                          data-tip
                          data-for="notify"
                          data-background-color="#fff"
                          data-text-color="#000"
                        >
                          <div
                            className={clsx("mr-3", classes.notificationsWrap)}
                          >
                            <Icon
                              visible={false}
                              backgroundColor="#36373e"
                              width={48}
                              height={48}
                              src={NotificationsIconDark}
                              borderRadius={12}
                              imgHeight={20}
                              imgWidth={20}
                              onClick={toggleNotificationsDrawer}
                            />
                            {unreadNotifications.length > 0 && (
                              <div className={classes.notifications}>
                                {unreadNotifications.length}
                              </div>
                            )}
                          </div>
                        </a>
                        <ReactTooltip id="notify" type="error">
                          <span>Notifications</span>
                        </ReactTooltip>
                        <a
                          data-tip
                          data-for="profile"
                          data-background-color="#fff"
                          data-text-color="#000"
                        >
                          <div className={clsx("col-md-6", styles.Avatar)}>
                            <Avatar
                              url={userDetails.photoURL}
                              onUserClick={handleClick}
                              width={48}
                              height={48}
                            />
                          </div>
                        </a>
                        <ReactTooltip id="profile" type="error">
                          <span>Profile</span>
                        </ReactTooltip>
                        {/* <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                          Open Menu
                        </Button> */}
                        <Menu
                          style={{ zIndex: 999999 }}
                          id="simple-menu"
                          anchorEl={anchorEl}
                          keepMounted
                          open={Boolean(anchorEl)}
                          onClose={handleClose}
                        >
                          <MenuItem
                            onClick={onUserClick}
                            style={{ fontSize: 14 }}
                          >
                            My Profile & Hangouts
                          </MenuItem>
                          <MenuItem
                            onClick={handleClose}
                            style={{ fontSize: 14 }}
                          >
                            <SettingsDrawer
                              onNotificationsClick={toggleNotificationsDrawer}
                            />
                          </MenuItem>
                          <MenuItem onClick={signOut} style={{ fontSize: 14 }}>
                            Log Out
                          </MenuItem>
                        </Menu>
                      </div>
                    )}
                  </div>
                </Toolbar>
              </Container>
            </AppBar>
          </div>
        ) : null}
        <main
          className={clsx(
            classes.content,
            !isNavbarVisible() && classes.contentWithoutNavbar
          )}
        >
          {userDetails && (
            <NotificationsDrawer
              open={notificationsDrawer}
              toggleCustomDrawer={toggleNotificationsDrawer}
            />
          )}

          {children}
        </main>
        <Footer />
      </Div100vh>
    </div>
  );
}

const MenuDrawer = ({ toggleDrawer }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const categories = useSelector(selectCategories);

  useEffect(() => {
    dispatch(fetchCategories());
  }, []);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={styles.MenuDrawer}>
      <div className={styles.Logo}>
        <Icon
          src={CloseIcon}
          backgroundColor="white"
          width={56}
          height={56}
          imgWidth={25}
          imgHeight={25}
          onClick={toggleDrawer}
          borderRadius={18}
        />
      </div>
      <div className={styles.MenuLogo}>
        <img src={YobeIcons} className={styles.Logo} />
      </div>
      <div className={styles.Categories}>
        <div onClick={handleClick} className="d-block mt-3">
          <h3 className={styles.Title}>All Categories</h3>
        </div>
        <Menu
          style={{ zIndex: 999999 }}
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {categories.map((category) => {
            return (
              <a href={`/discover/${category.slug}`} className="d-block mt-3">
                <MenuItem style={{ fontSize: 14 }}>
                  <h3
                    className={
                      location?.pathname === `/discover/${category.slug}`
                        ? styles.TitleActive
                        : styles.Title
                    }
                  >
                    {category.name}
                  </h3>
                </MenuItem>
              </a>
            );
          })}
        </Menu>
        <a href="/discover" className="d-block mt-3">
          <h3
            className={
              location?.pathname === "/discover"
                ? styles.TitleActive
                : styles.Title
            }
          >
            All Hosts
          </h3>
        </a>
      </div>
      <div className={styles.Company}>
        <h3 className={styles.TitleGray}>Company</h3>
        <a href="/about" className="d-block mt-3">
          <h3 className={clsx(styles.Title)}>About Butter N Binge</h3>
        </a>
        <a href="mailto:binge@butternbinge.com" className="d-block mt-3">
          <h3 className={clsx(styles.Title)}>Contact Us</h3>
        </a>
        <a
          href="https://www.privacypolicygenerator.info/live.php?token=ZnPjJoPXp332k9PNi8rMnW11dXNIvK0b"
          className="d-block mt-3"
        >
          <h3 className={clsx(styles.Title)}>Privacy Policy</h3>
        </a>
      </div>
    </div>
  );
};

ResponsiveDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  container: PropTypes.any,
};

export default ResponsiveDrawer;
