import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import "./chat.css";
import styles from "./Chat.module.css";
import FormControl from "react-bootstrap/FormControl";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import firebase from "firebase";
import EmokiIcon from "../../assets/icons/Emoji.svg";
import Form from "react-bootstrap/Form";
import { useSelector } from "react-redux";
import { selectUser } from "../../features/auth/authSlice";
import Avatar from "../Avatar/Avatar";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import TextField from "../TextField/TextField";
import { Picker } from "emoji-mart";
import "emoji-mart/css/emoji-mart.css";
import { Divider, Zoom } from "@material-ui/core";
import FormGroup from "react-bootstrap/FormGroup";

Chat.propTypes = {};

const Message = ({ name, message, avatar }) => {
  return (
    <div className="d-flex mt-1 mb-4">
      <Avatar url={avatar} imgHeight={48} imgWidth={48} />
      <div className="d-flex flex-column justify-content-between ml-3">
        <Typography variant="subtitle2" color="textPrimary">
          {name}
        </Typography>
        <Typography variant="subtitle1" className={styles.MessageBody}>
          {message}
        </Typography>
      </div>
    </div>
  );
};

const db = firebase.database();
let chatRef = null;

function Chat(props) {
  const messagesEndRef = useRef(null);

  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [emojiVisible, setEmojiVisible] = useState(false);
  const currentUser = useSelector(selectUser);
  const { user } = props;

  useEffect(() => {
    chatRef = db.ref(`chats/${props.channel}/messages`);

    const subscription = chatRef.on("value", function (snapshot, prevChildKey) {
      const fetchedMessages = [];
      snapshot.forEach(function (childSnapshot) {
        const childKey = childSnapshot.key;
        const childData = childSnapshot.val();
        const fetchedMessage = {
          ...childData,
          id: childKey,
        };
        fetchedMessages.push(fetchedMessage);
      });
      setMessages(fetchedMessages);
    });
    return function cleanup() {
      chatRef.off("value", subscription);
    };
  }, []);

  const scrollToBottom = () => {
    messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(scrollToBottom, [messages]);

  const onSendMessage = async (e) => {
    e.preventDefault();
    const msg = {
      body: message,
      date: new Date().toDateString(),
      uid: currentUser.uid,
      displayName: currentUser.displayName,
      avatar: currentUser.photoURL,
    };
    chatRef.push(msg);
    setMessage("");
  };

  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };
  const onEmojiClick = () => {
    setEmojiVisible(!emojiVisible);
  };

  const onEmojiSelect = (emoji) => {
    const msg = message + emoji.native;
    setMessage(msg);
  };

  const onEnterPress = async (e) => {
    if (e.key === "Enter") {
      await onSendMessage(e);
      setEmojiVisible(false);
    }
  };

  return (
    <div className="chat-container">
      <div className="d-flex">
        <Avatar url={user.photoURL} imgHeight={48} imgWidth={48} />
        <div className="d-flex flex-column justify-content-between ml-3">
          <Typography variant="subtitle2">{user.displayName}</Typography>
          <div className={styles.LiveStatus}>Live webinar</div>
        </div>
      </div>
      <Divider className="mt-3" />
      <div className={clsx(styles.Content, "mt-2")}>
        {messages.map((item) => (
          <Message
            key={item.id}
            name={item.displayName}
            message={item.body}
            currentUser={currentUser}
            uid={item.uid}
            avatar={item.avatar}
          />
        ))}
        <div ref={messagesEndRef} />
      </div>
      <div className="mt-2">
        <Form onSubmit={onSendMessage}>
          <div className={styles.SendInputContainer}>
            <FormGroup>
              <FormControl
                placeholder="Start typing…"
                aria-label="Message"
                autoFocus
                className={styles.SendInput}
                value={message}
                onKeyDown={onEnterPress}
                onChange={handleMessageChange}
              />
            </FormGroup>
            <Zoom in={emojiVisible}>
              <Picker
                set={"apple"}
                style={{
                  position: "absolute",
                  bottom: 65,
                  width: "100% !important",
                }}
                onSelect={onEmojiSelect}
              />
            </Zoom>
            <div className={styles.EmojiIcon} onClick={onEmojiClick}>
              <img src={EmokiIcon} />
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
}

export default Chat;
