import { css } from "@emotion/core";

const useClasses = (theme) => ({
  bannerWrapper: css`
    height: 210px;
    @media (max-width: 1024px) {
      height: auto;
    }
    overflow: hidden;
    border-radius: 20px;
    position: relative;
  `,
  bannerHeading: css`
    position: absolute;
    width: max-content;
    z-index: 1;
    color: #000;
    font-weight: 700;
    font-size: 42px;
    line-height: 36px;
    color: white;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    @media (max-width: 600px) {
      font-size: 30px;
    }
  `,
  bannerImgPlaceholder: css`
    height: 210px;
    @media (max-width: 1024px) {
      height: auto;
    }
    width: 100%;
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0.5;
      width: 100%;
      height: 100%;
      z-index: 1;
      overflow: hidden;
      border-radius: 20px;
    }
    img {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      height: 210px;
      @media (max-width: 1024px) {
        height: 200px;
        margin-top: -100px;
        object-fit: cover;
        position: relative;
        top: auto;
        left: auto;
        width: auto;
        height: auto;
        margin: auto;
      }
      overflow: hidden;
      border-radius: 20px;
      margin-top: -105px;
      margin-left: -50%;
      object-fit: cover;
    }
  `,
  bannerDescr: css`
    color: white;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    @media (max-width: 600px) {
      font-size: 20px;
      line-height: 1.4;
    }
  `,
});

export default useClasses;
