/** @jsx jsx */
import { jsx } from "@emotion/core";
import useClasses from "../talent-profile/styles";
import React, { useEffect, useState } from "react";
import styles from "./Profile.module.css";
import WebinarsRowList from "../../features/webinarDetails/components/WebinarsRowList";
import "./Profile.css";
import Player from "../../components/Player/Player";
import Layout from "../../components/Layout/Layout";
import Separator from "../../components/Separator/index";
import Avatar from "../../components/Avatar/Avatar";
import { useDispatch, useSelector } from "react-redux";
import format from "date-fns/format";
import {
  refreshUserDetails,
  selectConnectingStripe,
  selectUser,
  selectUserDetails,
  updateUser,
} from "../auth/authSlice";
import {
  getHistoryEvents,
  getRecordedEvents,
  getUpcomingEvents,
  selectEvents,
  selectHistoryEvents,
  selectLoading,
  selectRecordedEvents,
} from "./userSlice";
import { useHistory } from "react-router";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import Service from "./service";
import Typography from "@material-ui/core/Typography";
import ArrowButton from "../../components/ArrowButton/ArrowButton";
import { ReactComponent as ColoredClock } from "../../assets/icons/coloredClock.svg";
import { ReactComponent as ClockColored } from "../../assets/icons/ClockColoured.svg";
import FacebookIcon from "../../assets/icons/social/facebook_square.svg";
import PhoneIcon from "../../assets/icons/call.svg";
import InstagramIcon from "../../assets/icons/social/instagram.svg";
import YoutubeIcon from "../../assets/icons/social/youtube_outlined.svg";
import { Hidden, useTheme } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import BackButton from "../../components/BackButton/BackButton";
import Tab from "../../components/Tab/Tab";
import EditProfileDrawer from "../../components/EditProfileDrawer/EditProfileDrawer";
import {
  showErrorSnack,
  showInfoSnack,
  showSuccessSnack,
} from "../snackbar/snackBarSlice";
import ConfirmationModal from "../../components/ConfirmationModal/ConfirmationModal";
import TextField from "../../components/TextField/TextField";
import Icon from "../../components/Icon/Icon";
import Fade from "@material-ui/core/Fade";
import Container from "@material-ui/core/Container";
import PrimaryButton from "../../components/material/Button/PrimaryButton";
import { CONNECT_TO_STRIPE_URL } from "../../utils/constants";
import moment from "moment";

const openLinkInNewTab = (link) => {
  window.open(link, "_blank");
};

function Profile(props) {
  const user = useSelector(selectUser);
  const classes = useClasses();
  const userDetails = useSelector(selectUserDetails);
  const events = useSelector(selectEvents);
  const loading = useSelector(selectLoading);
  const historyEvents = useSelector(selectHistoryEvents);
  const recordedEvents = useSelector(selectRecordedEvents);

  const dispatch = useDispatch();
  const history = useHistory();
  const theme = useTheme();
  const [editDrawer, setEditDrawer] = useState(false);
  const [activeTab, setActiveTab] = useState("webinar");
  const [notificationsDrawer, setNotificationsDrawer] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const connectingStripe = useSelector(selectConnectingStripe);
  const [phoneCode, setPhoneCode] = useState("");
  const [phoneCodeWasSent, setPhoneCodeWasSent] = useState(false);
  const [phoneVerificationRequest, setPhoneVerificationRequest] =
    useState(false);

  const matchesDownMd = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    dispatch(getUpcomingEvents(user.uid));
  }, []);

  useEffect(() => {
    dispatch(getHistoryEvents(user.uid));
    dispatch(getRecordedEvents(user.uid));
  }, []);

  useEffect(() => {
    if (user?.displayName) {
      document.title = user?.displayName + " | Butter n Binge";
    }
  }, [user]);

  const toggleNotificationsDrawer = () => {
    setNotificationsDrawer(!notificationsDrawer);
  };
  const toggleConfirmationModal = () => {
    setConfirmationModal(!confirmationModal);
  };

  const enableSmsNotifications = () => {
    if (!userDetails.phoneNumber) {
      dispatch(showInfoSnack("Please add your phone number first!"));
    } else if (!userDetails.phoneVerified) {
      toggleConfirmationModal();
    } else {
      dispatch(updateUser({ smsNotificationsEnabled: true }));
    }
  };

  const checkWebinarStarting = (startDate) => {
    let result = moment(startDate).fromNow();

    if (moment().diff(moment(startDate)) < 0) {
      return result;
    }
  };

  if (loading) {
    return (
      <div className="centered_spinner_container">
        <CircularProgress color="secondary" />
      </div>
    );
  }

  const onSendVerificationCode = async () => {
    setPhoneVerificationRequest(true);
    try {
      await Service.sendVerificationCode();
      setPhoneVerificationRequest(false);
      setPhoneCodeWasSent(true);
    } catch (e) {
      dispatch(
        showErrorSnack(
          "Cannot send verification code. Check if your phone number is valid."
        )
      );
      setPhoneVerificationRequest(false);
      setPhoneCodeWasSent(false);
    }
  };
  const verifyCode = async () => {
    setPhoneVerificationRequest(true);
    try {
      await Service.verifyCode(phoneCode);
      setPhoneVerificationRequest(false);
      setConfirmationModal(false);
      dispatch(refreshUserDetails());
      dispatch(showSuccessSnack("Your phone number successfully verified!"));
    } catch (e) {
      if (e.response) {
        dispatch(showErrorSnack(`${e.response.data.error}`));
      } else {
        dispatch(showErrorSnack(`Something went wrong! Try again later.`));
      }
      setPhoneVerificationRequest(false);
    }
  };

  const onSubmitVerification = async () => {
    if (!phoneCodeWasSent) {
      await onSendVerificationCode();
    } else {
      await verifyCode();
    }
  };

  const onChangeCode = (e) => {
    setPhoneCode(e.target.value);
  };

  return (
    <Layout>
      <Container maxWidth="xl" className="mb-5 mt-5">
        <ConfirmationModal
          open={confirmationModal}
          handleClose={toggleConfirmationModal}
          mainButtonTitle={!phoneCodeWasSent ? "Send" : "Accept"}
          disabled={phoneVerificationRequest}
          onAcceptSuccessful={onSubmitVerification}
        >
          <div className="mt-3">
            <Icon src={PhoneIcon} backgroundColor={"rgba(97, 59, 255, 0.2)"} />
            <Typography variant="subtitle2" className="mt-3">
              Confirm your phone number
            </Typography>
            <div className="d-flex flex-column">
              <Typography
                variant="caption"
                color="textSecondary"
                className="mt-3"
              >
                We'll send you a 6 digit verification code to confirm your phone
                number.
              </Typography>
              <Typography
                variant="caption"
                color="textSecondary"
                className="font-weight-bold"
              >
                {userDetails.phoneNumber}
              </Typography>
            </div>
            {phoneCodeWasSent ? (
              <Fade in={phoneCodeWasSent}>
                <div>
                  <TextField
                    value={phoneCode}
                    onChange={onChangeCode}
                    placeholder="XXXXXX"
                  />
                  <Typography
                    variant="caption"
                    color="textSecondary"
                    className="font-weight-bold mt-3 cursor-pointer"
                    onClick={onSendVerificationCode}
                  >
                    Didn't receive code. Send it again.
                  </Typography>
                </div>
              </Fade>
            ) : null}
          </div>
        </ConfirmationModal>
        <Grid container spacing={5}>
          <Grid item md={5} lg={4} xs={12}>
            <div css={classes.talentProfile}>
              <BackButton
                fill="#8F92A1"
                onClick={() => history.goBack()}
                className="mb-4"
              />
              <div css={classes.talentVideo}>
                {userDetails.videoIntro ? (
                  <Player
                    url={userDetails.videoIntro}
                    play={false}
                    controls={false}
                  />
                ) : (
                  <div className={styles.UserImagePlaceholder}>
                    <Avatar url={null} />
                  </div>
                )}
              </div>

              <div className="d-flex align-items-center justify-content-center">
                <Typography variant="h4" color="textPrimary" className="mr-3">
                  {userDetails?.displayName}
                </Typography>
              </div>
              <Separator css={classes.talentVideoSeparator} />
              {recordedEvents?.count ? (
                <>
                  <div className="d-flex mr-3 justify-content-center">
                    <Typography
                      variant="subtitle2"
                      color="textPrimary"
                      className="font-weight-bold mr-2"
                    >
                      {recordedEvents.count}
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      style={{ color: "#8F92A1" }}
                      className="font-weight-bold"
                    >
                      Published webinars
                    </Typography>
                  </div>
                  <Separator css={classes.talentVideoSeparator} />
                </>
              ) : (
                <div className="mb-5"></div>
              )}
              <div css={classes.talentSocial}>
                <div
                  className="d-flex align-items-center mb-3"
                  onClick={() =>
                    userDetails.facebookLink &&
                    openLinkInNewTab(userDetails.facebookLink)
                  }
                  css={classes.talentSocialItem}
                >
                  <img
                    src={FacebookIcon}
                    style={{ height: 25, width: 25 }}
                    className="mr-2"
                  />
                  <Typography
                    variant="caption"
                    style={{ color: "#8F92A1", wordBreak: "break-word" }}
                  >
                    {userDetails.facebookLink
                      ? userDetails.facebookLink
                      : "Not linked"}
                  </Typography>
                </div>
                <div
                  className="d-flex align-items-center mb-3"
                  onClick={() =>
                    userDetails.instagramLink &&
                    openLinkInNewTab(userDetails.instagramLink)
                  }
                  css={classes.talentSocialItem}
                >
                  <img
                    src={InstagramIcon}
                    style={{ height: 25, width: 25 }}
                    className="mr-2"
                  />
                  <Typography
                    variant="caption"
                    style={{ color: "#8F92A1", wordBreak: "break-word" }}
                  >
                    {userDetails.instagramLink
                      ? userDetails.instagramLink
                      : "Not linked"}
                  </Typography>
                </div>
                <div
                  className="d-flex align-items-center mb-3"
                  onClick={() =>
                    userDetails.youtubeLink &&
                    openLinkInNewTab(userDetails.youtubeLink)
                  }
                  css={classes.talentSocialItem}
                >
                  <img
                    src={YoutubeIcon}
                    style={{ height: 25, width: 25 }}
                    className="mr-2"
                  />
                  <Typography
                    variant="caption"
                    style={{ color: "#8F92A1", wordBreak: "break-word" }}
                  >
                    {userDetails.youtubeLink
                      ? userDetails.youtubeLink
                      : "Not linked"}
                  </Typography>
                </div>
              </div>
              <Separator css={classes.talentVideoSeparator} />
              <div className="d-flex" css={classes.talentBtnsContainer}>
                <EditProfileDrawer open={editDrawer} />
                <a
                  href={
                    !userDetails.stripeDashboardUrl
                      ? `${CONNECT_TO_STRIPE_URL}`
                      : `${userDetails.stripeDashboardUrl}`
                  }
                  className={styles.connectStripeLink}
                >
                  <PrimaryButton
                    className={styles.connectStripeBtn}
                    color="primary"
                    variant="contained"
                  >
                    {!userDetails.stripeDashboardUrl
                      ? "Add Bank Account"
                      : "Open Payment Dashboard"}
                  </PrimaryButton>
                </a>
              </div>
            </div>
          </Grid>
          <Grid item md={7} xs={12} lg={8}>
            <Grid container spacing={5}>
              <Grid item md={12} lg={6} xs={12}>
                <div css={classes.talentBio}>
                  <Typography variant="h4" color="textPrimary">
                    Bio
                  </Typography>
                  <Separator className="mt-4 mb-4" style={classes.separator} />
                  <Typography
                    variant="caption"
                    color="textSecondary"
                    className="mb-3"
                    style={{ wordBreak: "break-word" }}
                  >
                    {userDetails.bio
                      ? userDetails.bio
                      : "Please add a bio in your profile settings."}
                  </Typography>
                </div>
              </Grid>
              <Grid item md={12} xs={12} lg={6}>
                <div css={classes.talentNextWebinar}>
                  <Typography variant="h4" color="textPrimary">
                    {events[0] ? "Next Hangout" : "No upcoming hangouts"}
                  </Typography>
                  {events[0] && (
                    <div
                      css={classes.webinarStarts}
                      className="d-flex align-items-center"
                    >
                      <ColoredClock />
                      <Typography
                        variant="caption"
                        color="textPrimary"
                        className="ml-2 mr-2"
                      >
                        {checkWebinarStarting(events[0].startDate)}
                      </Typography>
                      <ArrowButton
                        onClick={() =>
                          history.push(`/webinar/details/${events[0].slug}`)
                        }
                      />
                    </div>
                  )}
                  <Separator className="mt-4 mb-4" style={classes.separator} />
                  <div css={classes.webinar} className="mt-5">
                    {events[0] && (
                      <div className="d-flex justify-content-between mb-3">
                        <Typography variant="caption" color="textPrimary">
                          Webinar
                        </Typography>
                        <Typography variant="caption" color="textSecondary">
                          {format(
                            new Date(events[0].startDate),
                            "dd MMMM, yyyy"
                          )}
                        </Typography>
                      </div>
                    )}
                    <Typography
                      variant="subtitle2"
                      color="textPrimary"
                      className="mb-3"
                    >
                      {events[0] ? (
                        events[0].name
                      ) : (
                        <span onClick={() => history.push("/profile/schedule")}>
                          Click here to create your first hangout 🎉
                        </span>
                      )}
                    </Typography>
                    {events[0] && (
                      <div className="d-flex" css={classes.webinarTime}>
                        <ClockColored fill="#FFB3AB" />
                        <Typography
                          variant="h4"
                          color="textPrimary"
                          className="ml-2"
                        >
                          {format(new Date(events[0].startDate), "h:mm a")}
                        </Typography>
                      </div>
                    )}
                  </div>
                </div>
              </Grid>
              <Hidden mdDown>
                <Grid item md={12} xs={12}>
                  <div css={classes.webinarsListWrapper}>
                    <div className="d-flex align-items-center" id="upcoming">
                      <Tab
                        title={
                          matchesDownMd
                            ? "Upcoming hangouts"
                            : "Upcoming Binge Hangouts"
                        }
                        onClick={() => setActiveTab("webinar")}
                        active={activeTab === "webinar"}
                      />
                      <Tab
                        title="Past Hangouts"
                        onClick={() => setActiveTab("recorded")}
                        active={activeTab === "recorded"}
                      />
                      {/* <Tab title="Reviews" onClick={() => setActiveTab('reviews')} active={activeTab === 'reviews'} /> */}
                    </div>
                    <Separator
                      className="mt-4 mb-5"
                      css={classes.webinarsSeparator}
                    />
                    {activeTab === "webinar" &&
                      (events && events.length ? (
                        <WebinarsRowList items={events} />
                      ) : (
                        <Typography variant="subtitle2" className="mt-5">
                          Nothing here yet. Check back soon for some awesome
                          content ✨
                        </Typography>
                      ))}

                    {activeTab === "recorded" && (
                      <div css={classes.recorderdContent}>
                        {historyEvents ? (
                          <WebinarsRowList items={historyEvents} />
                        ) : (
                          <Typography variant="subtitle2" className="mt-5">
                            Nothing here yet. Check back soon for some awesome
                            content ✨
                          </Typography>
                        )}
                      </div>
                    )}
                  </div>
                </Grid>
              </Hidden>
            </Grid>
          </Grid>
          <Hidden lgUp>
            <Grid item md={12} xs={12}>
              <div css={classes.webinarsListWrapper}>
                <div className="d-flex align-items-center" id="upcoming">
                  <Tab
                    title={
                      matchesDownMd
                        ? "Upcoming Hangouts"
                        : "Upcoming Binge Hangouts"
                    }
                    onClick={() => setActiveTab("webinar")}
                    active={activeTab === "webinar"}
                  />
                  <Tab
                    title="Past Hangouts"
                    onClick={() => setActiveTab("recorded")}
                    active={activeTab === "recorded"}
                  />
                  {/* <Tab title="Reviews" onClick={() => setActiveTab('reviews')} active={activeTab === 'reviews'} /> */}
                </div>
                <Separator
                  className="mt-4 mb-5"
                  css={classes.webinarsSeparator}
                />
                {activeTab === "webinar" &&
                  (events && events.length ? (
                    <WebinarsRowList items={events} />
                  ) : (
                    <Typography variant="subtitle2" className="mt-5">
                      Nothing here yet. Check back soon for some awesome content
                      ✨
                    </Typography>
                  ))}

                {activeTab === "recorded" && (
                  <div css={classes.recorderdContent}>
                    {historyEvents ? (
                      <WebinarsRowList items={historyEvents} />
                    ) : (
                      <Typography variant="subtitle2" className="mt-5">
                        Nothing here yet. Check back soon for some awesome
                        content ✨
                      </Typography>
                    )}
                  </div>
                )}
              </div>
            </Grid>
          </Hidden>
        </Grid>
      </Container>
    </Layout>
  );
}
export default Profile;
