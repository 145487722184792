/** @jsx jsx */
import { jsx } from "@emotion/core";
import useClasses from "./styles";
import BannerCard from "../BannerCard";

const BannersList = ({ title, items }) => {
  const classes = useClasses();
  return (
    <div css={classes.bannersListWrapper}>
      <h1 css={classes.bannersHeading}>{title}</h1>
      <div css={classes.bannersList} className="mt-3 d-flex">
        {items.map((item) => (
          <BannerCard key={item.id} {...item} />
        ))}
      </div>
    </div>
  );
};

export default BannersList;
