/** @jsx jsx */
import { jsx } from "@emotion/core";
import styles from "../../WebinarDetails.module.css";
import CurrencyIcon from "../../../../assets/icons/Currency.svg";
import ClockIcon from "../../../../assets/icons/Clock.svg";
import SeatsIcon from "../../../../assets/icons/SeatsGrey.svg";
import Icon from "../../../../components/Icon/Icon";
import Typography from "@material-ui/core/Typography";
import format from "date-fns/format";
import clsx from "clsx";

const WebinarColumnInfo = ({
  price,
  startDate,
  endDate,
  seatsAmount,
  type,
  participantsCount,
}) => {
  return (
    <div className={styles.webinarInfo}>
      <div className={clsx("mb-4", styles.webinarDetails)}>
        <ItemDetails
          icon={CurrencyIcon}
          imgWidth="12"
          className="mr-4 mb-3"
          name={`$${
            price + price * (+process.env.REACT_APP_PLATFORM_COMISSION / 100)
          }`}
          subtitle={price === 0 ? "Free" : "Regular price"}
        />
        <ItemDetails
          icon={ClockIcon}
          imgWidth="20"
          className="mr-4 mb-3"
          name={format(new Date(startDate), "dd MMMM, yyyy")}
          subtitle={`From ${format(new Date(startDate), "h:mm a")} to ${format(
            new Date(endDate),
            "h:mm a"
          )}`}
        />
        {type === "Webinar" && (
          <ItemDetails
            icon={SeatsIcon}
            imgWidth="22"
            name={seatsAmount - participantsCount}
            subtitle="Seats available"
          />
        )}
      </div>
    </div>
  );
};

export const ItemDetails = ({ icon, name, subtitle, className, imgWidth }) => {
  return (
    <div className={clsx("d-flex", className)}>
      <Icon
        src={icon}
        backgroundColor={"rgb(255 255 255 / 0.2)"}
        imgWidth={imgWidth}
      />
      <div style={{ marginLeft: "0.625rem" }}>
        <Typography
          variant="subtitle2"
          style={{ lineHeight: 1.3, marginTop: 2 }}
        >
          {name}
        </Typography>
        <Typography variant="caption" color="textSecondary">
          {subtitle}
        </Typography>
      </div>
    </div>
  );
};

export default WebinarColumnInfo;
