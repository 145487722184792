/** @jsx jsx */
import { jsx } from "@emotion/core";
import React, { useState } from "react";
import useClasses from "./styles";
import Typography from "@material-ui/core/Typography";
import StarIcon from "@material-ui/icons/Star";
import Status from "../../../../components/Status/Status";
import ReactPlayer from "react-player";
import { isUserActive } from "../../../../utils/time";
import { useHistory } from "react-router";
import { isMobile } from "../../../../utils/mobile";
import CircularProgress from "@material-ui/core/CircularProgress";

const Coach = (event) => {
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const [isBuffering, setIsBuffering] = useState(true);
  const classes = useClasses();
  const history = useHistory();
  const goToCoach = () => {
    history.push(`/talent/${event.slug}`);
  };

  console.log("video intro", event.displayName, event.videoIntro);

  return (
    <div
      css={classes.coach}
      onClick={() => {
        goToCoach();
      }}
      onMouseEnter={() => !isMobile() && setIsVideoPlaying(true)}
      onMouseLeave={() => !isMobile() && setIsVideoPlaying(false)}
    >
      <div css={classes.coachVideoContainer}>
        {!event.videoIntro ? (
          <img
            css={classes.coachImg}
            src={require("../../../../assets/images/placeholder.webp")}
            alt="coach"
          />
        ) : (
          <div>
            <img src={event.videoIntroThumb} css={classes.coachImg} />
            {isVideoPlaying && (
              <div css={classes.coachVideo}>
                <ReactPlayer
                  playsinline
                  url={event.videoIntro}
                  loop
                  muted={!global.dontMute}
                  playing={true}
                  onReady={() => setIsBuffering(false)}
                  onPlay={() => setIsBuffering(false)}
                  onBuffer={() => setIsBuffering(true)}
                  width="100%"
                  height="100%"
                />

                {isBuffering && (
                  <div css={classes.coachProgress}>
                    <CircularProgress color="white" />
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </div>
      <div css={classes.coachInfo}>
        <div css={classes.coachRating} className="d-flex align-items-center">
          <StarIcon style={{ fontSize: 30, color: "#FFAA27" }} />
          <Typography
            variant="h3"
            color="textPrimary"
            className="font-weight-bold ml-1"
          >
            {event.averageRating}
          </Typography>
        </div>
        <h3>{event.displayName}</h3>
        {isUserActive(event.timestamp) ? (
          <Status styles={classes.coachStatus}>Online now</Status>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default Coach;
