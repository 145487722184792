import { createSlice } from "@reduxjs/toolkit";
import Service from "./service";

export const slice = createSlice({
  name: "discover",
  initialState: {
    discoverState: {
      tags: [],
      loading: false,
      error: null,
    },
  },
  reducers: {
    setDiscoverTagsLoading: (state, action) => {
      state.discoverState = {
        ...state.discoverState,
        loading: true,
      };
    },
    setDiscoverTags: (state, action) => {
      state.discoverState = {
        ...state.discoverState,
        tags: action.payload,
        loading: false,
      };
    },
  },
});

export const { setDiscoverTags, setDiscoverTagsLoading } = slice.actions;

export const getTagsWithEvents = (filter) => async (dispatch) => {
  dispatch(setDiscoverTagsLoading(true));
  try {
    const tags = await Service.getTags(filter);
    dispatch(setDiscoverTags(tags));
  } catch (e) {
    console.log(e);
    dispatch(setDiscoverTags([]));
  }
};

export const selectDiscoverTags = (state) => state.discover.discoverState.tags;
export const selectDiscoverTagsLoading = (state) =>
  state.discover.discoverState.loading;

export default slice.reducer;
