import firebase from "firebase";

const db = firebase.database();
let userInMeetingRoomRef = null;

export const changeUsersAudioState = (channel, userId, muted) => {
  if (!channel || !userId) {
    return;
  }
  userInMeetingRoomRef = db.ref(`webinars/${channel}/users/${userId}`);
  userInMeetingRoomRef
    .update({
      muted: muted,
    })
    .then((res) => {
      console.log(`Successfully changed muted to ${muted}`);
    })
    .catch(console.log);
};

export const removeUserFromMeeting = (channel, userId) => {
  if (!channel || !userId) {
    return;
  }
  userInMeetingRoomRef = db.ref(`webinars/${channel}/users/${userId}`);
  userInMeetingRoomRef
    .remove()
    .then((res) => {
      console.log(`Successfully removed from meeting`);
    })
    .catch(console.log);
};

export const rainseHandRequest = (channel, userId, isRaiseHandRequested) => {
  if (!channel || !userId) {
    return;
  }
  userInMeetingRoomRef = db.ref(`webinars/${channel}/users/${userId}`);
  userInMeetingRoomRef
    .update({
      isRaiseHandRequested: isRaiseHandRequested,
    })
    .then((res) => {
      console.log(`Successfully rainse hand requested by user ${userId}`);
    })
    .catch(console.log);
};

export const onUserConnectedToWebinar = (channel, user) => {
  if (!channel || !user) {
    return;
  }
  userInMeetingRoomRef = db.ref(`webinars/${channel}/users/${user.uid}`);
  const onceSubscription = userInMeetingRoomRef.once("value", (snapshot) => {
    userInMeetingRoomRef.set({
      id: user.uid,
      muted: false,
      userAvatar: user?.photoURL,
      userName: user?.displayName,
      isRaiseHandRequested: false,
    });
  });
};

export const onUserLeaveWebinar = (channel, user) => {
  if (!channel || !user) {
    return;
  }
  userInMeetingRoomRef = db.ref(`webinars/${channel}/users/${user.uid}`);
  return userInMeetingRoomRef.remove();
};
