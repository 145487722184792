import { createSlice } from "@reduxjs/toolkit";
import Service from "./service";

export const slice = createSlice({
  name: "booking",
  initialState: {
    availability: {
      availabilitySlots: [],
      loading: false,
      error: null,
    },
  },
  reducers: {
    setAvailabilityLoading: (state, action) => {
      state.availability = {
        ...state.availability,
        error: null,
        availabilitySlots: [],
        loading: true,
      };
    },
    setAvailabilitySlots: (state, action) => {
      state.availability = {
        ...state.availability,
        availabilitySlots: action.payload,
        loading: false,
      };
    },
    setAvailabilityError: (state, action) => {
      state.availability = {
        ...state.availability,
        availabilitySlots: [],
        loading: false,
        action,
        error: action.payload,
      };
    },
  },
});

export const {
  setAvailabilitySlots,
  setAvailabilityLoading,
  setAvailabilityError,
} = slice.actions;

export const getAvailability = (day, uid) => async (dispatch) => {
  dispatch(setAvailabilityLoading(true));
  try {
    const slots = await Service.getAvailability(day, uid);
    dispatch(setAvailabilitySlots(slots));
  } catch (e) {
    let error = e?.response?.data?.error
      ? e.response.data.error
      : "Cannot fetch user availability";
    dispatch(setAvailabilityError(error));
  }
};

export const selectAvailabilitySlots = (state) =>
  state.booking.availability.availabilitySlots;
export const selectAvailabilityLoading = (state) =>
  state.booking.availability.loading;
export const selectAvailabilityError = (state) =>
  state.booking.availability.error;

export default slice.reducer;
