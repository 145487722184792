import React from "react";
import { Button, withStyles } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
  },
  wrapper: {
    position: "relative",
    width: "100%",
  },
  buttonProgress: {
    color: theme.palette.primary.main,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

const SecondaryButtonWithStyles = withStyles({
  root: {
    border: "2px solid rgb(30, 31, 32)",
    borderRadius: 12,
    width: "100%",
    color: "white",
    fontSize: 14,
    fontWeight: 600,
    height: 56,
  },
  label: {
    textTransform: "none",
    color: "rgb(30, 31, 32)",
    fontWeight: "bold",
  },
})(Button);

const SecondaryButton = ({ loading, ...otherProps }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <SecondaryButtonWithStyles {...otherProps} />
        {loading && (
          <CircularProgress size={24} className={classes.buttonProgress} />
        )}
      </div>
    </div>
  );
};

export default SecondaryButton;
