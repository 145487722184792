import * as firebase from "firebase";
import "firebase/auth";

const isProduction =
  process.env.NODE_ENV === "production" || process.env.PRODUCTION || true;

const prodConfig = {
  apiKey: "AIzaSyDRe-dS8wLsmpAdea7RMGFFbF35NWCNAIU",
  authDomain: "butter-n-binge.firebaseapp.com",
  databaseURL: "https://butter-n-binge.firebaseio.com",
  projectId: "butter-n-binge",
  storageBucket: "butter-n-binge.appspot.com",
  messagingSenderId: "495543654041",
  appId: "1:495543654041:web:d0948a2d09f3077fdb8a08",
};

const devConfig = {
  apiKey: "AIzaSyDRe-dS8wLsmpAdea7RMGFFbF35NWCNAIU",
  authDomain: "butter-n-binge.firebaseapp.com",
  databaseURL: "https://butter-n-binge.firebaseio.com",
  projectId: "butter-n-binge",
  storageBucket: "butter-n-binge.appspot.com",
  messagingSenderId: "495543654041",
  appId: "1:495543654041:web:d0948a2d09f3077fdb8a08",
};

export const Firebase = !firebase.apps.length
  ? firebase.initializeApp(isProduction ? prodConfig : devConfig)
  : firebase;
export const FirebaseAuth = Firebase.auth();
export const FirebaseStorage = Firebase.storage();
