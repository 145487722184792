/** @jsx jsx */
import { jsx } from "@emotion/core";
import { useClasses } from "./styles";
import clsx from "clsx";
import coloredClock from "../../assets/icons/coloredClock.svg";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import PrimaryButton from "../../components/material/Button/PrimaryButton";

const BookNow = ({
  className,
  onClick,
  loading,
  disabled,
  startDate,
  checkWebinarStarting,
}) => {
  const classes = useClasses();
  return (
    <div
      css={classes.bookNowWrapper}
      className={clsx(
        "d-flex align-items-center justify-content-between",
        className
      )}
    >
      <div className="d-flex align-items-center">
        <img src={coloredClock} className="mr-2" />
        <Typography
          variant="caption"
          color="textPrimary"
          className="ml-1 mr-3"
          style={{ minWidth: "max-content" }}
        >
          {checkWebinarStarting(startDate)}
        </Typography>
      </div>
      <PrimaryButton
        color="secondary"
        variant="contained"
        onClick={onClick}
        loading={loading}
        disabled={disabled}
      >
        Book Now
      </PrimaryButton>
    </div>
  );
};

export default BookNow;
