import { css } from "@emotion/core";

const useClasses = () => ({
  bookNowWrapper: css`
    background: #1d0d3e;
    padding: 15px;
    border-radius: 15px;
    max-width: max-content;
    height: 80px;
    button {
      width: 160px;
    }
  `,
});

export { useClasses };
